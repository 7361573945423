import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  getConnectProfileUrl,
  watchExternalProfilesAndCalendars,
} from "../../services/ExternalEventsService";

import { AuthContext } from "../AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import Heading, { HEADING_LEVEL_5 } from "../Heading";
import Button, { BUTTON_SIZE_SMALL, BUTTON_SIZE_XSMALL } from "../Button";
import Dialog from "../Dialog";
import ExternalCalendarSync from "./ExternalCalendarSync";
import ExternalProfilesCalendars from "./ExternalProfilesCalendars";

import { ReactComponent as IconAdd } from "../../assets/icons/16-add.svg";

import styles from "./ProfileSidebar.module.scss";

function ProfileSidebarCalendars() {
  const {
    user: { isCronofyLegacyUser },
  } = useContext(AuthContext);

  const [externalProfiles, setExternalProfiles] = useState();
  useEffect(() => watchExternalProfilesAndCalendars(setExternalProfiles), []);

  const [showCalendarPermissionsDialog, setShowCalendarPermissionsDialog] =
    useState(false);
  const [showCalendarSyncDialog, setShowCalendarSyncDialog] = useState(false);
  const [hasCalendarSyncExpire, setHasCalendarSyncExpire] = useState(false);

  const handleExternalProfileClick = async () => {
    const width = 600;
    const height = 820;
    const left = window.outerWidth / 2 + window.screenX - width / 2;
    const top = window.outerHeight / 2 + window.screenY - height / 2;
    // I open the popup first and then I enter the url because:
    // - The popup must be opened contextually to the user's click, so it must
    //   happen before any asyncronous code, otherwise browsers may block it as
    //   "unwanted popup".
    // - The connection url must be requested when the user clicks the button
    //   because it contains a short lived access token, and the function
    //   call ensures that token is fresh and valid.
    const popup = window.open(
      "about:blank",
      "RPMconnectCalendar",
      `scrollbars=1,width=${width},height=${height},left=${left},top=${top}`
    );
    popup.location.href = await getConnectProfileUrl();
  };

  const handleSyncOptionsClick = useCallback(async () => {
    if (hasCalendarSyncExpire) return;

    if (isCronofyLegacyUser) {
      setShowCalendarPermissionsDialog(true);
    } else {
      setShowCalendarSyncDialog(true);
    }
  }, [isCronofyLegacyUser, hasCalendarSyncExpire]);

  const handleGrantAccessClick = useCallback(() => {
    setShowCalendarPermissionsDialog(false);
    handleExternalProfileClick();
  }, []);

  return (
    <>
      <div className={styles.calendarsHeading}>
        <Heading tag="span" level={HEADING_LEVEL_5}>
          Calendars
        </Heading>

        <div className={styles.buttonsAlignment}>
          {externalProfiles && !!externalProfiles.length && (
            <Button
              type="button"
              size={BUTTON_SIZE_SMALL}
              onClick={handleSyncOptionsClick}
              disabled={hasCalendarSyncExpire}
              tooltip
            >
              Sync Options
            </Button>
          )}
          <Button
            aria-label="Connect external calendar"
            tooltip
            size={BUTTON_SIZE_XSMALL}
            onClick={handleExternalProfileClick}
            iconOnly
          >
            <IconAdd role="presentation" />
          </Button>
        </div>
      </div>

      {!externalProfiles ? (
        <LoadingSpinner />
      ) : (
        <ExternalProfilesCalendars
          externalProfiles={externalProfiles}
          hasCalendarSyncExpire={hasCalendarSyncExpire}
        />
      )}

      <ExternalCalendarSync
        externalProfiles={externalProfiles}
        showCalendarSyncDialog={showCalendarSyncDialog}
        onClose={() => setShowCalendarSyncDialog(false)}
        setCalendarSyncExpire={setHasCalendarSyncExpire}
      />

      {showCalendarPermissionsDialog && (
        <Dialog
          headerTitle="Extended calendar permissions"
          footer={
            <>
              <Button
                block
                onClick={() => setShowCalendarPermissionsDialog(false)}
              >
                Cancel
              </Button>
              <Button block negative onClick={handleGrantAccessClick}>
                Grant access
              </Button>
            </>
          }
        >
          <p>
            You have to grant calendar access permissions to activate the
            syncing for all Actions and Blocks.
          </p>
        </Dialog>
      )}
    </>
  );
}

export default ProfileSidebarCalendars;
