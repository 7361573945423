import React from 'react';

import Button from '../Button';
import Dialog from '../Dialog';

const UnsavedChangesDialog = ({ onDiscard, onClose }) => {
  return (
    <Dialog
      headerTitle='Unsaved Changes'
      footer={
        <>
          <Button block onClick={onClose}>
            Cancel
          </Button>
          <Button block negative onClick={onDiscard}>
            Close without saving
          </Button>
        </>
      }
    >
      <p>You have made changes, but have not saved&nbsp;them.</p>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
