import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useContext,
} from "react";

export const ToastContext = createContext();

export function ToastContextProvider({ children }) {
  const [visibleToastArray, setVisibleToastArray] = useState([]);
  const [toastMap, setToastMap] = useState({});

  const setToastProps = useCallback((id, props) => {
    setToastMap((curMap) => {
      if (props) {
        curMap[id] = props;
      } else {
        delete curMap[id];
      }

      return curMap;
    });
  }, []);

  const registerToastOpen = useCallback((id) => {
    if (!id) return;

    setVisibleToastArray((curArray) => {
      if (curArray.includes(id)) return curArray;

      return [...curArray, id];
    });
  }, []);

  const registerToastClosed = useCallback((id) => {
    if (!id) return;

    setVisibleToastArray((curArray) => {
      if (!curArray.includes(id)) return curArray;

      return curArray.filter((itemId) => itemId !== id);
    });
  }, []);

  const value = useMemo(
    () => ({
      visibleToastArray,
      toastMap,
      registerToastOpen,
      registerToastClosed,
      setToastProps,
    }),
    [
      toastMap,
      visibleToastArray,
      registerToastOpen,
      registerToastClosed,
      setToastProps,
    ]
  );

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

export const useGlobalToast = () => {
  const { visibleToastArray, toastMap } = useContext(ToastContext);

  const toast = useMemo(() => {
    if (!visibleToastArray.length) return null;

    const id = visibleToastArray[visibleToastArray.length - 1];

    return {
      id,
      ...toastMap[id],
    };
  }, [visibleToastArray, toastMap]);

  return toast;
};
