import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import clsx from "clsx";

import useColorScheme, {
  COLOR_SCHEME_AUTO,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_DARK,
} from "../../hooks/useColorScheme";
import useUniqueId from "../../hooks/useUniqueId";

import Avatar from "../Avatar";
import Button from "../Button";
import Sidebar, { SidebarHeader, SidebarFooter } from "../Sidebar";
import Heading, { HEADING_LEVEL_2, HEADING_LEVEL_5 } from "../Heading";
import Dialog from "src/components/Dialog";
import Divider from "../Divider";
import Input, { InputLabel, INPUT_SIZE_SMALL } from "../Input";
import ProfileSidebarCalendars from "./ProfileSidebarCalendars";
import {
  NotificationsContext,
  getNotificationPreferences,
} from "../NotificationsContext";
import { AuthContext } from "../AuthContext";
import { GlobalToast } from "../Toast";

import { ReactComponent as IconExit } from "../../assets/icons/16-exit.svg";

import {
  signOut,
  getUser,
  setUser,
  AlreadyRegisteredUsernameError,
  InvalidParameterError,
} from "../../services/AuthService";

import styles from "./ProfileSidebar.module.scss";

function getPatch(newValues, initialValues) {
  return Object.keys(newValues).reduce((patch, slug) => {
    if (newValues[slug] === initialValues[slug]) return patch;

    return {
      ...patch,
      [slug]: newValues[slug],
    };
  }, null);
}

function ProfileSidebar({ ...props }) {
  const [colorScheme, setColorScheme] = useColorScheme();

  const editButtonRef = useRef();

  const errorToastId = useUniqueId();

  const { notifications, setNotifications } = useContext(NotificationsContext);
  const { user } = useContext(AuthContext);

  const { username, email = "", photoURL = "", displayName } = user || {};

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({});
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);

  // Update the user information when a user accesses the sidebar to be sure it's up to date
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (editing) return;
    // Set focus back to edit when editing closes
    editButtonRef.current && editButtonRef.current.focus();
  }, [editing]);

  // Update the user info before opening the form to be sure it's up to date
  const handleEditProfile = useCallback(async () => {
    const newUser = await getUser();
    setValues({
      firstName: newUser.firstName || "",
      lastName: newUser.lastName || "",
      username: newUser.username || "",
      photoURL: newUser.photoURL || "",
    });
    setEditing(true);
  }, []);

  const handleChange = useCallback((e, slug) => {
    let { value } = e.target;

    if (slug === "username") {
      value = value.replace(/\s/g, "");
    }

    setValues((oldValues) => ({
      ...oldValues,
      // Normalises all values to strings because photoURL can't be null
      [slug]: value || "",
    }));
  }, []);

  const handleNotificationToggle = useCallback(() => {
    // Rather than rely on the state (from the context) it is important that checks are made
    // against localstorage and Notification.permission to be sure that the user hasn't changed
    // a setting elsewhere that is undetectable (without continually polling a setInterval) that
    // would affect the assumed state of the toggle.
    const currentState = getNotificationPreferences();

    if (currentState === "default") {
      Notification.requestPermission((permission) => {
        setNotifications(permission);
      });
    } else {
      // There is no way to set Notification.permission (nor would it be desirable) for when a user
      // wants to opt out of notifications so in the NotificationsContext the users' preference is
      // stored as default (which translates to off in the UI) so that we can dutifully not send
      // them notifications until/if they turn the notifications back on – at this point the user is
      // treated as having no preference, but the browser treats it as granted so the local state
      // is updated, notifications are reenabled on the frontend and the user is shown no extra popup.
      setNotifications("default");
    }
  }, [setNotifications]);

  const errorMessage = error || (!values.username && "Username is required");

  const handleCancelConfirm = useCallback(() => {
    setShowUnsavedChangesDialog(false);
    setEditing(false);
  }, []);

  const handleCancel = useCallback(() => {
    if (getPatch(values, user) === null) {
      handleCancelConfirm();
    } else {
      setShowUnsavedChangesDialog(true);
    }
  }, [values, user, handleCancelConfirm]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (loading || errorMessage) return;

      setLoading(true);

      const patch = getPatch(values, user);

      if (patch === null) {
        // There are no changes, so just switch out of editing mode
        setEditing(false);
        setLoading(false);

        return;
      }

      try {
        await setUser(patch);
      } catch (e) {
        if (e instanceof AlreadyRegisteredUsernameError) {
          setError("Username is taken");
        } else if (e instanceof InvalidParameterError) {
          setError("Invalid username");
        } else {
          setError("Something went wrong, please try again");
          console.error(e);
        }

        setLoading(false);
        return;
      }

      setLoading(false);
      setEditing(false);
    },
    [loading, errorMessage, values, user]
  );

  const header = (
    <SidebarHeader className={styles.header}>
      <Avatar className={styles.avatar} src={photoURL} name={displayName} />
      <div className={styles.name}>
        <Heading tag="span" level={HEADING_LEVEL_2}>
          {displayName}
        </Heading>
      </div>
    </SidebarHeader>
  );

  const footer = (
    <SidebarFooter>
      <div className={styles.buttons}>
        <Button ref={editButtonRef} onClick={handleEditProfile}>
          Edit Profile
        </Button>
        <Button className={styles.signOut} negative onClick={signOut}>
          <IconExit role="presentation" /> Sign Out
        </Button>
      </div>
    </SidebarFooter>
  );

  return (
    <Sidebar header={!editing && header} footer={!editing && footer} {...props}>
      {!editing && (
        <>
          <dl>
            <div className={styles.detail}>
              <InputLabel tag="dt" size={INPUT_SIZE_SMALL}>
                Email
              </InputLabel>
              <dd>{email.replace("@", "\u200B@")}</dd>
            </div>

            <div className={styles.detail}>
              <InputLabel tag="dt" size={INPUT_SIZE_SMALL}>
                Username
              </InputLabel>
              <dd>{username}</dd>
            </div>
          </dl>

          <Divider className={styles.divider} />

          <Heading
            className={styles.subheading}
            tag="span"
            level={HEADING_LEVEL_5}
          >
            Preferences
          </Heading>

          {notifications !== "unsupported" && (
            <>
              <button
                className={styles.notifications}
                aria-label={
                  notifications === "granted"
                    ? "Disable notifications"
                    : "Enable notifications"
                }
                aria-checked={notifications === "granted"}
                role="switch"
                onClick={handleNotificationToggle}
                disabled={notifications === "denied"}
              >
                <InputLabel
                  tag="span"
                  size={INPUT_SIZE_SMALL}
                  className={styles.notificationsLabel}
                  aria-hidden="true"
                >
                  Notifications
                </InputLabel>
                <span
                  className={clsx(
                    styles.toggle,
                    notifications === "granted" && styles.active
                  )}
                  aria-hidden="true"
                />
              </button>

              {notifications === "denied" && (
                <p className={styles.denied}>
                  You have disabled notifications. To enable them, update the
                  notification settings in your browser and restart
                  the&nbsp;app.
                </p>
              )}
            </>
          )}

          <Input
            tag="select"
            className={styles.colorPreference}
            value={colorScheme}
            onChange={(e) => setColorScheme(e.target.value)}
            label="Color Mode"
            inlineLabel
            size={INPUT_SIZE_SMALL}
          >
            <option value={COLOR_SCHEME_AUTO}>Auto</option>
            <option value={COLOR_SCHEME_LIGHT}>Light</option>
            <option value={COLOR_SCHEME_DARK}>Dark</option>
          </Input>

          <Divider className={styles.divider} />

          <ProfileSidebarCalendars />
        </>
      )}

      {editing && (
        <form onSubmit={handleSubmit} noValidate>
          <Input
            label="First Name"
            value={values.firstName}
            onInput={() => setError("")}
            onChange={(e) => handleChange(e, "firstName")}
            aria-errormessage={errorMessage ? errorToastId : null}
            autoFocus
          />

          <Input
            label="Last Name"
            value={values.lastName}
            onInput={() => setError("")}
            onChange={(e) => handleChange(e, "lastName")}
          />

          <Input label="Email" value={email} readonly />

          <Input
            label="Username"
            value={values.username}
            onInput={() => setError("")}
            // Removes spaces as per the sign up flow
            onChange={(e) => handleChange(e, "username")}
          />

          <div className={styles.buttons}>
            <Button type="submit" loading={loading} disabled={errorMessage}>
              Save
            </Button>
            <Button type="button" negative onClick={handleCancel}>
              Cancel
            </Button>
          </div>

          {showUnsavedChangesDialog && (
            <Dialog
              headerTitle="Unsaved changes"
              footer={
                <>
                  <Button
                    block
                    onClick={() => setShowUnsavedChangesDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button block negative onClick={handleCancelConfirm}>
                    Close without saving
                  </Button>
                </>
              }
            >
              <p>You have made changes, but have not saved&nbsp;them.</p>
            </Dialog>
          )}

          {errorMessage && (
            <GlobalToast id={errorToastId} error>
              {errorMessage}
            </GlobalToast>
          )}
        </form>
      )}
    </Sidebar>
  );
}

export default ProfileSidebar;
