import { useEffect } from "react";

// Taken from https://medium.com/@im_rahul/focus-trapping-looping-b3ee658e5177, but should be
// amended if other focusable elements are detected, but not caught within this list
const FOCUSABLE_ELEMENT_SELECTORS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

const TRAPPABLE_TAB_KEY = "Tab";
const TRAPPABLE_ARROW_KEYS = [
  "ArrowUp",
  "ArrowDown",
  "PageUp",
  "PageDown",
  "Home",
  "End",
];

export const TRAPPABLE_KEYS = [TRAPPABLE_TAB_KEY, ...TRAPPABLE_ARROW_KEYS];

const FOCUS_NEXT = "next";
const FOCUS_PREV = "prev";

function getFocusableElements(element) {
  if (!element) return [];

  return [...element.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)];
}

function focusPrevOrNextEl(currentEl, focusableEls, prevNext) {
  var currentElIndex = focusableEls.indexOf(currentEl);

  let elToFocus;

  if (currentElIndex > -1) {
    if (prevNext === FOCUS_PREV) {
      elToFocus = focusableEls[currentElIndex - 1];
    } else if (prevNext === FOCUS_NEXT) {
      elToFocus = focusableEls[currentElIndex + 1];
    }
  }

  // This accounts for both the item being outside of the bounds and also the current element
  // not having an index
  if (!elToFocus && prevNext === FOCUS_PREV) {
    elToFocus = focusableEls[focusableEls.length - 1];
  } else if (!elToFocus && prevNext === FOCUS_NEXT) {
    elToFocus = focusableEls[0];
  }

  elToFocus && elToFocus.focus();
}

// Handles focus transfer for the first and last items when the tab key is pressed
export default function useFocusTrap(wrapperRef, args = {}) {
  const {
    buttonRef,
    onClose,
    transferFocusWithArrowKeys = false,
    isTopmostDialog,
  } = args;

  useEffect(() => {
    function handleKeyDown(e) {
      // This is needed to ensure that the active element is only handled within the relevant
      // trap, and ensures that `Escape` only closes the topmost dialog-like component
      if (!isTopmostDialog) return;

      const { key, shiftKey } = e;

      if (key === "Escape") {
        e.preventDefault();
        onClose && onClose();
      }

      if (transferFocusWithArrowKeys && key === TRAPPABLE_TAB_KEY) {
        e.preventDefault();
        onClose && onClose();

        const prevNext = e.shiftKey ? FOCUS_PREV : FOCUS_NEXT;
        focusPrevOrNextEl(
          buttonRef?.current,
          getFocusableElements(document),
          prevNext
        );

        return;
      }
      if (!TRAPPABLE_KEYS.includes(key) || !wrapperRef.current) return;

      const isValidArrowKey =
        transferFocusWithArrowKeys && TRAPPABLE_ARROW_KEYS.includes(key);
      const isValidTabKey =
        !transferFocusWithArrowKeys && key === TRAPPABLE_TAB_KEY;

      if (!isValidArrowKey && !isValidTabKey) return;

      // Focusable elements is recalcualted on every tab click to ensure that any changes that might
      // have happened to the DOM are also caught here.
      const focusableEls = getFocusableElements(wrapperRef.current);

      // If there are no focusable elements, no focus transfer should be made
      if (focusableEls.length === 0) {
        e.preventDefault();
      }

      const { activeElement } = document;

      const firstFocusableEl = focusableEls[0];
      const firstIsFocused = activeElement === firstFocusableEl;

      // Ensures that even if there is only one that the focus is properly transfered to that item, and
      // that nothing happens if that item is already focused.
      if (focusableEls.length === 1) {
        !firstIsFocused && firstFocusableEl.focus();
        e.preventDefault();
      }

      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const lastIsFocused = activeElement === lastFocusableEl;

      let newFocusEl;

      // Cycle the focus through the elements by shifting the focus back to the start/end depending
      // on the following conditions.
      // If neither of these conditions are met the event is not prevented and the regular focus
      // transfer occurs.
      // This could be rewritten to be a series of compound conditions, but its most easily understood
      // as a series of if/else statements
      if (key === "PageDown" || key === "End") {
        newFocusEl = lastFocusableEl;
      } else if (key === "PageUp" || key === "Home") {
        newFocusEl = firstFocusableEl;
      } else if (key === "Tab" && shiftKey && firstIsFocused) {
        newFocusEl = lastFocusableEl;
      } else if (key === "Tab" && !shiftKey && lastIsFocused) {
        newFocusEl = firstFocusableEl;
      } else if (key === "ArrowUp" && firstIsFocused) {
        newFocusEl = lastFocusableEl;
      } else if (key === "ArrowDown" && lastIsFocused) {
        newFocusEl = firstFocusableEl;
      } else if (key === "ArrowDown" || key === "ArrowUp") {
        focusPrevOrNextEl(
          activeElement,
          focusableEls,
          key === "ArrowDown" ? FOCUS_NEXT : FOCUS_PREV
        );
      }

      if (newFocusEl) {
        e.preventDefault();
        newFocusEl.focus();
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    wrapperRef,
    buttonRef,
    transferFocusWithArrowKeys,
    onClose,
    isTopmostDialog,
  ]);
}
