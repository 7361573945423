import React, { useState, useCallback, useLayoutEffect } from "react";
import clsx from "clsx";
import { format } from "date-fns";

import { clamp } from "src/utils/clamp";

import { useGetCategoryColor } from "../../CategoriesContext";
import Dialog from "../../Dialog";
import DialogHeader from "../../Dialog/DialogHeader";

import styles from "./EventDialog.module.scss";

const DIALOG_WIDTH = 350;
const DIALOG_HEIGHT = 350;
const VIEWPORT_TOP_MARGIN = 60;
const VIEWPORT_MARGIN = 20;
const EVENT_MARGIN = 10;

export function getEventDialogCss(boxRef, _dialogHeight = DIALOG_HEIGHT) {
  const boxRect = boxRef.current.getBoundingClientRect();

  const viewportWidth = document.documentElement.clientWidth;
  const viewportHeight = document.documentElement.clientHeight;

  // This ensures that the dialog is never larger than the available space of the viewport
  // albeit you'd have to be viewing the site on an Apple Watch to get this small
  const dialogHeight = Math.min(
    _dialogHeight,
    viewportHeight - (VIEWPORT_MARGIN + VIEWPORT_TOP_MARGIN)
  );
  const dialogWidth = Math.min(
    DIALOG_WIDTH,
    viewportWidth - VIEWPORT_MARGIN * 2
  );

  let top = boxRect.top;
  let left = boxRect.right + EVENT_MARGIN;

  if (left + dialogWidth > viewportWidth - VIEWPORT_MARGIN) {
    left = boxRect.left - dialogWidth - EVENT_MARGIN;
  }

  top = clamp(
    VIEWPORT_TOP_MARGIN,
    top,
    viewportHeight - VIEWPORT_MARGIN - dialogHeight
  );
  left = clamp(
    VIEWPORT_MARGIN,
    left,
    viewportWidth - VIEWPORT_MARGIN - dialogWidth
  );

  return {
    top: `${Math.round(top)}rem`,
    left: `${Math.round(left)}rem`,
    position: "fixed",
    height: `${dialogHeight}rem`,
    width: `${dialogWidth}rem`,
  };
}

function EventDialog({
  children,
  className,
  boxRef,
  preBodyChildren,
  hidden,
  onClose,
  eventName,
  startDate,
  subtitle,
  headerButtons,
  categoryId,
  dialogHeight = styles.scheduledEventDialogHeight,
  style = {},
  ...props
}) {
  const getCategoryColor = useGetCategoryColor();

  const [dialogBodyScrolled, setDialogBodyScrolled] = useState(false);
  const [dialogCss, setDialogCss] = useState();

  useLayoutEffect(() => {
    if (!boxRef?.current) return;

    const css = getEventDialogCss(boxRef, dialogHeight);

    setDialogCss(css);
  }, [dialogHeight, boxRef]);

  // The dialog header is only needed to be open once, it's a one and done
  // animation that doesn't reopen
  const handleDialogScroll = useCallback(
    (e) => {
      if (dialogBodyScrolled) return;

      setDialogBodyScrolled(true);
    },
    [dialogBodyScrolled]
  );

  return (
    <Dialog
      onClose={onClose}
      customBody
      lightbox={false}
      style={{
        ...style,
        ...dialogCss,
      }}
      tabIndex={-1}
    >
      <DialogHeader
        className={clsx(categoryId && styles.dialogHeaderCategory)}
        reversed={categoryId}
        title={startDate && format(startDate, "PPp")}
        subtitle={eventName}
        onClose={onClose}
        buttons={headerButtons}
        data-category-color={getCategoryColor(categoryId)}
        collapsed={dialogBodyScrolled}
      />
      {preBodyChildren}
      <div className={styles.dialogBody} onScroll={handleDialogScroll}>
        {children}
      </div>
    </Dialog>
  );
}

export default EventDialog;
