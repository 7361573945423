import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import {
  areIntervalsOverlapping,
  addSeconds,
  addWeeks,
  differenceInMilliseconds,
  differenceInSeconds,
  endOfWeek,
  startOfWeek,
  subWeeks,
  parseISO,
  formatISO,
  isEqual,
} from "date-fns";

import { sleep } from "src/utils/sleep";

import { api } from "./ApiService";
import { document, composeUnsubscribers } from "./DbService/general";

const POLLING_INTERVAL = 15000;
export const WEEKS_AROUND = 3;
const WEEKS_NAVIGATION_DEBOUNCE_SECONDS = 3;
const ENABLED_CALENDARS_DEBOUNCE_SECONDS = 3;

let started = false;
let currentWeekStart = startOfWeek(new Date());
let unsubscribeUser = null;
let enabledCalendars = [];
let currentFetchPollId = -1;
let eventsCache = [];
// Map in the shape { weekHash: true/false }
let weeksLoading = {};
// Map in the shape { weekHash: Date }, where Date is the date of the last fetch
// for that week
let weeksLoaded = {};
let pollTimeout;
let enabledCalendarsTimeout;
let watchers = [];

export class ExternalEvent {
  static fromBackend(object) {
    const startDate = parseISO(object.start);

    return Object.assign(new ExternalEvent(), {
      id: object.id,
      description: object.summary,
      details: object.description,
      startDate,
      duration: differenceInSeconds(parseISO(object.end), startDate),
      creationDate: parseISO(object.created),
    });
  }
}

// This service needs a signed-in user to work, so it starts/stops when the
// Firestore user becomes available/unavailable
firebase.auth().onAuthStateChanged(async (firebaseUser) => {
  if (firebaseUser) {
    startService();
  } else {
    stopService();
  }
});

function startService() {
  // Utility function to make it easier to test equality of enabled calendars:
  // converts enabledCalendars to a string and returns it
  function getEnabledCalendarsHash() {
    return enabledCalendars.sort().join("\n");
  }

  // This handler gets called at startup and every time there's a change in the
  // user's preferences
  unsubscribeUser = document().onSnapshot((userSnapshot) => {
    const oldCalendarsHash = getEnabledCalendarsHash();

    enabledCalendars = [];
    const profiles = userSnapshot.get("external_profiles_and_calendars") || {};
    for (const profile of Object.values(profiles)) {
      for (const [calendarId, calendar] of Object.entries(profile.calendars)) {
        if (calendar.enabled) enabledCalendars.push(calendarId);
      }
    }

    const newCalendarsHash = getEnabledCalendarsHash();

    if (!started) {
      // Call fetchAndPollCurrentWeek() disabling debounce, as we don't want
      // any additional delay for the fetches we do at startup
      fetchAndPollCurrentWeek(false);
      started = true;
    } else if (newCalendarsHash !== oldCalendarsHash) {
      // The list of enabled calendars changed, invalidate the cache and fetch
      // updated data
      weeksLoaded = {};
      weeksLoading = {};
      // This handler gets triggered every time a single calendar is
      // enabled/disabled, better to add some debouncing
      clearTimeout(enabledCalendarsTimeout);
      enabledCalendarsTimeout = setTimeout(
        fetchAndPollCurrentWeek,
        ENABLED_CALENDARS_DEBOUNCE_SECONDS * 1000
      );
    }
  });
}

function stopService() {
  started = false;
  // resetting currentFetchPollId ends all the currently running instances of
  // fetchAndPollCurrentWeek()
  currentFetchPollId = -1;
  clearTimeout(pollTimeout);
  clearTimeout(enabledCalendarsTimeout);
  if (unsubscribeUser) {
    unsubscribeUser();
    unsubscribeUser = null;
  }
  // This function gets called when the user signs out, so better removing all
  // the cached events, or they may wrongly reappear if the user signs in with
  // a different account.
  enabledCalendars = [];
  eventsCache = [];
  weeksLoaded = {};
  weeksLoading = {};
}

export function watchExternalEvents(callback) {
  // Immediately give to the watcher whatever we have in cache
  callWatchers(callback);
  // Add it to the list to receive future updates
  watchers.push(callback);
  // Return a function that when called releases the watcher
  return () => {
    watchers = watchers.filter(
      (watcherCallback) => watcherCallback !== callback
    );
  };
}

function callWatchers(watcherCallback = null) {
  // We send all the events in the cache instead of just the current week, so
  // client modules (currently React) can do the filtering themselves and be
  // faster, with one roundtrip (in React: a render) less.

  // Ensure we send a brand new reference to the watchers, so doing signaling
  // to React that there are changes to check
  const events = [...eventsCache];

  if (watcherCallback) {
    watcherCallback(events);
  } else {
    for (const watcherCallback of watchers) {
      watcherCallback(events);
    }
  }
}

export function setCurrentWeek(weekStart) {
  // periodStart can be a start of a week or a start of a non-first day of a week
  if (isEqual(weekStart, currentWeekStart)) return;
  currentWeekStart = weekStart;
  if (!started) return;
  fetchAndPollCurrentWeek();
}

async function fetchAndPollCurrentWeek(debounce = true) {
  // This async function will be often called several times in a short amount
  // of time, for example when the user is quickly navigating through weeks.
  // When this happens, only the most recent call must survive, while the others
  // must stop as soon as they can, as they are no longer current. To achieve
  // this I generate a unique id for each function call, and store it in the
  // global state. The function periodically checks if its id is the current one,
  // and if not it returns.
  const fetchPollId = currentFetchPollId + 1;
  currentFetchPollId = fetchPollId;

  // Immediately stop any previous poller installed
  clearTimeout(pollTimeout);

  // Quickly fetch the current week
  await fetchIfNeeded(currentWeekStart, endOfWeek(currentWeekStart));

  // It has been considered to cancel the call above if the user quickly moves
  // to another week, but since these calls are quite fast it's better to
  // let them finish and save the results in the cache, so we have more
  // pre-fetched data.

  if (debounce) {
    // Add a debounce timeout to not trigger too many calls while the user is
    // quickly navigating through weeks
    await sleep(WEEKS_NAVIGATION_DEBOUNCE_SECONDS * 1000);
  }

  // Check if another watcher has been spawned while this function was "awaiting"
  if (currentFetchPollId !== fetchPollId) return;

  // Looks like the user decided to stop on the current week: prefetch some
  // weeks ahead/behind to improve UX
  const weeksBeforeStart = subWeeks(currentWeekStart, WEEKS_AROUND);
  const weeksAheadEnd = addWeeks(endOfWeek(currentWeekStart), WEEKS_AROUND);
  await fetchIfNeeded(weeksBeforeStart, weeksAheadEnd);

  if (currentFetchPollId !== fetchPollId) return;

  // The user is still on this week after the prefetch, so install a poller to
  // keep the data of the week updated
  async function poll() {
    // If another instance has been spawned, stop running this poller
    if (currentFetchPollId !== fetchPollId) return;

    await fetchIfNeeded(currentWeekStart, endOfWeek(currentWeekStart));

    pollTimeout = setTimeout(poll, POLLING_INTERVAL);
  }

  pollTimeout = setTimeout(poll, POLLING_INTERVAL);
}

async function fetchIfNeeded(weekStart, weekEnd) {
  // Some utility functions

  function weekHash(weekStart) {
    return weekStart.toDateString();
  }

  function canBeSkipped(weekStart) {
    const hash = weekHash(weekStart);
    return (
      weeksLoading[hash] ||
      differenceInMilliseconds(new Date(), weeksLoaded[hash]) < POLLING_INTERVAL
    );
  }

  // Calls callback for each week in the interval, passing the first day as param
  function loopWeeks(weekStart, weekEnd, callback) {
    while (weekStart < weekEnd) {
      callback(weekStart);
      weekStart = addWeeks(weekStart, 1);
    }
  }

  function setWeeksLoading(weekStart, weekEnd, loading) {
    loopWeeks(weekStart, weekEnd, (curWeekStart) => {
      weeksLoading[weekHash(curWeekStart)] = loading;
    });
  }

  function setWeeksLoaded(weekStart, weekEnd, lastFetchDate) {
    loopWeeks(weekStart, weekEnd, (curWeekStart) => {
      weeksLoaded[weekHash(curWeekStart)] = lastFetchDate;
    });
  }

  // Here it begins

  // Skip weeks at the beginning of the interval that are already loading or
  // have been fetched recently
  while (weekStart < weekEnd && canBeSkipped(weekStart)) {
    weekStart = addWeeks(weekStart, 1);
  }

  // If all weeks have been skipped, there's nothing to do
  if (weekStart >= weekEnd) return;

  // Same as above, but for weeks at the end of the interval
  while (canBeSkipped(startOfWeek(weekEnd))) {
    weekEnd = subWeeks(weekEnd, 1);
  }

  setWeeksLoading(weekStart, weekEnd, true);

  // Fetch the events
  let freshEvents;
  try {
    freshEvents = await fetchEvents(weekStart, weekEnd);
  } catch (e) {
    // Log errors but don't do anything else, this service will automatically
    // retry this API call later
    console.error(e);
    return;
  } finally {
    // Whatever happens, remove the loading flag
    setWeeksLoading(weekStart, weekEnd, false);
  }

  // Remove from the cache all the events mathing the interval...
  const eventsCacheTmp = eventsCache.filter(
    (ev) =>
      !areIntervalsOverlapping(
        { start: ev.startDate, end: addSeconds(ev.startDate, ev.duration) },
        { start: weekStart, end: weekEnd }
      )
  );
  // ...and replace them with the fresh new ones
  eventsCacheTmp.push(...freshEvents);
  eventsCache = eventsCacheTmp;

  // Flag the weeks in this interval as updated
  setWeeksLoaded(weekStart, weekEnd, new Date());

  // Notify all the watchers that there's new data
  callWatchers();
}

async function fetchEvents(start, end) {
  // No need to bother the backend if no external calendar is enabled
  if (enabledCalendars.length === 0) return [];

  const url =
    "/api/external-calendars/events?" +
    new URLSearchParams({
      from: formatISO(start, { representation: "date" }),
      to: formatISO(end, { representation: "date" }),
      tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const response = await api(url, null, {
    method: "GET",
    authenticate: true,
  });

  return response.map(ExternalEvent.fromBackend);
}

async function refreshProfiles() {
  await api("/api/external-calendars/profiles/refresh", undefined, {
    authenticate: true,
  });
}

export async function getConnectProfileUrl() {
  const response = await api(
    "/api/external-calendars/profiles/connect",
    undefined,
    {
      authenticate: true,
    }
  );
  return response.authUrl;
}

export function watchExternalProfilesAndCalendars(callback) {
  let externalProfilesMap;

  function handleChange() {
    if (!externalProfilesMap) return;

    const profilesArray = [];
    for (const [id, profile] of Object.entries(externalProfilesMap)) {
      const calendarsArray = [];
      for (const [id, calendar] of Object.entries(profile.calendars)) {
        calendarsArray.push({ id, ...calendar });
      }
      // Sort calendars by name
      calendarsArray.sort((a, b) => a.name.localeCompare(b.name));

      profilesArray.push({ ...profile, id, calendars: calendarsArray });
    }

    // Sort profiles by name
    profilesArray.sort((a, b) => a.name.localeCompare(b.name));

    callback(profilesArray);
  }

  const interval = setInterval(() => {
    // Refresh profiles with their lists of calendars, but save API calls if
    // we don't have any profile in the list
    if (Object.keys(externalProfilesMap).length > 0) refreshProfiles();
  }, POLLING_INTERVAL);

  return composeUnsubscribers(
    document().onSnapshot((userSnapshot) => {
      externalProfilesMap =
        userSnapshot.get("external_profiles_and_calendars") || {};
      handleChange();
    }),
    () => clearInterval(interval)
  );
}

export async function deleteExternalProfile(profileId) {
  await api(`/api/external-calendars/profiles/${profileId}/disconnect`, null, {
    authenticate: true,
  });
}

export async function setExternalCalendarEnabled(
  profileId,
  calendarId,
  enabled
) {
  document().update({
    [`external_profiles_and_calendars.${profileId}.calendars.${calendarId}.enabled`]:
      enabled,
  });
}

export async function setExternalCalendarSync(profileId, calendarId) {
  const user = (await document().get()).data();
  const externalProfiles = user?.external_profiles_and_calendars;

  if (!externalProfiles) return;

  // Check for existing external calendars with an active sync and disable
  // to keep the requirement of only one calendar sync at a time
  for (const [profileId, profile] of Object.entries(externalProfiles)) {
    for (const [calendarId, calendar] of Object.entries(profile.calendars)) {
      if (calendar.bi_directional_sync_enabled) {
        await document().update({
          [`external_profiles_and_calendars.${profileId}.calendars.${calendarId}.bi_directional_sync_enabled`]: false,
        });
      }
    }
  }

  await document().update({
    [`external_profiles_and_calendars.${profileId}.calendars.${calendarId}.bi_directional_sync_enabled`]: true,
  });
}
