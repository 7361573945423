import { useState, useCallback, useEffect } from 'react';

import useHistoryBlock from './useHistoryBlock';

/**
 * Hook for the states of UnsavedChangesDialog.js combined with history navigation
 * lifecycle of useHistoryBlock hook and "beforeunload" event when the user attempts
 * to close the window/tab
 *
 * Returns states and functions which handle dialog state management
 *
 * @export
 * @param {function} onClose Handle component's own close state
 * @returns {Object} returnObj Control render of component
 * @returns {boolean} returnObj.showUnsavedChangesDialog Control render of component
 * @returns {React.SetStateAction} returnObj.setShowUnsavedChangesDialog Updated to render dialog
 * @returns {React.SetStateAction} returnObj.setIsDirty Updated when an input is changed
 * @returns {function} returnObj.handleConfirmClose Check for dirty input and render dialog
 * @returns {function} returnObj.handleCancelConfirm Close dialog on "close without saving"
 * @returns {function} returnObj.handleCloseDialog Close dialog on "cancel" button
 */
export default function useConfirmClose(onClose) {
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [historyContinue] = useHistoryBlock(isDirty, () => {
    setShowUnsavedChangesDialog(true);
  });

  useEffect(() => {
    if (!isDirty) return;
    function handleBeforeUnload(e) {
      e.preventDefault();
      e.returnValue = '';
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const handleCancelConfirm = useCallback(() => {
    setShowUnsavedChangesDialog(false);
    onClose();
    historyContinue();
  }, [onClose, historyContinue]);

  const handleConfirmClose = useCallback(() => {
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    handleCancelConfirm();
  }, [isDirty, handleCancelConfirm]);

  const handleCloseDialog = useCallback(() => {
    setShowUnsavedChangesDialog(false);
  }, []);

  return {
    showUnsavedChangesDialog,
    setIsDirty,
    handleConfirmClose,
    handleCancelConfirm,
    handleCloseDialog,
  };
}
