import React, { useCallback, useState, useRef, useMemo } from "react";

import clsx from "clsx";
import { format, startOfTomorrow } from "date-fns";

import {
  SNOOZED,
  ACTIVE,
  SNOOZED_LABELS,
  SNOOZED_LIST_ORDER,
  SNOOZED_UNTIL_DATE,
  BLOCK,
} from "src/services/DbService/constants";

import { calculateSnoozedToDate } from "src/services/DbService/general";

import useUniqueId from "src/hooks/useUniqueId";

import { CardButton } from "src/components/Card";
import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
} from "src/components/ContextualMenu";
import DueDateButton from "src/components/DueDateButton";

import { ReactComponent as IconSnooze } from "src/assets/icons/16-snooze.svg";

import styles from "./CardSnoozeButton.module.scss";

function CardSnoozeButton({
  parentType,
  parentState,
  parentSnoozedTo,
  parentSnoozedToString,
  cardWithColor,
  onSetState,
  className,
  iconOnly = true,
  ...props
}) {
  const ref = useRef();
  const menuId = useUniqueId();
  const [menuVisible, setMenuVisible] = useState(null);

  const [selectedStartDate, setSelectedStartDate] = useState(parentSnoozedTo);

  const handleSetState = useCallback(
    (state, snoozedTo, snoozedToString) => {
      setMenuVisible(false);
      onSetState(state, snoozedTo, snoozedToString);
      ref.current?.focus();
    },
    [onSetState]
  );

  const snoozedButtonLabel = useMemo(() => {
    if (parentState === SNOOZED) {
      let snoozeStr = "";

      if (parentSnoozedTo) {
        snoozeStr = format(parentSnoozedTo, "EEE, MMM d");
      } else {
        snoozeStr = parentSnoozedToString;

        const selectedSnoozedTo = calculateSnoozedToDate(parentSnoozedToString);

        if (!selectedSnoozedTo) return "Snoozed indefinitely";

        snoozeStr = format(selectedSnoozedTo, "EEE, MMM d");
      }

      return `Snoozed until ${snoozeStr}`;
    }

    return "Snooze";
  }, [parentState, parentSnoozedTo, parentSnoozedToString]);

  const parentLabel = parentType === BLOCK ? "block" : "action";

  return (
    <>
      <ContextualMenuTrigger
        visible={menuVisible}
        setVisible={setMenuVisible}
        menuId={menuId}
      >
        <CardButton
          ref={ref}
          aria-label={`Snooze ${parentLabel}`}
          cardWithColor={cardWithColor}
          className={clsx(
            styles.buttonSnoozeLabel,
            styles.focusRing,
            className
          )}
          iconOnly={false}
          {...props}
        >
          <IconSnooze role="presentation" />
          {!iconOnly && <span>{snoozedButtonLabel}</span>}
        </CardButton>
      </ContextualMenuTrigger>

      {menuVisible && (
        <ContextualMenu
          buttonRef={ref}
          onClose={() => setMenuVisible(false)}
          aria-labelledby={menuId}
        >
          {parentState !== ACTIVE && (
            <ContextualMenuLink
              onClick={() => handleSetState(ACTIVE, undefined)}
            >
              {`Make ${parentLabel} active`}
            </ContextualMenuLink>
          )}
          {SNOOZED_LIST_ORDER.map((snoozedToString) => {
            // Remember: always check the state too, otherwise when state is
            // ACTIVE and snoozedTo is null, snoozedToString may be "someday"
            // and that would hide the "Snooze to the future" option in this
            // menu, this also makes Snoozed Until option always active
            if (
              parentState === SNOOZED &&
              parentSnoozedToString !== SNOOZED_UNTIL_DATE &&
              parentSnoozedToString === snoozedToString
            ) {
              return null;
            }

            return snoozedToString === SNOOZED_UNTIL_DATE ? (
              <DueDateButton
                key={snoozedToString}
                portalId="snoozed-until-date-portal"
                popperPlacement="right-end"
                date={selectedStartDate}
                minDate={startOfTomorrow()}
                onChange={(newValue) => {
                  setSelectedStartDate(newValue);
                  if (!newValue) {
                    handleSetState(ACTIVE);
                  } else {
                    handleSetState(SNOOZED, newValue);
                  }
                }}
                customInput={
                  <ContextualMenuLink>
                    {SNOOZED_LABELS[SNOOZED_UNTIL_DATE].cta}
                  </ContextualMenuLink>
                }
              />
            ) : (
              <ContextualMenuLink
                key={snoozedToString}
                onClick={() => {
                  handleSetState(SNOOZED, null, snoozedToString);
                  setSelectedStartDate(null);
                }}
              >
                {SNOOZED_LABELS[snoozedToString].cta}
              </ContextualMenuLink>
            );
          })}
          <div id="snoozed-until-date-portal"></div>
        </ContextualMenu>
      )}
    </>
  );
}

export default CardSnoozeButton;
