import { useCallback } from "react";

import { PERSON_DELETED_NICKNAME } from "src/services/DbService/constants";

import { replaceMentions } from "src/utils/replaceMentions";

import usePeopleById from "./usePeople";

export function mentionToText(person) {
  return person ? `@${person.nickname}` : PERSON_DELETED_NICKNAME;
}

// Returns a function that works exactly like replaceMentions, but the replace
// callback passes a full person object instead of the sole personId.
// Bonus:
// - this hook triggers a re-render every time a person is updated, so all the
//   mentions get immediately updated too.
// - if no custom mentionReplaceCallback is provided, the default one replaces
//   the mentions with the simple "@nickname" text (the most common usage)
export default function useReplaceMentions(
  mentionReplaceCallback = mentionToText
) {
  const { peopleById } = usePeopleById();

  const superReplaceMentions = useCallback(
    (actionDescription) => {
      return replaceMentions(actionDescription, (personId) =>
        mentionReplaceCallback(peopleById[personId])
      );
    },
    [mentionReplaceCallback, peopleById]
  );

  return superReplaceMentions;
}
