import React, { useCallback, useContext, useRef, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { debounce } from "debounce";

import { sleep } from "src/utils/sleep";

import { ACTIVE } from "src/services/DbService/constants";

import { useProjects } from "../ProjectsContext";
import { DashboardContext } from "../Dashboard";
import { SidebarContext } from "../SidebarContext";

import {
  PLANNER_PROJECT_DETAIL_URL,
  PLANNER_URL,
  PROJECT_DETAIL_URL,
} from "../App";
import LoadingSpinner from "../LoadingSpinner";
import NavButton from "../NavButton";
import CreateProjectDialog from "../CreateDialog/CreateProjectDialog";

import { ReactComponent as IconProjects } from "src/assets/icons/16-projects.svg";
import { ReactComponent as IconAdd } from "src/assets/icons/16-add.svg";

import styles from "./SidebarNav.module.scss";

function SidebarNavProject() {
  const history = useHistory();
  const isPlannerRoute = useRouteMatch(PLANNER_URL)?.isExact;
  const { activeProjects } = useProjects();

  const [projectDialogVisible, setProjectDialogVisible] = useState(false);

  const createProjectRef = useRef();

  const { activeProjectId, setActiveProjectId } = useContext(DashboardContext);
  const {
    actionListExpanded,
    toggleActionListExpanded,
    thisWeekSidebarExpanded,
    toggleThisWeekSidebarExpanded,
    categorySidebarExpanded,
    toggleCategorySidebarExpanded,
  } = useContext(SidebarContext);

  const isActiveProject = useCallback(
    (projectId) => projectId === activeProjectId,
    [activeProjectId]
  );

  const handleSetActiveProject = debounce((projectId) => {
    if (!categorySidebarExpanded || isActiveProject(projectId)) {
      toggleCategorySidebarExpanded();
    }
    actionListExpanded && toggleActionListExpanded();
    thisWeekSidebarExpanded && toggleThisWeekSidebarExpanded();

    setActiveProjectId(projectId);
  }, 150);

  // The lack of async/await support for createCategory means that this UI update
  // would happen before the watcher had updated the categories context with the
  // new value. A small workaround is a minor delay to allow it to catch up, and
  // if it has taken longer than 200ms, there is no error, only a slightly degraded
  // UI as there would be always without this delay
  const handleProjectCreated = useCallback(
    async (projectId) => {
      await sleep(200);

      setActiveProjectId(projectId);
      if (isPlannerRoute) {
        history.push(generatePath(PLANNER_PROJECT_DETAIL_URL, { projectId }));
      } else {
        history.push(generatePath(PROJECT_DETAIL_URL, { projectId }));
      }
    },
    [setActiveProjectId, history, isPlannerRoute]
  );

  const handleCreateProjectDialogClose = useCallback(() => {
    setProjectDialogVisible(false);
    createProjectRef.current && createProjectRef.current.focus();
  }, []);

  if (!activeProjects) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {activeProjects
        .filter((project) => project.state === ACTIVE)
        .map((project) => (
          <NavButton
            key={project.id}
            icon={IconProjects}
            active={isActiveProject(project.id)}
            onClick={() => handleSetActiveProject(project.id)}
            aria-label={
              categorySidebarExpanded && isActiveProject(project.id)
                ? `Hide ${project.name} List`
                : `Show ${project.name} List`
            }
          >
            {project.name}
          </NavButton>
        ))}

      <NavButton
        ref={createProjectRef}
        className={styles.createButton}
        icon={IconAdd}
        onClick={() => setProjectDialogVisible(true)}
        aria-label={"New Project"}
      >
        Project
      </NavButton>

      {projectDialogVisible && (
        <CreateProjectDialog
          onClose={handleCreateProjectDialogClose}
          onCreate={handleProjectCreated}
        />
      )}
    </>
  );
}

export default SidebarNavProject;
