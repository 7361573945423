import { useEffect, useMemo, useState } from "react";
import { add, startOfDay, sub } from "date-fns";

import { UNCATEGORIZED_ID, BLOCK } from "src/services/DbService/constants";
import {
  composeUnsubscribers,
  watchActiveBlocksMap,
  watchActiveChildren,
  watchSnoozedChildren,
} from "src/services/DbService/general";
import { watchEvents } from "src/services/DbService/events";
import { Action } from "src/services/DbService/actions";

export default function useAction(parentId, parentType) {
  const [active, setActive] = useState(null);
  const [snoozed, setSnoozed] = useState(null);
  const [blocksById, setBlocksById] = useState({});
  const [eventsList, setEventsList] = useState(null);

  useEffect(() => {
    const unsubscribers = [];

    const now = new Date();
    const startDateInterval = sub(startOfDay(now), { months: 3, weeks: 1 });
    const endDateInterval = add(startOfDay(now), { months: 3, weeks: 1 });

    unsubscribers.push(watchActiveChildren(parentId, parentType, setActive));

    unsubscribers.push(
      watchEvents(startDateInterval, endDateInterval, setEventsList)
    );

    unsubscribers.push(
      watchSnoozedChildren(parentId, parentType, null, setSnoozed)
    );

    // Hide block outcome from Actions within a Block to avoid duplicity
    if (parentType !== BLOCK) {
      unsubscribers.push(
        watchActiveBlocksMap((blocks) => {
          setBlocksById(blocks);
        })
      );
    }

    return composeUnsubscribers(unsubscribers);
  }, [parentId, parentType]);

  // a map of events where key is actionId and value is the list of all its events
  const eventsByActionId = useMemo(() => {
    if (!eventsList) return {};

    const eventsData = eventsList.reduce((result, event) => {
      const actionIds = event.actionsIds || [];

      // each event has a very small number of actions
      // associated to it (usually 1). In the mobile
      // app, this relationship is considered 1:1
      // (1 event to 1 action)
      actionIds.forEach((actionId) => {
        if (!result[actionId]) {
          result[actionId] = [];
        }

        result[actionId].push(event);
      });

      return result;
    }, {});

    return eventsData;
  }, [eventsList]);

  // An object which maps the actions to their most recent events
  // using the action id as the object key
  const mostRecentEventByActionId = useMemo(() => {
    if (!eventsByActionId) return {};
    const mostRecentMap = {};

    for (let actionId in eventsByActionId) {
      mostRecentMap[actionId] = eventsByActionId[actionId].reduce((a, b) => {
        return a.startDate > b.startDate ? a : b;
      });
    }

    return mostRecentMap;
  }, [eventsByActionId]);

  const actions = useMemo(() => {
    if (!active || !snoozed) return null;

    // TODO: Find a way to avoid mixing different entities
    return [...active, ...snoozed]
      .filter((child) => child instanceof Action)
      .map((action) => {
        return {
          ...action,
          event: mostRecentEventByActionId[action.id],
        };
      });
  }, [active, snoozed, mostRecentEventByActionId]);

  const pendingActionsCounts = useMemo(() => {
    if (!active)
      return {
        pastDue: 0,
        unscheduled: 0,
        uncategorized: 0,
      };

    return active.reduce(
      (result, action) => {
        // unscheduled
        if (!mostRecentEventByActionId?.[action.id]?.startDate) {
          return { ...result, unscheduled: result.unscheduled + 1 };
        }

        const event = mostRecentEventByActionId[action.id];

        // uncategorized
        if (
          action.categoryId === UNCATEGORIZED_ID &&
          event.startDate >= new Date()
        ) {
          return { ...result, uncategorized: result.uncategorized + 1 };
        }

        // past due
        if (event.startDate < new Date()) {
          return { ...result, pastDue: result.pastDue + 1 };
        }

        return result;
      },
      {
        pastDue: 0,
        unscheduled: 0,
        uncategorized: 0,
      }
    );
  }, [active, mostRecentEventByActionId]);

  const totalPendingActions = useMemo(() => {
    const { pastDue, unscheduled, uncategorized } = pendingActionsCounts;
    return pastDue + unscheduled + uncategorized;
  }, [pendingActionsCounts]);

  return {
    actions,
    eventsList,
    blocksById,
    mostRecentEventByActionId,
    totalPendingActions,
  };
}
