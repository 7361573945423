import React from "react";
import { Link } from "react-router-dom";

import { PRODUCT_NAME } from "src/constants";

import Tooltip from "../Tooltip";

import MarqueSrc from "../../assets/marque.svg";

import styles from "./Nav.module.scss";

const LogoLink = ({ heading }) => {
  return (
    <>
      <Tooltip title={heading || PRODUCT_NAME}>
        <Link className={styles.marque} to={"/"}>
          <span className="sr-only">{PRODUCT_NAME}</span>
          <img src={MarqueSrc} alt="" />
        </Link>
      </Tooltip>
      {heading && <span className={styles.heading}>{heading}</span>}
    </>
  );
};

export default LogoLink;
