import clsx from "clsx";
import React from "react";
import { CardButton } from ".";

import { ReactComponent as SelectArrow } from "../Input/select-arrow.svg";

import styles from "./Card.module.scss";

function CardButtonSelect({
  className,
  value,
  label,
  onChange,
  readonly,
  children,
  custom,
  ...props
}) {
  return (
    <CardButton
      type="button"
      className={clsx(styles.selectButton, className)}
      iconOnly={false}
      {...props}
    >
      <select value={value} onChange={onChange} disabled={readonly} {...props}>
        {children}
      </select>
      <span className={styles.focusRing} aria-hidden="true" />
      <span className={styles.label}>{label}</span>
      <SelectArrow className={styles.selectArrow} role="presentation" />
    </CardButton>
  );
}

export default CardButtonSelect;
