/**
 * Scrolls to the child if it is not in the scroll view of the parent
 * @param  {HTMLElement} el
 * @param  {HTMLElement} parent
 */
export function scrollIntoViewIfNeeded(el, parent) {
  if (!el || !parent) return;

  const elRect = el.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  if (elRect.top < parentRect.top || elRect.bottom > parentRect.bottom) {
    el.scrollIntoView({
      block: "nearest",
    });
  }
}
