import React from "react";
import clsx from "clsx";

import { ReactComponent as IconMentions } from "../../assets/icons/16-mentions.svg";
import { ReactComponent as IconLeveraged } from "../../assets/icons/16-leveraged.svg";

import Counter from "./Counter";

import styles from "./Counters.module.scss";

function PeopleCounters({ leverages = 0, mentions = 0, className, ...props }) {
  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <Counter
        icon={IconLeveraged}
        short={leverages}
        long={`${leverages} actions leveraged`}
        smallIcon
      />
      <Counter
        icon={IconMentions}
        short={mentions}
        long={`${mentions} mentions in actions`}
        smallIcon
      />
    </div>
  );
}

export default PeopleCounters;
