import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";

import {
  CATEGORY_ACTIVE,
  CATEGORY_HIDDEN,
  CATEGORY_ICONS,
  UNCATEGORIZED_ICON,
} from "src/services/DbService/constants";
import { watchCategories } from "src/services/DbService/categories";
import {
  composeUnsubscribers,
  watchQuickCaptureOptions,
} from "src/services/DbService/general";

import { AuthContext } from "./AuthContext";

const CategoriesContext = createContext();

function computeMetadata(categories) {
  if (categories === null) return {};

  const categoriesFlat = Object.values(categories).flat();

  const metadata = {};
  for (const category of categoriesFlat) {
    const { id, name, icon, color } = category;
    metadata[id] = {
      name,
      color,
      icon,
    };
  }
  return metadata;
}

export function CategoriesContextProvider({ children }) {
  const { user } = useContext(AuthContext);

  const [categories, setCategories] = useState(null);

  // optionsGroups and optionsMap are needed in ActionDialog rather than within ActionParentSelect because
  // the newParentOption is dependant on them for the blockId and categoryId of the new parent. These could
  // be sent back up through the onChange callback, but that requires all instances of newActionParent to be
  // changed to provide the blockId and categoryId so that if a user opens it from a BlockDetail both
  // bits of information are known before the onChange is called (if at all). Finally, it removes the need
  // to pass up some kind of onInit event to handle the latency between mounting ActionDialog and running
  // the watchers in ActionParentSelect to prevent a two stage loading of a simple piece of UI.
  const [optionsGroups, setOptionsGroups] = useState([]);
  const [optionsMap, setOptionsMap] = useState({});

  useEffect(() => {
    if (!user) return;

    return composeUnsubscribers(
      watchCategories(setCategories),
      watchQuickCaptureOptions(({ optionsGroups, optionsMap }) => {
        setOptionsGroups(optionsGroups);
        setOptionsMap(optionsMap);
      })
    );
  }, [user]);

  const value = useMemo(() => {
    const metadata = computeMetadata(categories);

    const activeCategories = categories?.[CATEGORY_ACTIVE] || null;
    const hiddenCategories = categories?.[CATEGORY_HIDDEN] || null;

    const categoryOptions = { optionsGroups, optionsMap };

    return {
      activeCategories,
      hiddenCategories,
      metadata,
      categoryOptions,
    };
  }, [categories, optionsGroups, optionsMap]);

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
}

/**
 * A small hook for getting all active and hidden categories for the user.
 * @returns {Object}
 */
export function useCategories() {
  const { activeCategories, hiddenCategories } = useContext(CategoriesContext);

  return { activeCategories, hiddenCategories };
}

/**
 * A small hook for getting the category options group and map.
 * @returns {Object}
 */
export function useCategoryOptions() {
  const {
    categoryOptions: { optionsGroups, optionsMap },
  } = useContext(CategoriesContext);

  return { optionsGroups, optionsMap };
}

/**
 * A function for getting the name of the colour of a given category. Colours
 * are currently named 0–8.
 * @returns {Function}
 */
export function useGetCategoryColor() {
  const { metadata } = useContext(CategoriesContext);

  return useCallback((categoryId) => metadata[categoryId]?.color, [metadata]);
}

/**
 * @param  {String} categoryId
 * @returns {Object} the category icon object that contains the SVG, label etc
 */
export function useCategoryIcon(categoryId) {
  const { metadata } = useContext(CategoriesContext);

  const icon = metadata[categoryId]?.icon;

  if (icon === UNCATEGORIZED_ICON.key) {
    return UNCATEGORIZED_ICON;
  }

  return CATEGORY_ICONS[icon] || {};
}
