import React from "react";
import clsx from "clsx";

import SidebarNav from "../SidebarNav";
import DashboardActionList from "../Dashboard/DashboardActionList";
import DashboardSidebar from "../Dashboard/DashboardSidebar/DashboardSidebar";
import DashboardSidebarThisWeek from "../Dashboard/DashboardSidebar/DashboardSidebarThisWeek";

import styles from "./SidebarContainer.module.scss";

function SidebarContainer({
  categoryExpanded,
  thisWeekExpanded,
  actionListExpanded,
}) {
  return (
    <>
      <SidebarNav
        expanded={categoryExpanded || thisWeekExpanded || actionListExpanded}
      />

      <DashboardSidebar
        className={clsx(styles.sidebar, !categoryExpanded && styles.hidden)}
      />

      <DashboardSidebarThisWeek
        className={clsx(styles.sidebar, !thisWeekExpanded && styles.hidden)}
      />

      <DashboardActionList
        className={clsx(styles.sidebar, !actionListExpanded && styles.hidden)}
      />
    </>
  );
}

export default SidebarContainer;
