import isUUID from "validator/es/lib/isUUID";
import {
  generateId,
  document,
  collection,
  ParameterError,
  firestore,
  CacheDelayer,
} from "./general";

import { BLOCK } from "./constants";

import { trackEvent } from "../AnalyticsService";
import FirestoreRollingBatch from "./FirestoreRollingBatch";

export class Event {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Event(), {
      id: snapshot.id,
      categoryId: data.category_id,
      blockId: data.block_id,
      startDate: data.start_date && new Date(data.start_date),
      duration: data.duration, // in seconds
      actionsIds: data.actions_ids || [],
      creationDate: data.date_of_creation && new Date(data.date_of_creation),
    });
  }
}

export function watchEvents(startDate, endDate, callback) {
  const cacheDelayer = new CacheDelayer();
  const unsubscribe = collection("events")
    .where("start_date", ">=", startDate.toISOString())
    .where("start_date", "<=", endDate.toISOString())
    .onSnapshot((snapshot) => {
      const docs = snapshot.docs.map(Event.fromFirestore);
      cacheDelayer.delayIfCached(() => callback(docs), snapshot);
    });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  };
}

export function createEventHelper(
  batch,
  startDate,
  duration,
  categoryId,
  blockId,
  actionsIds
) {
  if (!startDate instanceof Date)
    throw new ParameterError({ startDate }, "not a Date object");
  if (isNaN(startDate)) throw new ParameterError({ startDate }, "invalid date");
  if (!Number.isInteger(duration))
    throw new ParameterError({ duration }, "not an integer number");
  if (duration <= 0) throw new ParameterError({ duration }, "can't be <= 0");
  if (!isUUID(categoryId))
    throw new ParameterError({ categoryId }, "not a valid UUID");
  if (blockId !== null && !isUUID(blockId))
    throw new ParameterError({ blockId }, "can be only null or a valid UUID");
  if (!Array.isArray(actionsIds))
    throw new ParameterError({ actionsIds }, "not an array");
  if (actionsIds.some((id) => !isUUID(id)))
    throw new ParameterError({ actionsIds }, "must contain only UUIDs");

  const id = generateId();

  const newEvent = {
    id,
    date_of_creation: new Date().toISOString(),
    start_date: startDate.toISOString(),
    duration,
    category_id: categoryId,
    block_id: blockId,
    actions_ids: actionsIds,
  };

  batch.set(document(`events/${id}`), newEvent);

  return newEvent;
}

export async function createEvent(
  startDate,
  duration,
  categoryId,
  blockId,
  actionsIds
) {
  const batch = firestore().batch();
  const newEvent = createEventHelper(
    batch,
    startDate,
    duration,
    categoryId,
    blockId,
    actionsIds
  );
  await batch.commit();

  trackEvent("User created an Event", {
    id: newEvent.id,
    duration,
    categoryId,
    blockId,
    actionsIds,
    startDate,
  });
}

export function deleteEvent(eventId, externalBatch = null) {
  const batch = externalBatch || firestore().batch();
  batch.delete(document(`events/${eventId}`));
  if (!externalBatch) batch.commit();
}

export async function getEvent(eventId) {
  const docSnapshot = await document(`events/${eventId}`).get();
  return Event.fromFirestore(docSnapshot);
}

export async function getEventsMap(parentType, parentId) {
  if (parentType !== BLOCK)
    throw new ParameterError(
      { parentType },
      `Only "${BLOCK}" is current supported.`
    );

  const snapshot = await collection("events")
    .where("block_id", "==", parentId)
    .get();

  const events = snapshot.docs.map(Event.fromFirestore);

  return events.reduce((result, event) => {
    if (!event?.actionsIds?.[0]) return result;

    result[event.actionsIds[0]] = event;
    return result;
  }, {});
}

/**
 * @param {(Event|string)} eventOrId The Event object to be used for the
 *                                     the dirty checks, or alternatively just
 *                                     its id (in this case a database read will
 *                                     be issued to get the full object)
 * @param {Object} updates Map of the changes to make
 * @param {Object=} externalBatch Optional batch where to add all the updates
 */
export async function updateEvent(eventOrId, updates, externalBatch) {
  const {
    categoryId,
    blockId,
    startDate,
    duration, // in seconds
    actionsIds,
    creationDate,
  } = updates;

  const batch = externalBatch || new FirestoreRollingBatch();

  let eventId, event;
  // it would be better to check for "instanceof Event", but since SortableJs
  // sometimes strips out the prototype better to not rely on that
  if (typeof eventOrId === "object") {
    event = eventOrId;
    eventId = event.id;
  } else {
    eventId = eventOrId;
    event = await getEvent(eventId);
  }

  const eventDocument = document(`events/${eventId}`);
  const {
    categoryId: oldCategoryId,
    blockId: oldBlockId,
    startDate: oldStartDate,
    duration: oldDuration,
    actionsIds: oldActionsIds,
    creationDate: oldCreationDate,
  } = event;

  const equals = (a, b) =>
    a.length === b.length && a.every((v, i) => v === b[i]);

  const categoryChanges =
    categoryId !== undefined && categoryId !== oldCategoryId;
  const blockChanges = blockId !== undefined && blockId !== oldBlockId;
  const startDateChanges =
    startDate !== undefined && startDate.getTime() !== oldStartDate.getTime();
  const durationChanges = duration !== undefined && duration !== oldDuration;
  const actionsIdsChanges =
    actionsIds !== undefined && !equals(actionsIds, oldActionsIds);
  const creationDateChanges =
    creationDate !== undefined &&
    creationDate.getTime() !== oldCreationDate.getTime();

  if (categoryChanges) {
    if (!isUUID(categoryId))
      throw new ParameterError({ categoryId }, "must be a valid UUID");
    batch.update(eventDocument, { category_id: categoryId });
  }

  if (blockChanges) {
    if (blockId !== null && !isUUID(blockId))
      throw new ParameterError({ blockId }, "must be null or a valid UUID");
    batch.update(eventDocument, { block_id: blockId });
  }

  if (startDateChanges) {
    if (!(startDate instanceof Date))
      throw new ParameterError({ startDate }, "not a Date object");

    if (isNaN(startDate))
      throw new ParameterError({ startDate }, "invalid date");

    batch.update(eventDocument, { start_date: startDate.toISOString() });
  }

  if (durationChanges) {
    if (!Number.isInteger(duration))
      throw new ParameterError({ duration }, "not an integer number");

    if (duration <= 0) throw new ParameterError({ duration }, "can't be <= 0");

    batch.update(eventDocument, { duration });
  }

  if (actionsIdsChanges) {
    if (!Array.isArray(actionsIds))
      throw new ParameterError({ actionsIds }, "not an array");

    if (actionsIds.some((id) => !isUUID(id)))
      throw new ParameterError({ actionsIds }, "must contain only UUIDs");

    batch.update(eventDocument, { actions_ids: actionsIds });
  }

  if (creationDateChanges) {
    if (!(creationDate instanceof Date))
      throw new ParameterError({ startDate }, "not a Date object");

    if (isNaN(creationDate))
      throw new ParameterError({ startDate }, "invalid date");

    batch.update(eventDocument, {
      date_of_creation: creationDate.toISOString(),
    });
  }

  if (!externalBatch) batch.commit();
}

export async function batchEventsRegister(events) {
  if (!events || !Array.isArray(events)) return;

  const batch = new FirestoreRollingBatch();

  const promises = [];

  for (const event of events) {
    if (event.isDeleted) {
      deleteEvent(event.id);
    } else if (event.creationDate === null) {
      promises.push(
        createEventHelper(
          batch,
          event.startDate,
          event.duration,
          event.categoryId,
          event.blockId,
          event.actionsIds
        )
      );
    } else {
      promises.push(
        updateEvent(event.id, { ...event, creationDate: new Date() }, batch)
      );
    }
  }

  // all updates should be ready to execute in batch
  await Promise.all(promises);

  await batch.commit();
}

export async function updateEventScheduleFromActionId(
  actionId,
  startDate,
  duration,
  externalBatch
) {
  const batch = externalBatch || firestore().batch();

  if (!actionId) return;

  const eventsSnap = await collection("events")
    .where("actions_ids", "array-contains", actionId)
    .get();

  // update the startDate and duration of the action event
  eventsSnap.docs.forEach((eventSnap) => {
    const event = Event.fromFirestore(eventSnap);

    // if a date is selected, update the event
    if (startDate) {
      batch.update(eventSnap.ref, {
        start_date: startDate.toISOString(),
      });

      if (duration) {
        batch.update(eventSnap.ref, {
          duration,
        });
      }
    } else {
      // on the other hand, if no date is selected (date == null),
      // delete the event
      deleteEvent(event.id, batch);
    }
  });

  if (!externalBatch) batch.commit();
}

export async function setEventStartDate(eventId, startDate) {
  if (!startDate instanceof Date)
    throw new ParameterError({ startDate }, "not a Date object");
  if (isNaN(startDate)) throw new ParameterError({ startDate }, "invalid date");

  document(`events/${eventId}`).update({
    start_date: startDate.toISOString(),
  });
}

export async function setEventDuration(eventId, duration) {
  if (!Number.isInteger(duration))
    throw new ParameterError({ duration }, "not an integer number");
  if (duration <= 0) throw new ParameterError({ duration }, "can't be <= 0");

  document(`events/${eventId}`).update({
    duration,
  });
}

export function composeEventDescription(block, actions, replaceMentions) {
  const eventHasActions = actions?.length > 0;

  if (block && !eventHasActions) return block.result;

  if (eventHasActions) {
    return actions
      .map((action) => replaceMentions(action.description))
      .join(", ");
  }

  return "[Empty event]";
}
