import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";

const INITIAL_TOOLTIP_DELAY = 600;
const TOOLTIP_LINGER_DURATION = 1000;

const TooltipContext = createContext();

export function TooltipContextProvider({ children }) {
  const [tooltipDelay, setTooltipDelay] = useState(INITIAL_TOOLTIP_DELAY);
  const lingerTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(lingerTimeoutRef.current);
    };
  }, []);

  // The open and close functions handle the changing of the delay for tooltips
  // opening. Once a user has opened a tooltip after the initial pause, any
  // subsequence tooltips thatare open within the timeout are opened immediately.
  // This mimics the behavior of the title attribute within browsers and makes
  // tooltips slightly more useful when scouting around the page.
  // Every time a new tooltip is opened, the timer is restarted to allow a user
  // to interact with as many as they so wish.
  const onTooltipOpen = useCallback(() => {
    clearTimeout(lingerTimeoutRef.current);
    setTooltipDelay(0);
  }, []);
  const onTooltipClose = useCallback(() => {
    if (tooltipDelay !== 0) return;
    clearTimeout(lingerTimeoutRef.current);

    lingerTimeoutRef.current = setTimeout(() => {
      setTooltipDelay(INITIAL_TOOLTIP_DELAY);
    }, TOOLTIP_LINGER_DURATION);
  }, [tooltipDelay]);

  const value = useMemo(() => {
    return {
      tooltipDelay,
      onTooltipOpen,
      onTooltipClose,
    };
  }, [onTooltipOpen, onTooltipClose, tooltipDelay]);

  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
}

/**
 * @returns delay, and the open and close functions for the tooltip contest
 */
export function useTooltipContext() {
  const { tooltipDelay, onTooltipOpen, onTooltipClose } =
    useContext(TooltipContext);
  return {
    tooltipDelay,
    onTooltipOpen,
    onTooltipClose,
  };
}
