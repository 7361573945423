import { useEffect, useState } from "react";
import isElectron from "is-electron";
import {
  addHours,
  addMinutes,
  addSeconds,
  differenceInMilliseconds,
  format,
  isPast,
  subMinutes,
} from "date-fns";
import useReplaceMentions from "../hooks/useReplaceMentions";
import { getAction } from "../services/DbService/actions";
import { getBlock } from "../services/DbService/blocks";
import {
  composeEventDescription,
  watchEvents,
} from "../services/DbService/events";
import notificationIconUrl from "../assets/icons/96-notification.png";

const MINUTES_BEFORE = 10;
const BUFFERED_HOURS = 1;

const TIME_FORMAT = "h:mma";

const MILLIS_IN_HOUR = 60 * 60 * 1000;

// Disable the icon when running in Electron on Mac: notifications sent from
// there show already the app icon on the left side, and it would be duplicated
// on the right side setting the icon in the notification too.
// In all the other cases setting the icon in the notification improves the
// look, including Electron on Windows, that otherwise would display a text-only
// notification.
const SHOW_NOTIFICATION_ICON = !(
  isElectron() && navigator.platform.startsWith("Mac")
);

function EventsNotifier() {
  const [events, setEvents] = useState([]);

  const replaceMentions = useReplaceMentions();

  // Watch the events in the next n hours
  useEffect(() => {
    let timeout, unsubscribe;

    function tick() {
      const windowStart = new Date();
      // Suppose it's 1pm, BUFFERED_HOURS=1 and MINUTES_BEFORE=10, and there's
      // an event scheduled at 2:05pm. If the buffer is large only 1 hour that
      // event would not be picked up in the first window (1pm-2pm), and when it
      // gets picked up in the next window it's too late to trigger the
      // notification (5 minutes to the event, but MINUTES_BEFORE=10). This is
      // why the buffer must be large BUFFERED_HOURS + MINUTES_BEFORE.
      const windowEnd = addMinutes(
        addHours(windowStart, BUFFERED_HOURS),
        MINUTES_BEFORE
      );

      if (unsubscribe) unsubscribe();
      unsubscribe = watchEvents(windowStart, windowEnd, setEvents);

      timeout = setTimeout(tick, BUFFERED_HOURS * MILLIS_IN_HOUR);
    }

    tick();

    return () => {
      clearTimeout(timeout);
      unsubscribe();
    };
  }, []);

  // Set the timeouts and the notifications for the events in the buffer
  useEffect(() => {
    const timeouts = [];

    events.forEach((event) => {
      const { startDate, duration, blockId, actionsIds } = event;

      const notificationDate = subMinutes(startDate, MINUTES_BEFORE);
      if (isPast(notificationDate)) return;

      const delay = differenceInMilliseconds(notificationDate, new Date());

      const timeout = setTimeout(async () => {
        const endDate = addSeconds(startDate, duration);
        const block = blockId ? await getBlock(blockId) : null;
        const actions = await Promise.all(
          (actionsIds || []).map((actionId) => getAction(actionId))
        );

        const title = composeEventDescription(block, actions, replaceMentions);
        const body = `${format(startDate, TIME_FORMAT)} – ${format(
          endDate,
          TIME_FORMAT
        )}`;

        new Notification(title, {
          body,
          // NOTE: the largest place where the notification icon is displayed
          // is the Windows Electron notification, where it has to be at least
          // 48x48, so 96x96 for retina screens. SVGs don't work here.
          icon: SHOW_NOTIFICATION_ICON && notificationIconUrl,
          requireInteraction: true,
        });
      }, delay);
      timeouts.push(timeout);
    });

    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [events, replaceMentions]);

  // Nothing to render
  return null;
}

export default EventsNotifier;
