import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useContext,
} from "react";

import {
  CATEGORY_DEFAULT_COLOR,
  UNCATEGORIZED_ID,
} from "../../../services/DbService/constants";

import { useGetCategoryColor } from "../../CategoriesContext";
import {
  ActionDialogContext,
  useShowCreateActionDialog,
} from "../../ActionDialog";
import Dialog from "../../Dialog";
import EventCard, { useBoxesRefs } from "../EventCard";
import { getEventDialogCss } from "../EventDialog";
import NewEventDialogRoot from "./NewEventDialogRoot";
import NewEventDialogBlock from "./NewEventDialogBlock";

import Button, { BUTTON_SIZE_SMALL } from "../../Button";

import { ReactComponent as IconAdd } from "../../../assets/icons/16-add.svg";
import { ReactComponent as IconPrevious } from "../../../assets/icons/16-previous.svg";

import styles from "./NewEvent.module.scss";

function NewEvent({ event, boxes, onClose }) {
  const { id: eventId, startDate, duration } = event || {};

  const hidden = !event;

  const dialogRef = useRef();
  const boxesRefs = useBoxesRefs(boxes?.length);

  const [dialogCss, setDialogCss] = useState();
  const [selectedBlock, setSelectedBlock] = useState(null);

  const getCategoryColor = useGetCategoryColor();

  const { setNewActionEvent } = useContext(ActionDialogContext);
  const showCreateActionDialog = useShowCreateActionDialog();

  useEffect(() => {
    if (hidden) {
      // Reset dialog state when it gets hidden
      setSelectedBlock(null);
    } else {
      // Because the dialog is always present the dialog focus transfer on opening
      // won't do anything, so it needs to be manually transfered when the dialog
      // is no longer hidden
      dialogRef.current && dialogRef.current.focus();
    }
  }, [hidden]);

  useLayoutEffect(() => {
    // Since NewEvent is only ever made up of one box, we can safely assume that
    // the first ref in the array is the element against which to calculate the dialog
    // size and position
    const [boxRef] = boxesRefs;

    if (!boxRef?.current) return;

    const css = getEventDialogCss(boxRef);

    setDialogCss(css);
  }, [startDate, boxesRefs]);

  const handleNewEvent = useCallback(() => {
    setNewActionEvent({
      startDate,
      duration,
    });
    showCreateActionDialog(UNCATEGORIZED_ID);
  }, [startDate, duration, setNewActionEvent, showCreateActionDialog]);

  let headerButtons, headerTitle;
  const headerButtonProps = {
    iconOnly: true,
    tooltip: true,
    size: BUTTON_SIZE_SMALL,
  };
  if (selectedBlock === null) {
    headerTitle = "Select action to schedule";
    headerButtons = (
      <Button
        {...headerButtonProps}
        aria-label="New Event"
        onClick={handleNewEvent}
      >
        <IconAdd role="presentation" />
      </Button>
    );
  } else {
    headerTitle = selectedBlock?.result;
    headerButtons = (
      <Button
        {...headerButtonProps}
        aria-label="Back"
        onClick={() => setSelectedBlock(null)}
      >
        <IconPrevious role="presentation" />
      </Button>
    );
  }

  // Preload the components keeping them hidden
  return (
    <>
      {!hidden && (
        <EventCard
          eventId={eventId}
          startDate={startDate}
          duration={duration}
          boxes={boxes}
          boxesRefs={boxesRefs}
          color={CATEGORY_DEFAULT_COLOR}
          resizable={true}
        />
      )}
      {/* These dialogs are in portals, so they don't inherit
        the hidden state of the ancestor */}
      <Dialog
        ref={dialogRef}
        hidden={hidden}
        lightbox={false}
        headerTitle={headerTitle}
        headerButtons={headerButtons}
        onClose={onClose}
        customBody
        data-category-color={getCategoryColor(selectedBlock?.categoryId)}
        style={dialogCss}
      >
        <NewEventDialogRoot
          className={styles.dialogSection}
          hidden={selectedBlock !== null}
          dialogHidden={hidden}
          startDate={startDate}
          duration={duration}
          onBlockSelected={(bool) => setSelectedBlock(bool)}
          onClose={onClose}
        />
        <NewEventDialogBlock
          className={styles.dialogSection}
          hidden={selectedBlock === null}
          dialogHidden={hidden}
          startDate={startDate}
          duration={duration}
          block={selectedBlock}
          onClose={onClose}
        />
      </Dialog>
    </>
  );
}

export default NewEvent;
