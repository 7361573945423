import React from "react";

import { trackEvent } from "src/services/AnalyticsService";

import { DASHBOARD_URL } from "../App";
import Button from "../Button";
import Nav from "../Nav";

export default function OnboardingNav() {
  return (
    <Nav>
      <Button
        linkTo={DASHBOARD_URL}
        onClick={() => trackEvent("User skipped onboarding")}
      >
        Skip for now
      </Button>
    </Nav>
  );
}
