import React, { useRef, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { sleep } from "src/utils/sleep";
import {
  signIn,
  verifyEmail,
  NotAuthorizedError,
  resendVerificationCode,
} from "../../services/AuthService";
import { NetworkError } from "../../services/ApiService";

import { SIGN_IN_URL, ONBOARDING_GOALS_URL } from "../App";
import Input from "../Input";
import Button, { BUTTON_COLOR_FILL } from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import SmartLink from "../SmartLink";
import PageNoUi from "../PageNoUi";
import AuthForm from "./AuthForm";

import styles from "./AuthForm.module.scss";

function EmailVerification() {
  const history = useHistory();

  const { username, password } = history.location.state || {};

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resendError, setResendError] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const [codeRequestLoading, setCodeRequestLoading] = useState(false);
  const codeRef = useRef();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!code) {
        setError("Code is required");
        codeRef.current.focus();
        return;
      }

      setLoading(true);
      try {
        await verifyEmail(username, code);
      } catch (e) {
        setLoading(false);
        if (e instanceof NetworkError) {
          setError(
            "Network error, please check your internet connection and try again"
          );
        } else if (e instanceof NotAuthorizedError) {
          setError("Invalid activation code, please try again");
        } else {
          setError("Unexpected error, please try again");
          console.error(e);
        }
        // Move the focus on the input so the user can make corrections or just
        // press Enter to try again
        codeRef.current.focus();
        return;
      }
      // If execution gets here the validation succeded, proceeed
      // with the login and redirect the user to the onboarding flow
      try {
        await signIn(username, password);
        history.push(ONBOARDING_GOALS_URL);
      } catch (e) {
        console.error(e);
        history.push(SIGN_IN_URL);
      }
    },
    [history, username, password, code]
  );

  const resendCode = async () => {
    setCodeRequestLoading(true);
    try {
      await Promise.all([resendVerificationCode(username), sleep(500)]);
      setCodeResent(true);
    } catch (e) {
      console.error(e);
      setResendError("Could not send activation code, please try again later.");
    }
    setCodeRequestLoading(false);
  };

  const pageHelpText =
    "Retrieve the activation code from your email that was sent and continue the onboarding process.";

  let resendMessage = null;

  if (codeResent)
    resendMessage = "We’ve sent a new activation code to your email.";

  if (resendError) resendMessage = resendError;

  return (
    <PageNoUi pageTitle="Verify Email" text={pageHelpText}>
      <AuthForm
        error={error}
        loading={loading}
        buttons={
          <>
            <Button linkTo={SIGN_IN_URL}>Cancel</Button>
            <Button
              type="submit"
              loading={loading}
              onClick={onSubmit}
              color={BUTTON_COLOR_FILL}
              block
            >
              Next
            </Button>
          </>
        }
        bottomText={
          <>
            <p>
              Didn’t receive an activation code?{" "}
              <SmartLink type="button" onClick={resendCode}>
                Resend activation code
              </SmartLink>
            </p>
            {resendMessage && !codeRequestLoading && (
              <p className={styles.message}>{resendMessage}</p>
            )}

            {codeRequestLoading && <LoadingSpinner opaque />}
          </>
        }
      >
        <Input
          label="Activation code"
          type="text"
          autoFocus
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={codeRef}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </AuthForm>
    </PageNoUi>
  );
}

export default EmailVerification;
