/**
 * We commonly need to delay changes in the UI for user experience benefits. This function
 * allows for a common pattern of returning setTimeout as a a self-resolving Promise to
 * be a bit cleaner.
 * @param  {Number} delayMs The delay in milliseconds
 * @returns {Promise} delay A promise that resolves itself after the given timeout
 */
export function sleep(delayMs) {
  return new Promise((resolve) => setTimeout(resolve, delayMs));
}
