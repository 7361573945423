import React from "react";

import clsx from "clsx";

import styles from "./NavItem.module.scss";

function NavItem({ active, icon: Icon, children }) {
  return (
    <div
      className={clsx(styles.navItem, active && styles.active)}
      data-active={active}
    >
      {Icon && <Icon role="presentation" />}
      <span>{children}</span>
    </div>
  );
}

export default NavItem;
