import React, { useState, createContext, useMemo, useContext } from "react";

export const PlannerSaveDialogContext = createContext();

export function PlannerSaveDialogContextProvider({ children }) {
  const [visible, setVisible] = useState(false);

  const value = useMemo(
    () => ({
      visible,
      setVisible,
    }),
    [visible, setVisible]
  );

  return (
    <PlannerSaveDialogContext.Provider value={value}>
      {children}
    </PlannerSaveDialogContext.Provider>
  );
}

/**
 * Returns a function that can be called to show the PlannerSaveDialog,
 * showPlannerSaveDialog(boolean)
 */
export function useShowPlannerSaveDialog() {
  const { setVisible } = useContext(PlannerSaveDialogContext);
  // PlannerSaveDialog detects changes to this state and automatically
  // becomes visible when a new value is set
  return setVisible;
}
