import React, { useCallback, useContext, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { sleep } from "src/utils/sleep";

import {
  ACTIVE,
  CATEGORY_ICONS,
  UNCATEGORIZED_ID,
} from "src/services/DbService/constants";

import { useCategories } from "../CategoriesContext";
import { DashboardContext } from "../Dashboard";
import { SidebarContext } from "../SidebarContext";

import { CATEGORY_DETAIL_URL } from "../App";
import LoadingSpinner from "../LoadingSpinner";
import NavButton from "../NavButton";
import CreateCategoryDialog from "../CreateDialog/CreateCategoryDialog";

import { ReactComponent as IconAdd } from "src/assets/icons/16-add.svg";

import styles from "./SidebarNav.module.scss";

function SidebarNavCategory({ isActive, setActive }) {
  const history = useHistory();
  const { activeCategories } = useCategories();

  const [categoryDialogVisible, setCategoryDialogVisible] = useState(false);

  const createCategoryRef = useRef();

  const { categorySidebarExpanded } = useContext(SidebarContext);
  const { setActiveCategoryId } = useContext(DashboardContext);

  // The lack of async/await support for createCategory means that this UI update
  // would happen before the watcher had updated the categories context with the
  // new value. A small workaround is a minor delay to allow it to catch up, and
  // if it has taken longer than 200ms, there is no error, only a slightly degraded
  // UI as there would be always without this delay
  const handleCategoryCreated = useCallback(
    async (categoryId) => {
      await sleep(200);

      setActiveCategoryId(categoryId);
      history.push(generatePath(CATEGORY_DETAIL_URL, { categoryId }));
    },
    [setActiveCategoryId, history]
  );

  const handleCreateCategoryDialogClose = useCallback(() => {
    setCategoryDialogVisible(false);
    createCategoryRef.current && createCategoryRef.current.focus();
  }, []);

  if (!activeCategories) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {activeCategories
        .filter(
          (category) =>
            category.id !== UNCATEGORIZED_ID && category.state === ACTIVE
        )
        .map((category) => (
          <NavButton
            key={category.id}
            icon={CATEGORY_ICONS[category.icon]?.icon}
            active={categorySidebarExpanded && isActive(category.id)}
            onClick={() => setActive(category.id)}
            aria-label={
              categorySidebarExpanded && isActive(category.id)
                ? `Hide ${category.name} List`
                : `Show ${category.name} List`
            }
            data-category-color={category.color}
          >
            {category.name}
          </NavButton>
        ))}

      <NavButton
        ref={createCategoryRef}
        className={styles.createButton}
        icon={IconAdd}
        onClick={() => setCategoryDialogVisible(true)}
        aria-label={"New Category"}
      >
        Category
      </NavButton>

      {categoryDialogVisible && (
        <CreateCategoryDialog
          onClose={handleCreateCategoryDialogClose}
          onCreate={handleCategoryCreated}
        />
      )}
    </>
  );
}

export default SidebarNavCategory;
