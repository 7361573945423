import React, { useCallback, useRef, useState } from "react";

import { scrollIntoViewIfNeeded } from "src/utils/scrollIntoViewIfNeeded";

import useUniqueId from "../../../hooks/useUniqueId";

import EventCard, { useBoxesRefs } from "../EventCard";
import ExternalEventDialog from "./ExternalEventDialog";

function ExternalEventCard({
  event,
  allDay = false,
  scrollableRef,
  boxes,
  open,
  onOpen,
  onClose,
}) {
  const { id: eventId, startDate, duration, description } = event;

  const [boxRef, setBoxRef] = useState(null);

  const elemId = useUniqueId();

  const eventRef = useRef();
  const boxesRefs = useBoxesRefs(boxes?.length);

  const handleDialogClose = useCallback(() => {
    if (!open) return;

    onClose();
    eventRef.current.focus();
    setBoxRef(null);
  }, [onClose, open]);

  const handleClick = useCallback(
    (boxRef) => {
      if (open) {
        handleDialogClose();
      } else {
        onOpen(eventId);
        setBoxRef(boxRef);
      }
    },
    [eventId, open, onOpen, handleDialogClose]
  );

  const handleFocus = useCallback(() => {
    const boxToScrollTo = boxRef?.current || boxesRefs?.[0]?.current;
    scrollIntoViewIfNeeded(boxToScrollTo, scrollableRef?.current);
  }, [boxesRefs, boxRef, scrollableRef]);

  return (
    <>
      <EventCard
        ref={eventRef}
        eventId={eventId}
        allDay={allDay}
        description={description}
        startDate={startDate}
        duration={duration}
        boxes={boxes}
        boxesRefs={boxesRefs}
        onClick={handleClick}
        onFocus={handleFocus}
        aria-expanded={open}
        id={elemId}
        external
      />

      {open && (
        <ExternalEventDialog
          event={event}
          boxRef={boxRef}
          onClose={handleDialogClose}
          aria-labelledby={elemId}
        />
      )}
    </>
  );
}

export default ExternalEventCard;
