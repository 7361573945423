import React, { useRef, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { NetworkError, ApiParameterError } from "../../services/ApiService";
import { registerForUpdates } from "../../services/AuthService";

import Button, { BUTTON_COLOR_FILL } from "../Button";
import Input from "../Input";
import PageNoUi from "../PageNoUi";
import AuthForm from "./AuthForm";

function Subscribe() {
  const history = useHistory();

  const [email, setEmail] = useState(history.location.state?.email || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setError("");

    const email = emailRef.current.value;

    if (!email) {
      setError("Email is required");
      emailRef.current.focus();
      return;
    }
    setLoading(true);

    try {
      await registerForUpdates(email);
    } catch (e) {
      setLoading(false);
      if (e instanceof NetworkError) {
        setError(
          "Network error, please check your internet connection and try\xa0again"
        );
      } else if (e instanceof ApiParameterError) {
        setError("Invalid email\xa0address");
      } else {
        setError("Unexpected error, please try\xa0again");
      }
      // Move the focus on email so the user can make corrections or just
      // press Enter to try again
      emailRef.current.focus();
      return;
    }

    setSuccess(true);
    setLoading(false);
  }, []);

  const text = !success
    ? "Sorry you don’t have access yet. Please enter your email below to get launch\xa0updates."
    : "Thank you for registering your\xa0interest.";

  return (
    <PageNoUi pageTitle="Subscribe" text={text}>
      {!success && (
        <AuthForm
          error={error}
          loading={loading}
          buttons={
            <Button
              type="submit"
              loading={loading}
              onClick={onSubmit}
              color={BUTTON_COLOR_FILL}
              block
            >
              Get Updates
            </Button>
          }
        >
          <Input
            label="Your email"
            type="email"
            autoFocus
            autoComplete="email"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            ref={emailRef}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </AuthForm>
      )}
    </PageNoUi>
  );
}

export default Subscribe;
