export const PRODUCT_NAME = process.env.REACT_APP_NAME;

export const DATASET_ENVS = {
  prod: "PROD",
  staging: "STAGING",
  dev: "DEV",
};

export const NBSP = "\xa0";
export const HAIRSPACE = "\u200A";

export const CONTACT_US_LINK = "https://www.tonyrobbins.com/contact-us/";
export const FAQ_LINK = "https://wiki.rpmcalendar.com/tips-and-tricks";
export const HELP_LINK = "https://wiki.rpmcalendar.com/getting-started";
