import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";

import {
  ONBOARDING_GOALS_URL,
  ONBOARDING_CREATE_CATEGORY_URL,
  ONBOARDING_CATEGORY_DETAILS_URL,
  DASHBOARD_URLS,
} from "../App";

import { AuthContext } from "../AuthContext";
import DashboardNav from "../DashboardNav";
import ActionDialog from "../ActionDialog";
import OnboardingNav from "../OnboardingNav";

function NavContainer() {
  const { user } = useContext(AuthContext);

  const routeIsDashboard = useRouteMatch({
    path: [...DASHBOARD_URLS],
    exact: true,
  });
  const routeIsOnboarding = useRouteMatch({
    path: [
      ONBOARDING_GOALS_URL,
      ONBOARDING_CREATE_CATEGORY_URL,
      ONBOARDING_CATEGORY_DETAILS_URL,
    ],
    exact: true,
  });

  if (routeIsDashboard && user) {
    return (
      <>
        <DashboardNav />
        <ActionDialog />
      </>
    );
  }

  if (routeIsOnboarding) {
    return <OnboardingNav />;
  }

  return null;
}

export default NavContainer;
