import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getEnvVar, api, ApiError, ApiParameterError } from "./ApiService";
import { ENABLE_FIRESTORE_PERSISTENCE } from "./DbService/constants";

const USER_TOKENS = "userTokens";

export const USER_PROFILE_IMAGE_MAX_FILE_SIZE_MB = 2;
export const USER_PROFILE_IMAGE_MAX_LENGTH = 800;

const subscribers = new Set();

/**
 * The function param for the callback "newUser" can be null if the user is
 * logged out, a user object (not a User from the DBService) if they are logged
 * in, or an Error if something went
 * @param  {Function} callback
 */
export function watchUser(callback) {
  subscribers.add(callback);
  return () => {
    subscribers.delete(callback);
  };
}

function notifySubscribers(newUser) {
  subscribers.forEach((callback) => callback(newUser));
}

firebase.initializeApp({
  apiKey: getEnvVar("FIREBASE_API_KEY"),
  authDomain: getEnvVar("FIREBASE_AUTH_DOMAIN"),
  databaseURL: getEnvVar("FIREBASE_DATABASE_URL"),
  projectId: getEnvVar("FIREBASE_PROJECT_ID"),
  storageBucket: getEnvVar("FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getEnvVar("FIREBASE_MESSAGING_SENDER_ID"),
  appId: getEnvVar("FIREBASE_APP_ID"),
  measurementId: getEnvVar("FIREBASE_MEASUREMENT_ID"),
});
if (ENABLE_FIRESTORE_PERSISTENCE) firebase.firestore().enablePersistence();

firebase.auth().onAuthStateChanged((firebaseUser) => {
  // If the user has signed out from Firebase or has signed in without any
  // valid token on local storage make sure to remove also its other credentials
  const { accessToken } = getAuthTokens();

  if (firebaseUser === null || (firebaseUser && !accessToken)) {
    removeAuthTokens();
    notifySubscribers(null);
    return;
  }

  getUser();
});

export class InvalidCacheError extends Error {}
export class InvalidEmailError extends Error {}
export class InvalidParameterError extends Error {}
export class InvalidPasswordError extends Error {}
export class AlreadyRegisteredEmailError extends Error {}
export class AlreadyRegisteredUsernameError extends Error {}
export class NotAuthorizedError extends Error {}
export class NotVerifiedError extends Error {}
export class UnauthorizedEmailError extends Error {}
export class CognitoJwtExpiredError extends Error {}
export class CorruptedUserError extends Error {}
export class UserNotFoundError extends Error {}

function setAuthTokens(response) {
  const {
    idToken: IDToken,
    accessToken,
    refreshToken,
    user: { username, uid, calendarID },
  } = response;

  localStorage.setItem(
    USER_TOKENS,
    JSON.stringify({
      IDToken,
      accessToken,
      refreshToken,
      username,
      uid,
      calendarID,
    })
  );
}

export function getAuthTokens() {
  return JSON.parse(localStorage.getItem(USER_TOKENS)) || {};
}

function removeAuthTokens() {
  localStorage.removeItem(USER_TOKENS);
}

function setCalendarId(calendarID) {
  localStorage.setItem(
    USER_TOKENS,
    JSON.stringify({
      ...getAuthTokens(),
      calendarID,
    })
  );
}

export async function getRpmCalendarUrl() {
  let { calendarID, username, refreshToken } = getAuthTokens();
  if (!calendarID) {
    // Users who logged in with an old version of the application don't have
    // the calendarID saved in the localstorage, so it has to be retrieved
    // from the servers. This code will be no more needed in future.
    const response = await api(
      `/api/users?username=${username}&cognitoRefreshToken=${refreshToken}`,
      null,
      { method: "GET" }
    );
    if (response.errors) {
      throw new ApiError(response);
    }
    calendarID = response.calendarID;
    setCalendarId(calendarID);
  }
  return new URL(
    `/api/calendar?calendarID=${calendarID}`,
    getEnvVar("BACKEND_API_URL")
  );
}

export async function signIn(usernameOrEmail, password) {
  // Username or email can be used to sign in
  const response = await api("/api/auth/login", {
    username: usernameOrEmail,
    password,
  });

  if (response.errors) {
    const errorType = response.error_type;
    const { code: errorCode, message: errorMessage } = response.errors[0];
    if (
      errorType === "ValidationError" ||
      errorCode === "NotAuthorizedException"
    ) {
      // ValidationError is triggered when the username is not valid or there
      // is an empty field, not meaningful enough to require a separate
      // error handling
      throw new NotAuthorizedError(errorMessage);
    } else if (errorCode === "UserNotFoundException") {
      throw new UserNotFoundError(errorMessage);
    } else if (errorCode === "UserNotConfirmedException") {
      throw new NotVerifiedError(errorMessage);
    } else if (
      errorType === "CognitoError" &&
      errorMessage.includes("profile corrupted")
    ) {
      throw new CorruptedUserError("No user document – signIn()");
    } else {
      throw new ApiError(response);
    }
  }

  await firebase.auth().signInWithCustomToken(response.firebaseToken);

  setAuthTokens(response);
  notifySubscribers(response.user);
}

export async function signUp(email, password, username) {
  const response = await api("/api/auth/register", {
    email,
    username,
    password,
  });
  if (response.errors) {
    // Unfortunately some errors don't have the field 'code', so I have
    // to filter them using the less robust 'message'
    const { message, code: errorCode } = response.errors[0];

    if (
      message === "username is a required field" ||
      message === "username must be a valid email"
    ) {
      throw new InvalidEmailError(message);
    } else if (
      message === "password is a required field" ||
      (errorCode === "min" &&
        message === "password must be at least 6 characters")
    ) {
      throw new InvalidPasswordError(message);
    } else if (errorCode === "UsernameExistsException") {
      throw new AlreadyRegisteredUsernameError(message);
    } else if (errorCode === "EmailExistsException") {
      throw new AlreadyRegisteredEmailError(message);
    } else if (
      errorCode === "UnauthorizedEmail" &&
      message === "The email is not authorized to register"
    ) {
      throw new UnauthorizedEmailError(message);
    } else if (errorCode === "InvalidParameterException") {
      throw new InvalidParameterError(message);
    } else {
      throw new ApiError(response);
    }
  }
}

export async function verifyEmail(username, code) {
  const response = await api("/api/auth/verify", {
    username,
    confirmationCode: code,
  });
  if (response.errors) {
    const message = response.errors[0].message;
    const errorCode = response.errors[0].code;
    if (
      errorCode === "ExpiredCodeException" ||
      errorCode === "CodeMismatchException" ||
      message === "confirmationCode is a required field"
    ) {
      throw new NotAuthorizedError(message);
    } else {
      throw new ApiError(response);
    }
  }
}

export async function resendVerificationCode(username) {
  const response = await api("/api/auth/resend-verification-code", {
    username,
  });
  if (response.errors) {
    const errorCode = response.errors[0].code;
    if (errorCode) {
      throw new ApiError(response);
    }
  }
}

export async function checkAccess(email) {
  const response = await api("/api/auth/check-registration-access", { email });
  if (response.errors) {
    const { message } = response.errors[0];

    if (message === "email must be a valid email") {
      throw new InvalidEmailError(message);
    } else {
      throw new ApiError(response);
    }
  }

  return response;
}

export async function forgotPassword(username) {
  const response = await api("/api/auth/forgot-password", { username });
  if (response.errors) {
    throw new ApiError(response);
  }

  return response;
}

export async function resetPassword(username, confirmationCode, newPassword) {
  const response = await api("/api/auth/confirm-forgot-password", {
    username,
    confirmationCode,
    password: newPassword,
  });

  if (response.errors) {
    // Unfortunately some errors don't have the field 'code', so I have
    // to filter them using the less robust 'message'
    const { message, code: errorCode } = response.errors[0];

    if (
      message === "password is a required field" ||
      (errorCode === "min" &&
        message === "password must be at least 6 characters")
    ) {
      throw new InvalidPasswordError(message);
    } else if (
      errorCode === "ExpiredCodeException" ||
      errorCode === "CodeMismatchException" ||
      message === "confirmationCode is a required field"
    ) {
      throw new NotAuthorizedError(message);
    }

    throw new ApiError(response);
  }

  return response;
}

export async function getUser() {
  const response = await api("/api/users/me", null, {
    method: "GET",
    authenticate: true,
  });

  if (response.errors) {
    if (response.error_type === "CognitoUnauthorizedError") {
      // This passes the error through to the AuthContext which allows for
      // errors to be handled appropriately
      notifySubscribers(new InvalidCacheError("Firestore cache mismatch"));
      return;
    }

    throw new ApiError(response);
  }

  notifySubscribers(response);

  return response;
}

export async function setUser(body) {
  const response = await api("/api/users/me", body, {
    method: "PATCH",
    authenticate: true,
  });

  if (response.errors) {
    const { code: errorCode, message: errorMessage } = response.errors[0];

    if (response.error_type === "ValidationError") {
      throw new NotAuthorizedError(errorMessage);
    } else if (errorCode === "UserNotConfirmedException") {
      throw new NotVerifiedError(errorMessage);
    } else if (errorCode === "AliasExistsException") {
      throw new AlreadyRegisteredUsernameError(errorMessage);
    } else if (errorCode === "InvalidParameterException") {
      throw new InvalidParameterError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  const updatedUserResponse = await getUser();

  if (updatedUserResponse.errors) {
    const { code: errorCode, message: errorMessage } =
      updatedUserResponse.errors[0];

    if (response.error_type === "ValidationError") {
      throw new NotAuthorizedError(errorMessage);
    } else if (errorCode === "UserNotConfirmedException") {
      throw new NotVerifiedError(errorMessage);
    } else if (errorCode === "AliasExistsException") {
      throw new AlreadyRegisteredUsernameError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  return updatedUserResponse;
}

export async function signOut() {
  await firebase.auth().signOut();
}

export async function refreshTokens() {
  const { username, refreshToken } = getAuthTokens();

  console.log("Refreshing Access Token...");
  const response = await api("/api/auth/refresh", { username, refreshToken });
  if (response.errors) {
    if (response.errors.some(({ code }) => code === "NotAuthorizedException")) {
      console.log("Refresh Token is invalid, signing out");
      signOut();
      return;
    } else {
      // We received unexpected errors
      throw new ApiError(response);
    }
  }

  // If execution gets here we have fresh tokens to store
  setAuthTokens(response);
  notifySubscribers(response.user);
  console.log("Access Token refreshed successfully");
}

export async function registerForUpdates(email) {
  const response = await api("/api/auth/register-for-updates", { email });

  if (response.errors) {
    const errorMessage = response.errors[0].message;

    if (
      response.error_type === "ValidationError" &&
      errorMessage === "email must be a valid email"
    ) {
      throw new ApiParameterError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  // An extra check to make sure that the email was successfuly added, if not
  // throw a generic error.
  if (!response.success) {
    throw new ApiError(response);
  }

  return response;
}
