import imageCompression from "browser-image-compression";

import { api } from "./ApiService";

export async function uploadPhoto(file, maxSizeMb, maxLength) {
  const compressedFile = await imageCompression(file, {
    maxSizeMB: maxSizeMb,
    maxWidthOrHeight: maxLength,
  });

  const formData = new FormData();
  formData.append("photo", compressedFile);

  const response = await api("/api/photos/upload", formData, {
    contentType: "multipart/form-data",
    authenticate: true,
  });

  return response.location;
}
