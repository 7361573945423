import React, { useRef, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  CATEGORY_ACTIVE,
  CATEGORY_HIDDEN,
  CATEGORY_NAME_MAX_LENGTH,
  CATEGORY_FIELDS,
  CATEGORY_ICONS,
  TYPE_LABELS,
} from "src/services/DbService/constants";
import {
  watchCategory,
  updateCategory,
  setCategoryState,
} from "src/services/DbService/categories";

import { replaceMultipleLineBreaks } from "src/utils/replaceMultipleLineBreaks";
import useUniqueId from "src/hooks/useUniqueId";

import { useGetCategoryColor } from "../../CategoriesContext";
import { DASHBOARD_URL } from "../../App";
import Button from "../../Button";
import CategoryRadioGroupIcon from "../../CategoryRadioGroup/CategoryRadioGroupIcon";
import CategoryRadioGroupColor from "../../CategoryRadioGroup/CategoryRadioGroupColor";
import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
} from "../../ContextualMenu";

import DetailPanelWrapper from "../DetailPanelWrapper";
import DetailPanelHero from "../DetailPanelHero";
import DetailPanelFields from "../DetailPanelFields";
import DetailPanelForm from "../DetailPanelForm";

import { ReactComponent as IconClose } from "src/assets/icons/16-close.svg";
import { ReactComponent as IconMore } from "src/assets/icons/16-more.svg";

function CategoryDetail({ closeRedirectUrl = DASHBOARD_URL, ...props }) {
  const { categoryId: paramsCategoryId } = useParams();

  const iconLabelId = useUniqueId();
  const colorLabelId = useUniqueId();

  const [category, setCategory] = useState({});

  const editButtonRef = useRef();
  const closeButtonRef = useRef();

  const { id: categoryId, name, state, icon } = category;

  const [editing, setEditing] = useState(null);
  const [newColor, setNewColor] = useState(null);

  const getCategoryColor = useGetCategoryColor();

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(null);

  useEffect(
    () => watchCategory(paramsCategoryId, setCategory),
    [paramsCategoryId]
  );

  // When the category is opened, transfer focus to the close button
  useEffect(() => {
    if (!categoryId) return;
    closeButtonRef.current && closeButtonRef.current.focus();
  }, [categoryId]);

  const handleToggleCategoryState = useCallback(() => {
    if (!categoryId) return;

    const newState =
      state === CATEGORY_ACTIVE ? CATEGORY_HIDDEN : CATEGORY_ACTIVE;

    setCategoryState(categoryId, newState);
    setOptionsMenuVisible(false);
    optionsMenuButtonRef.current && optionsMenuButtonRef.current.focus();
  }, [categoryId, state]);

  const handleSubmit = useCallback(
    (values) => {
      const formData = { ...values };

      for (const [key, value] of Object.entries(formData)) {
        formData[key] =
          typeof value === "string" ? replaceMultipleLineBreaks(value) : value;
      }

      const {
        name,
        vision,
        purpose,
        roles,
        thrive,
        resources,
        yearResults,
        color,
        icon,
      } = formData;

      updateCategory({
        id: categoryId,
        name: name.trim(),
        icon,
        color,
        vision: vision || "",
        purpose: purpose || "",
        roles: roles || "",
        thrive: thrive || "",
        resources: resources || "",
        yearResults: yearResults || "",
      });

      setEditing(false);

      editButtonRef.current && editButtonRef.current.focus();
    },
    [categoryId, setEditing]
  );

  const handleChange = useCallback((values) => {
    setNewColor(values?.color);
  }, []);

  const categoryColor = editing ? newColor : getCategoryColor(categoryId);

  return (
    <DetailPanelWrapper
      name={name}
      editing={editing}
      loading={!categoryId}
      data-category-color={categoryColor}
      {...props}
    >
      {!editing && (
        <>
          <DetailPanelHero
            categoryId={categoryId}
            heading={name}
            headingIcon={CATEGORY_ICONS[icon]?.icon}
            buttons={
              <>
                <Button ref={editButtonRef} onClick={() => setEditing("name")}>
                  Edit
                  <span className="sr-only">{TYPE_LABELS.category.title}</span>
                </Button>
                <ContextualMenuTrigger
                  menuId={optionsMenuButtonId}
                  visible={optionsMenuVisible}
                  setVisible={setOptionsMenuVisible}
                >
                  <Button
                    ref={optionsMenuButtonRef}
                    iconOnly
                    aria-label={`${TYPE_LABELS.category.title} Options`}
                    tooltip
                  >
                    <IconMore role="presentation" />
                  </Button>
                </ContextualMenuTrigger>

                {optionsMenuVisible && (
                  <ContextualMenu
                    buttonRef={optionsMenuButtonRef}
                    onClose={() => setOptionsMenuVisible(false)}
                    aria-labelledby={optionsMenuButtonId}
                  >
                    <ContextualMenuLink onClick={handleToggleCategoryState}>
                      {state !== CATEGORY_HIDDEN ? "Hide" : "Show"}{" "}
                      {TYPE_LABELS.category.title}
                    </ContextualMenuLink>
                  </ContextualMenu>
                )}

                <Button
                  ref={closeButtonRef}
                  linkTo={closeRedirectUrl}
                  iconOnly
                  aria-label={`Close ${TYPE_LABELS.category.title}`}
                  tooltip
                >
                  <IconClose role="presentation" />
                </Button>
              </>
            }
          />

          <DetailPanelFields
            fields={CATEGORY_FIELDS}
            values={category}
            setEditing={setEditing}
          />
        </>
      )}

      <DetailPanelForm
        editing={editing}
        setEditing={setEditing}
        values={category}
        onSubmit={handleSubmit}
        onChange={handleChange}
        fields={[
          {
            slug: "name",
            label: "Name",
            placeholder: "e.g. Health and Vitality",
            maxLength: CATEGORY_NAME_MAX_LENGTH,
            counter: true,
          },
          {
            slug: "color",
            id: colorLabelId,
            label: "Color",
            component: CategoryRadioGroupColor,
          },
          {
            slug: "icon",
            id: iconLabelId,
            label: "Icon",
            component: CategoryRadioGroupIcon,
          },
          ...CATEGORY_FIELDS,
        ]}
      />
    </DetailPanelWrapper>
  );
}

export default CategoryDetail;
