import React, { useCallback, useState, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { sleep } from "src/utils/sleep";

import useUniqueId from "../../hooks/useUniqueId";

import {
  ACTIVE,
  COMPLETED,
  CATEGORY,
  STATE_LABELS,
  TYPE_LABELS,
} from "../../services/DbService/constants";
import {
  blockHasIncompleteActions,
  blockHasEvents,
  deleteBlock,
  updateBlock,
} from "src/services/DbService/blocks";
import fireConfetti from "src/services/ConfettiService";

import { BLOCK_DETAIL_URL, DASHBOARD_URL } from "../App";
import Button from "../Button";
import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
  ContextualMenuTaxonomy,
  ContextualMenuProjects,
} from "src/components/ContextualMenu";
import Dialog from "../Dialog";

const DELETE_DIALOG_EVENTS = "events";
const DELETE_DIALOG_INCOMPLETE = "incomplete";

export const tryCompleteBlock = async (blockId, onFail) => {
  const hasIncompleteBlocks = await blockHasIncompleteActions(blockId);

  if (hasIncompleteBlocks) {
    onFail();
    return;
  }

  // saveActiveActions defaults to captureList to ensure that no uncaught actions
  // are missed – this is an extra safety precaution that is unlikely to be used.
  updateBlock(blockId, { state: COMPLETED, activeActionsTo: CATEGORY });
  fireConfetti();
};

function BlockItemCompleteDialog({ onClose, blockId, ...props }) {
  const handleSetCompleted = useCallback(() => {
    updateBlock(blockId, { state: COMPLETED, activeActionsTo: COMPLETED });
    onClose();
  }, [blockId, onClose]);

  const handleRemoveFromBlock = useCallback(() => {
    updateBlock(blockId, { state: COMPLETED, activeActionsTo: CATEGORY });
    onClose();
  }, [blockId, onClose]);

  return (
    <Dialog
      headerTitle="Incomplete actions"
      onClose={onClose}
      footer={
        <>
          <Button block onClick={handleSetCompleted}>
            Mark as Completed
          </Button>
          <Button block onClick={handleRemoveFromBlock}>
            Remove from block
          </Button>
        </>
      }
    >
      <p>
        What would you like to do with the incomplete actions within this block?
      </p>
    </Dialog>
  );
}

function BlockItemMenu({
  block,
  showCelebrationDialog,
  previousUrl = DASHBOARD_URL,
  detailUrl = BLOCK_DETAIL_URL,
  onEdit,
  onExportPdf,
  showSnoozeButton = true,
  showActiveButton = true,
  showCategoryButton = true,
  menuVisible,
  onClose,
  ...props
}) {
  const { id: blockId, projectId, categoryId, state } = block;

  const match = useRouteMatch(detailUrl);
  const active = match?.params.blockId === blockId;
  const history = useHistory();

  const [completeDialogVisible, setCompleteDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const categoryMenuButtonRef = useRef();
  const categoryMenuButtonId = useUniqueId();
  const [categoryMenuVisible, setCategoryMenuVisible] = useState(null);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleEdit = useCallback(() => {
    onEdit && onEdit();
    handleClose();
  }, [onEdit, handleClose]);

  const handleComplete = useCallback(() => {
    tryCompleteBlock(blockId, () => {
      handleClose();
      setCompleteDialogVisible(true);
    });
  }, [handleClose, blockId]);

  const handleSetActive = useCallback(() => {
    handleClose();
    updateBlock(blockId, { state: ACTIVE });
  }, [handleClose, blockId]);

  const handleSetProject = useCallback(
    (newProjectId) => {
      updateBlock(blockId, { projectId: newProjectId });
    },
    [blockId]
  );

  const handleSetCategory = useCallback(
    (newCategoryId) => {
      updateBlock(blockId, { categoryId: newCategoryId });
      setCategoryMenuVisible(false);
      handleClose();
    },
    [handleClose, blockId]
  );

  const handleExportPdf = useCallback(() => {
    handleClose();
    onExportPdf && onExportPdf();
  }, [handleClose, onExportPdf]);

  const performDelete = useCallback(
    (saveActiveActions) => {
      deleteBlock(blockId, saveActiveActions);
      setDeleteDialogVisible(false);
      if (active) history.push(previousUrl ? previousUrl : DASHBOARD_URL);
    },
    [blockId, active, history, previousUrl]
  );

  const handleDelete = useCallback(async () => {
    handleClose();
    // This check is ignored if the dialog is already showing the message about
    // scheduled events (and is in this order to avoid blockHasEvents being called
    // unnecessarily).
    if (
      deleteDialogVisible !== DELETE_DIALOG_EVENTS &&
      (await blockHasEvents(blockId))
    ) {
      setDeleteDialogVisible(DELETE_DIALOG_EVENTS);
      return;
    }

    if (await blockHasIncompleteActions(blockId)) {
      if (deleteDialogVisible === DELETE_DIALOG_EVENTS) {
        // Because a dialog is already showing, hiding it and popping the other one –
        // even with just a 200ms delay – gives the user the understanding that
        // there is a new dialog to interact with. It's a short enough delay that
        // there should be no ill effects if a user tries to do something before
        // the new modal pops up.
        setDeleteDialogVisible(false);
        await sleep(200);
      }

      setDeleteDialogVisible(DELETE_DIALOG_INCOMPLETE);
      return;
    }

    performDelete(true);
  }, [blockId, performDelete, deleteDialogVisible, handleClose]);

  return (
    <>
      {menuVisible && (
        <ContextualMenu onClose={onClose} {...props}>
          {onEdit && state !== COMPLETED && (
            <ContextualMenuLink onClick={handleEdit}>
              Edit {TYPE_LABELS.block.title}
            </ContextualMenuLink>
          )}

          {state !== ACTIVE && showActiveButton && (
            <ContextualMenuLink onClick={handleSetActive}>
              Make {TYPE_LABELS.block.title} {STATE_LABELS.active.title}
            </ContextualMenuLink>
          )}

          {state !== COMPLETED && (
            <ContextualMenuLink onClick={handleComplete}>
              Complete {TYPE_LABELS.block.title}
            </ContextualMenuLink>
          )}

          {showCategoryButton && (
            <>
              <ContextualMenuTrigger
                visible={categoryMenuVisible}
                setVisible={setCategoryMenuVisible}
                menuId={categoryMenuButtonId}
                submenu
              >
                <ContextualMenuLink ref={categoryMenuButtonRef} submenu>
                  Change {TYPE_LABELS.category.title}
                </ContextualMenuLink>
              </ContextualMenuTrigger>

              {categoryMenuVisible && (
                <ContextualMenu
                  buttonRef={categoryMenuButtonRef}
                  onClose={() => setCategoryMenuVisible(false)}
                  submenu
                  aria-labelledby={categoryMenuButtonId}
                >
                  <ContextualMenuTaxonomy
                    parentType={CATEGORY}
                    onItemClick={handleSetCategory}
                    activeId={categoryId}
                  />
                </ContextualMenu>
              )}
            </>
          )}

          <ContextualMenuProjects
            activeProjectId={projectId}
            onProjectClick={handleSetProject}
            onClose={handleClose}
          />

          {onExportPdf && (
            <ContextualMenuLink onClick={handleExportPdf}>
              Export PDF
            </ContextualMenuLink>
          )}

          <ContextualMenuLink onClick={handleDelete} negative>
            Delete {TYPE_LABELS.block.title}
          </ContextualMenuLink>
        </ContextualMenu>
      )}

      {deleteDialogVisible === DELETE_DIALOG_EVENTS && (
        <Dialog
          headerTitle="Scheduled Block"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>
                Cancel
              </Button>
              <Button block onClick={handleDelete} negative>
                Delete block
              </Button>
            </>
          }
        >
          <p>
            This block is scheduled. Deleting it may also delete those scheduled
            events. Do you wish to&nbsp;proceed?
          </p>
        </Dialog>
      )}

      {deleteDialogVisible === DELETE_DIALOG_INCOMPLETE && (
        <Dialog
          headerTitle="Incomplete Actions"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block negative onClick={() => performDelete(false)}>
                Delete Actions
              </Button>
              <Button block onClick={() => performDelete(true)}>
                Remove From Block
              </Button>
            </>
          }
        >
          <p>
            This block has incomplete actions. Do you want to remove them from
            the block or delete them?
          </p>
        </Dialog>
      )}

      {completeDialogVisible && (
        <BlockItemCompleteDialog
          blockId={blockId}
          onClose={() => setCompleteDialogVisible(false)}
        />
      )}
    </>
  );
}

BlockItemCompleteDialog.displayName = "BlockItemCompleteDialog";

export { BlockItemMenu, BlockItemCompleteDialog };
