// This uses a forked version of https://www.npmjs.com/package/canvas-confetti to get the
// custom shapes required by design
const confetti = require("./canvas-confetti.js");

const CONFETTI_DEFAULTS = {
  origin: {
    y: -0.1,
  },
  startVelocity: 100,
  gravity: 4,
  ticks: 400,
  useWorker: true,
  colors: ["#FFE100", "#FF0000", "#0061FF", "#00C8FF", "#FF8000"],
  shapes: [
    "squiggle",
    // The latter 2 are in the config more frequently for a higher ratio
    // of squares and circles to squiggles
    "rounded-square",
    "rounded-square",
    "circle",
    "circle",
  ],
  disableForReducedMotion: true,
  zIndex: 999999,
};

function fire(particleRatio, options = {}) {
  const confettiOptions = {
    ...CONFETTI_DEFAULTS,
    ...options,
    particleCount: Math.floor(options.particleCount * particleRatio),
  };

  confetti(confettiOptions);
}

export default function fireConfetti(
  particleCount = 120,
  ticks = CONFETTI_DEFAULTS.ticks
) {
  fire(0.25, {
    particleCount,
    spread: 140,
    ticks: ticks * 1.1,
  });

  fire(0.2, {
    spread: 60,
  });

  fire(0.35, {
    particleCount,
    spread: 100,
    decay: 0.91,
    gravity: CONFETTI_DEFAULTS.gravity * 0.8,
    ticks: ticks * 0.8,
  });

  fire(0.2, {
    particleCount,
    spread: 120,
    startVelocity: CONFETTI_DEFAULTS.startVelocity * 0.8,
    decay: 0.92,
  });
}
