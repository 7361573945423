import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

import { PRODUCT_NAME, DATASET_ENVS } from "src/constants";

function Head({ title = "", titleSuffix = PRODUCT_NAME }) {
  const dataset = process.env.REACT_APP_DATASET;

  // datasetString must be a string to avoid <Helmet> throwing an error
  const datasetString = dataset !== DATASET_ENVS.prod ? ` [${dataset}]` : "";

  const titleChunks = [];

  if (title) {
    if (title.length > 45) {
      title = `${title.substr(0, 45)}…`;
    }
    titleChunks.push(title);
  }

  titleSuffix && titleChunks.push(titleSuffix);

  return (
    <Helmet>
      <title>
        {titleChunks.join(" | ")}
        {datasetString}
      </title>
    </Helmet>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
};

export default Head;
