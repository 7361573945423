import React, { forwardRef } from "react";
import clsx from "clsx";

import NavItem from "../NavItem";

import styles from "./NavButton.module.scss";

const NavButton = forwardRef(
  ({ className, icon, children, active, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(className, styles.navButton)}
        {...props}
      >
        <NavItem active={active} icon={icon}>
          {children}
        </NavItem>
      </button>
    );
  }
);

NavButton.displayName = "NavButton";

export default NavButton;
