import React, { forwardRef } from "react";
import clsx from "clsx";

import { CATEGORY_COLOR_LABELS } from "../../services/DbService/constants";

import useUniqueId from "../../hooks/useUniqueId";

import Tooltip from "../Tooltip";

import { ReactComponent as IconCheck } from "./../../assets/icons/16-check.svg";

import styles from "./CategoryRadioGroupColor.module.scss";

const CategoryRadioGroupColor = forwardRef(
  ({ className, value, onChange, ...props }, ref) => {
    const colorName = useUniqueId();

    return (
      <div
        className={clsx(className, styles.colors)}
        role="radiogroup"
        ref={ref}
        {...props}
      >
        {Object.entries(CATEGORY_COLOR_LABELS).map(([slug, label]) => {
          const checked = value === slug;

          return (
            <Tooltip title={label} key={slug}>
              <div className={styles.color} data-category-color={slug}>
                <input
                  type="radio"
                  name={colorName}
                  value={slug}
                  checked={checked}
                  onChange={onChange}
                  aria-label={label}
                />
                <span className={styles.focusRing} aria-hidden="true" />
                <IconCheck role="presentation" />
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
);

CategoryRadioGroupColor.displayName = "CategoryRadioGroupColor";

export default CategoryRadioGroupColor;
