import {
  STARRED,
  UNSTARRED,
  ACTIVE,
  CATEGORY_ACTIVE,
  CATEGORY_HIDDEN,
  CATEGORY_NAME_MAX_LENGTH,
  CATEGORY_ICONS,
  CATEGORY_DEFAULT_ICON,
  UNCATEGORIZED_ICON,
  CATEGORY_COLOR_LABELS,
  CATEGORY_DEFAULT_COLOR,
  UNCATEGORIZED_ID,
} from "./constants";
import {
  firestore,
  collection,
  document,
  ParameterError,
  generateId,
} from "./general";
import { trackEvent } from "../AnalyticsService";

const LEGACY_COLORS_MAP = {
  "#00C4D2": "5",
  "#2790FF": "5",
  "#7EB800": "4",
  "#8935FF": "6",
  "#FF44B5": "8",
  "#FF4D4A": "1",
  "#7C7C99": "0",
  "#FF9529": "2",
};

export class Category {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;

    const categoryId = snapshot.id;

    let { color } = data;

    // Legacy colours were stored as hexes, so if no color string is set, we convert the
    // background_color.hex value (if present) using the LEGACY_COLORS_MAP into the
    // closest colour string we have in the palette. If not present, the default
    // colour is used.
    if (!color) {
      const backgroundLegacyDecimal = data.background_color?.hex;
      const backgroundLegacyHex =
        backgroundLegacyDecimal &&
        `#${backgroundLegacyDecimal
          .toString(16)
          .padStart(6, "0")
          .toUpperCase()}`;

      color = LEGACY_COLORS_MAP[backgroundLegacyHex] || CATEGORY_DEFAULT_COLOR;
    }

    // CATEGORY_ICONS[data.icon || CATEGORY_DEFAULT_ICON] doesn't work here in instances
    // where old icon names were used. As this change was after the cut off for stable data,
    // we have to safely degrade to using the CATEGORY_DEFAULT_ICON if category.icon is truthy,
    // but invalid (i.e. an old icon slug).
    const icon =
      categoryId === UNCATEGORIZED_ID
        ? UNCATEGORIZED_ICON.key
        : CATEGORY_ICONS[data.icon]
        ? data.icon
        : CATEGORY_DEFAULT_ICON;

    const orderedActiveStarredActionsIds =
      data.actions_lists?.[ACTIVE]?.[STARRED]?.ordered_ids || [];
    const orderedActiveUnstarredActionsIds =
      data.actions_lists?.[ACTIVE]?.[UNSTARRED]?.ordered_ids || [];
    const orderedActiveBlocksIds =
      data.blocks_lists?.[ACTIVE]?.ordered_ids || [];

    return Object.assign(new Category(), {
      id: categoryId,
      name: data.name,
      icon,
      color,
      editable: data.is_editable,
      // active is set when state is empty to handle legacy categories with no state
      state: data.state || CATEGORY_ACTIVE,
      orderedActiveChildrenIds: data.active_children_ordered_ids || [],
      // TODO: remove all usages of orderedActionsIds and orderedBlocksIds when
      // the new code using orderedChildrenIds has been out for a while
      orderedActionsIds: {
        [ACTIVE]: {
          [STARRED]: orderedActiveStarredActionsIds,
          [UNSTARRED]: orderedActiveUnstarredActionsIds,
        },
      },
      orderedBlocksIds: {
        [ACTIVE]: orderedActiveBlocksIds,
      },
      vision: data.vision || "",
      purpose: data.purpose || "",
      roles: data.roles || "",
      thrive: data.thrive || "",
      resources: data.resources || "",
      yearResults: data.year_results || "",
    });
  }
}

export function watchCategories(callback) {
  return collection("categories")
    .orderBy("name", "asc")
    .onSnapshot((snapshot) => {
      let uncategorized;
      const categories = {
        [CATEGORY_ACTIVE]: [],
        [CATEGORY_HIDDEN]: [],
      };

      snapshot.docs.forEach((docSnapshot) => {
        const category = Category.fromFirestore(docSnapshot);

        if (category.id === UNCATEGORIZED_ID) {
          uncategorized = category;
          return;
        }

        categories[category.state].push(category);
      });

      if (uncategorized) {
        categories[CATEGORY_ACTIVE].push(uncategorized);
      }

      callback(categories);
    });
}

export function watchCategory(categoryId, callback) {
  return document(`categories/${categoryId}`).onSnapshot((docSnapshot) => {
    callback(Category.fromFirestore(docSnapshot));
  });
}

/**
 * Create a category
 */

export function createCategory(name, icon, color, callback = null) {
  if (typeof name !== "string")
    throw new ParameterError({ name }, "not a string");
  const nameTrim = name.trim();
  if (nameTrim === "") throw new ParameterError({ name }, "empty after trim");
  if (nameTrim.length > CATEGORY_NAME_MAX_LENGTH)
    throw new ParameterError(
      { name },
      "length must be <= " + CATEGORY_NAME_MAX_LENGTH
    );
  if (!Object.keys(CATEGORY_ICONS).includes(icon))
    throw new ParameterError(
      { icon },
      "invalid icon slug. Must be one of CATEGORY_ICONS"
    );
  if (!Object.keys(CATEGORY_COLOR_LABELS).includes(color))
    throw new ParameterError(
      { color },
      `invalid color slug. Must be one of ${CATEGORY_COLOR_LABELS.join(", ")}`
    );
  if (callback !== null && typeof callback !== "function")
    throw new ParameterError({ callback }, 'must be "null" or a function');

  const id = generateId();

  const newCategory = {
    id,
    name: nameTrim,
    color,
    is_built_in: false,
    is_editable: true,
    state: CATEGORY_ACTIVE,
    background_color: {
      // Old builds of the iOS app require a hex value here otherwise the categories
      // don't show. It was decided that we would add in the default gray colour with
      // no updating or legacy colour mapping on web with the view to revisiting
      // its inclusion once the `color` key was implemented on iOS and there were no
      // remaining users on the older version of the app.
      hex: 8158361,
    },
    icon,
  };

  const batch = firestore().batch();

  batch.set(document(`categories/${id}`), newCategory);
  batch.update(document(), {
    "categories_list.ordered_ids": firestore.FieldValue.arrayUnion(id),
  });

  batch.commit();

  trackEvent("User created a Category", {
    id,
    nameLength: nameTrim.length,
  });

  callback && callback(id);
}

export function updateCategory(category) {
  const {
    name,
    id,
    icon,
    color,
    vision,
    purpose,
    roles,
    thrive,
    resources,
    yearResults,
  } = category;

  if (id === UNCATEGORIZED_ID)
    throw new ParameterError({ id }, "Uncategorized is not editable");

  if (typeof name !== "string")
    throw new ParameterError({ name }, "not a string");
  const nameTrim = name.trim();
  if (nameTrim === "") throw new ParameterError({ name }, "empty after trim");
  if (nameTrim.length > CATEGORY_NAME_MAX_LENGTH)
    throw new ParameterError(
      { name },
      "length must be <= " + CATEGORY_NAME_MAX_LENGTH
    );

  if (!Object.keys(CATEGORY_ICONS).includes(icon))
    throw new ParameterError(
      { icon },
      "invalid icon slug, it must be one of CATEGORY_ICONS"
    );

  if (!Object.keys(CATEGORY_COLOR_LABELS).includes(color))
    throw new ParameterError(
      { color },
      `invalid color slug. Must be one of ${CATEGORY_COLOR_LABELS.join(", ")}`
    );

  if (typeof vision !== "string")
    throw new ParameterError(
      { vision },
      "must be a string (empty strings are permitted)"
    );
  if (typeof purpose !== "string")
    throw new ParameterError(
      { purpose },
      "must be a string (empty strings are permitted)"
    );
  if (typeof roles !== "string")
    throw new ParameterError(
      { roles },
      "must be a string (empty strings are permitted)"
    );
  if (typeof thrive !== "string")
    throw new ParameterError(
      { thrive },
      "must be a string (empty strings are permitted)"
    );
  if (typeof resources !== "string")
    throw new ParameterError(
      { resources },
      "must be a string (empty strings are permitted)"
    );
  if (typeof yearResults !== "string")
    throw new ParameterError(
      { yearResults },
      "must be a string (empty strings are permitted)"
    );

  document(`categories/${id}`).update({
    name: nameTrim,
    icon,
    color,
    vision: vision.trim(),
    purpose: purpose.trim(),
    roles: roles.trim(),
    resources: resources.trim(),
    thrive: thrive.trim(),
    year_results: yearResults,
  });
}

export async function setCategoryState(categoryId, newState) {
  if (newState !== CATEGORY_ACTIVE && newState !== CATEGORY_HIDDEN)
    throw new ParameterError(
      { newState },
      `must be "${CATEGORY_ACTIVE}", "${CATEGORY_HIDDEN}"`
    );

  const { state: oldState } = await getCategory(categoryId);

  if (newState === oldState) return;

  const batch = firestore().batch();

  batch.update(document(`categories/${categoryId}`), {
    state: newState,
  });

  if (newState === CATEGORY_ACTIVE) {
    batch.update(document(), {
      "categories_list.ordered_ids":
        firestore.FieldValue.arrayUnion(categoryId),
    });
  } else {
    batch.update(document(), {
      "categories_list.ordered_ids":
        firestore.FieldValue.arrayRemove(categoryId),
    });
  }

  batch.commit();
}

export async function getCategory(categoryId) {
  const docSnapshot = await document(`categories/${categoryId}`).get();
  return Category.fromFirestore(docSnapshot);
}
