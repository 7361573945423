import { firestore } from "./general";

// Batched writes in Firestore have a limit of 500 operations. Here the limit
// is set to 400 to leave room to potential mistakes in estimating the number
// of operations currently in the batch (unfortunately at the time of
// development the SDK provides no built-in accurate way to do that)
const BATCH_OPERATIONS_LIMIT = 400;

/**
 * Wrapper around the original Firestore batch() that counts the operations
 * sent to the batch, and when the number is close to the 500 limit of Firestore
 * it automatically commits the underlying batch and creates a new one ready to
 * accept the next operation. The interface exposed is identical to batch(), so
 * it can be used as a drop-in replacement. For less than 500 operations this
 * class behaves exactly like the original batch.
 */
export default class FirestoreRollingBatch {
  constructor() {
    this.batch = firestore().batch();
    this.operations = 0;
  }

  _countExtraOperations(data) {
    let extraOperations = 0;
    Object.values(data).forEach((value) => {
      // Each instance of FieldValue counts as an extra operation:
      // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes

      // At the time of development the objects returned by FieldValue's methods
      // are not instances of FiledValue:
      //   (FieldValue.increment(1) instanceof FieldValue) === false
      // so to detect them I check if "value" has a isEqual() function, a thing
      // that all the FieldValue share. Since there are no other cases when
      // "values" contains functions, this workaround is decently robust.
      if (!value) return;
      if (typeof value.isEqual === "function") extraOperations++;
    });
    return extraOperations;
  }

  _handleOperationAddedToBatch(data) {
    // An operation has been made, count it
    this.operations++;
    // If the operation had a payload, check if that payload contains some
    // extra operations
    if (data) this.operations += this._countExtraOperations(data);

    // If operations are still below the limit there's nothing else to do
    if (this.operations <= BATCH_OPERATIONS_LIMIT) return;
    // Otherwise commit this batch and start a new one
    this.batch.commit();
    this.operations = 0;
    this.batch = firestore().batch();
  }

  set(documentRef, data, options) {
    this.batch.set(documentRef, data, options);
    this._handleOperationAddedToBatch(data);
  }

  update(documentRef, data) {
    this.batch.update(documentRef, data);
    this._handleOperationAddedToBatch(data);
  }

  delete(documentRef) {
    this.batch.delete(documentRef);
    this._handleOperationAddedToBatch();
  }

  async commit() {
    return this.batch.commit();
  }
}
