import React from "react";
import clsx from "clsx";

import styles from "./Sidebar.module.scss";

export function SidebarHeader({ className, children, plain, ...props }) {
  return (
    <div
      className={clsx(className, styles.header, plain && styles.headerPlain)}
      {...props}
    >
      {children}
    </div>
  );
}

export function SidebarFooter({ className, children, ...props }) {
  return (
    <div className={clsx(className, styles.footer)} {...props}>
      {children}
    </div>
  );
}

function Sidebar({
  className,
  children,
  header,
  footer,
  tag: SidebarTag = "aside",
  ...props
}) {
  return (
    <SidebarTag className={clsx(className, styles.sidebar)} {...props}>
      {header}

      <div className={styles.scrollerOuter}>
        <div className={styles.scrollerInner}>{children}</div>
      </div>

      {footer}
    </SidebarTag>
  );
}

export default Sidebar;
