import React from "react";
import clsx from "clsx";

import styles from "./TabPanel.module.scss";

function TabBar({ className, children, active, id, ...props }) {
  return (
    <div
      className={clsx(className, styles.panel)}
      role="tabpanel"
      id={id}
      hidden={!active}
      {...props}
    >
      {children}
    </div>
  );
}

export default TabBar;
