import React, { useEffect, useContext, useMemo } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import clsx from "clsx";

import useUniqueId from "../../hooks/useUniqueId";

import { useGetCategoryColor } from "../CategoriesContext";
import { ToastContext } from ".";
import Tooltip from "../Tooltip";

import { ReactComponent as IconErrorOutline } from "./../../assets/icons/16-error-outline.svg";

import styles from "./Toast.module.scss";

export function Toaster({ children }) {
  const fallbackId = useUniqueId();

  // The UI only works if each child has a unique ID, so this filters out any that don't and
  // returns an empty div with the fallback ID if none exist.
  // SwitchTransition requires a child to switch between one and the other. TransitionGroup
  // does not have the same requirement, but does not handle the out-in mode so this empty
  // div is required to ensure that an exception is not thrown
  const toastEl = useMemo(() => {
    const firstChildWithId = React.Children.toArray(children).find(
      (child) => child?.props?.id
    );
    return firstChildWithId || <div id={fallbackId} />;
  }, [fallbackId, children]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={toastEl?.props?.id}
        classNames="slide-toast"
        appear
        timeout={+styles.transitionDuration}
      >
        {toastEl}
      </CSSTransition>
    </SwitchTransition>
  );
}

// Because the component has a useEffect with props as a dependency, it must always be memoized
// to avoid continually storing each version of the props in the ToastContext map
export const GlobalToast = React.memo(({ id, ...props }) => {
  const localId = useUniqueId();
  const toastId = useMemo(() => id || localId, [id, localId]);

  const { registerToastOpen, registerToastClosed, setToastProps } =
    useContext(ToastContext);

  useEffect(() => {
    if (!toastId) return;

    registerToastOpen(toastId);
    return () => {
      registerToastClosed(toastId);
    };
  }, [toastId, registerToastOpen, registerToastClosed]);

  useEffect(() => {
    if (!toastId) return;

    setToastProps(toastId, props);

    if (process.env.NODE_ENV === "development" && props.onClick) {
      console.warn(
        `Using an onClick for a global toast has accessibility implications as it is not easy to tab to the new toast. Consider using a local one wrapped in its own <Toaster> in the parent component.`
      );
    }

    return () => {
      setToastProps(toastId, null);
    };
  }, [toastId, props, setToastProps]);

  return null;
});

function Toast({
  className,
  children,
  categoryId,
  error,
  onClick,
  icon: Icon,
  // This controls the z-index of the toast to ensure that it appears above/below the dialog
  fromDialog,
  global,
  tooltip,
  ...props
}) {
  const getCategoryColor = useGetCategoryColor();

  if (!props.id) return null;

  const ToastTag = onClick ? "button" : "div";

  const toastProps = {
    ...props,
    onClick,
    "data-category-color": getCategoryColor(categoryId),
    className: clsx(
      className,
      styles.toast,
      error && styles.error,
      categoryId && styles.category,
      fromDialog && styles.dialog,
      !global && styles.local
    ),
  };

  let icon = Icon && <Icon role="presentation" />;

  if (error) {
    if (!icon) {
      icon = <IconErrorOutline aria-label="Error" />;
    }
    toastProps.role = toastProps.role || "alert";
  }

  toastProps.children = (
    <>
      {icon}
      {children}
    </>
  );

  const tooltipTitle = tooltip && toastProps["aria-label"];

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <ToastTag {...toastProps} />
      </Tooltip>
    );
  }

  return <ToastTag {...toastProps} />;
}

export default Toast;
