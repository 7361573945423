import React, { forwardRef } from "react";
import clsx from "clsx";

import Button, {
  BUTTON_BORDER_STYLE_SQUARE,
  BUTTON_SIZE_SMALL,
} from "../Button";

import styles from "./Card.module.scss";

const CardButton = forwardRef(
  ({ className, cardWithColor, selected, children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={clsx(
          className,
          styles.button,
          cardWithColor && styles.withColor,
          selected && styles.buttonActive,
          selected && cardWithColor && styles.buttonActiveWithColor
        )}
        borderStyle={BUTTON_BORDER_STYLE_SQUARE}
        size={BUTTON_SIZE_SMALL}
        iconOnly
        tooltip
        {...props}
      >
        {children}
      </Button>
    );
  }
);

CardButton.displayName = "CardButton";

export default CardButton;
