import React from "react";
import clsx from "clsx";

import styles from "./LoadingSpinner.module.scss";

function LoadingSpinner({
  className,
  absolute = false,
  opaque = false,
  ...props
}) {
  return (
    <div
      className={clsx(
        className,
        styles.spinner,
        absolute && styles.absolute,
        opaque && styles.opaque
      )}
      role="alert"
      aria-label="Loading"
      {...props}
    >
      <div className={styles.bounce} />
      <div className={styles.bounce} />
      <div className={styles.bounce} />
    </div>
  );
}

export default LoadingSpinner;
