/**
 * Swaps all & and < for HTML encoded characters
 * @param  {String} string
 */
export function encode(string) {
  return string.replace(/&/g, "&amp;").replace(/</g, "&lt;");
}

/**
 * Swaps all & and < HTML encoded characters for unencoded characters
 * @param  {String} string
 */
export function decode(string) {
  return string.replace(/&amp;/g, "&").replace(/&lt;/g, "<");
}
