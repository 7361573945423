import React from "react";
import ReactDOM from "react-dom";

import { initErrorTracking } from "./services/AnalyticsService";

import App from "./components/App";

import "./index.scss";

initErrorTracking();

// This rule, already present in index.css, is duplicated
// here to fix a bug in Safari.
// PostCSS writes in the final CSS the result of this
// operation, that is 0.0625em. But Safari does not
// interpret that value correctly, breaking the entire
// layout of the page. Keeping the original calc function
// fixes the issue.
// TODO: find a cleaner way to fix this.
document.documentElement.style.fontSize = "calc(1em/16)";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("react-root")
);
