import React, { useCallback, useState } from "react";
import clsx from "clsx";

import { uploadPhoto } from "src/services/PhotoService";

import Input, { InputLabel } from "src/components/Input";
import Button, { BUTTON_SIZE_SMALL } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";

import styles from "./Input.module.scss";
import useUniqueId from "src/hooks/useUniqueId";
import { INPUT_SIZE_REGULAR } from "./Input";

export const IMAGE_UPLOAD_STATE_IDLE = "idle";
export const IMAGE_UPLOAD_STATE_UPLOADING = "uploading";
export const IMAGE_UPLOAD_STATE_ERROR = "error";
export const IMAGE_UPLOAD_STATE_SUCCESS = "success";

function InputImageUpload({
  value,
  id,
  onChange,
  onStateChange,
  label,
  size = INPUT_SIZE_REGULAR,
  maxLength = 1920,
  maxSizeMb = 2,
  placeholder = "No file chosen",
  ...props
}) {
  const localId = useUniqueId();
  const inputId = id || localId;

  const [state, setState] = useState(() =>
    value ? IMAGE_UPLOAD_STATE_SUCCESS : IMAGE_UPLOAD_STATE_IDLE
  );

  const handleStateChange = useCallback(
    (newState) => {
      setState(newState);
      onStateChange && onStateChange(newState);
    },
    [onStateChange]
  );

  const handleValueChange = useCallback(
    (newValue) => {
      if (!onChange) return;

      // This mimics the shape of an Event so that this component
      // can be used interchangeably with Input
      onChange({
        target: {
          value: newValue,
        },
      });
    },
    [onChange]
  );

  const handleImageChange = useCallback(
    async (e) => {
      const [file] = e.target.files;

      if (!file) return;

      handleStateChange(IMAGE_UPLOAD_STATE_UPLOADING);

      let imageUrl;
      try {
        imageUrl = await uploadPhoto(file, maxSizeMb, maxLength);
      } catch (e) {
        handleStateChange(IMAGE_UPLOAD_STATE_ERROR);
        return;
      }

      handleValueChange(imageUrl);
      handleStateChange(IMAGE_UPLOAD_STATE_SUCCESS);
    },
    [maxSizeMb, maxLength, handleValueChange, handleStateChange]
  );

  const handleRemoveImage = useCallback(
    (e) => {
      e.preventDefault();

      handleValueChange(null);
      handleStateChange(IMAGE_UPLOAD_STATE_IDLE);
    },
    [handleValueChange, handleStateChange]
  );

  const isIdle = state === IMAGE_UPLOAD_STATE_IDLE;
  const isError = state === IMAGE_UPLOAD_STATE_ERROR;
  const isSuccess = state === IMAGE_UPLOAD_STATE_SUCCESS;
  const isUploading = state === IMAGE_UPLOAD_STATE_UPLOADING;

  return (
    <>
      <div className={styles.imageLabel}>
        {label && (
          <InputLabel tag="span" fauxFilled>
            {label}
          </InputLabel>
        )}

        {isSuccess && (
          <Button
            negative
            onClick={handleRemoveImage}
            size={BUTTON_SIZE_SMALL}
            type="button"
          >
            Remove
          </Button>
        )}
      </div>

      {(isIdle || isError) && (
        <Input
          className={styles.imageInput}
          fieldTag="div"
          onChange={handleImageChange}
          accept="image/png,image/jpeg,image/gif"
          type="file"
          size={size}
          id={inputId}
          wrapperChildren={
            <>
              <span className={styles.imagePlaceholder} aria-hidden="true">
                <span>{placeholder}</span>
              </span>
              {/* This complex structure allows for a button with an inner
                  element to be extended across the whole wrapper to have the
                  hover state triggered when a user hovers the input. The
                  aria-label is used to label the input with the actual intended
                  label whilst keeping "Select file" as the CTA in the
                  field. */}
              <div className={styles.imageButtonOuter}>
                <Button
                  tag="label"
                  htmlFor={inputId}
                  aria-label={label}
                  size={BUTTON_SIZE_SMALL}
                >
                  <span
                    className={clsx(styles.imageButtonInner, styles[size])}
                  />
                  Select&nbsp;file
                </Button>
              </div>
            </>
          }
          {...props}
        />
      )}

      {(isUploading || isSuccess) && (
        <div className={styles.imagePreview}>
          {isUploading && <LoadingSpinner absolute />}
          {isSuccess && <img src={value} alt={`${label} preview`} />}
        </div>
      )}
    </>
  );
}

export default InputImageUpload;
