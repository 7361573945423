/**
 * @param  {string} string
 * @param  {string} replaceWith='\n'
 * @returns {string} string The original string, but with 2+ consecutive line-breaks replaced with the replaceWith character
 */
export function replaceMultipleLineBreaks(string, replaceWith = "\n") {
  if (typeof string !== "string" || typeof replaceWith !== "string")
    throw new Error(`string and replaceWith must be of type string.`);
  return string.replace(/[\r\n]{2,}/g, replaceWith);
}
