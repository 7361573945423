import clsx from "clsx";
import React from "react";
import { CardButton } from ".";

import { ReactComponent as IconStar } from "../../assets/icons/16-star.svg";
import { ReactComponent as IconStarOutline } from "../../assets/icons/16-star-outline.svg";

import styles from "./CardFavorite.module.scss";

// The favorite name refers to a generic status of an item.
// Currently, this has a star icon, but in the future, it
// can be a heart icon or another one.
function CardFavoriteButton({
  className,
  cardWithColor,
  selected,
  children,
  ...props
}) {
  return (
    <CardButton
      type="button"
      role="switch"
      aria-checked={selected}
      className={clsx(
        className,
        styles.outline,
        selected && styles.buttonActive
      )}
      cardWithColor={cardWithColor}
      selected={selected}
      iconOnly
      {...props}
    >
      {selected && <IconStar role="presentation" />}
      {!selected && <IconStarOutline role="presentation" />}
    </CardButton>
  );
}

export default CardFavoriteButton;
