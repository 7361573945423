import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  generatePath,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import clsx from "clsx";

import { NBSP } from "src/constants";

import {
  UNCATEGORIZED_ID,
  ACTIVE,
  STATE_LABELS,
  TYPE_LABELS,
  PROJECT,
  CATEGORY,
  THIS_WEEK,
} from "src/services/DbService/constants";
import { watchActiveActionsCounters } from "src/services/DbService/actions";

import { useCategories } from "../../CategoriesContext";
import { useProjects } from "../../ProjectsContext";
import {
  CATEGORY_DETAIL_URL,
  PROJECT_DETAIL_URL,
  PLANNER_URL,
  PLANNER_CATEGORY_DETAIL_URL,
  PLANNER_PROJECT_DETAIL_URL,
} from "../../App";
import Button from "../../Button";
import { ActionsCounters } from "../../Counters";
import Heading, { HEADING_LEVEL_2, HEADING_LEVEL_5 } from "../../Heading";
import { SidebarHeader } from "../../Sidebar";

import { ReactComponent as IconNext } from "src/assets/icons/16-next.svg";

import styles from "./DashboardSidebarHeader.module.scss";

const ACTIVE_LABEL = STATE_LABELS[ACTIVE].subheading;

function DetailsButton({ parentType, parentId }) {
  const history = useHistory();
  const location = useLocation();
  const isPlannerRoute = useRouteMatch(PLANNER_URL)?.isExact;

  const buttonRef = useRef();
  const previousPathnameRef = useRef();

  const parentPath = useMemo(() => {
    if (parentType === PROJECT)
      if (isPlannerRoute) {
        return generatePath(PLANNER_PROJECT_DETAIL_URL, {
          projectId: parentId,
        });
      } else {
        return generatePath(PROJECT_DETAIL_URL, { projectId: parentId });
      }
    if (parentType === CATEGORY) {
      if (isPlannerRoute) {
        return generatePath(PLANNER_CATEGORY_DETAIL_URL, {
          categoryId: parentId,
        });
      } else {
        return generatePath(CATEGORY_DETAIL_URL, { categoryId: parentId });
      }
    }
    throw new Error(
      `parentType (${parentType}) must be "${PROJECT}" or "${CATEGORY}"`
    );
  }, [parentType, parentId, isPlannerRoute]);

  const parentRouteMatch = useRouteMatch(parentPath, { exact: true });

  // When the detail is closed, the open button should be refocused for
  // keyboard user consistency. This ref stores the value of the previous
  // pathname and uses that to check whether it was the detail closing or
  // whether it was something else that can be ignored – page loading, another
  // parent detail closing for example
  useEffect(() => {
    if (previousPathnameRef.current === parentPath) {
      buttonRef.current && buttonRef.current.focus();
    }

    previousPathnameRef.current = location.pathname;
  }, [location.pathname, parentPath]);

  // This is an onClick history.push rather than an <a> to allow the disabled
  // attribute to be used to prevent unintentional usage
  const handleDetailClick = useCallback(() => {
    history.push(parentPath);
  }, [history, parentPath]);

  // No detail button is shown for uncategorized parents
  if (parentType === CATEGORY && parentId === UNCATEGORIZED_ID) {
    return null;
  }

  return (
    <Button
      ref={buttonRef}
      className={styles.detailsButton}
      iconOnly
      onClick={handleDetailClick}
      tooltip
      aria-label={`Open ${TYPE_LABELS[parentType].title} Details`}
      disabled={parentRouteMatch}
    >
      <IconNext role="presentation" />
    </Button>
  );
}

function findCurrent(currentId, active, hidden) {
  const allItems = [...(active || []), ...(hidden || [])];
  return allItems.find((item) => item.id === currentId) || {};
}

function DashboardSidebarHeader({ parentType, parentId, children }) {
  const { activeCategories, hiddenCategories } = useCategories();
  const { activeProjects, hiddenProjects } = useProjects();

  const [counters, setCounters] = useState(null);

  useEffect(() => {
    if (parentType === THIS_WEEK) {
      setCounters(null);
      return;
    }

    return watchActiveActionsCounters(parentId, parentType, setCounters);
  }, [parentId, parentType]);

  // Rather than have complicated styling for the case when the label is hidden
  // or shown, the fallback is a non-breaking space with aria-hidden to keep
  // the line there without affecting screenreaders.
  const [titleText, titleLabel] = useMemo(() => {
    if (parentType === THIS_WEEK) {
      return [ACTIVE_LABEL, NBSP];
    }

    let currentParent = {};

    if (parentType === PROJECT) {
      currentParent = findCurrent(parentId, activeProjects, hiddenProjects);
    } else if (parentType === CATEGORY) {
      currentParent = findCurrent(parentId, activeCategories, hiddenCategories);
    }

    const { name = NBSP } = currentParent;
    const { title } = TYPE_LABELS[parentType];

    return [name, title];
  }, [
    parentType,
    parentId,
    activeCategories,
    hiddenCategories,
    activeProjects,
    hiddenProjects,
  ]);

  if (
    activeCategories === null ||
    hiddenCategories === null ||
    activeProjects === null ||
    hiddenProjects === null
  ) {
    return null;
  }

  return (
    <SidebarHeader
      className={clsx(parentType === CATEGORY && styles.hasCategory)}
      plain={parentType === THIS_WEEK}
    >
      <div className={styles.dropdown}>
        <Heading
          className={clsx(
            styles.dropdownWrapper,
            parentType === THIS_WEEK && styles.dropdownWrapperThisWeek
          )}
          tag="h2"
          level={HEADING_LEVEL_2}
        >
          <Heading
            className={styles.dropdownLabel}
            level={HEADING_LEVEL_5}
            tag="span"
            aria-hidden={parentType === THIS_WEEK}
          >
            {titleLabel}
          </Heading>
          <span className={styles.dropdownHeading}>{titleText}</span>
        </Heading>

        {parentType !== THIS_WEEK && (
          <DetailsButton parentType={parentType} parentId={parentId} />
        )}
      </div>

      {parentType !== THIS_WEEK && (
        <ActionsCounters
          className={styles.counters}
          total={counters?.total}
          starred={counters?.starred}
        />
      )}

      {children}
    </SidebarHeader>
  );
}

export default DashboardSidebarHeader;
