import React, { useContext } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import clsx from "clsx";

import {
  DASHBOARD_URL,
  BLOCK_DETAIL_URL,
  CATEGORY_DETAIL_URL,
  PROJECT_DETAIL_URL,
} from "../App";

import { DashboardContextProvider } from "./DashboardContext";
import { SidebarContext } from "../SidebarContext";

import Page from "../Page";
import BlockDetail from "../DetailPanels/BlockDetail";
import CategoryDetail from "../DetailPanels/CategoryDetail";
import CalendarPanel from "../CalendarPanel/CalendarPanel";
import ProjectDetail from "../DetailPanels/ProjectDetail";
import PageTransition from "../PageTransition";
import SidebarContainer from "../SidebarContainer";

import styles from "./Dashboard.module.scss";

function Dashboard({ ...props }) {
  const location = useLocation();

  const {
    actionListExpanded,
    thisWeekSidebarExpanded,
    categorySidebarExpanded,
  } = useContext(SidebarContext);

  const isCalendarRoute = useRouteMatch(DASHBOARD_URL).isExact;

  return (
    <Page
      className={clsx(
        (actionListExpanded ||
          thisWeekSidebarExpanded ||
          categorySidebarExpanded) &&
          styles.expanded
      )}
      pageTitle="Calendar"
      sidebarNav={true}
      {...props}
    >
      <SidebarContainer
        actionListExpanded={actionListExpanded}
        thisWeekExpanded={thisWeekSidebarExpanded}
        categoryExpanded={categorySidebarExpanded}
      />

      <main className={styles.main}>
        <CalendarPanel
          className={clsx(
            styles.calendar,
            !isCalendarRoute && styles.calendarHidden
          )}
        />
        <PageTransition>
          <Switch location={location}>
            <Route path={BLOCK_DETAIL_URL}>
              <BlockDetail className={styles.detail} />
            </Route>
            <Route path={CATEGORY_DETAIL_URL}>
              <CategoryDetail className={styles.detail} />
            </Route>
            <Route path={PROJECT_DETAIL_URL}>
              <ProjectDetail className={styles.detail} />
            </Route>
          </Switch>
        </PageTransition>
      </main>
    </Page>
  );
}

const DashboardWithContext = (props) => (
  <DashboardContextProvider>
    <Dashboard {...props} />
  </DashboardContextProvider>
);

export default DashboardWithContext;
