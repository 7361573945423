import React from "react";
import clsx from "clsx";

import { timeToText } from "src/utils/timeToText";

import { ReactComponent as IconStarOutline } from "../../assets/icons/16-star-outline.svg";
// import { ReactComponent as IconCalendar } from '../../assets/icons/16-calendar.svg';
import { ReactComponent as IconScheduled } from "../../assets/icons/16-scheduled.svg";

import Counter from "./Counter";

import styles from "./Counters.module.scss";

function ActionsCounters({ starred = 0, total = 0, className }) {
  const starredText = timeToText(starred);
  const totalText = timeToText(total);

  return (
    <div className={clsx(styles.wrapper, styles.actions, className)}>
      {/* Scheduled actions not implemented yet */}
      {/*<Counter
        icon={IconCalendar}
        short='0h'
        long='0 hours scheduled actions'
      />*/}
      <Counter
        icon={IconStarOutline}
        short={starredText.short}
        long={`${starredText.long} starred actions`}
      />
      <Counter
        icon={IconScheduled}
        short={totalText.short}
        long={`${totalText.long} total actions`}
      />
    </div>
  );
}

export default ActionsCounters;
