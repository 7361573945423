import { useRef, useEffect } from "react";

// Uses useRef to store a previous value to allow comparisons in
// useEffect within templates.
export default function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
