import React from "react";
import clsx from "clsx";

import styles from "./EmptyState.module.scss";

function EmptyState({ className, actions, children, ...props }) {
  return (
    <div {...props} className={clsx(className, styles.container)}>
      {actions && (
        <>
          <div className={styles.action} role="presentation" />
          <div className={styles.action} role="presentation" />
          <div className={styles.action} role="presentation" />
        </>
      )}

      <p className={styles.message}>{children}</p>
    </div>
  );
}

export default EmptyState;
