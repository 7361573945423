import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory, generatePath } from "react-router-dom";

import { sleep } from "src/utils/sleep";

import { trackEvent } from "src/services/AnalyticsService";
import { CATEGORY_FIELDS } from "src/services/DbService/constants";
import {
  watchCategory,
  getCategory,
  updateCategory,
} from "src/services/DbService/categories";

import { CATEGORY_DETAIL_URL, ONBOARDING_CREATE_CATEGORY_URL } from "../App";
import { DashboardContext, DashboardContextProvider } from "../Dashboard";
import Button, { BUTTON_COLOR_FILL } from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import Input from "../Input";
import OnboardingPage from "./OnboardingPage";

import styles from "./Onboarding.module.scss";

function CategoryDetails() {
  const history = useHistory();

  const { categoryId } = history.location.state || {};

  const { setActiveCategoryId } = useContext(DashboardContext);

  const [newFields, setNewFields] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // To ensure that the same category is the one being manipulated here, if the user
    // has got to this page from anywhere but the previous page they are redirected
    // to the creation screen
    if (!categoryId) {
      history.push(ONBOARDING_CREATE_CATEGORY_URL);
      return;
    }

    return watchCategory(categoryId, (category) => {
      const categoryFields = {};

      for (const { slug } of CATEGORY_FIELDS) {
        categoryFields[slug] = category[slug] || "";
      }

      setNewFields(categoryFields);
    });
  }, [history, categoryId]);

  const handleFieldChange = useCallback((slug, value) => {
    setNewFields((newFields) => ({
      ...newFields,
      [slug]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      // The latency with FireStore is so low, that without the timeout it feels like something
      // went wrong. This just delays the user long enough for it to feel deliberate.
      await sleep(500);

      const oldCategory = await getCategory(categoryId);

      const newCategory = {
        ...oldCategory,
        ...newFields,
      };

      updateCategory(newCategory);

      setActiveCategoryId(categoryId);

      trackEvent("User completed onboarding");

      history.push(generatePath(CATEGORY_DETAIL_URL, { categoryId }));
    },
    [history, categoryId, newFields, setActiveCategoryId]
  );

  return (
    <OnboardingPage
      title="Magnificent 7"
      intro="Now it's time to dive deeper into precisely what you want in this Category of Improvement. While it takes some time to complete this, once it's done, you'll come back to this vision for each of your Categories every week when you do your RPM Weekly Plan."
    >
      <form onSubmit={handleSubmit}>
        {newFields === null ? (
          <LoadingSpinner absolute />
        ) : (
          CATEGORY_FIELDS.map(({ slug, ...field }, index) => (
            <Input
              key={slug}
              type="text"
              tag="textarea"
              // Originally autoFocus was decided based on the first unfilled
              // input, but that meant that the page might jump after the inputs
              // had loaded which felt janky, so focusing the first item
              // regardless of filled state was preferred
              autoFocus={index === 0}
              value={newFields[slug]}
              onChange={(e) => handleFieldChange(slug, e.target.value)}
              minRows={1}
              maxRows={6}
              {...field}
            />
          ))
        )}

        <div className={styles.buttons}>
          <Button
            loading={loading}
            color={BUTTON_COLOR_FILL}
            block
            disabled={newFields === null}
          >
            Next
          </Button>
        </div>
      </form>
    </OnboardingPage>
  );
}

const CategoryDetailsWithContext = (props) => (
  <DashboardContextProvider>
    <CategoryDetails {...props} />
  </DashboardContextProvider>
);

export default CategoryDetailsWithContext;
