import React from "react";

import Head from "src/components/Head";
import LoadingSpinner from "src/components/LoadingSpinner";

import styles from "./DetailPanel.module.scss";

export function DetailPanelWrapper({
  children,
  name,
  editing,
  loading,
  tag: WrapperTag = "div",
  outsideCard,
  ...props
}) {
  return (
    <WrapperTag {...props}>
      <Head title={`${editing ? "Editing: " : ""}${name}`} />

      <div className={styles.card}>
        {loading && <LoadingSpinner absolute />}
        {!loading && children}
      </div>

      {outsideCard}
    </WrapperTag>
  );
}

export default DetailPanelWrapper;
