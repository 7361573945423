import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from "react";
import { format } from "date-fns";
import clsx from "clsx";

import usePeople from "../../hooks/usePeople";
import useHistoryBlock from "../../hooks/useHistoryBlock";

import { ActionDialogContext, useShowCreateActionDialog } from ".";

import {
  ACTIVE,
  ACTION,
  ACTION_DESCRIPTION_MAX_LENGTH,
  UNCATEGORIZED_ID,
  SNOOZED,
  PERSON_DELETED_NICKNAME,
} from "../../services/DbService/constants";
import {
  calculateSnoozedToString,
  getActionParentInfo,
} from "../../services/DbService/general";
import {
  createAction,
  deleteAction,
  moveAction,
  updateAction,
  actionHasEvents,
} from "../../services/DbService/actions";
import { getBlock } from "src/services/DbService/blocks";
import {
  createEvent,
  updateEventScheduleFromActionId,
} from "src/services/DbService/events";

import { modifierKey } from "src/utils/modifierKey";
import { replaceMentions } from "src/utils/replaceMentions";
import { timeIncrements } from "src/utils/timeIncrements";
import { timeRoundUp } from "src/utils/timeRoundUp";

import useUniqueId from "../../hooks/useUniqueId";

import { useCategoryOptions } from "../CategoriesContext";
import Dialog from "../Dialog";
import LeverageDialog from "../ActionItem/LeverageDialog";
import Autocomplete from "./Autocomplete";
import ActionParentSelect from "./ActionParentSelect";
import Button, { BUTTON_COLOR_FILL, BUTTON_COLOR_OUTLINE } from "../Button";
import CreateDialog from "../CreateDialog";
import Divider from "../Divider";
import {
  CardButton,
  CardButtonDurationSelect,
  CardButtonSelect,
  CardSnoozeButton,
  UNSET,
} from "../Card";
import DueDateButton from "../DueDateButton";
import { GlobalToast } from "../Toast";
import { InputLabel, INPUT_SIZE_SMALL } from "../Input";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

import { ReactComponent as IconCalendar } from "../../assets/icons/16-calendar.svg";
import { ReactComponent as IconStar } from "../../assets/icons/16-star.svg";
import { ReactComponent as IconStarOutline } from "../../assets/icons/16-star-outline.svg";
import { ReactComponent as IconLeveraged } from "../../assets/icons/16-leveraged.svg";
import { ReactComponent as IconTrash } from "../../assets/icons/16-trash.svg";

import styles from "./ActionDialog.module.scss";

const TIME_INTERVAL = 15;
const HALF_HOUR_INCREMENTS = timeIncrements(TIME_INTERVAL);
const NEW_EVENT_DURATION = 60 * 60; // one hour

const NO_DATE_DEFAULT_TEXT = "Schedule";

function ActionDialog({ className, children }) {
  const { people } = usePeople();

  const [visible, setVisible] = useState(false);
  const [parentSelectOpen, setParentSelectOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [starred, setStarred] = useState(false);
  const [actionState, setActionState] = useState({
    state: ACTIVE,
    snoozedTo: undefined,
    snoozedToString: undefined,
  });
  const leverageButtonRef = useRef();
  const [leveragedPerson, setLeveragedPerson] = useState(null);

  const errorToastId = useUniqueId();

  const autocompleteRef = useRef();
  const autocompleteLabelId = useUniqueId();

  const { peopleById } = usePeople();

  const showCreateActionDialog = useShowCreateActionDialog();

  const {
    quickCaptureParent,
    newActionEvent,
    newActionParent,
    setNewActionParent,
    actionToEdit,
    setActionToEdit,
  } = useContext(ActionDialogContext);

  const [newActionDescriptionValue, setNewActionDescriptionValue] =
    useState("");
  const [newActionParentValue, setNewActionParentValue] = useState("");

  const [isDirty, setIsDirty] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);

  const [leverageDialogVisible, setLeverageDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const { optionsGroups, optionsMap } = useCategoryOptions();

  const newParentOption = useMemo(
    () => optionsMap?.[newActionParentValue] || {},
    [optionsMap, newActionParentValue]
  );

  const newActionDescriptionLength = useMemo(() => {
    if (!newActionDescriptionValue) return 0;

    const descriptionWithoutMentions = replaceMentions(
      newActionDescriptionValue,
      () => ""
    );
    return descriptionWithoutMentions.length;
  }, [newActionDescriptionValue]);

  const [historyContinue] = useHistoryBlock(isDirty, () => {
    setShowUnsavedChangesDialog(true);
  });

  // Reset dialog (and context) state when it gets hidden
  useEffect(() => {
    if (visible) return;
    setNewActionDescriptionValue("");
    setNewActionParentValue(quickCaptureParent || UNCATEGORIZED_ID);
    // When the dialog gets closed reset also the two "triggers"
    // for "edit" and "create" modes
    setActionToEdit(null);
    setNewActionParent(null);
    setSelectedDuration(null);
    setSelectedStartDate(null);
    setStarred(false);
    historyContinue();

    // Set next available half hour increment.
    const nextTime = timeRoundUp(TIME_INTERVAL * 60 * 1000);
    const nowValue = format(nextTime, "HH:mm");
    setSelectedTime(nowValue);

    setActionState({
      state: ACTIVE,
      snoozedTo: undefined,
      snoozedToString: undefined,
    });

    setLeveragedPerson(null);
  }, [
    visible,
    quickCaptureParent,
    setNewActionParent,
    setActionToEdit,
    historyContinue,
  ]);

  // Show the dialog when newActionParent is set
  useEffect(() => {
    if (!newActionParent) return;
    setNewActionParentValue(newActionParent);
    setVisible(true);
  }, [newActionParent, setVisible]);

  // Show the dialog when actionToEdit is set
  useEffect(() => {
    if (!actionToEdit) return;
    const { action, event } = actionToEdit;

    const {
      description,
      blockId,
      categoryId,
      starred,
      state,
      snoozedTo,
      leveragedPersonId,
      duration,
    } = action;
    const { startDate, duration: eventDuration } = event || {};

    setNewActionDescriptionValue(description);
    setNewActionParentValue(blockId || categoryId);

    if (startDate) {
      const startDateValue = new Date(startDate);

      const nextTime = timeRoundUp(TIME_INTERVAL * 60 * 1000, startDateValue);
      const selectedNewTime = format(nextTime, "HH:mm");

      const [hour, minute] = selectedNewTime.split(":");
      startDateValue.setHours(parseInt(hour));
      startDateValue.setMinutes(parseInt(minute));

      setSelectedStartDate(startDateValue);
      setSelectedTime(selectedNewTime);
    }

    setSelectedDuration(eventDuration ?? duration);

    setIsDirty(false);
    setStarred(starred === true);
    setVisible(true);

    setActionState({
      state,
      snoozedTo,
      snoozedToString:
        state === SNOOZED && !snoozedTo
          ? calculateSnoozedToString(snoozedTo)
          : null,
    });

    setLeveragedPerson(leveragedPersonId);
  }, [actionToEdit, setVisible]);

  const leveragedPersonNickname = useMemo(() => {
    if (!leveragedPerson || !peopleById) return "";
    // if the person exists, then nickname will too. If there is no nickname,
    // it is safe to assume they have been deleted
    const nickname = peopleById[leveragedPerson]?.nickname;
    return nickname ? `@${nickname}` : PERSON_DELETED_NICKNAME;
  }, [peopleById, leveragedPerson]);

  // Reset actionParent when empty action value
  useEffect(() => {
    const currentOption = optionsMap?.[newActionParentValue];
    if (!currentOption) {
      console.warn("Could not find option for save action");
      setNewActionParentValue(UNCATEGORIZED_ID);
    }
  }, [newActionParentValue, optionsMap]);

  // Toggle the dialog on CMD+K
  useEffect(() => {
    function handleKeydownAnywhere(e) {
      if (e.key === "k" && e[modifierKey]) {
        e.preventDefault();
        showCreateActionDialog(quickCaptureParent || UNCATEGORIZED_ID);
      } else if (e.key === "Escape") {
        // If the actionParent is selected Escape should handle the closure of the focus rather
        // than the closure of the action dialog
        if (parentSelectOpen) return;
        e.preventDefault();
        showCreateActionDialog(null);
      }
    }
    document.addEventListener("keydown", handleKeydownAnywhere);
    return () => document.removeEventListener("keydown", handleKeydownAnywhere);
  }, [parentSelectOpen, quickCaptureParent, showCreateActionDialog]);

  const handleClose = useCallback(
    (e) => {
      // If the actionParent is selected Escape should handle the closure of the focus rather
      // than the closure of the action dialog
      if (parentSelectOpen) return;

      setShowUnsavedChangesDialog(false);
      setVisible(false);
    },
    [parentSelectOpen]
  );

  const handleConfirmClose = useCallback(() => {
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
      return;
    }

    handleClose();
  }, [isDirty, handleClose]);

  const handleLeverageToggle = useCallback(() => {
    setLeverageDialogVisible(true);
  }, []);

  const handleLeverageDialogConfirm = useCallback((person) => {
    setLeveragedPerson(person.id);
  }, []);

  const handleLeverageDialogClose = useCallback(() => {
    setLeverageDialogVisible(false);
    leverageButtonRef.current?.focus();
  }, []);

  const handleRemoveLeverage = useCallback(() => {
    setLeveragedPerson(null);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newDescription = newActionDescriptionValue.trim();
      if (!newDescription) return;

      if (newActionDescriptionLength > ACTION_DESCRIPTION_MAX_LENGTH) return;

      let newBlockId = null;
      let newCategoryId = newActionParentValue;

      if (newParentOption.parentId) {
        newBlockId = newActionParentValue;
        newCategoryId = newParentOption.parentId;
      }

      if (actionToEdit) {
        // set the scheduled time in the startDate
        if (selectedStartDate) {
          if (selectedTime) {
            const [hour, minute] = selectedTime.split(":");
            selectedStartDate.setHours(parseInt(hour));
            selectedStartDate.setMinutes(parseInt(minute));
          } else {
            selectedStartDate.setHours(0);
            selectedStartDate.setMinutes(0);
          }
        }

        const {
          action: { id: actionId, blockId, categoryId },
        } = actionToEdit;
        const { state, snoozedTo, snoozedToString } = actionState;

        const snoozeUpdate = {};

        if (state === SNOOZED) {
          if (snoozedTo) {
            snoozeUpdate.snoozedTo = snoozedTo.toISOString();
          } else {
            snoozeUpdate.snoozedToString = snoozedToString;
          }
        }

        await updateAction(actionId, {
          description: newDescription,
          duration: selectedDuration,
          starred: starred,
          state,
          ...snoozeUpdate,
          leveragedPersonId: leveragedPerson,
        });

        // unscheduled actions don't have an associated event,
        // so we need to create one
        actionHasEvents(actionId).then((hasEvents) => {
          if (hasEvents) {
            updateEventScheduleFromActionId(
              actionId,
              selectedStartDate,
              selectedDuration
            );
          } else {
            if (!selectedStartDate) return;
            createEvent(
              selectedStartDate,
              selectedDuration || NEW_EVENT_DURATION,
              categoryId,
              blockId,
              [actionId]
            );
          }
        });

        if (newCategoryId !== categoryId || newBlockId !== blockId) {
          const { parentId, parentType } = getActionParentInfo(
            newCategoryId,
            newBlockId
          );
          moveAction(actionId, parentId, parentType, state);
        }
      } else {
        let { startDate = null, duration = null } = newActionEvent || {};

        let projectId;
        if (newBlockId) {
          projectId = (await getBlock(newBlockId))?.projectId;
        }

        if (!duration && selectedDuration) {
          duration = selectedDuration;
        }

        if (!startDate && selectedStartDate) {
          startDate = new Date(selectedStartDate);
          if (selectedTime) {
            const [hour, minute] = selectedTime.split(":");
            startDate.setHours(parseInt(hour));
            startDate.setMinutes(parseInt(minute));
          }
        }

        if (startDate && !duration) {
          // Default to 1 hour, in seconds.
          duration = 3600;
        }

        if (duration && !startDate) {
          // Cannot schedule an event with no start date.
          duration = null;
        }

        const { state, snoozedTo, snoozedToString } = actionState;
        const snoozeOptions = {};

        if (state === SNOOZED) {
          if (snoozedTo) {
            snoozeOptions.snoozedTo = snoozedTo.toISOString();
          } else {
            snoozeOptions.snoozedToString = snoozedToString;
          }
        }

        createAction(
          newDescription,
          newCategoryId,
          newBlockId,
          projectId || null,
          startDate,
          duration,
          starred,
          leveragedPerson,
          state,
          snoozeOptions
        );
      }
      setVisible(false);
    },
    [
      newActionDescriptionValue,
      newActionParentValue,
      newActionDescriptionLength,
      newParentOption,
      actionToEdit,
      newActionEvent,
      setVisible,
      selectedStartDate,
      selectedTime,
      selectedDuration,
      starred,
      actionState,
      leveragedPerson,
    ]
  );

  const onDurationSelectChange = useCallback((e) => {
    const newDuration =
      e.target.value === UNSET ? null : parseInt(e.target.value);
    setSelectedDuration(newDuration);
  }, []);

  const descriptionErrorMessage =
    newActionDescriptionLength > ACTION_DESCRIPTION_MAX_LENGTH
      ? "Too many characters"
      : null;

  const emailBody = (actionToEdit?.action?.emailBody || "").trim();

  const timeLabel = useMemo(() => {
    const time = HALF_HOUR_INCREMENTS.find(
      ({ value }) => value === selectedTime
    );
    return time?.label || "--";
  }, [selectedTime]);

  const dueDateButtonText = useMemo(() => {
    let dateTimeText = "";

    if (selectedStartDate) {
      if (timeLabel && timeLabel !== "--") {
        dateTimeText += `${timeLabel} on `;
      }

      dateTimeText += format(selectedStartDate, "EEE, MMM d");
    }

    if (dateTimeText === "") {
      return NO_DATE_DEFAULT_TEXT;
    }

    return dateTimeText;
  }, [selectedStartDate, timeLabel]);

  const onToggleStarred = useCallback(() => {
    setStarred(!starred);
  }, [starred, setStarred]);

  const handleSetState = useCallback((state, snoozedTo, snoozedToString) => {
    setActionState({ state, snoozedTo, snoozedToString });
  }, []);

  const performDelete = useCallback(() => {
    if (actionToEdit) {
      const {
        action: { id: actionId },
      } = actionToEdit;

      deleteAction(actionId);

      setDeleteDialogVisible(false);
      handleClose();
    }
  }, [actionToEdit, handleClose]);

  const handleDelete = useCallback(async () => {
    setDeleteDialogVisible(true);
  }, []);

  if (!visible) return null;

  return (
    <CreateDialog onClose={handleConfirmClose} wide>
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* The max length here is longer to allow a little bit of overflow, but the form will be
            invalid when it is over so won't be submittable. */}
        <Autocomplete
          ref={autocompleteRef}
          aria-labelledby={autocompleteLabelId}
          aria-errormessage={descriptionErrorMessage ? errorToastId : null}
          className={styles.autocomplete}
          placeholder="e.g. Prepare for meeting"
          maxLength={ACTION_DESCRIPTION_MAX_LENGTH + 5}
          value={newActionDescriptionValue}
          onChange={(value) => {
            setNewActionDescriptionValue(value);
            setIsDirty(value !== "");
          }}
          onEnterKeyDown={handleSubmit}
          people={people}
        />

        <div className={styles.characterCount}>
          {newActionDescriptionLength}/{ACTION_DESCRIPTION_MAX_LENGTH}
        </div>

        <ActionParentSelect
          className={styles.parentSelect}
          optionsGroups={optionsGroups}
          optionsMap={optionsMap}
          value={newActionParentValue}
          onOpen={() => setParentSelectOpen(true)}
          onClose={() => setParentSelectOpen(false)}
          onChange={setNewActionParentValue}
          onEnterKeyDown={handleSubmit}
        />

        <Divider />

        <div className={styles.actionFooter}>
          <div className={styles.scheduleWrapper}>
            {
              <>
                <CardButton
                  type="button"
                  className={clsx(styles.configActionButton)}
                  aria-label={starred ? "Unstar Action" : "Star Action"}
                  iconOnly={false}
                  onClick={onToggleStarred}
                >
                  <span
                    className={clsx(
                      styles.buttonDueDateLabel,
                      starred && styles.favoriteIcon
                    )}
                  >
                    {starred && <IconStar role="presentation" />}
                    {!starred && <IconStarOutline role="presentation" />}
                    <span>Star</span>
                  </span>
                </CardButton>

                <DueDateButton
                  portalId="action-date-portal"
                  date={selectedStartDate}
                  onChange={(newValue) => {
                    setSelectedStartDate(newValue);
                    if (selectedTime === null) {
                      setSelectedTime(null);
                    }

                    // remove the snoozed status when select a new date
                    handleSetState(ACTIVE, undefined);

                    if (selectedDuration === null) {
                      setSelectedDuration(NEW_EVENT_DURATION);
                    }
                  }}
                  customInput={
                    <CardButton
                      type="button"
                      className={clsx(
                        styles.buttonDueDate,
                        styles.configActionButton
                      )}
                      aria-label="Set Date"
                      iconOnly={false}
                    >
                      <span
                        className={clsx(
                          styles.buttonDueDateLabel,
                          selectedStartDate && styles.activeIcon
                        )}
                      >
                        <IconCalendar role="presentation" />
                        <span>{dueDateButtonText}</span>
                      </span>
                    </CardButton>
                  }
                  customActions={
                    <>
                      <Divider />
                      <label className={styles.timeWrapper}>
                        <InputLabel tag="span" size={INPUT_SIZE_SMALL}>
                          Set time
                        </InputLabel>

                        <CardButtonSelect
                          className={styles.timeSelect}
                          value={selectedTime || UNSET}
                          label={timeLabel}
                          onChange={(e) => {
                            // Normalises value to be a null if it's empty
                            let value = e.target.value;
                            if (value === UNSET) value = null;
                            setSelectedTime(value);

                            if (selectedDuration === null) {
                              setSelectedDuration(NEW_EVENT_DURATION);
                            }
                          }}
                        >
                          <option value={UNSET}>--</option>
                          {HALF_HOUR_INCREMENTS.map(({ value, label }) => (
                            <option
                              key={`schedule-action-${value}`}
                              value={value}
                            >
                              {label}
                            </option>
                          ))}
                        </CardButtonSelect>
                      </label>

                      {selectedStartDate && <Divider />}
                    </>
                  }
                />
                <div id="action-date-portal"></div>
                <CardButtonDurationSelect
                  className={clsx(
                    styles.configActionButton,
                    selectedDuration && styles.activeSelector
                  )}
                  aria-label="Set Time"
                  tag="label"
                  value={selectedDuration}
                  onChange={onDurationSelectChange}
                />

                <div className={clsx(styles.configActionButton)}>
                  <CardSnoozeButton
                    type="button"
                    className={
                      actionState["state"] === SNOOZED && styles.activeIcon
                    }
                    parentType={ACTION}
                    parentState={actionState["state"]}
                    parentSnoozedTo={actionState["snoozedTo"]}
                    parentSnoozedToString={actionState["snoozedToString"]}
                    cardWithColor
                    onSetState={handleSetState}
                    iconOnly={false}
                  />
                </div>

                <CardButton
                  type="button"
                  ref={leverageButtonRef}
                  className={clsx(
                    styles.buttonDueDate,
                    styles.configActionButton
                  )}
                  aria-label={
                    leveragedPerson
                      ? `Remove leveraged from ${leveragedPersonNickname}`
                      : "Leverage action"
                  }
                  iconOnly={false}
                  onClick={handleLeverageToggle}
                >
                  <span
                    className={clsx(
                      styles.buttonDueDateLabel,
                      leveragedPerson && styles.activeIcon
                    )}
                  >
                    <IconLeveraged role="presentation" />
                    <span>
                      {leveragedPerson
                        ? `Leveraged to ${leveragedPersonNickname}`
                        : "Leverage"}
                    </span>
                  </span>
                </CardButton>

                {actionToEdit && (
                  <CardButton
                    type="button"
                    className={clsx(
                      styles.buttonDueDate,
                      styles.configActionButton
                    )}
                    aria-label="Delete action"
                    iconOnly={false}
                    onClick={handleDelete}
                  >
                    <span className={styles.buttonDueDateLabel}>
                      <IconTrash role="presentation" />
                      <span>Delete</span>
                    </span>
                  </CardButton>
                )}
              </>
            }
          </div>

          <div className={styles.actionsWrapper}>
            <Button
              className={styles.cancelButton}
              color={BUTTON_COLOR_OUTLINE}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button color={BUTTON_COLOR_FILL} type="submit">
              {actionToEdit ? "Save" : "Create"}
              <span className="sr-only"> Action</span>
            </Button>
          </div>
        </div>
      </form>

      {emailBody && (
        <section className={styles.emailBody}>
          <h3 className={styles.emailBodyTitle}>Email Description</h3>
          <p>{emailBody}</p>
        </section>
      )}

      {descriptionErrorMessage && (
        <GlobalToast id={errorToastId} error fromDialog>
          {descriptionErrorMessage}
        </GlobalToast>
      )}

      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={() => setShowUnsavedChangesDialog(false)}
          onDiscard={handleClose}
        />
      )}

      {leverageDialogVisible && (
        <LeverageDialog
          onConfirm={handleLeverageDialogConfirm}
          onClose={handleLeverageDialogClose}
          onRemoveLeverage={handleRemoveLeverage}
          selectedPersonNickname={leveragedPersonNickname}
        />
      )}

      {deleteDialogVisible && (
        <Dialog
          headerTitle="Delete Action"
          footer={
            <>
              <Button block onClick={performDelete} negative>
                Delete action
              </Button>
              <Button block onClick={() => setDeleteDialogVisible(false)}>
                Cancel
              </Button>
            </>
          }
        >
          <p>
            This action is scheduled. Deleting it will also delete those
            scheduled events. Do you wish to&nbsp;proceed?
          </p>
        </Dialog>
      )}
    </CreateDialog>
  );
}

export default ActionDialog;
