import React, { useState, useRef, useCallback, useMemo } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { BLOCK_DETAIL_URL } from "../App";

import { trackEvent } from "src/services/AnalyticsService";
import fireConfetti from "src/services/ConfettiService";

import {
  ACTIVE,
  COMPLETED,
  CATEGORY,
  SNOOZED,
  UNCATEGORIZED_ID,
  UNNECESSARY,
  PERSON_DELETED_NICKNAME,
  BLOCK,
} from "../../services/DbService/constants";
import { calculateSnoozedToString } from "src/services/DbService/general";
import {
  deleteAction,
  updateAction,
  moveAction,
} from "../../services/DbService/actions";

import usePeople from "../../hooks/usePeople";
import useReplaceMentions, {
  mentionToText,
} from "../../hooks/useReplaceMentions";

import { useShowEditActionDialog } from "../ActionDialog";
import { useProjectName } from "../ProjectsContext";
import { useCategoryOptions, useGetCategoryColor } from "../CategoriesContext";
import Button from "../Button";
import { CardButton, CardParentMenu } from "../Card";
import Dialog from "../Dialog";
import LeverageDialog from "./LeverageDialog";
import Tooltip from "../Tooltip";
import CompletionCheckbox from "../CompletionCheckbox/CompletionCheckbox";
import CardFavoriteButton from "../Card/CardFavoriteButton";

import { timeToText } from "src/utils/timeToText";

import { ReactComponent as IconLeveraged } from "../../assets/icons/16-leveraged.svg";
import { ReactComponent as IconUnneccessary } from "../../assets/icons/16-unnecessary.svg";
import { ReactComponent as IconCalendar } from "../../assets/icons/16-calendar.svg";
import { ReactComponent as IconExclamation } from "../../assets/icons/16-exclamation.svg";
import { ReactComponent as IconResult } from "../../assets/icons/32-target.svg";
// This uses the same dropdown arrows as <Input tag="select" /> but otherwise has the visual
// styles of a Button so only the icon is imported

import styles from "./ActionItem.module.scss";

export const CARD_STATUS_SNOOZED = "snoozed";
export const CARD_STATUS_UNSCHEDULED = "unscheduled";
export const CARD_STATUS_PAST_DUE = "pastdue";
export const CARD_STATUS_UNCATEGORIZED = "uncategorized";
export const CARD_STATUS_ON_TIME = "ontime";

const getDateTimeString = (dateTime) =>
  format(dateTime, "h:mm aa 'on' EEE, MMM d");

const mentionToTag = (person) => `<span>${mentionToText(person)}</span>`;

function ActionItem({
  className,
  children,
  action,
  block,
  event,
  "data-capture-list": isCaptureList,
  readonly,
  cardWithColor,
  enableDetailLink = true,
  enableProjectBanner = true,
  number,
  ...props
}) {
  const actionRef = useRef();

  const {
    id: actionId,
    categoryId,
    projectId,
    blockId,
    description,
    starred,
    unnecessary,
    fromEmail,
    leveragedPersonId,
    duration,
    state,
    snoozedTo,
  } = action;

  const { result: blockResult } = block || {};
  const { startDate, duration: eventDuration } = event || {};

  const getCategoryColor = useGetCategoryColor();
  const projectName = useProjectName(projectId, true);
  const showEditActionDialog = useShowEditActionDialog();

  const history = useHistory();

  const { peopleById } = usePeople();

  const [leverageDialogVisible, setLeverageDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const optionsMenuButtonRef = useRef();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(null);

  const leveragedPersonNickname = useMemo(() => {
    if (!leveragedPersonId || !peopleById) return "";
    // if the person exists, then nickname will too. If there is no nickname,
    // it is safe to assume they have been deleted
    const nickname = peopleById[leveragedPersonId]?.nickname;
    return nickname ? `@${nickname}` : PERSON_DELETED_NICKNAME;
  }, [peopleById, leveragedPersonId]);

  const newState =
    unnecessary || state === SNOOZED || state === ACTIVE ? COMPLETED : ACTIVE;

  const completed = state === COMPLETED;

  const replaceMentions = useReplaceMentions();
  const replaceMentionsWithTags = useReplaceMentions(mentionToTag);

  const [descriptionText, descriptionHtml] = useMemo(() => {
    return [replaceMentions(description), replaceMentionsWithTags(description)];
  }, [replaceMentions, replaceMentionsWithTags, description]);

  const handleLeverageDialogConfirm = useCallback(
    (person) => {
      updateAction(action, { leveragedPersonId: person.id });
    },
    [action]
  );

  const handleLeverageDialogClose = useCallback(() => {
    setLeverageDialogVisible(false);
    optionsMenuButtonRef?.current && optionsMenuButtonRef.current.focus();
  }, []);

  const onToggleStarred = useCallback(
    (e) => {
      e.stopPropagation();
      updateAction(action, { starred: !starred });
    },
    [action, starred]
  );

  const onToggleCompletionButton = useCallback(
    (e, key) => {
      e.stopPropagation();
      e.currentTarget.blur();

      if (key === "state") {
        if (newState === COMPLETED) {
          updateAction(action, { state: COMPLETED, unnecessary: false });
          fireConfetti();
        } else {
          updateAction(action, { state: newState });
        }
      } else if (key === UNNECESSARY) {
        updateAction(action, { state: COMPLETED, unnecessary: !unnecessary });
      }

      // onToggleCompletionButton is called outside the menu too, but given
      // that any mouse clicks outside the menu would also close the menu
      // this is safe to call any time this function is called.
      setOptionsMenuVisible(false);
    },
    [action, newState, unnecessary, setOptionsMenuVisible]
  );

  // the optionsMap object associates the id of the available parents
  // (categories, blocks, action list) with the type of each
  const { optionsMap } = useCategoryOptions();

  // handle two different parent cases: categories and blocks
  const handleParentClick = useCallback(
    (newParentId) => {
      const parentData = optionsMap?.[newParentId] || {};

      if ([CATEGORY, BLOCK].includes(parentData.type)) {
        const newParentType = parentData.type;
        const oldParentId = newParentType === CATEGORY ? categoryId : blockId;

        // if something changed, then update the action with  the selected
        // category or block
        if (oldParentId === newParentId) return;
        moveAction(
          actionId,
          newParentId,
          newParentType,
          state,
          null,
          calculateSnoozedToString(snoozedTo)
        );
      }
    },
    [actionId, blockId, categoryId, state, snoozedTo, optionsMap]
  );

  const handleEdit = useCallback(() => {
    showEditActionDialog({ action, block, event });
  }, [showEditActionDialog, action, block, event]);

  const handleEditBlock = useCallback(
    (e) => {
      e.stopPropagation();

      const blockUrl = generatePath(BLOCK_DETAIL_URL, { blockId });

      trackEvent("User clicked a Block outcome Item", {
        id: actionId,
        blockId,
      });

      history.push(blockUrl);
    },
    [history, actionId, blockId]
  );

  const performDelete = useCallback(() => {
    deleteAction(actionId);
  }, [actionId]);

  const renderCardDateTime = useMemo(() => {
    let dateTimeString = null;

    // snoozed actions must show the snoozedTo
    if (state === SNOOZED) {
      let snoozeStr = calculateSnoozedToString(snoozedTo);
      if (snoozedTo) {
        snoozeStr = format(snoozedTo, "EEE, MMM d");
      }

      if (snoozedTo) {
        dateTimeString = `Snoozed until ${snoozeStr}`;
      } else {
        dateTimeString = "Snoozed indefinitely";
      }
    } else {
      // scheduled actions have a startDate and can have a duration
      if (startDate) {
        dateTimeString = getDateTimeString(startDate);

        if (eventDuration) {
          dateTimeString += ` | ${timeToText(eventDuration).dropdown}`;
        }
      }

      // in case of an unscheduled action, we want to show the time duration (if any)
      if (!dateTimeString && duration) {
        dateTimeString = timeToText(duration).dropdown;
      }
    }

    if (dateTimeString) {
      return (
        <div
          className={clsx(
            styles.dateTimeLabel,
            state !== SNOOZED && startDate < new Date() && styles.pastDue
          )}
        >
          {dateTimeString}
        </div>
      );
    }

    return null;
  }, [startDate, eventDuration, duration, snoozedTo, state]);

  const actionCardStatus = useMemo(() => {
    if (state === SNOOZED) {
      return CARD_STATUS_SNOOZED;
    }

    // unscheduled actions
    if (!startDate) {
      return CARD_STATUS_UNSCHEDULED;
    }

    // past due actions
    if (startDate < new Date()) {
      return CARD_STATUS_PAST_DUE;
    }

    // uncategorized actions
    if (categoryId === UNCATEGORIZED_ID) {
      return CARD_STATUS_UNCATEGORIZED;
    }

    return CARD_STATUS_ON_TIME;
  }, [state, startDate, categoryId]);

  const renderCardActionableButton = useMemo(() => {
    const actionable = actionCardStatus;

    if ([CARD_STATUS_SNOOZED, CARD_STATUS_UNSCHEDULED].includes(actionable)) {
      return (
        <CardButton
          aria-label="Schedule action"
          onClick={handleEdit}
          cardWithColor={cardWithColor}
          className={styles.actionableButton}
        >
          <IconCalendar role="presentation" />
        </CardButton>
      );
    }

    if (actionable === CARD_STATUS_PAST_DUE) {
      return (
        <CardButton
          aria-label="Schedule action"
          onClick={handleEdit}
          cardWithColor={cardWithColor}
          className={styles.actionableButton}
        >
          <IconExclamation
            className={clsx(styles.pastDue)}
            role="presentation"
          />
        </CardButton>
      );
    }

    if (actionable === CARD_STATUS_UNCATEGORIZED) {
      return (
        <CardParentMenu
          parentType={BLOCK}
          // if it does not belong to a block, then it may have a
          // category (or they could be null)
          parentId={blockId || categoryId}
          onParentClick={handleParentClick}
          cardWithColor={cardWithColor}
          className={styles.actionableButton}
        />
      );
    }

    return null;
  }, [
    blockId,
    categoryId,
    handleParentClick,
    handleEdit,
    actionCardStatus,
    cardWithColor,
  ]);

  const enableClickToEdit = enableDetailLink && !readonly;
  const LabelTag = enableClickToEdit ? "button" : "span";
  const tooltip = enableClickToEdit
    ? `Edit Action: ${descriptionText}`
    : descriptionText;

  if (peopleById === null) return null;

  return (
    <li
      ref={actionRef}
      className={clsx(className, styles.cardContainer)}
      data-category-color={getCategoryColor(categoryId)}
      data-block-id={blockId}
      {...props}
    >
      <div
        className={clsx(styles.card)}
        role="button"
        aria-label={tooltip}
        onClick={enableClickToEdit ? handleEdit : undefined}
      >
        {enableProjectBanner && (
          <span className={styles.banner} aria-hidden={!projectId}>
            {projectId && (
              <Tooltip title={projectName}>
                <span>{projectName}</span>
              </Tooltip>
            )}
          </span>
        )}
        <div
          className={clsx(styles.content, number && styles.contentWithNumber)}
        >
          <div className={clsx(styles.quickView)}>
            <div className={clsx(styles.info)}>
              {/* TODO: Come back and fix this (it is currently obscured by the
                        checkbox) */}
              {/* {number && <span className={styles.number}>{number}.</span>} */}
              <Tooltip title={tooltip} disabled={optionsMenuVisible}>
                <LabelTag className={styles.label}>
                  {fromEmail && (
                    <span className={styles.emailLabel}>Email Capture</span>
                  )}
                  <span
                    className={clsx(
                      styles.description,
                      completed && styles.descriptionCompleted
                    )}
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                  />
                  {leveragedPersonId && (
                    <span
                      className={clsx(
                        styles.leveraged,
                        completed && styles.leveragedCompleted
                      )}
                    >
                      <IconLeveraged role="presentation" />
                      <span className={styles.leveragedLabel}>
                        <span>Leveraged to&nbsp;</span>
                        <span className={styles.leveragedName}>
                          {leveragedPersonNickname}
                        </span>
                      </span>
                    </span>
                  )}
                </LabelTag>
              </Tooltip>
              {renderCardDateTime}
            </div>

            <div className={clsx(styles.buttons, "sortable-ignore")}>
              <CardFavoriteButton
                aria-label={starred ? "Unstar Action" : "Star Action"}
                onClick={onToggleStarred}
                disabled={readonly}
                selected={starred}
                cardWithColor={cardWithColor}
              />

              {renderCardActionableButton}
            </div>
          </div>
        </div>

        {blockResult && (
          <Button
            className={clsx(styles.blockOutcome)}
            aria-label={blockResult}
            onClick={handleEditBlock}
            tabIndex={0}
          >
            <IconResult
              className={styles.labelIcon}
              role="presentation"
            ></IconResult>
            <div className={clsx(styles.infoBlockOutcome)}>{blockResult}</div>
          </Button>
        )}

        <CompletionCheckbox
          className={clsx(
            "sortable-ignore",
            styles.completionButton,
            categoryId !== 0 && cardWithColor && styles.cardWithColor
          )}
          onClick={(e) => onToggleCompletionButton(e, "state")}
          disabled={readonly}
          label={newState === ACTIVE ? "Mark as active" : "Mark as completed"}
          completed={completed}
          icon={unnecessary ? IconUnneccessary : undefined}
          categoryColor={cardWithColor}
        />
      </div>

      {leverageDialogVisible && (
        <LeverageDialog
          onConfirm={handleLeverageDialogConfirm}
          onClose={handleLeverageDialogClose}
        />
      )}

      {deleteDialogVisible && (
        <Dialog
          headerTitle="Delete Action"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>
                Cancel
              </Button>
              <Button block onClick={performDelete} negative>
                Delete action
              </Button>
            </>
          }
        >
          <p>
            This action is scheduled. Deleting it will also delete those
            scheduled events. Do you wish to&nbsp;proceed?
          </p>
        </Dialog>
      )}
    </li>
  );
}

export default ActionItem;
