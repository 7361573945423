import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useCallback,
} from "react";

import { ALL_CATEGORY_ID } from "src/services/DbService/constants";
import { getAuthTokens } from "src/services/AuthService";

const LOCALSTORAGE_LAST_USER_UID = "dashboardLastUserUid";
const LOCALSTORAGE_ACTIVE_TAXONOMY = "dashboardActiveTaxonomy";
const LOCALSTORAGE_STARRED_FILTER = "starredFilter";

export const DashboardContext = createContext();

function checkLocalDashboardContextValues() {
  // The last user uid is stored here rather than AuthService as this isn't
  // mounted at the point that watchUser’s subscribers are notified during sign
  // in/sign up.
  const { uid: currentUid } = getAuthTokens();
  const oldUid = localStorage.getItem(LOCALSTORAGE_LAST_USER_UID);

  if (currentUid === oldUid) return;

  localStorage.setItem(LOCALSTORAGE_LAST_USER_UID, currentUid);
  localStorage.removeItem(LOCALSTORAGE_ACTIVE_TAXONOMY);
}

export function DashboardContextProvider({ children }) {
  const [activeTaxonomy, setActiveTaxonomy] = useState(() => {
    const localValue = localStorage.getItem(LOCALSTORAGE_ACTIVE_TAXONOMY);
    return localValue
      ? JSON.parse(localValue)
      : { categoryId: ALL_CATEGORY_ID };
  });

  const [activeStarred, setActiveStarred] = useState(() => {
    const localValue = localStorage.getItem(LOCALSTORAGE_STARRED_FILTER);
    return localValue ? JSON.parse(localValue) : false;
  });

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_STARRED_FILTER,
      JSON.stringify(activeStarred)
    );
  }, [activeStarred]);

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_ACTIVE_TAXONOMY,
      JSON.stringify(activeTaxonomy)
    );
  }, [activeTaxonomy]);

  useEffect(() => {
    checkLocalDashboardContextValues();
  }, []);

  const setActiveCategoryId = useCallback((categoryId) => {
    setActiveTaxonomy({ categoryId });
  }, []);

  const setActiveProjectId = useCallback((projectId) => {
    setActiveTaxonomy({ projectId });
  }, []);

  const toggleActiveStarred = useCallback(() => {
    setActiveStarred((starred) => !starred);
  }, []);

  const starredFilter = useCallback(
    (action) => (activeStarred ? action.starred : Boolean),
    [activeStarred]
  );

  const value = useMemo(() => {
    const activeProjectId = activeTaxonomy?.projectId;
    const activeCategoryId = activeTaxonomy?.categoryId;
    return {
      activeCategoryId,
      setActiveCategoryId,
      activeProjectId,
      setActiveProjectId,
      activeStarred,
      toggleActiveStarred,
      starredFilter,
    };
  }, [
    activeTaxonomy,
    setActiveCategoryId,
    setActiveProjectId,
    activeStarred,
    toggleActiveStarred,
    starredFilter,
  ]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}
