import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import clsx from "clsx";
import {
  add,
  addDays,
  differenceInDays,
  endOfWeek,
  startOfDay,
  startOfWeek,
  sub,
  subDays,
} from "date-fns";

import { modifierKey } from "src/utils/modifierKey";

import { NBSP } from "src/constants";
import { generateId, ParameterError } from "src/services/DbService/general";
import { watchExternalEvents } from "src/services/ExternalEventsService";
import {
  batchEventsRegister,
  Event,
  watchEvents,
} from "src/services/DbService/events";

import useConfirmClose from "src/hooks/useConfirmClose";

import {
  PLANNER_BLOCK_DETAIL_URL,
  PLANNER_CATEGORY_DETAIL_URL,
  PLANNER_PROJECT_DETAIL_URL,
  PLANNER_URL,
} from "../App";
import { useCategories } from "../CategoriesContext";
import { DashboardContextProvider } from "../Dashboard";
import { SidebarContext } from "../SidebarContext";
import Page from "../Page";
import LoadingSpinner from "../LoadingSpinner";
import Dialog from "../Dialog";
import { GlobalToast } from "../Toast";
import BlockDetail from "../DetailPanels/BlockDetail";
import CategoryDetail from "../DetailPanels/CategoryDetail";
import ProjectDetail from "../DetailPanels/ProjectDetail";
import PageTransition from "../PageTransition";
import SidebarContainer from "../SidebarContainer";
import PeriodView from "../CalendarPanel/PeriodView";
import CalendarPanelTitle from "../CalendarPanel/CalendarPanelTitle";
import PlannerSaveDialog from "../PlannerSaveDialog/PlannerSaveDialog";
import { useShowPlannerSaveDialog } from "../PlannerSaveDialog/PlannerSaveDialogContext";
import Button from "../Button";
import Tooltip from "../Tooltip";
import Heading, { HEADING_LEVEL_1 } from "../Heading";

import { ReactComponent as IconPrevious } from "../../assets/icons/16-previous.svg";
import { ReactComponent as IconNext } from "../../assets/icons/16-next.svg";

import styles from "./Planner.module.scss";

const SYMBOL_LEFT_ARROW = "\u21E6";
const SYMBOL_RIGHT_ARROW = "\u21E8";

const DISPLAY_PERIOD_WEEK = "week";
const INITIAL_DISPLAY_PERIOD = DISPLAY_PERIOD_WEEK;

const displayPeriods = {
  [DISPLAY_PERIOD_WEEK]: {
    endOfPeriodFunc: endOfWeek,
    startOfPeriodFunc: startOfWeek,
    tabId: DISPLAY_PERIOD_WEEK,
    tabLabel: "Week",
  },
};

const getPeriodStart = (displayPeriod, date = new Date()) =>
  displayPeriods[displayPeriod].startOfPeriodFunc(date);

const getPeriodEnd = (displayPeriod, date = new Date()) =>
  displayPeriods[displayPeriod].endOfPeriodFunc(date);

function ConfirmPlannerDialog({
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "Yes",
  cancelText = "No",
}) {
  return (
    <Dialog
      headerTitle={title}
      footer={
        <>
          <Button block onClick={onClose}>
            {cancelText}
          </Button>
          <Button block onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      }
    >
      <p>{message}</p>
    </Dialog>
  );
}

function AlertPlannerDialog({ onClose, title, message }) {
  return (
    <Dialog
      headerTitle={title}
      footer={
        <>
          <Button block onClick={onClose}>
            Ok
          </Button>
        </>
      }
    >
      <p>{message}</p>
    </Dialog>
  );
}

function Planner({ ...props }) {
  const location = useLocation();

  // controls the Planner save dialog
  const showPlannerSaveDialog = useShowPlannerSaveDialog();

  // refs needed to retrieve the enabled state of the save button
  // and avoid to navigate through keyboard shortcuts
  const previousPeriodButtonRef = useRef();
  const nextPeriodButtonRef = useRef();

  const displayPeriod = INITIAL_DISPLAY_PERIOD;

  const initialPeriodStart = getPeriodStart(DISPLAY_PERIOD_WEEK);
  // This date defines which week will be rendered
  const [periodStart, setPeriodStart] = useState(initialPeriodStart);

  // modal to confirm if the user wants to cancel/discard changes
  // when clicking cancel on save dialog
  const [confirmCancelDialogVisible, setConfirmCancelDialogVisible] =
    useState(false);
  // an alert modal to inform that the planner has been saved successfully
  const [successAlertDialogVisible, setSuccessAlertDialogVisible] =
    useState(false);
  // an alert modal to inform that an error occurred saving the planner
  const [errorAlertDialogVisible, setErrorAlertDialogVisible] = useState(false);

  // controls the visibility of the saving toast
  const [savingPlannerToast, setSavingPlannerToast] = useState(false);

  const periodEnd = useMemo(
    () => getPeriodEnd(displayPeriod, periodStart),
    [displayPeriod, periodStart]
  );

  const {
    actionListExpanded,
    thisWeekSidebarExpanded,
    categorySidebarExpanded,
  } = useContext(SidebarContext);

  const history = useHistory();

  const { activeCategories } = useCategories();

  // events fetched from the database
  const [databaseEvents, setDatabaseEvents] = useState([]);

  // copy of the database events, used as the draft state
  const [draftEvents, setDraftEvents] = useState([]);

  // controls the save dialog
  const [hasPlannerChanges, setHasPlannerChanges] = useState(false);

  // controls the confirmation dialog shown if the user exits the
  // Planner without saving
  const {
    showUnsavedChangesDialog,
    setIsDirty,
    handleCloseDialog,
    handleCancelConfirm,
  } = useConfirmClose(() => setHasPlannerChanges(false));

  const [externalEventsBuffer, setExternalEventsBuffer] = useState([]);
  useEffect(() => watchExternalEvents(setExternalEventsBuffer), []);

  const startMaxDateInterval = useMemo(
    () => sub(startOfDay(new Date()), { months: 3, weeks: 1 }),
    []
  );
  const endMaxDateInterval = useMemo(
    () => add(startOfDay(new Date()), { months: 3, weeks: 1 }),
    []
  );

  // create a copy from an events array
  const copyEventsArray = useCallback((eventArray) => {
    return [
      ...eventArray.map((event) => Object.assign(new Event(), { ...event })),
    ];
  }, []);

  useEffect(
    () => showPlannerSaveDialog(hasPlannerChanges),
    [hasPlannerChanges, showPlannerSaveDialog]
  );

  // fetch events from database
  useEffect(() => {
    return watchEvents(
      startMaxDateInterval,
      endMaxDateInterval,
      setDatabaseEvents
    );
  }, [startMaxDateInterval, endMaxDateInterval]);

  // min and max dates allowed to be shown on the Planner calendar
  const firstDateToShow = useMemo(
    () => add(startMaxDateInterval, { weeks: 1 }),
    [startMaxDateInterval]
  );

  const lastDateToShow = useMemo(
    () => sub(endMaxDateInterval, { weeks: 1 }),
    [endMaxDateInterval]
  );

  // update eventData when databaseEvents change
  useEffect(() => {
    if (!databaseEvents) return;

    setDraftEvents(copyEventsArray(databaseEvents));
    setHasPlannerChanges(false);
    setIsDirty(false);
  }, [databaseEvents, copyEventsArray, setIsDirty]);

  const setEventStartDate = useCallback(
    (eventId, startDate) => {
      if (!startDate instanceof Date)
        throw new ParameterError({ startDate }, "not a Date object");
      if (isNaN(startDate))
        throw new ParameterError({ startDate }, "invalid date");

      const event = draftEvents.find((event) => event.id === eventId);

      setDraftEvents([
        ...draftEvents.filter((event) => event.id !== eventId),
        Object.assign(new Event(), { ...event, startDate }),
      ]);

      setHasPlannerChanges(true);
      setIsDirty(true);
    },
    [draftEvents, setIsDirty]
  );

  const setEventDuration = useCallback(
    (eventId, duration) => {
      if (!Number.isInteger(duration))
        throw new ParameterError({ duration }, "not an integer number");
      if (duration <= 0)
        throw new ParameterError({ duration }, "can't be <= 0");

      const event = draftEvents.find((event) => event.id === eventId);

      setDraftEvents([
        ...draftEvents.filter((event) => event.id !== eventId),
        Object.assign(new Event(), { ...event, duration }),
      ]);

      setHasPlannerChanges(true);
      setIsDirty(true);
    },
    [draftEvents, setIsDirty]
  );

  const periodDays =
    useMemo(
      () => differenceInDays(periodEnd, periodStart),
      [periodEnd, periodStart]
    ) + 1;

  const handlePreviousPeriod = useCallback(() => {
    setPeriodStart(subDays(periodStart, periodDays));
  }, [periodDays, periodStart]);

  const handleNextPeriod = useCallback(() => {
    setPeriodStart(addDays(periodStart, periodDays));
  }, [periodDays, periodStart]);

  const routeIsPlanner = useRouteMatch(PLANNER_URL).isExact;

  // Previous/Next periods on CMD/CTRL + arrows
  useEffect(() => {
    if (!routeIsPlanner) return;

    function handleKeydownAnywhere(e) {
      if ((e.key !== "ArrowRight" && e.key !== "ArrowLeft") || !e[modifierKey])
        return;

      e.preventDefault();

      if (e.key === "ArrowRight") {
        // avoid navigate when button disabled
        if (nextPeriodButtonRef?.current?.disabled) return;
        handleNextPeriod();
      } else {
        // avoid navigate when button disabled
        if (previousPeriodButtonRef?.current?.disabled) return;
        handlePreviousPeriod();
      }
    }
    document.addEventListener("keydown", handleKeydownAnywhere);
    return () => document.removeEventListener("keydown", handleKeydownAnywhere);
  }, [routeIsPlanner, handlePreviousPeriod, handleNextPeriod]);

  const savePlannerToDatabase = useCallback(async () => {
    await batchEventsRegister(draftEvents);
    setConfirmCancelDialogVisible(false);
  }, [draftEvents]);

  const handleCancelDialog = useCallback(() => {
    if (!databaseEvents) return;

    setDraftEvents(copyEventsArray(databaseEvents));
    setHasPlannerChanges(false);

    setConfirmCancelDialogVisible(false);
    showPlannerSaveDialog(false);
    setIsDirty(false);
  }, [databaseEvents, copyEventsArray, showPlannerSaveDialog, setIsDirty]);

  // Confirm changes on changes dialog
  const handleConfirmDialog = useCallback(async () => {
    try {
      setSavingPlannerToast(true);
      await savePlannerToDatabase();
      setSavingPlannerToast(false);

      setHasPlannerChanges(false);
      setSuccessAlertDialogVisible(true);
      setIsDirty(false);
    } catch (e) {
      setSavingPlannerToast(false);
      setErrorAlertDialogVisible(false);
      setIsDirty(false);
    }
  }, [savePlannerToDatabase, setIsDirty]);

  const handleRemoveFromCalendar = useCallback(
    (eventId) => {
      const eventRemoved = draftEvents.find((event) => event.id === eventId);

      // When an event is removed from the calendar inside the Planner View,
      // we need to check if its still on draft with "creationDate", otherwise
      // we should keep it inside draftEvents so the user is able to cancel or
      // update the changes in the database with "isDeleted"
      if (!eventRemoved.creationDate) {
        setDraftEvents([
          ...draftEvents.filter((event) => event.id !== eventId),
        ]);
      } else {
        setDraftEvents([
          ...draftEvents.map((event) =>
            event.id === eventId
              ? {
                  ...event,
                  isDeleted: true,
                }
              : event
          ),
        ]);
      }

      setHasPlannerChanges(true);
      setIsDirty(true);
    },
    [draftEvents, setIsDirty]
  );

  const dropActionEvent = useCallback(
    (id, startDate, duration, categoryId, blockId, actionsIds) => {
      const events = draftEvents.filter((event) =>
        event?.actionsIds?.includes(id)
      );

      if (events.length > 0) {
        const newEvents = events.map((event) => {
          return Object.assign(new Event(), {
            ...event,
            startDate,
            duration,
            categoryId,
            blockId,
            actionsIds,
          });
        });

        setDraftEvents([
          ...draftEvents.filter((event) => !event.actionsIds.includes(id)),
          ...newEvents,
        ]);
      } else {
        const newEvent = Object.assign(new Event(), {
          id: generateId(),
          categoryId,
          blockId,
          startDate,
          duration,
          actionsIds,
          creationDate: null, // this is a new event
        });

        setDraftEvents([...draftEvents, newEvent]);
      }

      setHasPlannerChanges(true);
      setIsDirty(true);
    },
    [draftEvents, setIsDirty, setHasPlannerChanges]
  );

  const pageProps = {
    pageTitle: "Planner",
    ...props,
  };

  if (activeCategories === null) {
    return (
      <Page {...pageProps}>
        <LoadingSpinner absolute />
      </Page>
    );
  }

  const mustIncludeDayInTitle = periodDays < 2;
  const displayedPeriodLabel = displayPeriods[displayPeriod].tabLabel;

  return (
    <Page
      className={clsx(
        (categorySidebarExpanded ||
          thisWeekSidebarExpanded ||
          actionListExpanded) &&
          styles.expanded
      )}
      sidebarNav={true}
      {...pageProps}
    >
      <SidebarContainer
        categoryExpanded={categorySidebarExpanded}
        thisWeekExpanded={thisWeekSidebarExpanded}
        actionListExpanded={actionListExpanded}
      />

      <main className={styles.main}>
        <PageTransition>
          <Switch location={location}>
            <Route path={PLANNER_CATEGORY_DETAIL_URL}>
              <CategoryDetail
                closeRedirectUrl={PLANNER_URL}
                className={styles.detail}
              />
            </Route>
            <Route path={PLANNER_PROJECT_DETAIL_URL}>
              <ProjectDetail
                closeRedirectUrl={PLANNER_URL}
                className={styles.detail}
              />
            </Route>
            <Route path={PLANNER_URL}>
              <div className={styles.container}>
                <div className={styles.header}>
                  <CalendarPanelTitle
                    date={periodStart}
                    includeDay={mustIncludeDayInTitle}
                  />

                  <div className={styles.headerPanel}>
                    <Heading className={styles.title} level={HEADING_LEVEL_1}>
                      Planner View
                    </Heading>

                    <div className={styles.buttons}>
                      <Tooltip
                        title={`Previous ${displayPeriods[displayPeriod].tabLabel}`}
                        shortcut={SYMBOL_LEFT_ARROW}
                        shortcutModifier
                      >
                        <Button
                          ref={previousPeriodButtonRef}
                          onClick={handlePreviousPeriod}
                          iconOnly
                          aria-label={`Previous ${displayedPeriodLabel}`}
                          disabled={firstDateToShow > periodStart}
                        >
                          <IconPrevious role="presentation" />
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={`Next ${displayedPeriodLabel}`}
                        shortcut={SYMBOL_RIGHT_ARROW}
                        shortcutModifier
                      >
                        <Button
                          ref={nextPeriodButtonRef}
                          onClick={handleNextPeriod}
                          iconOnly
                          aria-label={`Next ${displayedPeriodLabel}`}
                          disabled={lastDateToShow < periodEnd}
                        >
                          <IconNext role="presentation" />
                        </Button>
                      </Tooltip>

                      <Button
                        className={styles.todayButton}
                        onClick={() => setPeriodStart(initialPeriodStart)}
                        aria-label="Go to today"
                        tooltip
                      >
                        Today
                      </Button>
                    </div>
                  </div>
                </div>
                <PeriodView
                  periodStart={periodStart}
                  periodEnd={periodEnd}
                  eventsBuffer={draftEvents.filter((event) => !event.isDeleted)}
                  setEventsBuffer={setDraftEvents}
                  externalEventsBuffer={externalEventsBuffer}
                  dropActionEvent={dropActionEvent}
                  setEventDuration={setEventDuration}
                  setEventStartDate={setEventStartDate}
                  showEventDialog={false}
                  showCompletionOnEvents={false}
                  onRemoveFromCalendar={handleRemoveFromCalendar}
                />
              </div>

              <Switch location={location}>
                <Route path={PLANNER_BLOCK_DETAIL_URL}>
                  <Dialog
                    className={styles.blockDialog}
                    customBody
                    onClose={() => history.push(PLANNER_URL)}
                  >
                    <BlockDetail
                      previousUrl={PLANNER_URL}
                      detailUrl={PLANNER_BLOCK_DETAIL_URL}
                    />
                  </Dialog>
                </Route>
              </Switch>
            </Route>
          </Switch>
        </PageTransition>
      </main>
      {/* planner save dialog */}
      <PlannerSaveDialog
        onConfirm={handleConfirmDialog}
        onClose={() => setConfirmCancelDialogVisible(true)}
      />
      {/* confirm dialog on cancel changes */}
      {confirmCancelDialogVisible && (
        <ConfirmPlannerDialog
          onClose={() => setConfirmCancelDialogVisible(false)}
          onConfirm={handleCancelDialog}
          title="Confirm Cancel Changes"
          message="You have made changes in your Planner, but have not saved&nbsp;them.&nbsp;Are you sure you want to cancel?"
        />
      )}
      {/* success alert after saving changes */}
      {successAlertDialogVisible && (
        <AlertPlannerDialog
          onClose={() => setSuccessAlertDialogVisible(false)}
          title="Planner Updates Successfully Saved"
          message="Your Planner updates have been successfully saved!"
        />
      )}
      {/* error alert after saving changes */}
      {errorAlertDialogVisible && (
        <AlertPlannerDialog
          onClose={() => setErrorAlertDialogVisible(false)}
          title="Error Saving Planner updates"
          message="There was an error saving your Planner updates. Please try again."
        />
      )}
      {/* saving status toast */}
      {savingPlannerToast && (
        <GlobalToast fromDialog>
          <LoadingSpinner />
          <span style={{ marginRight: "20rem" }}>{NBSP}</span> Saving Planner
          updates...
        </GlobalToast>
      )}
      {showUnsavedChangesDialog && (
        <ConfirmPlannerDialog
          onClose={handleCloseDialog}
          onConfirm={handleCancelConfirm}
          title="Are you sure you want to leave the Planner?"
          message="You have scheduled Actions on the Planner,&nbsp;if you leave your updated schedule will not be saved."
          confirmText="Leave Planner"
          cancelText="Cancel"
        />
      )}
    </Page>
  );
}

const PlannerWithContext = (props) => {
  return (
    <DashboardContextProvider>
      <Planner {...props} />
    </DashboardContextProvider>
  );
};

export default PlannerWithContext;
