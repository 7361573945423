import React, { useContext } from "react";
import clsx from "clsx";

import { DashboardContext } from "../Dashboard";
import Button, {
  BUTTON_BORDER_STYLE_SQUARE,
  BUTTON_ICON_ALIGN_RIGHT,
} from "../Button";

import { ReactComponent as IconStar } from "src/assets/icons/16-star.svg";
import { ReactComponent as IconStarOutline } from "src/assets/icons/16-star-outline.svg";

import styles from "./StarredFilterButton.module.scss";

function StarredFilterButton() {
  const { activeStarred, toggleActiveStarred } = useContext(DashboardContext);

  return (
    <Button
      className={clsx(styles.button, activeStarred && styles.active)}
      onClick={toggleActiveStarred}
      borderStyle={BUTTON_BORDER_STYLE_SQUARE}
      iconAlign={BUTTON_ICON_ALIGN_RIGHT}
      aria-checked={activeStarred}
      role="checkbox"
    >
      Only show Starred
      {activeStarred && <IconStar role="presentation" />}
      {!activeStarred && <IconStarOutline role="presentation" />}
    </Button>
  );
}

export default StarredFilterButton;
