import React, { useCallback, useContext } from "react";
import { debounce } from "debounce";
import clsx from "clsx";

import {
  CAPTURE_LIST_LABEL,
  THIS_WEEK_LABEL,
  CAPTURE_LIST,
} from "../../services/DbService/constants";

import { DashboardContext } from "../Dashboard";
import { SidebarContext } from "../SidebarContext";

import useAction from "src/hooks/useAction";

import NavButton from "../NavButton";
import Divider from "../Divider";
import CounterBadge from "../CounterBadge/CounterBadge";
import SidebarNavCategory from "./SidebarNavCategory";
import SidebarNavProject from "./SidebarNavProject";
import Tooltip from "../Tooltip";

import { ReactComponent as IconActionList } from "src/assets/icons/24-action-list.svg";
import { ReactComponent as IconCalendarWeek } from "src/assets/icons/24-calendar-week.svg";

import styles from "./SidebarNav.module.scss";

function SidebarNav({ expanded }) {
  const { activeCategoryId, setActiveCategoryId } =
    useContext(DashboardContext);
  const {
    actionListExpanded,
    toggleActionListExpanded,
    thisWeekSidebarExpanded,
    toggleThisWeekSidebarExpanded,
    categorySidebarExpanded,
    toggleCategorySidebarExpanded,
  } = useContext(SidebarContext);
  const { totalPendingActions } = useAction(null, CAPTURE_LIST);

  const handleActionListExpanded = debounce(() => {
    categorySidebarExpanded && toggleCategorySidebarExpanded();
    thisWeekSidebarExpanded && toggleThisWeekSidebarExpanded();

    toggleActionListExpanded();
  }, 150);

  const handleThisWeekExpanded = debounce(() => {
    categorySidebarExpanded && toggleCategorySidebarExpanded();
    actionListExpanded && toggleActionListExpanded();

    toggleThisWeekSidebarExpanded();
  }, 150);

  const isActiveCategory = useCallback(
    (categoryId) => categoryId === activeCategoryId,
    [activeCategoryId]
  );

  const handleSetActiveCategory = debounce((categoryId) => {
    actionListExpanded && toggleActionListExpanded();
    thisWeekSidebarExpanded && toggleThisWeekSidebarExpanded();

    if (!categorySidebarExpanded || isActiveCategory(categoryId)) {
      toggleCategorySidebarExpanded();
    }
    setActiveCategoryId(categoryId);
  }, 150);

  return (
    <nav className={styles.sidebarNav}>
      <div className={styles.scrollable}>
        <NavButton
          icon={IconActionList}
          active={actionListExpanded}
          onClick={handleActionListExpanded}
          aria-label={
            actionListExpanded
              ? `Hide ${CAPTURE_LIST_LABEL}`
              : `Show ${CAPTURE_LIST_LABEL}`
          }
          style={{ position: "relative" }}
        >
          {CAPTURE_LIST_LABEL}
          {totalPendingActions > 0 && (
            <CounterBadge counter={totalPendingActions} />
          )}
        </NavButton>
        <NavButton
          icon={IconCalendarWeek}
          active={thisWeekSidebarExpanded}
          onClick={handleThisWeekExpanded}
          aria-label={
            thisWeekSidebarExpanded
              ? `Hide ${THIS_WEEK_LABEL} List`
              : `Show ${THIS_WEEK_LABEL} List`
          }
        >
          {THIS_WEEK_LABEL}
        </NavButton>

        <Divider className={styles.divider} />

        <SidebarNavCategory
          isActive={(categoryId) => isActiveCategory(categoryId)}
          setActive={(categoryId) => handleSetActiveCategory(categoryId)}
        />

        <Divider className={styles.divider} />

        <SidebarNavProject />
      </div>

      <Tooltip title="Show Action List">
        <button
          className={clsx(expanded && styles.expanded, styles.sidebarCollapse)}
          onClick={handleActionListExpanded}
        />
      </Tooltip>
    </nav>
  );
}

export default SidebarNav;
