import React, { useMemo, useCallback, useRef, useState } from "react";

import { scrollIntoViewIfNeeded } from "src/utils/scrollIntoViewIfNeeded";

import { ACTIVE, COMPLETED } from "../../../services/DbService/constants";
import { composeEventDescription } from "../../../services/DbService/events";
import { updateAction } from "../../../services/DbService/actions";

import useUniqueId from "../../../hooks/useUniqueId";
import useReplaceMentions from "../../../hooks/useReplaceMentions";

import EventCard, { useBoxesRefs } from "./../EventCard";
import ScheduledEventDialog from "./ScheduledEventDialog";

function ScheduledEvent({
  event,
  category,
  block,
  actions,
  dragging = false,
  scrollableRef,
  boxes,
  onOpen,
  onClose,
  onRemoveFromCalendar,
  open,
  showCompletionOption = true,
}) {
  const { id: eventId, startDate, duration } = event;

  const dialogId = useUniqueId();

  const [boxRef, setBoxRef] = useState(null);

  const eventRef = useRef();
  const boxesRefs = useBoxesRefs(boxes?.length);

  // Check all actions to see if they are completed.
  const completed = useMemo(
    () => actions.every((action) => action.state === COMPLETED),
    [actions]
  );

  const handleDialogClose = useCallback(() => {
    if (!open) return;

    onClose();
    eventRef.current.focus();
    setBoxRef(null);
  }, [onClose, open]);

  const handleClick = useCallback(
    (boxRef) => {
      if (open) {
        handleDialogClose();
      } else {
        setBoxRef(boxRef);
        onOpen(eventId);
      }
    },
    [eventId, open, onOpen, handleDialogClose]
  );

  const handleFocus = useCallback(() => {
    const boxToScrollTo = boxRef?.current || boxesRefs?.[0]?.current;
    scrollIntoViewIfNeeded(boxToScrollTo, scrollableRef?.current);
  }, [boxesRefs, boxRef, scrollableRef]);

  const handleComplete = useCallback(
    (completed) => {
      // Update all actions within.
      actions.forEach((action) => {
        const newState = completed ? COMPLETED : ACTIVE;
        updateAction(action, { state: newState });
      });
    },
    [actions]
  );

  const replaceMentions = useReplaceMentions();

  const description = useMemo(
    () => composeEventDescription(block, actions, replaceMentions),
    [block, actions, replaceMentions]
  );

  return (
    <>
      <EventCard
        ref={eventRef}
        eventId={eventId}
        color={category?.color}
        resizable
        draggable
        dragging={dragging}
        description={description}
        startDate={startDate}
        duration={duration}
        boxes={boxes}
        boxesRefs={boxesRefs}
        completed={completed}
        onClick={handleClick}
        onComplete={
          actions.length && showCompletionOption ? handleComplete : undefined
        }
        onFocus={handleFocus}
        onRemoveFromCalendar={onRemoveFromCalendar}
        aria-expanded={open}
        id={dialogId}
      />

      {open && (
        <ScheduledEventDialog
          eventId={eventId}
          startDate={startDate}
          duration={duration}
          boxRef={boxRef}
          category={category}
          block={block}
          actions={actions}
          onClose={handleDialogClose}
          aria-labelledby={dialogId}
        />
      )}
    </>
  );
}

export default ScheduledEvent;
