import React from "react";
import clsx from "clsx";
import { format } from "date-fns";
import Heading, { HEADING_LEVEL_0, HEADING_LEVEL_1 } from "../Heading";

import styles from "./CalendarPanel.module.scss";

function CalendarPanelTitlePart({ chunks = [], date, ...props }) {
  if (!chunks.length || !date) return null;

  return (
    <span {...props}>
      {chunks[0] && <span>{format(date, chunks[0])}</span>}
      {chunks[1] && (
        <Heading
          tag="span"
          level={HEADING_LEVEL_1}
          className={styles.titleChunkTwo}
        >
          {format(date, chunks[1])}
        </Heading>
      )}
    </span>
  );
}

function CalendarPanelTitle({
  className,
  date,
  includeDay,
  style = {},
  ...props
}) {
  // This is a magic number for the short format minimum width
  style = {
    minWidth: includeDay ? "7ch" : "5ch",
    ...style,
  };

  const long = [includeDay ? "E d MMMM" : "MMMM", "yyyy"];
  const short = [includeDay ? "d MMM" : "MMM", "yy"];

  return (
    <Heading
      className={clsx(className, styles.title)}
      level={HEADING_LEVEL_0}
      style={style}
      {...props}
    >
      {/* The title is broken down into two parts – one long and one short – that are shown based on the space
          available. */}
      <CalendarPanelTitlePart
        className={styles.titlePartLong}
        date={date}
        chunks={long}
      />
      <CalendarPanelTitlePart
        className={styles.titlePartShort}
        date={date}
        chunks={short}
        aria-hidden="true"
      />
    </Heading>
  );
}

export default CalendarPanelTitle;
