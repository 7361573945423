import React, { useState, useCallback, useEffect } from "react";

import {
  PERSON_NICKNAME_MAX_LENGTH,
  TYPE_LABELS,
} from "src/services/DbService/constants";

import useUniqueId from "../../hooks/useUniqueId";

import CreateDialog from "../CreateDialog";
import PeopleSuggester from "../PeopleSuggester";
import { InputLabel } from "../Input";

import styles from "./LeverageDialog.module.scss";

function LeverageDialog({
  onClose,
  onConfirm,
  onRemoveLeverage,
  selectedPersonNickname,
}) {
  const [input, setInput] = useState("");
  const [inputPrefixed, setInputPrefixed] = useState("");
  const optionsId = useUniqueId();

  const updateInputPersonNickname = useCallback((value) => {
    if (value !== "" && !value.startsWith("@")) value = `@${value}`;
    value = value.toLowerCase().replace(/\s/g, "");
    setInputPrefixed(value);
    setInput(value.substr(1));
  }, []);

  useEffect(() => {
    updateInputPersonNickname(selectedPersonNickname);
  }, [selectedPersonNickname, updateInputPersonNickname]);

  const handleChange = useCallback(
    (e) => {
      updateInputPersonNickname(e.target.value);
    },
    [updateInputPersonNickname]
  );

  const handleConfirm = useCallback(
    (personId) => {
      onConfirm(personId);
      onClose();
    },
    [onConfirm, onClose]
  );

  const handleRemoveLeverage = useCallback(() => {
    onRemoveLeverage();
    onClose();
  }, [onRemoveLeverage, onClose]);

  const [deleteButtonFocused, setDeleteButtonFocused] = useState(false);
  const onFocusDeleteButton = () => setDeleteButtonFocused(true);
  const onBlurDeleteButton = () => setDeleteButtonFocused(false);

  return (
    <CreateDialog onClose={onClose}>
      <form className={styles.form}>
        <label className={styles.label}>
          <InputLabel tag="span">
            Leverage {TYPE_LABELS.person.title}
          </InputLabel>
          <div className={styles.leverageInput}>
            <input
              className={styles.input}
              type="text"
              autoFocus
              maxLength={PERSON_NICKNAME_MAX_LENGTH}
              placeholder="e.g. @rob"
              value={inputPrefixed}
              onChange={handleChange}
              autoComplete="off"
              spellCheck={false}
              role="combobox"
              aria-owns={optionsId}
              aria-controls={optionsId}
              aria-autocomplete="list"
              aria-expanded={true}
            />
            {selectedPersonNickname !== "" && (
              <button
                type="button"
                aria-label="Remove leverage"
                className={styles.removeLeverage}
                onClick={handleRemoveLeverage}
                onFocus={onFocusDeleteButton}
                onBlur={onBlurDeleteButton}
              >
                Remove
              </button>
            )}
          </div>
          <span className={styles.focusRing} aria-hidden="true" />
          <PeopleSuggester
            id={optionsId}
            query={input}
            onConfirm={handleConfirm}
            className={styles.suggestions}
            disableKeyboardSelection={deleteButtonFocused}
          />
        </label>
      </form>
    </CreateDialog>
  );
}

export default LeverageDialog;
