import React, { createRef, useEffect, useState } from "react";
import clsx from "clsx";
import { startOfDay, subDays } from "date-fns";

import { CONTACT_US_LINK, FAQ_LINK, HELP_LINK } from "../../constants";
import { watchUserCounters } from "../../services/DbService/general";
import { setUserGoals, watchUserGoals } from "../../services/DbService/user";
import { PROJECT_FIELDS } from "../../services/DbService/constants";

import Button from "../Button";
import Page, { PageMain } from "../Page";
import ProfileSidebar from "./ProfileSidebar";
import Divider from "../Divider";
import LoadingSpinner from "../LoadingSpinner";
import Heading from "../Heading";
import Input from "../Input";

import { DialogContextProvider } from "../Dialog";

import { ReactComponent as IconBlocks } from "../../assets/icons/16-blocks.svg";
import { ReactComponent as IconActions } from "../../assets/icons/16-actions.svg";
import { ReactComponent as IconLeveraged } from "../../assets/icons/16-leveraged.svg";
import { ReactComponent as IconStarOutline } from "../../assets/icons/16-star-outline.svg";

import styles from "./Profile.module.scss";

const [goalsField, progressField] = PROJECT_FIELDS;

function ProfileMainSection({
  subtitle,
  blocksCompleted,
  actionsCompleted,
  actionsCompletedStarred,
  actionsCompletedLeveraged,
  loading,
  ...props
}) {
  return (
    <div>
      <Divider className={styles.topLine} />
      <Heading level="h4" className={styles.subtitle}>
        {subtitle}
      </Heading>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ul className={styles.sectionList}>
          <li>
            <IconBlocks role="presentation" />
            <span>
              <span className={styles.count}>{blocksCompleted}</span> blocks
              completed
            </span>
          </li>
          <li>
            <IconActions role="presentation" />
            <span>
              <span className={styles.count}>{actionsCompleted}</span> actions
              completed
            </span>
          </li>
          <li>
            <IconLeveraged role="presentation" />
            <span>
              <span className={styles.count}>{actionsCompletedLeveraged}</span>{" "}
              actions leveraged
            </span>
          </li>
          <li>
            <IconStarOutline role="presentation" />
            <span>
              <span className={styles.count}>{actionsCompletedStarred}</span>{" "}
              actions starred
            </span>
          </li>
        </ul>
      )}
    </div>
  );
}

function Profile({ ...props }) {
  const [weekCounters, setWeekCounters] = useState(null);
  const [monthCounters, setMonthCounters] = useState(null);
  const [allCounters, setAllCounters] = useState(null);
  const [goals, setGoals] = useState(null);

  const handleSaveVision = (newVision) => {
    const newPurpose = goals?.purpose || "";
    setUserGoals(newVision, newPurpose);
  };

  const handleSavePurpose = (newPurpose) => {
    const newVision = goals?.vision || "";
    setUserGoals(newVision, newPurpose);
  };

  useEffect(() => {
    const startDate = startOfDay(subDays(new Date(), 7));
    return watchUserCounters(startDate, setWeekCounters);
  }, []);

  useEffect(() => {
    const startDate = startOfDay(subDays(new Date(), 30));
    return watchUserCounters(startDate, setMonthCounters);
  }, []);

  // TODO: restore incremental counters when they are implemented on mobile too

  // useEffect(() => watchUserAllTimeCounters(setAllCounters), []);
  useEffect(() => watchUserCounters(new Date(0), setAllCounters), []);

  useEffect(() => watchUserGoals(setGoals), []);

  return (
    <Page pageTitle="Profile">
      <ProfileSidebar />
      <PageMain className={styles.profilePage}>
        <Heading level="h1" className={styles.title}>
          Progress
        </Heading>
        <div className={styles.sections}>
          <ProfileMainSection
            subtitle="Last Week"
            {...weekCounters}
            loading={weekCounters === null}
          />
          <ProfileMainSection
            subtitle="Last Month"
            {...monthCounters}
            loading={monthCounters === null}
          />
          <ProfileMainSection
            subtitle="All Time"
            {...allCounters}
            loading={allCounters === null}
          />
        </div>

        {goals && (
          <div className={clsx(styles.sections, styles.goals)}>
            <EditableGoal
              heading="Ultimate Vision"
              goal={goals?.vision}
              placeholder={goalsField.placeholder}
              onSave={handleSaveVision}
              editLabel="Edit Ultimate Vision"
              saveLabel="Save Ultimate Vision"
            />

            <EditableGoal
              heading="Ultimate Purpose"
              goal={goals?.purpose}
              placeholder={progressField.placeholder}
              onSave={handleSavePurpose}
              editLabel="Edit Ultimate Purpose"
              saveLabel="Save Ultimate Purpose"
            />
          </div>
        )}

        <div className={styles.profileFooter}>
          <div className={styles.footerLinks}>
            <Button
              className={styles.link}
              href={HELP_LINK}
              target="_blank"
              rel="noreferral"
            >
              Get Started with RPM
            </Button>
            <Button
              className={styles.link}
              href={FAQ_LINK}
              target="_blank"
              rel="noreferral"
              aria-label="Frequently asked questions"
            >
              FAQ
            </Button>
            <Button
              className={styles.link}
              href={CONTACT_US_LINK}
              target="_blank"
              rel="noreferral"
            >
              Contact Us
            </Button>
          </div>
        </div>
      </PageMain>
    </Page>
  );
}

function EditableGoal({
  heading,
  goal,
  placeholder,
  editLabel,
  saveLabel,
  onSave,
}) {
  const ref = createRef();
  const [isEditing, setIsEditing] = useState(false);
  const [goalValue, setGoalValue] = useState(goal);

  const showPlaceholder = !isEditing && !goalValue;
  const showGoal = !isEditing && goalValue;
  const showInput = isEditing;

  const handleSave = () => {
    onSave && onSave(goalValue);
    setIsEditing(false);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <div className={styles.goalBlock}>
      <div className={styles.goalHeading}>
        <Heading level="h2" className={styles.title}>
          {heading}
        </Heading>
        {!isEditing && (
          <Button onClick={() => setIsEditing(true)} aria-label={editLabel}>
            Edit
          </Button>
        )}
        {isEditing && (
          <Button onClick={handleSave} aria-label={saveLabel}>
            Save
          </Button>
        )}
      </div>
      <Divider className={styles.topLine} />

      {showPlaceholder && (
        <p className={styles.placeholder} onClick={() => setIsEditing(true)}>
          {placeholder}
        </p>
      )}

      {showGoal && <p>{goalValue}</p>}

      {showInput && (
        <Input
          ref={ref}
          tag="textarea"
          className={styles.goalInput}
          placeholder={placeholder}
          value={goalValue}
          onChange={(e) => setGoalValue(e.target.value)}
        />
      )}
    </div>
  );
}

const ProfileWithContext = (props) => (
  <DialogContextProvider>
    <Profile {...props} />
  </DialogContextProvider>
);

export default ProfileWithContext;
