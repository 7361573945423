import React, { useState, useCallback, useRef } from "react";

import { BLOCK_RESULT_MAX_LENGTH } from "../../services/DbService/constants";
import { createBlock } from "../../services/DbService/blocks";

import useUniqueId from "../../hooks/useUniqueId";
import useConfirmClose from "../../hooks/useConfirmClose";

import CreateDialog from "./CreateDialog";
import CreateButton from "../CreateButton";
import Divider from "../Divider";
import { BUTTON_COLOR_FILL } from "../Button";
import { InputLabel } from "../Input";
import { GlobalToast } from "../Toast";
import CharacterCounter from "../CharacterCounter";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

import styles from "./CreateDialog.module.scss";

function CreateBlockDialog({
  onClose,
  projectId,
  categoryId,
  state,
  starred,
  snoozedToString,
}) {
  const inputRef = useRef();

  const errorToastId = useUniqueId();

  const [result, setResult] = useState("");

  const {
    showUnsavedChangesDialog,
    setIsDirty,
    handleConfirmClose,
    handleCancelConfirm,
    handleCloseDialog,
  } = useConfirmClose(onClose);

  const nameError =
    result.length > BLOCK_RESULT_MAX_LENGTH ? "Too many characters" : null;

  const handleChange = useCallback(
    (e) => {
      setResult(e.target.value);
      setIsDirty(e.target.value !== "");

      // Resizes the textarea to allow it to only take up the space it needs
      inputRef.current.style.height = "";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    },
    [setIsDirty]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!result || nameError) return;

      createBlock(
        categoryId,
        projectId,
        state,
        result,
        starred,
        snoozedToString
      );

      onClose();
    },
    [
      onClose,
      result,
      state,
      categoryId,
      projectId,
      nameError,
      starred,
      snoozedToString,
    ]
  );

  // Blocks are rendered as a textarea, but should behave like an input
  // so Enter is prevented from creating line breaks, but instead is used for submitting
  const handleKeyDown = useCallback(
    (e) => {
      const { key } = e;

      if (key === "Enter") {
        handleSubmit(e);
        return;
      }
    },
    [handleSubmit]
  );

  return (
    <CreateDialog onClose={handleConfirmClose} wide>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          <InputLabel tag="span">Enter new result</InputLabel>
          {/* The max length here is longer to allow a little bit of overflow but the form will be
              invalid when it is over so won't be submittable. */}
          <textarea
            className={styles.input}
            ref={inputRef}
            type="text"
            autoFocus
            maxLength={BLOCK_RESULT_MAX_LENGTH + 5}
            aria-errormessage={nameError ? errorToastId : null}
            placeholder="What do you want to achieve?"
            value={result}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            spellCheck={false}
            rows={1}
          />
          <span aria-hidden="true" className={styles.focusRing} />
        </label>

        <CharacterCounter
          className={styles.counter}
          count={result.length}
          max={BLOCK_RESULT_MAX_LENGTH}
        />

        <Divider vertical />

        <CreateButton
          error={nameError}
          disabled={!result}
          color={BUTTON_COLOR_FILL}
          type="submit"
          aria-label="Create New Result"
        />
      </form>

      {nameError && (
        <GlobalToast id={errorToastId} error fromDialog>
          {nameError}
        </GlobalToast>
      )}

      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={handleCloseDialog}
          onDiscard={handleCancelConfirm}
        />
      )}
    </CreateDialog>
  );
}

export default CreateBlockDialog;
