import { useContext } from "react";

import pkg from "../../../package.json";

import { AuthContext } from "../AuthContext";
import Page from "../Page";

import { ReactComponent as IconEnvelope } from "../../assets/icons/20-envelope.svg";

import styles from "./HelpPage.module.scss";

const HelpPage = () => {
  const { user } = useContext(AuthContext);
  const email = "customerservice@tonyrobbins.com";
  const emailSubject = "RPM Web Support";
  const emailBody = `

How can we help you? Please write your issue here.

–––––––––––––––––––––––––

Username: ${user.username}

Email: ${user.email}

Application: RPM Web
v${pkg.version}`;

  return (
    <Page pageTitle="Help" className={styles.page}>
      <iframe
        title="RPM Help"
        src="https://wiki.rpmcalendar.com?hideContactUs=1"
      />

      <a
        href={`mailto:${email}?subject=${encodeURIComponent(
          emailSubject
        )}&body=${encodeURIComponent(emailBody)}`}
        target="_blank"
        rel="noreferrer"
        className={styles.helpFloatingButton}
        data-category-color="5"
      >
        <IconEnvelope role="presentation" />
        Contact
      </a>
    </Page>
  );
};

export default HelpPage;
