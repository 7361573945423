import React from "react";
import { NBSP } from "src/constants";

import Accordion from "src/components/Accordion";
import Button, { BUTTON_COLOR_FILL } from "src/components/Button";
import PageNoUi from "src/components/PageNoUi";
import SmartLink from "src/components/SmartLink";

import styles from "./AppError.module.scss";

function AppError({
  error,
  pageTitle = `Oops, Something Went${NBSP}Wrong`,
  pageText = "Please try again.",
  // Most instances of a catch all error can be handled with a page reload as
  // the HTML isn't cached so any missing files will be rectified at this point.
  onReset = () => window.location.reload(),
  resetButtonLabel = "Reload the App",
}) {
  error && console.error(error);

  const pageTextComputed = (
    <>
      {pageText && (
        <>
          {pageText}
          <br />
          <br />
        </>
      )}
      {"If this issue persists, "}
      <SmartLink href="https://tonyrobbins.com/contact">
        contact an administrator
      </SmartLink>
      .
    </>
  );

  return (
    <PageNoUi role="alert" heading={pageTitle} text={pageTextComputed}>
      {onReset && (
        <Button color={BUTTON_COLOR_FILL} onClick={onReset} block>
          {resetButtonLabel}
        </Button>
      )}

      {error?.message && (
        <Accordion className={styles.error} heading="Error message">
          <pre>{error.toString()}</pre>
        </Accordion>
      )}
    </PageNoUi>
  );
}

export default AppError;
