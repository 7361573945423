import React from "react";
import { Redirect, generatePath, useParams } from "react-router-dom";

import usePeople from "../../hooks/usePeople";

import { PEOPLE_URL } from "../App";

import Page from "../Page";
import LoadingSpinner from "../LoadingSpinner";
import PersonDetail from "../DetailPanels/PersonDetail";
import PeopleSidebar from "./PeopleSidebar";

import { TYPE_LABELS } from "src/services/DbService/constants";

import { DialogContextProvider } from "../Dialog";

function People({ ...props }) {
  const { personId } = useParams();

  const { people, peopleInit } = usePeople();

  const pageProps = {
    pageTitle: TYPE_LABELS.person.titlePlural,
    ...props,
  };

  if (!peopleInit) {
    return (
      <Page {...pageProps}>
        <LoadingSpinner absolute />
      </Page>
    );
  }

  if (!personId && people[0]) {
    return (
      <Redirect
        to={generatePath(PEOPLE_URL, {
          personId: people[0].id,
        })}
      />
    );
  }

  return (
    <Page {...pageProps}>
      <PeopleSidebar />
      {personId && <PersonDetail key={personId} />}
      {/* TODO: Design empty state for people */}
    </Page>
  );
}

const PeopleWithContext = (props) => (
  <DialogContextProvider>
    <People {...props} />
  </DialogContextProvider>
);

export default PeopleWithContext;
