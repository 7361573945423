import React, { useContext, useState, useMemo } from "react";
import clsx from "clsx";

import {
  ALL_CATEGORY_ID,
  CATEGORY,
  PROJECT,
  THIS_WEEK,
  TYPE_LABELS,
  ACTION,
  RESULT,
} from "src/services/DbService/constants";

import useUniqueId from "../../../hooks/useUniqueId";

import { useGetCategoryColor } from "../../CategoriesContext";
import { DashboardContext } from "../../Dashboard";
import Sidebar from "../../Sidebar";
import TabBar from "../../TabBar";
import DashboardSidebarHeader from "./DashboardSidebarHeader";
import DashboardSidebarTaxonomy from "./DashboardSidebarTaxonomy";
import StarredFilterButton from "src/components/StarredFilterButton";
import DashboardSidebarThisWeek from "./DashboardSidebarThisWeek";

import styles from "./DashboardSidebar.module.scss";

function DashboardSidebar({ className, style = {}, detailUrl, ...props }) {
  const { activeCategoryId, activeProjectId } = useContext(DashboardContext);

  const [parentType, parentId] = useMemo(() => {
    if (activeProjectId) {
      return [PROJECT, activeProjectId];
    }
    if (activeCategoryId && activeCategoryId !== ALL_CATEGORY_ID) {
      return [CATEGORY, activeCategoryId];
    }
    return [THIS_WEEK];
  }, [activeCategoryId, activeProjectId]);

  const getCategoryColor = useGetCategoryColor();

  const resultsId = useUniqueId();
  const actionsId = useUniqueId();

  const parentTabs = useMemo(() => {
    return [
      {
        id: resultsId,
        type: RESULT,
        label: TYPE_LABELS[RESULT].titlePlural,
      },
      {
        id: actionsId,
        type: ACTION,
        label: TYPE_LABELS[ACTION].titlePlural,
      },
    ];
  }, [resultsId, actionsId]);

  const [activeParentTabId, setActiveParentTabId] = useState(resultsId);

  const activeTabType = useMemo(
    () => parentTabs.find(({ id }) => id === activeParentTabId)?.type,
    [activeParentTabId, parentTabs]
  );

  const header = useMemo(
    () => (
      <DashboardSidebarHeader parentType={parentType} parentId={parentId}>
        <TabBar
          className={styles.tabBar}
          aria-label="Show only"
          tabs={parentTabs}
          active={activeParentTabId}
          onTabClick={setActiveParentTabId}
        />
      </DashboardSidebarHeader>
    ),
    [parentType, parentId, parentTabs, activeParentTabId]
  );

  const categoryColor = getCategoryColor(activeCategoryId);

  return (
    <Sidebar
      className={clsx(className, styles.sidebar)}
      header={header}
      data-category-color={categoryColor}
      style={{
        background: categoryColor && `var(--category-sidebar-${categoryColor})`,
        ...style,
      }}
      {...props}
    >
      <StarredFilterButton />

      {/* This div is needed to ensure that the sidebar spacing for :first and
          :last children isn't applied to the tabs incorrectly */}
      <div>
        {parentType === THIS_WEEK && (
          <DashboardSidebarThisWeek detailUrl={detailUrl} />
        )}
        {parentType !== THIS_WEEK && (
          <DashboardSidebarTaxonomy
            parentId={parentId}
            parentType={parentType}
            activeTab={activeTabType}
            tabs={parentTabs}
            detailUrl={detailUrl}
          />
        )}
      </div>
    </Sidebar>
  );
}

export default DashboardSidebar;
