import React, { useMemo, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import { UNCATEGORIZED_ID } from "src/services/DbService/constants";
import {
  DASHBOARD_DETAIL_URLS,
  DASHBOARD_URL,
  HELP_URL,
  PEOPLE_URL,
  PLANNER_BLOCK_DETAIL_URL,
  PLANNER_URL,
  PROFILE_URL,
} from "../App";
import { useShowCreateActionDialog } from "../ActionDialog";
import Button, { BUTTON_COLOR_FILL } from "../Button";
import Tooltip from "../Tooltip";
import Nav from "../Nav";
import NavItem from "../NavItem";

import { ReactComponent as IconCreateAction } from "../../assets/icons/24-create-action.svg";
import { ReactComponent as IconCalendar } from "../../assets/icons/24-calendar-alt.svg";
import { ReactComponent as IconPlanner } from "../../assets/icons/24-planner-alt.svg";
import { ReactComponent as IconPeople } from "../../assets/icons/24-people-alt.svg";
import { ReactComponent as IconProfile } from "../../assets/icons/24-user.svg";
import { ReactComponent as IconInfo } from "../../assets/icons/24-info.svg";

import styles from "./DashboardNav.module.scss";

function DashboardLink({ icon, children, isActive, ...props }) {
  const [active, setActive] = useState(false);

  const handleIsActive = (match, location) => {
    let activeCheck = !!match;

    if (isActive) {
      activeCheck = isActive(match, location);
    }

    setActive(activeCheck);
    return activeCheck;
  };

  return (
    <NavLink className={styles.navLink} isActive={handleIsActive} {...props}>
      <NavItem active={active} icon={icon}>
        {children}
      </NavItem>
    </NavLink>
  );
}

export default function DashboardNav() {
  const showCreateActionDialog = useShowCreateActionDialog();
  const routeIsDashboard = useRouteMatch({
    path: [DASHBOARD_URL, ...DASHBOARD_DETAIL_URLS],
    exact: true,
  });
  const routeIsPlanner = useRouteMatch({
    path: [PLANNER_URL, PLANNER_BLOCK_DETAIL_URL],
  });
  const routeIsProfile = useRouteMatch(PROFILE_URL);
  const routeIsPeople = useRouteMatch(PEOPLE_URL);
  const routeIsHelp = useRouteMatch(HELP_URL);

  const handleCreateAction = () => {
    showCreateActionDialog(UNCATEGORIZED_ID);
  };

  const heading = useMemo(() => {
    if (routeIsDashboard) return "Calendar";
    if (routeIsPlanner) return "Planner";
    if (routeIsPeople) return "People";
    if (routeIsProfile) return "Profile";
    if (routeIsHelp) return "Help";
  }, [
    routeIsDashboard,
    routeIsPlanner,
    routeIsPeople,
    routeIsProfile,
    routeIsHelp,
  ]);

  return (
    <Nav className={styles.dashboardNav} heading={heading}>
      <div className={styles.centerLinks}>
        <DashboardLink
          to={DASHBOARD_URL}
          exact
          isActive={() => routeIsDashboard}
          icon={IconCalendar}
          aria-label="Calendar"
        >
          Calendar
        </DashboardLink>
        <DashboardLink to={PLANNER_URL} icon={IconPlanner} aria-label="Planner">
          Planner
        </DashboardLink>
        <DashboardLink
          to={PEOPLE_URL}
          icon={IconPeople}
          isActive={(match, location) =>
            location.pathname.startsWith("/people")
          }
          aria-label="People"
        >
          People
        </DashboardLink>
        <DashboardLink to={PROFILE_URL} icon={IconProfile} aria-label="Profile">
          Profile
        </DashboardLink>
        <DashboardLink to={HELP_URL} icon={IconInfo} aria-label="Help">
          Help
        </DashboardLink>
      </div>

      <div className={styles.actions}>
        <Tooltip title="Capture New Action" shortcut="K" shortcutModifier>
          <Button
            color={BUTTON_COLOR_FILL}
            className={styles.createActionButton}
            onClick={handleCreateAction}
          >
            <IconCreateAction
              className={styles.createActionIcon}
              role="presentation"
            />{" "}
            Create Action
          </Button>
        </Tooltip>
      </div>
    </Nav>
  );
}
