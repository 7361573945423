import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  PROJECT_NAME_MAX_LENGTH,
  PROJECT_FIELDS,
} from "src/services/DbService/constants";
import {
  watchProject,
  updateProject,
  deleteProject,
} from "src/services/DbService/projects";
import useUniqueId from "src/hooks/useUniqueId";

import { DASHBOARD_URL } from "src/components/App";
import Button from "src/components/Button";
import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
} from "src/components/ContextualMenu";
import Dialog from "src/components/Dialog";
import { SidebarContext } from "src/components/SidebarContext";

import DetailPanelWrapper from "../DetailPanelWrapper";
import DetailPanelHero from "../DetailPanelHero";
import DetailPanelFields from "../DetailPanelFields";
import DetailPanelForm from "../DetailPanelForm";

import { ReactComponent as IconClose } from "src/assets/icons/16-close.svg";
import { ReactComponent as IconMore } from "src/assets/icons/16-more.svg";
import { ReactComponent as IconProjects } from "src/assets/icons/16-projects.svg";

function ProjectDetail({ closeRedirectUrl = DASHBOARD_URL, ...props }) {
  const { projectId: paramProjectId } = useParams();
  const history = useHistory();

  const editButtonRef = useRef();
  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();

  const [editing, setEditing] = useState(null);
  const [project, setProject] = useState({});
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const {
    actionListExpanded,
    toggleActionListExpanded,
    categorySidebarExpanded,
    toggleCategorySidebarExpanded,
    thisWeekSidebarExpanded,
    toggleThisWeekSidebarExpanded,
  } = useContext(SidebarContext);

  const { id: projectId, name } = project;

  useEffect(() => {
    return watchProject(paramProjectId, (proj) => {
      if (proj) {
        setProject(proj);
      } else {
        // Navigate away from project details.
        history.replace(closeRedirectUrl);
      }
    });
  }, [paramProjectId, history, closeRedirectUrl]);

  const handleSubmit = useCallback(
    (values) => {
      const { name, vision, purpose } = values;

      updateProject(projectId, name.trim(), vision, purpose);

      setEditing(false);

      editButtonRef.current && editButtonRef.current.focus();
    },
    [projectId]
  );

  const handleDeleteConfirmation = useCallback(() => {
    setDeleteDialogVisible(true);
  }, []);

  const handlePerformDelete = useCallback(async () => {
    setDeleteDialogVisible(false);

    // Delete project
    await deleteProject(projectId);

    history.replace(closeRedirectUrl);

    // Check for active Sidebars and toggle off
    categorySidebarExpanded && toggleCategorySidebarExpanded();
    thisWeekSidebarExpanded && toggleThisWeekSidebarExpanded();

    // Make Action List active
    !actionListExpanded && toggleActionListExpanded();
  }, [
    projectId,
    history,
    closeRedirectUrl,
    categorySidebarExpanded,
    toggleCategorySidebarExpanded,
    thisWeekSidebarExpanded,
    toggleThisWeekSidebarExpanded,
    actionListExpanded,
    toggleActionListExpanded,
  ]);

  return (
    <>
      <DetailPanelWrapper
        name={name}
        editing={editing}
        loading={!projectId}
        {...props}
      >
        {!editing && (
          <>
            <DetailPanelHero
              heading={name}
              headingIcon={IconProjects}
              buttons={
                <>
                  <Button
                    ref={editButtonRef}
                    onClick={() => setEditing("name")}
                  >
                    Edit
                  </Button>
                  <ContextualMenuTrigger
                    menuId={optionsMenuButtonId}
                    visible={optionsMenuVisible}
                    setVisible={setOptionsMenuVisible}
                  >
                    <Button
                      ref={optionsMenuButtonRef}
                      iconOnly
                      aria-label={`Project Options`}
                      tooltip
                    >
                      <IconMore role="presentation" />
                    </Button>
                  </ContextualMenuTrigger>
                  <Button linkTo={closeRedirectUrl} iconOnly aria-label="Back">
                    <IconClose role="presentation" />
                  </Button>

                  {optionsMenuVisible && (
                    <ContextualMenu
                      buttonRef={optionsMenuButtonRef}
                      onClose={() => setOptionsMenuVisible(false)}
                      aria-labelledby={optionsMenuButtonId}
                    >
                      <ContextualMenuLink
                        onClick={handleDeleteConfirmation}
                        negative
                      >
                        Complete Project
                      </ContextualMenuLink>
                    </ContextualMenu>
                  )}
                </>
              }
            />

            <DetailPanelFields
              setEditing={setEditing}
              values={project}
              fields={PROJECT_FIELDS}
            />
          </>
        )}

        <DetailPanelForm
          editing={editing}
          setEditing={setEditing}
          values={project}
          onSubmit={handleSubmit}
          fields={[
            {
              slug: "name",
              label: "Name",
              maxLength: PROJECT_NAME_MAX_LENGTH,
              counter: true,
            },
            ...PROJECT_FIELDS,
          ]}
        />
      </DetailPanelWrapper>

      {deleteDialogVisible && (
        <Dialog
          headerTitle="Complete Project"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>
                Cancel
              </Button>
              <Button block onClick={handlePerformDelete} negative>
                Complete project
              </Button>
            </>
          }
        >
          <p>
            Completing this project will also update all blocks. Uncompleted
            blocks will remain available in their assigned categories. Do you
            wish to proceed?
          </p>
        </Dialog>
      )}
    </>
  );
}

export default ProjectDetail;
