import React, { forwardRef, useState, useCallback, useRef } from "react";

import {
  TYPE_LABELS,
  PROJECT_NAME_MAX_LENGTH,
} from "src/services/DbService/constants";
import { createProject } from "src/services/DbService/projects";

import useUniqueId from "../../hooks/useUniqueId";
import useConfirmClose from "../../hooks/useConfirmClose";

import CreateDialog from "../CreateDialog";
import CreateButton from "../CreateButton";
import { BUTTON_COLOR_FILL } from "../Button";
import Divider from "../Divider";
import { InputLabel } from "../Input";
import { GlobalToast } from "../Toast";
import CharacterCounter from "../CharacterCounter";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

import styles from "./CreateDialog.module.scss";

const CreateProjectDialog = forwardRef(({ onClose, onCreate }, ref) => {
  const inputRef = useRef();

  const errorToastId = useUniqueId();

  const [name, setName] = useState("");

  const {
    showUnsavedChangesDialog,
    setIsDirty,
    handleConfirmClose,
    handleCancelConfirm,
    handleCloseDialog,
  } = useConfirmClose(onClose);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value.replace(/[\r\n]/g, ""); // Stop new lines from being put in
      setName(value);
      setIsDirty(value !== "");
    },
    [setIsDirty]
  );

  const nameErrorMessage =
    name.length > PROJECT_NAME_MAX_LENGTH ? "Too many characters" : null;

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (nameErrorMessage) return;

      const callback = (newProjectId) => {
        onCreate && onCreate(newProjectId);
      };

      createProject(name, callback);

      onClose();
    },
    [onClose, name, onCreate, nameErrorMessage]
  );

  return (
    <CreateDialog ref={ref} onClose={handleConfirmClose}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          <InputLabel tag="span">Enter project name</InputLabel>
          {/* The max length here is longer to allow a little bit of overflow (as well as an increase of 1
              to allow for the @, but the form will be invalid when it is over so won't be submittable. */}
          <input
            className={styles.input}
            ref={inputRef}
            type="text"
            autoFocus
            maxLength={PROJECT_NAME_MAX_LENGTH + 1 + 5}
            aria-errormessage={nameErrorMessage ? errorToastId : null}
            placeholder="e.g. Movie Premiere Party"
            value={name}
            onChange={handleChange}
            autoComplete="off"
            spellCheck={false}
          />
        </label>

        <CharacterCounter
          className={styles.counter}
          count={name.length}
          max={PROJECT_NAME_MAX_LENGTH}
        />

        <Divider vertical />

        <CreateButton
          error={nameErrorMessage}
          disabled={!name}
          color={BUTTON_COLOR_FILL}
          type="submit"
          aria-label={`Create New ${TYPE_LABELS.project.title}`}
        />
      </form>

      {nameErrorMessage && (
        <GlobalToast id={errorToastId} error>
          {nameErrorMessage}
        </GlobalToast>
      )}

      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={handleCloseDialog}
          onDiscard={handleCancelConfirm}
        />
      )}
    </CreateDialog>
  );
});

CreateProjectDialog.displayName = "CreateProjectDialog";

export default CreateProjectDialog;
