import React from "react";

import { timeToText } from "src/utils/timeToText";

import CardButtonSelect from "./CardButtonSelect";

export const TIME_VALUES = [
  900, 1800, 2700, 3600, 5400, 7200, 10800, 18000, 28800, 43200,
];

export const UNSET = "unset";

const CardButtonDurationSelect = ({ value, ...props }) => {
  let label = "--";

  if (value && value !== "unset") {
    label = timeToText(value).dropdown;
  }

  return (
    <CardButtonSelect label={label} {...props}>
      <option value={UNSET}>–-</option>
      {TIME_VALUES.map((value) => (
        <option key={value} value={value}>
          {timeToText(value).dropdown}
        </option>
      ))}
    </CardButtonSelect>
  );
};

export default CardButtonDurationSelect;
