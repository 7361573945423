import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

/**
 * Hook into the history navigation lifecycle to block history's next navigation.
 *
 * Returns continue and unblock functions for the component to re-enable and trigger
 * previous navigation attempt.
 *
 * @export
 * @param {boolean} willBlock
 * @param {function} callback
 * @return {[function, function]} [historyContinue, historyUnblock]
 */
export default function useHistoryBlock(willBlock, callback) {
  const unblockRef = useRef();
  const history = useHistory();

  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (!willBlock) {
      // navigation will not block, reset state, remove ref.
      setLocation(null);
      unblockRef.current = null;
      return;
    }

    const unblock = history.block((location) => {
      // Save the attempted location change.
      setLocation(location);

      // history change callback for the component
      callback && callback(location);

      // Prevent navigation.
      return false;
    });

    // Save the unblock handler as the ref.
    unblockRef.current = unblock;

    return () => {
      // Destroy the unblock handler as there can only be one prompt
      // registered at a time.
      unblockRef.current && unblockRef.current();
    };
  }, [willBlock, callback, history]);

  const historyUnblock = useCallback(() => {
    unblockRef.current && unblockRef.current();
  }, []);

  const historyContinue = useCallback(() => {
    historyUnblock();

    if (location) {
      history.push(location.pathname, location.state);
    }
  }, [location, history, historyUnblock]);

  return [historyContinue, historyUnblock];
}
