import React, { useMemo } from "react";
import clsx from "clsx";

import {
  ACTIVE,
  CAPTURE_LIST,
  CAPTURE_LIST_LABEL,
  PAST_DUE,
  SNOOZED,
  STATE_LABELS,
  UNCATEGORIZED,
  UNCATEGORIZED_ID,
  UNSCHEDULED,
} from "src/services/DbService/constants";

import useUniqueId from "src/hooks/useUniqueId";

import Heading, { HEADING_LEVEL_2 } from "src/components/Heading";
import Sidebar, { SidebarHeader } from "src/components/Sidebar";
import StarredFilterButton from "src/components/StarredFilterButton";
import ActionList from "src/components/ActionList";
import { useShowCreateActionDialog } from "src/components/ActionDialog";
import CreateButton from "src/components/CreateButton";

import styles from "./DashboardActionList.module.scss";

function DashboardActionList({ className }) {
  const showCreateActionDialog = useShowCreateActionDialog();

  const pastDueId = useUniqueId();
  const unscheduledId = useUniqueId();
  const uncategorizedId = useUniqueId();
  const snoozedId = useUniqueId();

  const sectionFilters = useMemo(() => {
    return [
      {
        id: pastDueId,
        label: STATE_LABELS[PAST_DUE].subheading,
        state: ACTIVE,
        filter: (action) => action.event?.startDate < new Date(),
      },
      {
        id: unscheduledId,
        label: STATE_LABELS[UNSCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) => !action.event?.startDate,
      },
      {
        id: uncategorizedId,
        label: STATE_LABELS[UNCATEGORIZED].subheading,
        state: ACTIVE,
        filter: (action) =>
          action.categoryId === UNCATEGORIZED_ID &&
          action.event?.startDate >= new Date(),
      },
      {
        id: snoozedId,
        label: STATE_LABELS[SNOOZED].subheading,
        state: SNOOZED,
        filter: (action) => action.categoryId === UNCATEGORIZED_ID,
      },
    ];
  }, [pastDueId, unscheduledId, uncategorizedId, snoozedId]);

  return (
    <Sidebar
      className={clsx(className)}
      header={
        <SidebarHeader plain>
          <Heading
            className={styles.captureHeading}
            tag="h2"
            level={HEADING_LEVEL_2}
          >
            {CAPTURE_LIST_LABEL}
          </Heading>
        </SidebarHeader>
      }
    >
      <StarredFilterButton />

      {/* Capture List children cannot be part of projects,
        but this hides the banner to be on the safe side */}
      <ActionList
        parentId={null}
        parentType={CAPTURE_LIST}
        sectionFilters={sectionFilters}
        enableProjectBanner={false}
      />

      <CreateButton
        onClick={() => showCreateActionDialog(UNCATEGORIZED_ID)}
        aria-label="Create New Action"
      />
    </Sidebar>
  );
}

export default DashboardActionList;
