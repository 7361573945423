import React, { forwardRef } from "react";
import clsx from "clsx";

import { CATEGORY_ICONS } from "../../services/DbService/constants";

import Tooltip from "../Tooltip";

import styles from "./CategoryRadioGroupIcon.module.scss";

const CategoryRadioGroupIcon = forwardRef(
  ({ className, value, onChange, ...props }, ref) => {
    return (
      <div
        className={clsx(className, styles.icons)}
        role="radiogroup"
        {...props}
        ref={ref}
      >
        {Object.entries(CATEGORY_ICONS).map(([slug, icon]) => {
          const { label, icon: Icon } = icon;
          const selected = value === slug;
          return (
            <Tooltip title={label} key={slug}>
              <div
                className={clsx(styles.icon, selected && styles.iconSelected)}
              >
                <Icon role="presentation" />
                <input
                  type="radio"
                  value={slug}
                  checked={selected}
                  onChange={onChange}
                  aria-label={label}
                />
                <span className={styles.focusRing} aria-hidden="true" />
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
);

CategoryRadioGroupIcon.displayName = "CategoryRadioGroupIcon";

export default CategoryRadioGroupIcon;
