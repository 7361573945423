import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { ReactSortable } from "react-sortablejs";
import clsx from "clsx";

import { NBSP } from "src/constants";

import {
  ACTIVE,
  SNOOZED,
  COMPLETED,
  PROJECT,
  PAGE_SIZE,
  CAPTURE_LIST,
  UNCATEGORIZED_ID,
  CATEGORY,
  ACTION,
  BLOCK,
  THIS_WEEK,
  STATE_LABELS,
  TYPE_LABELS,
  ITEM_LABELS,
} from "src/services/DbService/constants";
import {
  watchActiveChildren,
  watchSnoozedChildren,
  setActiveChildrenOrder,
} from "src/services/DbService/general";
import { moveBlock, watchCompletedBlocks } from "src/services/DbService/blocks";
import {
  moveAction,
  watchCompletedActions,
} from "src/services/DbService/actions";

import useAction from "src/hooks/useAction";

import ActionItem from "../ActionItem";
import BlockItem from "../BlockItem";
import LoadingSpinner from "../LoadingSpinner";
import LoadMoreButton from "../LoadMoreButton";
import EmptyState from "../EmptyState";

import Dialog from "../Dialog";
import Button from "../Button";

import styles from "./ChildrenList.module.scss";

function ChildrenList({
  className,
  parentType,
  parentId,
  state,
  snoozedToString,
  completedChildType,
  emptyState,
  list,
  filter,
  detailUrl,
  enableDetailLink,
  enableActionNumbers,
  enableProjectBanner = true,
  enableLoader = true,
  onFetchCompleted,
  readonlyActions,
  ...props
}) {
  if (readonlyActions && parentType !== BLOCK) {
    throw new Error(
      `readonlyActions can only be true when parentType is "${BLOCK}"`
    );
  }
  if (state === COMPLETED && ![ACTION, BLOCK].includes(completedChildType)) {
    throw new Error(
      `completedChildType must be "${ACTION}" or "${BLOCK}" when state is` +
        `"${COMPLETED}"`
    );
  }
  if (state !== COMPLETED && onFetchCompleted) {
    throw new Error(
      `onFetchCompleted can only be used when state is "${COMPLETED}"`
    );
  }
  if ((state !== ACTIVE || parentId !== null) && parentType === THIS_WEEK) {
    throw new Error(
      `State ${state} or parentId ${parentId} cannot be used when parentType is "${THIS_WEEK}"`
    );
  }

  const manualSort = state === ACTIVE && !filter;
  const disabled = readonlyActions;

  const sortableRef = useRef();

  const { blocksById, mostRecentEventByActionId } = useAction(
    parentId,
    parentType
  );
  const [children, setChildren] = useState(null);

  const [limit, setLimit] = useState(PAGE_SIZE);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [confirmRemovalDialogVisible, setConfirmRemovalDialogVisible] =
    useState(false);

  const [confirmRemovalDialogData, setConfirmRemovalDialogData] =
    useState(null);

  useEffect(() => {
    // This component can also receive a list of children to avoid creating
    // separate watchers for each state, refer to useAction hook for more details
    if (list) {
      setChildren(list);
      return;
    }

    switch (state) {
      case ACTIVE:
        return watchActiveChildren(parentId, parentType, setChildren);
      case SNOOZED:
        return watchSnoozedChildren(
          parentId,
          parentType,
          snoozedToString,
          setChildren
        );
      case COMPLETED:
        const watchChildren =
          completedChildType === ACTION
            ? watchCompletedActions
            : watchCompletedBlocks;

        return watchChildren(
          parentId,
          parentType,
          limit,
          (children, hasMore) => {
            setLoadingMore(false);
            setChildren(children);
            setHasMore(hasMore);
            onFetchCompleted && onFetchCompleted(children, hasMore);
          }
        );
      default:
        throw new Error(`Unexpected value for state prop: ${state}`);
    }
  }, [
    state,
    parentType,
    parentId,
    snoozedToString,
    completedChildType,
    onFetchCompleted,
    limit,
    list,
  ]);

  // An object which maps the actions to their most recent events
  // using the action id as the object key
  const sortChildren = useCallback((a, b) => {
    const titleA = a?.description || a?.result || "";
    const titleB = b?.description || b?.result || "";

    // if both children are starred, let's try to compare by its startDate
    // in case there is no startDate (e.g. a block, unscheduled actions),
    // we will compare them by the title
    if (a.starred && b.starred) {
      if (!a?.event?.startDate && !b?.event?.startDate) {
        return titleA.localeCompare(titleB);
      }

      // we set a very future date as defined in ECMA-262 to sort them to the end:
      // https://262.ecma-international.org/5.1/#sec-15.9.1.1
      return (
        (b?.event?.startDate?.getTime() || 864e13) -
        (a?.event?.startDate?.getTime() || 864e13)
      );
    }

    // only a is starred
    if (a?.starred) return -1;

    // only b is starred
    if (b?.starred) return 1;

    // compare by startDate
    return (
      (b?.event?.startDate?.getTime() || 864e13) -
      (a?.event?.startDate?.getTime() || 864e13)
    );
  }, []);

  const filteredList = useMemo(() => {
    if (!children) return [];

    // List needs to be cloned to ensure ReactSortable rerenders when the
    // filter changes as well as the list
    const listTemp = [...children.sort(sortChildren)];

    return filter ? listTemp.filter(filter) : listTemp;
  }, [filter, children, sortChildren]);

  // Bug: changing the "sort" or "disabled" prop on <ReactSortable> when
  // the component is already rendered does nothing: if it is rendered with
  // sort then it cannot be enabled again, and viceversa.
  // This effect solves the issues bypassing the React wrapper.
  useEffect(() => {
    sortableRef.current &&
      sortableRef.current.sortable.option("sort", manualSort);
  }, [manualSort]);

  useEffect(() => {
    sortableRef.current &&
      sortableRef.current.sortable.option("disabled", disabled);
  }, [disabled]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingMore(true);
    setLimit((limit) => limit + PAGE_SIZE);
  }, []);

  const handleOrderChange = useCallback(() => {
    if (state !== ACTIVE) return;
    const orderedIds = sortableRef.current.sortable.toArray();
    setActiveChildrenOrder(parentId, parentType, orderedIds);
  }, [sortableRef, parentType, parentId, state]);

  const moveChild = useCallback(
    (id, type, orderedIds) => {
      if (type === ACTION) {
        moveAction(
          id,
          parentId,
          parentType,
          state,
          orderedIds,
          snoozedToString
        );
      } else {
        moveBlock(id, parentId, parentType, state, snoozedToString, orderedIds);
      }
    },
    [state, snoozedToString, parentId, parentType]
  );

  const handleItemAdd = useCallback(
    async (e) => {
      const { id, type, sourceParentType } = e.item.dataset;
      const orderedIds = sortableRef.current.sortable.toArray();

      if (
        (sourceParentType === BLOCK && parentType === CATEGORY) ||
        (sourceParentType === BLOCK && parentType === THIS_WEEK)
      ) {
        // set the parameters for the moveChild function to be executed
        // on the modal
        setConfirmRemovalDialogData({
          id,
          type,
          orderedIds,
        });
      } else {
        // in the case there is no need to show the confirmation dialog,
        // move anyway
        moveChild(id, type, orderedIds);
      }
    },
    [parentType, moveChild]
  );

  useEffect(() => {
    if (confirmRemovalDialogData) {
      setConfirmRemovalDialogVisible(true);
    } else {
      setConfirmRemovalDialogVisible(false);
    }
  }, [confirmRemovalDialogData]);

  const emptyStateComputed = useMemo(() => {
    // Allows the emptyState to be turned off with emptyState={false}, to be
    // empty with emptyState={''} or to pass through JSX for the contents
    if (emptyState !== undefined) return emptyState;

    const stateText = STATE_LABELS[state] ? ` ${STATE_LABELS[state].text}` : "";
    const typeText =
      state === COMPLETED
        ? TYPE_LABELS[completedChildType].textPlural
        : ITEM_LABELS.textPlural;

    let emptyStateComputed = `No ${stateText}${NBSP}${typeText}`;
    if (state === ACTIVE && !readonlyActions) {
      emptyStateComputed += `\nDrag or create one${NBSP}below`;
    }

    return emptyStateComputed;
  }, [emptyState, state, completedChildType, readonlyActions]);

  const ConfirmRemovalDialog = useCallback(() => {
    const { id, type, orderedIds } = confirmRemovalDialogData || {};

    return (
      <Dialog
        headerTitle="Confirm removal"
        footer={
          <>
            <Button block onClick={() => setConfirmRemovalDialogData(null)}>
              Cancel
            </Button>
            <Button
              block
              onClick={() => {
                moveChild(id, type, orderedIds);
                setConfirmRemovalDialogData(null);
              }}
            >
              Confirm Removal
            </Button>
          </>
        }
      >
        <p>Would you like to remove the Action from the Result?</p>
      </Dialog>
    );
  }, [confirmRemovalDialogData, moveChild]);

  const hasEmptyState = emptyStateComputed !== false;

  if (children === null || (parentType === CAPTURE_LIST && !blocksById)) {
    if (!enableLoader) return null;
    return (
      <div
        className={clsx(styles.loading, hasEmptyState && styles.hasEmptyState)}
        {...props}
      >
        <LoadingSpinner absolute />
      </div>
    );
  }

  return (
    <>
      <ReactSortable
        {...props}
        className={clsx(
          className,
          styles.sortable,
          hasEmptyState && styles.hasEmptyState,
          !disabled && styles.sortableEnabled
        )}
        tag="ul"
        ref={sortableRef}
        list={filteredList}
        setList={() => {}}
        group={{
          pull(to, from, item) {
            return to.el.dataset.clone ? "clone" : true;
          },
          put(to, from, item) {
            const { type: itemType, captureList: itemInCaptureList } =
              item.dataset;

            if (itemType === ACTION) {
              if (
                parentType === CATEGORY &&
                parentId === UNCATEGORIZED_ID &&
                state === ACTIVE
              ) {
                // If this list is the Uncategorzied section in the active state
                // you can't drop actions here (because they would go in the
                // capture list)
                return false;
              }
              if (itemInCaptureList && parentType === PROJECT) {
                // Adding capture list's actions to project is disabled
                return false;
              }
              if (itemInCaptureList && parentType === THIS_WEEK) {
                // Capture list's actions can't be dropped into This Week
                return false;
              }
              if (parentType === CAPTURE_LIST) {
                // Can't drop actions between sections inside the capture list
                return false;
              }
              if (state === COMPLETED && completedChildType === BLOCK) {
                return false;
              }
              return true;
            } else if (itemType === BLOCK) {
              if (parentType === BLOCK) {
                // Can't drop blocks inside other blocks
                return false;
              }
              if (parentType === CAPTURE_LIST) {
                // Can't drop blocks inside the capture list
                return false;
              }
              if (state === COMPLETED && completedChildType === ACTION) {
                return false;
              }
              return true;
            }

            return false;
          },
        }}
        filter=".sortable-ignore"
        preventOnFilter={false}
        forceFallback={true}
        fallbackOnBody={true}
        animation={300}
        delay={50}
        sort={manualSort}
        disabled={disabled}
        onUpdate={handleOrderChange}
        onAdd={handleItemAdd}
        // invertSwap tells SortableJs to make only the outer edges of items
        // sensible to the swap intent
        invertSwap={true}
        // swapThreshold=0.49 means that 49% of the items will be used for the
        // sensible area, that is almost 25% at the top and almost 25% at the
        // bottom; the center 51% is left for the dropzones of blocks (the
        // extra 1% is to better separate the swap and drop areas, because when
        // they are too close they cause flickerings)
        swapThreshold={0.49}
      >
        {blocksById &&
          mostRecentEventByActionId &&
          filteredList.map((child, index) => {
            // SortableJs after some operations rewrites the objects in the list
            // stripping out the prototype information, so you can't use
            // instanceof to detect the type of the child
            if (child.__type === BLOCK) {
              return (
                <BlockItem
                  key={child.id}
                  block={child}
                  data-id={child.id}
                  data-type={BLOCK}
                  data-category-id={child.categoryId}
                  enableDetailLink={enableDetailLink}
                  detailUrl={detailUrl}
                  // Block dropzones are only enabled when manual sorting is also
                  // enabled to prevent actions being dragged on top of block
                  // dropzones, but then being unable to be replaced in the list.
                  // This is the lesser of two evils, and ought to be fixed with
                  // a better DND library implementation.
                  enableDropzone={manualSort}
                  snoozedToString={snoozedToString}
                  enableProjectBanner={enableProjectBanner}
                  cardWithColor
                />
              );
            } else {
              return (
                <ActionItem
                  key={child.id}
                  action={child}
                  block={blocksById[child.blockId]}
                  data-id={child.id}
                  event={mostRecentEventByActionId[child.id]}
                  data-type={ACTION}
                  data-capture-list={parentType === CAPTURE_LIST}
                  data-category-id={child.categoryId}
                  data-state={state}
                  data-duration={child.duration}
                  data-source-parent-type={parentType}
                  enableDetailLink={enableDetailLink}
                  enableProjectBanner={enableProjectBanner}
                  readonly={readonlyActions}
                  number={enableActionNumbers && index + 1}
                  cardWithColor
                />
              );
            }
          })}
      </ReactSortable>

      {/* This has to be afterwards to allow use of the :empty selector on the
          .sortable class to hide/show the text here */}
      {emptyStateComputed !== false && (
        <EmptyState className={styles.emptyState} aria-hidden={children.length}>
          {emptyStateComputed}
        </EmptyState>
      )}

      {hasMore && (
        <LoadMoreButton loading={loadingMore} onClick={handleLoadMoreClick} />
      )}

      {/* confirm dialog on removing an action from a block to a category */}
      {confirmRemovalDialogVisible && <ConfirmRemovalDialog />}
    </>
  );
}

export default ChildrenList;
