import { HAIRSPACE, NBSP } from "src/constants";

/**
 * @param  {number|null} seconds
 * @returns {Object} time
 * @returns {string} time.short Exact Time with prime format 0'00"
 * @returns {string} time.long Exact Time with long unit e.g 1 hour 20 minutes
 * @returns {string} time.dropdown Rounded Time in M or H for use in dropdowns
 */
export function timeToText(seconds = 0) {
  if (typeof seconds !== "number" || isNaN(seconds))
    throw new Error(`seconds (${seconds}) must be a number`);

  if (seconds === 0) {
    return {
      long: `0${NBSP}minutes`,
      short: `0H${HAIRSPACE}00M`,
      dropdown: null,
    };
  }

  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
  const MINUTES_IN_HOUR = 60;

  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  let minutes =
    Math.round(seconds / SECONDS_IN_MINUTE) - hours * MINUTES_IN_HOUR;
  // Ensures that if seconds are 1 or more that it doesn't return 0 minutes
  if (!hours && !minutes) {
    minutes = 1;
  }

  const longParts = [];

  function addLongPart(value, unit) {
    if (!value) return;
    longParts.push(`${value}${NBSP}${unit}${value !== 1 ? "s" : ""}`);
  }

  addLongPart(hours, "hour");
  addLongPart(minutes, "minute");

  const long = longParts.join(" ");

  const shortHours = `${hours}H`;
  const shortMinutes = `${minutes.toString().padStart(2, "0")}M`;
  const short = [shortHours, shortMinutes].join(HAIRSPACE);

  // For less than an hour, it shows in minutes, for more than an hour, it shows
  // in hours rounded to the nearest .5
  let dropdown = !hours
    ? `${minutes}M`
    : `${Math.round((seconds / SECONDS_IN_HOUR) * 2) / 2}H`;

  // For hours with time intervals like these we can just return `short`
  // to avoid confusion with a rounded value, i.e 1H 45M instead of 2H
  if (hours && minutes && [15, 45].includes(minutes)) {
    dropdown = short;
  }

  return {
    long,
    short,
    dropdown,
  };
}
