import clsx from "clsx";
import React from "react";

import LogoLink from "./LogoLink";

import styles from "./Nav.module.scss";

function Nav({ className, heading, children }) {
  return (
    <nav className={clsx(styles.nav, className)}>
      <div className={styles.title}>
        <LogoLink heading={heading} />
      </div>
      {children}
    </nav>
  );
}

export default Nav;
