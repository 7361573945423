import {
  PERSON_NICKNAME_MAX_LENGTH,
  PERSON_DESCRIPTION_MAX_LENGTH,
  ACTIVE,
  SNOOZED,
  COMPLETED,
  MENTIONED,
  LEVERAGED,
} from "./constants";
import {
  firestore,
  collection,
  document,
  ParameterError,
  generateId,
  CacheDelayer,
  composeUnsubscribers,
} from "./general";
import { Action } from "./actions";
import { trackEvent } from "../AnalyticsService";

export class Person {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Person(), {
      id: snapshot.id,
      description: data.description,
      fullName: data.full_name,
      nickname: data.nickname,
      image: data.background_image,
    });
  }
}

export async function getPeople() {
  const snapshot = await collection("people").get();
  return snapshot.docs.map(Person.fromFirestore);
}

export function watchPeople(callback) {
  return collection("people")
    .orderBy("nickname", "asc")
    .onSnapshot((snapshot) => {
      const people = snapshot.docs.map(Person.fromFirestore);
      callback(people);
    });
}

export function watchPerson(personId, callback) {
  return document(`people/${personId}`).onSnapshot((snapshot) => {
    callback(Person.fromFirestore(snapshot));
  });
}

export function watchPersonActiveActions(personId, callback) {
  let mentionedActions, leveragedActions;

  function handleChange() {
    if (mentionedActions === undefined || leveragedActions === undefined)
      return;

    callback({
      [MENTIONED]: mentionedActions,
      [LEVERAGED]: leveragedActions,
    });
  }

  const mentionedActionsQuery = collection(`actions`)
    .where("mentions", "array-contains", personId)
    .where("state", "in", [ACTIVE, SNOOZED])
    .orderBy("is_starred", "desc")
    .orderBy("date_of_completion", "desc");

  const leveragedActionsQuery = collection(`actions`)
    .where("leveraged_to", "==", personId)
    .where("state", "in", [ACTIVE, SNOOZED])
    .orderBy("is_starred", "desc")
    .orderBy("date_of_completion", "desc");

  return composeUnsubscribers(
    mentionedActionsQuery.onSnapshot((snapshot) => {
      mentionedActions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    }),
    leveragedActionsQuery.onSnapshot((snapshot) => {
      leveragedActions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    })
  );
}

export function watchPersonCompletedActions(personId, type, limit, callback) {
  if (![LEVERAGED, MENTIONED].includes(type))
    throw new ParameterError(
      { type },
      `must be "${LEVERAGED}" or "${MENTIONED}"`
    );
  if (!limit)
    // Completed actions can be many thousands, must be fetched using pagination
    throw new ParameterError(
      { limit },
      "must be > 0 when querying completed actions"
    );

  let query = collection("actions")
    .where("state", "==", COMPLETED)
    // The extra action is used only to peek above the limit to see if there
    // are more actions
    .limit(limit + 1);

  if (type === LEVERAGED) {
    query = query.where("leveraged_to", "==", personId);
  } else {
    query = query.where("mentions", "array-contains", personId);
  }

  query = query
    .orderBy("is_starred", "desc")
    .orderBy("date_of_completion", "desc");

  const cacheDelayer = new CacheDelayer();
  const unsubscribe = query.onSnapshot((snapshot) => {
    const actions = snapshot.docs.map(Action.fromFirestore);
    const hasMore = actions.length > limit;
    // Don't return the extra action we used to peek above the limit
    if (hasMore) actions.pop();
    cacheDelayer.delayIfCached(() => callback(actions, hasMore), snapshot);
  });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  };
}

export function createPerson(nickname) {
  if (typeof nickname !== "string")
    throw new ParameterError({ nickname }, "not a string");
  const nicknameTrim = nickname.trim();
  if (nicknameTrim === "")
    throw new ParameterError({ nickname }, "empty after trim");
  if (nicknameTrim.length > PERSON_NICKNAME_MAX_LENGTH)
    throw new ParameterError(
      { nickname },
      "length must be <= " + PERSON_NICKNAME_MAX_LENGTH
    );

  const id = generateId();
  const person = {
    id,
    description: null,
    full_name: null,
    nickname: nicknameTrim,
  };

  const batch = firestore().batch();

  batch.set(document(`people/${id}`), person);

  batch.commit();

  trackEvent("User created a Person", {
    id,
    nicknameLength: nicknameTrim.length,
  });

  return person;
}

export function updatePerson(personId, nickname, description, image) {
  if (typeof nickname !== "string")
    throw new ParameterError({ nickname }, "must be a string");
  const nicknameTrim = nickname.trim();
  if (nicknameTrim === "")
    throw new ParameterError({ nickname }, "empty after trim");
  if (nickname.length > PERSON_NICKNAME_MAX_LENGTH)
    throw new ParameterError(
      { nickname },
      "length must be <= " + PERSON_NICKNAME_MAX_LENGTH
    );

  if (typeof description !== "string")
    throw new ParameterError({ description }, "must be a string or null");
  const descriptionTrim = description.trim();
  if (descriptionTrim.length > PERSON_DESCRIPTION_MAX_LENGTH)
    throw new ParameterError(
      { description },
      "length must be <= " + PERSON_DESCRIPTION_MAX_LENGTH
    );

  if (image !== null && typeof image !== "string")
    throw new ParameterError({ image }, 'must be "null" or a string');

  document(`people/${personId}`).update({
    nickname: nicknameTrim,
    description: descriptionTrim,
    background_image: image,
  });
}

export function deletePerson(personId) {
  document(`people/${personId}`).delete();
}
