import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { replaceMultipleLineBreaks } from "src/utils/replaceMultipleLineBreaks";
import { sleep } from "src/utils/sleep";

import { trackEvent } from "../../services/AnalyticsService";
import { watchUserGoals, setUserGoals } from "../../services/DbService/user";

import { ONBOARDING_CREATE_CATEGORY_URL } from "../App";
import Input from "../Input";
import LoadingSpinner from "../LoadingSpinner";
import Button, { BUTTON_COLOR_FILL } from "../Button";
import OnboardingPage from "./OnboardingPage";

import styles from "./Onboarding.module.scss";

function Goals() {
  const history = useHistory();

  const [autoFocus, setAutofocus] = useState(null);
  const [vision, setVision] = useState(null);
  const [purpose, setPurpose] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent("User started onboarding");
  }, []);

  useEffect(() => {
    return watchUserGoals((goals) => {
      const newVision = goals.vision || "";
      const newPurpose = goals.purpose || "";
      const newAutofocus = newVision && !newPurpose ? "purpose" : "vision";

      setVision(newVision);
      setPurpose(newPurpose);
      setAutofocus(newAutofocus);
    });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      // The latency with FireStore is so low, that without the timeout it feels like something
      // went wrong. This just delays the user long enough for it to feel deliberate.
      await sleep(500);

      setUserGoals(
        replaceMultipleLineBreaks(vision),
        replaceMultipleLineBreaks(purpose)
      );

      history.push(ONBOARDING_CREATE_CATEGORY_URL);
    },
    [history, vision, purpose]
  );

  return (
    <OnboardingPage
      title="Your Goals"
      intro="For RPM to work, you first need to tap into your Ultimate Vision and Purpose for your life. Be sure consult your emotions, not just your logical mind. Take your time, get quiet and listen for your own voice to emerge."
    >
      <form onSubmit={handleSubmit}>
        {autoFocus === null && <LoadingSpinner absolute />}
        {autoFocus !== null && (
          <>
            <Input
              label="Ultimate Vision"
              tag="textarea"
              autoFocus={autoFocus === "vision"}
              minRows={1}
              maxRows={5}
              value={vision}
              onChange={(e) => setVision(e.target.value)}
            />
            <Input
              label="Ultimate Purpose"
              tag="textarea"
              autoFocus={autoFocus === "purpose"}
              minRows={1}
              maxRows={5}
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
            />
          </>
        )}

        <div className={styles.buttons}>
          <Button loading={loading} color={BUTTON_COLOR_FILL} block>
            Next
          </Button>
        </div>
      </form>
    </OnboardingPage>
  );
}

export default Goals;
