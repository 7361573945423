import React, { useCallback, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import {
  BLOCK,
  CATEGORY,
  UNCATEGORIZED_ID,
} from "../../services/DbService/constants";

import useUniqueId from "../../hooks/useUniqueId";

import CardButton from "./CardButton";
import { useCategoryIcon, useCategoryOptions } from "../CategoriesContext";
import ContextualMenu, {
  ContextualMenuTrigger,
  ContextualMenuTaxonomy,
} from "../ContextualMenu";

import styles from "./Card.module.scss";

function CardParentMenu({
  className,
  parentId,
  parentType = CATEGORY,
  cardWithColor,
  onParentClick,
  disabled,
}) {
  const menuButtonRef = useRef();
  const menuButtonId = useUniqueId();
  const [menuVisible, setMenuVisible] = useState(null);

  // the optionsMap object associates the id of the available parents
  // (categories, blocks, action list) with the type of each
  const { optionsMap } = useCategoryOptions();

  // retrieve the category id of the parent
  const categoryId = useMemo(() => {
    const parentData = optionsMap?.[parentId] || {};

    // in case of uncategorized and category menus, the categoryId is the parentId
    if ([CATEGORY, "capture"].includes(parentData.type)) {
      return parentId;

      // on the other hand, in case of block menus, we should retrieve the parentId
      // from the optionsMap
    } else if (parentData.type === BLOCK) {
      return parentData?.parentId;
    }

    return null;
  }, [parentId, optionsMap]);

  const CategoryIcon = useCategoryIcon(categoryId)?.icon;

  const handleParentClick = useCallback(
    (parentId) => {
      setMenuVisible(false);

      onParentClick && onParentClick(parentId);
    },
    [onParentClick]
  );

  return (
    <>
      <ContextualMenuTrigger
        visible={menuVisible}
        setVisible={setMenuVisible}
        menuId={menuButtonId}
      >
        <CardButton
          ref={menuButtonRef}
          className={clsx(
            className,
            // Uncategorized is shown without the colour regardless of whether it
            // is a coloured card or not
            !cardWithColor &&
              parentId !== UNCATEGORIZED_ID &&
              styles.buttonCategory
          )}
          disabled={disabled}
          aria-label="Change category"
        >
          {CategoryIcon && <CategoryIcon role="presentation" />}
        </CardButton>
      </ContextualMenuTrigger>

      {menuVisible && (
        <ContextualMenu
          buttonRef={menuButtonRef}
          onClose={() => setMenuVisible(false)}
          aria-labelledby={menuButtonId}
        >
          <ContextualMenuTaxonomy
            parentType={parentType}
            onItemClick={handleParentClick}
            activeId={parentId}
          />
        </ContextualMenu>
      )}
    </>
  );
}

export default CardParentMenu;
