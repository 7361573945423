import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";

import useHistoryBlock from "src/hooks/useHistoryBlock";
import useUniqueId from "src/hooks/useUniqueId";
import { replaceMultipleLineBreaks } from "src/utils/replaceMultipleLineBreaks";

import Button from "../Button";
import Input, {
  InputField,
  InputLabel,
  InputImageUpload,
  IMAGE_UPLOAD_STATE_UPLOADING,
  IMAGE_UPLOAD_STATE_ERROR,
} from "../Input";
import UnsavedChangesDialog from "../UnsavedChangesDialog";
import { GlobalToast } from "../Toast";

import styles from "./DetailPanel.module.scss";

export function DetailPanelForm({
  fields,
  editing,
  setEditing,
  onSubmit,
  onChange,
  optionsMenu,
  values,
  error,
  ...props
}) {
  const errorToastId = useUniqueId();

  const initialValuesRef = useRef({});

  // onChange is stored as a ref in case it is not memoized in the parent
  // which would cause the useEffect below to return an infinite loop.
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [fieldValues, setFieldValues] = useState(null);
  const [imageStates, setImageStates] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  // Initial values are stored in a ref to avoid fields being reset if values
  // changes
  useEffect(() => {
    const initialValues = {};
    // values is often the whole item object (e.g. an entire category), but
    // only the values associated to fields are needed, so this compiles an
    // initial values object of only those fields so that it can passed back
    // at submit as just the values from the form
    for (const { slug } of fields) {
      initialValues[slug] = values[slug];
    }
    initialValuesRef.current = initialValues;
  }, [values, fields]);

  useEffect(() => {
    setFieldValues(editing ? initialValuesRef.current : null);
  }, [editing]);

  const handleFieldChange = useCallback((slug, value) => {
    setFieldValues((oldFieldValues) => ({
      ...oldFieldValues,
      [slug]: value,
    }));
  }, []);

  useEffect(() => {
    if (fieldValues === null) return;
    onChangeRef.current && onChangeRef.current(fieldValues);
  }, [fieldValues]);

  const handleImageState = useCallback((slug, newState) => {
    setImageStates((oldImageStates) => ({
      ...oldImageStates,
      [slug]: newState,
    }));
  }, []);

  const handleCancelConfirm = useCallback(() => {
    setShowUnsavedChangesDialog(false);
    setEditing(false);
  }, [setEditing]);

  const handleCancelClick = useCallback(
    (e) => {
      e.preventDefault();

      let isEqual = true;
      for (const { slug } of fields) {
        if (values[slug] !== fieldValues[slug]) {
          isEqual = false;
          break;
        }
      }

      if (isEqual) {
        handleCancelConfirm();
      } else {
        setShowUnsavedChangesDialog(true);
      }
    },
    [values, fields, fieldValues, handleCancelConfirm]
  );

  const imageIsUploading = Object.values(imageStates).includes(
    IMAGE_UPLOAD_STATE_UPLOADING
  );

  const errorMessage = useMemo(() => {
    if (error) return error;

    if (Object.values(imageStates).includes(IMAGE_UPLOAD_STATE_ERROR)) {
      return "Image upload error";
    }

    // The first field is the name/nickname field and is required. This could
    // probably be improved to be more robust and support stronger validation
    const [firstField] = fields;

    if (firstField) {
      const firstFieldValue = fieldValues?.[firstField.slug] || "";
      if (!firstFieldValue.trim()) {
        return `${firstField.label} is required`;
      }
      if (firstFieldValue.length > firstField.maxLength) {
        return `${firstField.label} is too long`;
      }
    }

    return;
  }, [error, fields, fieldValues, imageStates]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (errorMessage) return;

      const formData = { ...fieldValues };

      for (const [key, value] of Object.entries(formData)) {
        formData[key] =
          typeof value === "string" ? replaceMultipleLineBreaks(value) : value;
      }

      onSubmit && onSubmit(formData);
    },
    [onSubmit, fieldValues, errorMessage]
  );

  const [historyContinue] = useHistoryBlock(editing && isDirty, () => {
    setShowUnsavedChangesDialog(true);
  });

  const handleConfirmChanges = useCallback(
    (confirm) => {
      if (confirm) {
        // Attempt history continue if navigation change.
        historyContinue();

        // Change back from edit.
        handleCancelConfirm();
      }

      setShowUnsavedChangesDialog(false);
    },
    [historyContinue, handleCancelConfirm]
  );

  if (!editing || !fieldValues) return null;

  return (
    <form className={styles.form} onSubmit={handleSubmit} {...props}>
      <div className={styles.buttons}>
        {optionsMenu}
        <Button type="submit" disabled={errorMessage || imageIsUploading}>
          Save
        </Button>
        <Button negative onClick={handleCancelClick}>
          Cancel
        </Button>
      </div>

      <div className={clsx(styles.scrollable)}>
        <div className={clsx(styles.group)}>
          {fields &&
            fields.map((field, index) => {
              const {
                slug,
                label,
                type,
                component: CustomInputComponent,
                id,
                transformOnChange,
                ...fieldProps
              } = field;

              fieldProps.value = fieldValues[slug] || "";
              fieldProps.onChange = (e) => {
                let value = e.target.value;
                if (transformOnChange) {
                  value = transformOnChange(value);
                }
                handleFieldChange(slug, value);

                // When any field has changed, mark as dirty
                setIsDirty(value !== '');
              };

              if (type === "image") {
                return (
                  <InputImageUpload
                    key={slug}
                    label={label}
                    onStateChange={(newState) =>
                      handleImageState(slug, newState)
                    }
                    {...fieldProps}
                  />
                );
              }

              if (CustomInputComponent) {
                return (
                  <InputField key={slug}>
                    <InputLabel fauxFilled id={id} tag="span">
                      {label}
                    </InputLabel>
                    <CustomInputComponent
                      aria-labelledby={id}
                      {...fieldProps}
                    />
                  </InputField>
                );
              }

              if (fieldProps.maxLength > 50) {
                fieldProps.tag = "textarea";
                fieldProps.minRows = 1;
                fieldProps.maxRows = 10;
              }

              if (index === 0 && errorMessage) {
                fieldProps["aria-errormessage"] = errorToastId;
              }

              return (
                <Input
                  key={slug}
                  label={label}
                  id={id}
                  type={type}
                  autoFocus={editing === slug}
                  {...fieldProps}
                />
              );
            })}
        </div>
      </div>

      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={() => setShowUnsavedChangesDialog(false)}
          onDiscard={handleConfirmChanges}
        />
      )}

      {errorMessage && (
        <GlobalToast id={errorToastId} error>
          {errorMessage}
        </GlobalToast>
      )}
    </form>
  );
}

export default DetailPanelForm;
