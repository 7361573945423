import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useRouteMatch } from "react-router-dom";
import {
  addDays,
  addWeeks,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from "date-fns";

import { modifierKey } from "src/utils/modifierKey";

import { DASHBOARD_URL } from "../App";
import Button from "../Button";
import TabBar from "../TabBar";
import Tooltip from "../Tooltip";
import CalendarPanelTitle from "./CalendarPanelTitle";
import PeriodView from "./PeriodView";
import { ActionDialogContext } from "../ActionDialog";

import { ReactComponent as IconPrevious } from "../../assets/icons/16-previous.svg";
import { ReactComponent as IconNext } from "../../assets/icons/16-next.svg";
import { ReactComponent as IconAdd } from "../../assets/icons/16-add.svg";

import styles from "./CalendarPanel.module.scss";
import differenceInDays from "date-fns/differenceInDays";
import {
  watchExternalEvents,
  WEEKS_AROUND,
} from "src/services/ExternalEventsService";
import {
  createEvent,
  setEventDuration,
  setEventStartDate,
  updateEventScheduleFromActionId,
  watchEvents,
} from "src/services/DbService/events";
import { actionHasEvents, updateAction } from "src/services/DbService/actions";
import { ACTIVE } from "src/services/DbService/constants";

const SYMBOL_LEFT_ARROW = "\u21E6";
const SYMBOL_RIGHT_ARROW = "\u21E8";

const DISPLAY_PERIOD_DAY = "day";
const DISPLAY_PERIOD_WEEK = "week";

const INITIAL_DISPLAY_PERIOD = DISPLAY_PERIOD_WEEK;

const availableDisplayPeriods = [DISPLAY_PERIOD_DAY, DISPLAY_PERIOD_WEEK];

const displayPeriods = {
  [DISPLAY_PERIOD_DAY]: {
    endOfPeriodFunc: endOfDay,
    startOfPeriodFunc: startOfDay,
    tabId: DISPLAY_PERIOD_DAY,
    tabLabel: "Day",
  },
  [DISPLAY_PERIOD_WEEK]: {
    endOfPeriodFunc: endOfWeek,
    startOfPeriodFunc: startOfWeek,
    tabId: DISPLAY_PERIOD_WEEK,
    tabLabel: "Week",
  },
};

const getPeriodStart = (displayPeriod, date = new Date()) =>
  displayPeriods[displayPeriod].startOfPeriodFunc(date);

const getPeriodEnd = (displayPeriod, date = new Date()) =>
  displayPeriods[displayPeriod].endOfPeriodFunc(date);

export default function CalendarPanel({ ...props }) {
  const [createEventClicked, setCreateEventClicked] = useState(null);
  const [displayPeriod, setDisplayPeriod] = useState(INITIAL_DISPLAY_PERIOD);

  if (!availableDisplayPeriods.includes(displayPeriod)) {
    throw new Error(`Unknown display period ${displayPeriod}`);
  }

  const initialPeriodStart = getPeriodStart(displayPeriod);
  // This date defines which week will be rendered
  const [periodStart, setPeriodStart] = useState(initialPeriodStart);

  const periodEnd = useMemo(
    () => getPeriodEnd(displayPeriod, periodStart),
    [displayPeriod, periodStart]
  );

  const { newActionEvent } = useContext(ActionDialogContext);

  // Prefetch events from previous/following weeks to speed up UI
  const [eventsBuffer, setEventsBuffer] = useState([]);

  useEffect(() => {
    const startDate = subWeeks(periodStart, WEEKS_AROUND);
    const endDate = addWeeks(periodEnd, WEEKS_AROUND);
    return watchEvents(startDate, endDate, setEventsBuffer);
  }, [periodStart, periodEnd]);

  const [externalEventsBuffer, setExternalEventsBuffer] = useState([]);
  useEffect(() => watchExternalEvents(setExternalEventsBuffer), []);

  const routeIsDashboard = useRouteMatch(DASHBOARD_URL).isExact;
  const { newActionParent: actionDialogActive } =
    useContext(ActionDialogContext);

  useEffect(
    () => setPeriodStart(getPeriodStart(displayPeriod)),
    [displayPeriod]
  );

  const periodDays =
    useMemo(
      () => differenceInDays(periodEnd, periodStart),
      [periodEnd, periodStart]
    ) + 1;

  const handlePreviousPeriod = useCallback(() => {
    setPeriodStart(subDays(periodStart, periodDays));
  }, [periodDays, periodStart]);

  const handleNextPeriod = useCallback(() => {
    setPeriodStart(addDays(periodStart, periodDays));
  }, [periodDays, periodStart]);

  const handleCreateEvent = useCallback(() => {
    setCreateEventClicked(true);
  }, []);

  // Previous/Next periods on CMD/CTRL + arrows
  useEffect(() => {
    if (!routeIsDashboard || actionDialogActive) return;

    function handleKeydownAnywhere(e) {
      if ((e.key !== "ArrowRight" && e.key !== "ArrowLeft") || !e[modifierKey])
        return;

      e.preventDefault();

      if (e.key === "ArrowRight") {
        handleNextPeriod();
      } else {
        handlePreviousPeriod();
      }
    }
    document.addEventListener("keydown", handleKeydownAnywhere);
    return () => document.removeEventListener("keydown", handleKeydownAnywhere);
  }, [
    routeIsDashboard,
    handlePreviousPeriod,
    handleNextPeriod,
    actionDialogActive,
  ]);

  const updateDatabaseEvent = useCallback(
    (id, startDate, duration, categoryId, blockId, actionsIds) => {
      actionHasEvents(id).then(async (hasEvents) => {
        if (hasEvents) {
          await updateEventScheduleFromActionId(id, startDate, duration);
        } else {
          if (!startDate) return;
          createEvent(startDate, duration, categoryId, blockId, actionsIds);
        }

        // snoozed actions should be changed to active state
        // when dropped in the calendar
        await updateAction(id, { state: ACTIVE, duration });
      });
    },
    []
  );

  const updateDatabaseActionDuration = useCallback((event) => {
    // snoozed actions should be changed to active state
    // when reescheduled
    event.actionsIds.forEach((actionId) => {
      updateAction(actionId, {
        state: ACTIVE,
        duration: event.duration,
      });
    });
  }, []);

  const tabs = availableDisplayPeriods.map((periodKey) => ({
    id: displayPeriods[periodKey].tabId,
    label: displayPeriods[periodKey].tabLabel,
  }));

  const mustIncludeDayInTitle = periodDays < 2;
  const displayedPeriodLabel = displayPeriods[displayPeriod].tabLabel;

  return (
    <div {...props}>
      <div className={styles.container}>
        <div className={styles.header}>
          <CalendarPanelTitle
            date={periodStart}
            includeDay={mustIncludeDayInTitle}
          />

          <div className={styles.buttons}>
            <TabBar
              className={styles.dialogTabBar}
              active={displayPeriod}
              tabs={tabs}
              onTabClick={setDisplayPeriod}
            />
            <Button
              className={styles.createEventButton}
              onClick={handleCreateEvent}
              iconOnly
              aria-label="New Event"
              tooltip
            >
              <IconAdd role="presentation" />
            </Button>

            <Tooltip
              title={`Previous ${displayPeriods[displayPeriod].tabLabel}`}
              shortcut={SYMBOL_LEFT_ARROW}
              shortcutModifier
            >
              <Button
                onClick={handlePreviousPeriod}
                iconOnly
                aria-label={`Previous ${displayedPeriodLabel}`}
              >
                <IconPrevious role="presentation" />
              </Button>
            </Tooltip>

            <Tooltip
              title={`Next ${displayedPeriodLabel}`}
              shortcut={SYMBOL_RIGHT_ARROW}
              shortcutModifier
            >
              <Button
                onClick={handleNextPeriod}
                iconOnly
                aria-label={`Next ${displayedPeriodLabel}`}
              >
                <IconNext role="presentation" />
              </Button>
            </Tooltip>

            <Button
              className={styles.todayButton}
              onClick={() => setPeriodStart(initialPeriodStart)}
              aria-label="Go to today"
              tooltip
            >
              Today
            </Button>
          </div>
        </div>

        <PeriodView
          periodStart={periodStart}
          periodEnd={periodEnd}
          createEventClicked={createEventClicked}
          setCreateEventClicked={setCreateEventClicked}
          newActionEvent={newActionEvent}
          eventsBuffer={eventsBuffer}
          setEventsBuffer={setEventsBuffer}
          externalEventsBuffer={externalEventsBuffer}
          dropActionEvent={updateDatabaseEvent}
          updateActionDuration={updateDatabaseActionDuration}
          setEventDuration={setEventDuration}
          setEventStartDate={setEventStartDate}
          showEventDialog={true}
        />
      </div>
    </div>
  );
}
