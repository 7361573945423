import React, {
  forwardRef,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";

import {
  TYPE_LABELS,
  PERSON_NICKNAME_MAX_LENGTH,
} from "src/services/DbService/constants";
import { createPerson } from "src/services/DbService/people";

import useUniqueId from "../../hooks/useUniqueId";
import usePeople from "../../hooks/usePeople";
import useConfirmClose from "../../hooks/useConfirmClose";

import CreateDialog from "../CreateDialog";
import CreateButton from "../CreateButton";
import { BUTTON_COLOR_FILL } from "../Button";
import Divider from "../Divider";
import { InputLabel } from "../Input";
import { GlobalToast } from "../Toast";
import CharacterCounter from "../CharacterCounter";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

import styles from "./CreateDialog.module.scss";

const CreatePersonDialog = forwardRef(({ onClose, personGroupId }, ref) => {
  const inputRef = useRef();

  const errorToastId = useUniqueId();
  const { people } = usePeople();

  const [input, setInput] = useState("");
  const [inputPrefixed, setInputPrefixed] = useState("");

  const {
    showUnsavedChangesDialog,
    setIsDirty,
    handleConfirmClose,
    handleCancelConfirm,
    handleCloseDialog,
  } = useConfirmClose(onClose);

  const matchesExisting = useMemo(() => {
    return people.find(
      ({ nickname }) => nickname.toLowerCase() === input.toLowerCase()
    );
  }, [people, input]);

  const handleChange = useCallback(
    (e) => {
      let value = e.target.value;
      if (value !== "" && !value.startsWith("@")) value = `@${value}`;
      value = value.toLowerCase().replace(/\s/, ""); // Stop spaces from being put in
      setInputPrefixed(value);
      setInput(value.substr(1));
      setIsDirty(value !== "");
    },
    [setIsDirty]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      // This shouldn't trigger as [type="submit"] is [disabled], but to be on the safe side
      if (matchesExisting) return;

      createPerson(input, personGroupId);

      onClose();
    },
    [onClose, input, personGroupId, matchesExisting]
  );

  const nicknameError = useMemo(() => {
    if (matchesExisting)
      return `${TYPE_LABELS.person.title} already exists with username @${input}`;
    if (input.length > PERSON_NICKNAME_MAX_LENGTH) return `Too many characters`;
    return null;
  }, [input, matchesExisting]);

  return (
    <CreateDialog ref={ref} onClose={handleConfirmClose}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          <InputLabel tag="span">
            Enter New {TYPE_LABELS.person.title} Nickname
          </InputLabel>
          {/* The max length here is longer to allow a little bit of overflow (as well as an increase of 1
              to allow for the @, but the form will be invalid when it is over so won't be submittable. */}
          <input
            className={styles.input}
            ref={inputRef}
            type="text"
            autoFocus
            maxLength={PERSON_NICKNAME_MAX_LENGTH + 1 + 5}
            aria-errormessage={nicknameError ? errorToastId : null}
            placeholder="e.g. @rob"
            value={inputPrefixed}
            onChange={handleChange}
            autoComplete="off"
            spellCheck={false}
          />
        </label>

        <CharacterCounter
          className={styles.counter}
          count={input.length}
          max={PERSON_NICKNAME_MAX_LENGTH}
        />

        <Divider vertical />

        <CreateButton
          error={nicknameError}
          disabled={!input}
          color={BUTTON_COLOR_FILL}
          type="submit"
          aria-label={`Create New ${TYPE_LABELS.person.title}`}
        />
      </form>

      {nicknameError && (
        <GlobalToast id={errorToastId} error>
          {nicknameError}
        </GlobalToast>
      )}

      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={handleCloseDialog}
          onDiscard={handleCancelConfirm}
        />
      )}
    </CreateDialog>
  );
});

CreatePersonDialog.displayName = "CreatePersonDialog";

export default CreatePersonDialog;
