import { getAuthTokens, refreshTokens } from "./AuthService";

export class NetworkError extends Error {}
export class ApiParameterError extends Error {}
export class ApiError extends Error {
  constructor(response) {
    super(
      "API call replied with errors:\n" + JSON.stringify(response, null, 2)
    );
  }
}

// This provides an environment variable based on the dataset as described in
// #Environments in the readme
export const getEnvVar = (key) =>
  process.env[`REACT_APP_${process.env.REACT_APP_DATASET}_${key}`];

export async function api(endpoint, body, args = {}) {
  const {
    method = "POST",
    contentType = "application/json",
    authenticate = false,
    autoRefreshToken = true,
  } = args;

  const fetchConfig = { method, headers: {} };

  fetchConfig.headers["rpm-platform"] = "web";

  if (authenticate) {
    const { accessToken } = getAuthTokens();
    fetchConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  // For multipart/form-data it's best not to set the Content-Type and allow the
  // browser to infer the required boundaries
  if (contentType !== "multipart/form-data") {
    fetchConfig.headers["Content-Type"] = contentType;
  }

  if (contentType === "application/json" && method !== "GET" && body) {
    fetchConfig.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(
      new URL(endpoint, getEnvVar("BACKEND_API_URL")),
      fetchConfig
    );

    // If we tryed to authenticate and got a 401 it means we have to refresh
    // the access token and retry. When retrying, autoRefreshToken is set to
    // false because if another 401 happens we have to raise an error, otherwise
    // the app would be stuck in an infinte loop of tokens refresh
    if (response.status === 401 && authenticate && autoRefreshToken) {
      await refreshTokens();
      return await api(endpoint, body, { ...args, autoRefreshToken: false });
    }

    if (response.status === 204) {
      return;
    } else {
      const resContentType = response.headers.get("content-type");
      if (resContentType && resContentType.indexOf("application/json") !== -1) {
        return await response.json();
      } else {
        return await response.text();
      }
    }
  } catch (e) {
    if (
      e.message === "Timeout" ||
      e.message === "Network request failed" ||
      e.message === "Failed to fetch"
    ) {
      throw new NetworkError(e.message);
    } else {
      throw e;
    }
  }
}
