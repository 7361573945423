import React, { useContext, useMemo } from "react";
import clsx from "clsx";

import { NBSP } from "src/constants";

import {
  ACTION,
  BLOCK,
  ITEM_LABELS,
  TYPE_LABELS,
} from "src/services/DbService/constants";

import { DashboardContext } from "../Dashboard";
import useAction from "src/hooks/useAction";
import LoadingSpinner from "../LoadingSpinner";
import EmptyState from "../EmptyState";
import Heading, { HEADING_LEVEL_3 } from "../Heading";
import ChildrenList from "../ChildrenList";

import styles from "./ActionList.module.scss";

function ActionList({
  parentType,
  parentId,
  sectionFilters,
  snoozedToString,
  enableActionNumbers,
  enableProjectBanner = true,
  readonlyActions,
  ...props
}) {
  if (!sectionFilters || !Array(sectionFilters)) {
    throw new Error(`Unexpected value for sectionFilters prop.`);
  }

  const { actions } = useAction(parentId, parentType);
  const { activeStarred, starredFilter } = useContext(DashboardContext);

  const sections = useMemo(() => {
    if (!actions) return null;

    return sectionFilters.reduce((result, section) => {
      const { id, label, state, filter } = section;

      const filteredActions = actions.filter((child) => {
        const filtered = child.state === state && filter(child);
        // Avoid filtering starred actions inside BlockDetail
        if (parentType !== BLOCK) {
          return filtered && starredFilter(child);
        }
        return filtered;
      });

      return filteredActions.length
        ? [
            ...result,
            {
              id,
              label,
              actions: filteredActions,
            },
          ]
        : result;
    }, []);
  }, [actions, sectionFilters, parentType, starredFilter]);

  if (sections === null) {
    return (
      <div className={clsx(styles.loading, styles.hasEmptyState)} {...props}>
        <LoadingSpinner absolute />
      </div>
    );
  }

  if (!sections.length) {
    return (
      <EmptyState
        className={clsx(styles.emptyState, styles.emptyActionList)}
        aria-hidden={sections.length}
      >
        {activeStarred
          ? `No ${ITEM_LABELS.textPlural} active`
          : `No ${TYPE_LABELS[ACTION].textPlural} created`}
        {`\nCreate one${NBSP}below`}
      </EmptyState>
    );
  }

  return (
    <>
      {sections.map(({ id, label, actions: list }) => (
        <div key={id}>
          <Heading className={styles.heading} level={HEADING_LEVEL_3}>
            {label}
          </Heading>

          <ChildrenList
            list={list}
            parentId={parentId}
            parentType={parentType}
            emptyState={false}
            enableProjectBanner={enableProjectBanner}
            readonlyActions={readonlyActions}
            enableActionNumbers
          />
        </div>
      ))}
    </>
  );
}

export default ActionList;
