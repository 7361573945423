import React, { useRef, useCallback, forwardRef } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import useEscKey from "../../hooks/useEscKey";

import { useGetCategoryColor } from "../CategoriesContext";
import Button, { BUTTON_SIZE_XSMALL } from "../Button";
import Divider from "../Divider";

import { ReactComponent as IconClose } from "../../assets/icons/16-close.svg";
import { ReactComponent as IconPrevious } from "../../assets/icons/16-previous.svg";
import { ReactComponent as IconNext } from "../../assets/icons/16-next.svg";

import styles from "./DueDateButton.module.scss";

const CustomButton = forwardRef(
  (
    {
      className,
      value,
      onClick,
      onFocus, // Spread, but unused as it adds undesired functionality
      customInput: CustomInput,
      ...props
    },
    ref
  ) => {
    if (CustomInput) {
      return React.cloneElement(CustomInput, { ref, onClick });
    }

    return (
      <Button ref={ref} {...props} onClick={onClick} className={styles.button}>
        {!value && "Set due date"}
        {value && (
          <>
            <span className={styles.buttonDue}>Due:&nbsp;</span>
            {format(new Date(value), "P")}
          </>
        )}
      </Button>
    );
  }
);

const CustomContainer = ({ className, children, categoryColor }) => (
  <div className={className} data-category-color={categoryColor}>
    {children}
  </div>
);

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  onCloseClick,
}) => (
  <div className={styles.header}>
    <Button
      iconOnly
      type="button"
      onClick={onCloseClick}
      aria-label="Close dialog"
      size={BUTTON_SIZE_XSMALL}
    >
      <IconClose role="presentation" />
    </Button>
    <span className={styles.headerDate}>{format(date, "MMMM yyyy")}</span>
    <Button
      iconOnly
      type="button"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      aria-label="Previous Month"
      tooltip
      size={BUTTON_SIZE_XSMALL}
    >
      <IconPrevious role="presentation" />
    </Button>
    <Button
      iconOnly
      type="button"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      aria-label="Next Month"
      tooltip
      size={BUTTON_SIZE_XSMALL}
    >
      <IconNext role="presentation" />
    </Button>
    <Divider aria-hidden="true" />
  </div>
);

function DueDateButton({
  date,
  minDate,
  onChange,
  categoryId,
  disabled,
  customInput,
  customActions,
  portalId,
  popperPlacement = "bottom-start",
  ...props
}) {
  const pickerRef = useRef();

  const getCategoryColor = useGetCategoryColor();

  const handleClose = useCallback(() => {
    pickerRef.current.setOpen(false);
  }, []);

  // react-datepicker doesn't expose a ref for the calendar or the selected calendar
  // day to focus when the calendar opens so a native set of querySelectors are required.
  const handleCalendarOpen = useCallback(() => {
    const calendarRef = pickerRef.current.calendar.instanceRef.monthContainer;
    const focusedDay = calendarRef.querySelector(
      ".react-datepicker__day--keyboard-selected"
    );
    const selectedDay = calendarRef.querySelector(
      ".react-datepicker__day--selected"
    );

    if (focusedDay) {
      focusedDay.focus();
    } else if (selectedDay) {
      selectedDay.focus();
    }
  }, []);

  const handleCalendarClose = useCallback(() => {
    // This needs a timeout to happen after a rerender.
    setTimeout(() => pickerRef?.current?.input?.focus(), 100);
  }, []);

  const handleClearDate = useCallback(() => {
    onChange(null);
    handleClose();
  }, [onChange, handleClose]);

  useEscKey(handleClose);

  // This requires a wrapper to pass className down to as <DatePicker /> is wrapped in something that
  // doesn't inherit the className.
  return (
    <DatePicker
      ref={pickerRef}
      selected={date}
      minDate={minDate}
      onChange={onChange}
      customInput={<CustomButton customInput={customInput} {...props} />}
      renderCustomHeader={(headerProps) =>
        CustomHeader({ ...headerProps, onCloseClick: handleClose })
      }
      popperPlacement={popperPlacement}
      showPopperArrow={false}
      calendarContainer={(containerProps) =>
        CustomContainer({
          ...containerProps,
          categoryColor: getCategoryColor(categoryId),
        })
      }
      onCalendarOpen={handleCalendarOpen}
      onCalendarClose={handleCalendarClose}
      portalId={portalId || "due-date-portal"}
      disabled={disabled}
    >
      {customActions}

      {date && (
        <Button
          className={styles.footerClear}
          negative
          onClick={handleClearDate}
        >
          Clear Date
        </Button>
      )}
    </DatePicker>
  );
}

export default DueDateButton;
