import React, { useCallback, useRef, useState } from "react";

import {
  CATEGORY,
  TYPE_LABELS,
  PROJECT,
  BLOCK,
  UNCATEGORIZED_ID,
} from "src/services/DbService/constants";

import useUniqueId from "../../hooks/useUniqueId";

import { BUTTON_SIZE_XSMALL } from "../Button";
import {
  useCategories,
  useCategoryOptions,
  useGetCategoryColor,
} from "../CategoriesContext";
import { useProjects } from "../ProjectsContext";
import ContextualMenu, {
  ContextualMenuTrigger,
  ContextualMenuLink,
  ContextualMenuHeading,
} from ".";
import CreateButton from "../CreateButton";

import styles from "./ContextualMenu.module.scss";

const MAX_LEN_PARENT_NAME = 15;

function ItemList({ items, onItemClick, parentType, activeId }) {
  const getCategoryColor = useGetCategoryColor();
  const { optionsMap } = useCategoryOptions();

  // retrieve the color of the parent
  const getItemColor = useCallback(
    (item) => {
      let categoryColor = null;

      if (parentType === CATEGORY) {
        categoryColor = getCategoryColor(item.id);
      } else if (parentType === BLOCK) {
        const blockData = optionsMap?.[item.id] || {};

        // in the BLOCK menu, we have both items: categories
        // and blocks
        if (blockData.type === BLOCK) {
          categoryColor = getCategoryColor(blockData.parentId);
        } else if (blockData.type === CATEGORY) {
          categoryColor = getCategoryColor(item.id);
        }
      }

      return categoryColor;
    },
    [parentType, getCategoryColor, optionsMap]
  );

  return (
    <>
      {items &&
        items.map((item) => (
          <React.Fragment key={item.id}>
            <ContextualMenuLink
              onClick={(e) => {
                e.stopPropagation();
                onItemClick(item.id);
              }}
              category
              selected={item.id === activeId}
              data-category-color={getItemColor(item)}
            >
              {item.name}
            </ContextualMenuLink>
            {parentType === BLOCK &&
              item?.options?.map((option) => (
                <ContextualMenuLink
                  key={option.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemClick(option.id);
                  }}
                  block
                  selected={option.id === activeId}
                  data-category-color={getItemColor(option)}
                  className={styles.blocks}
                >
                  <span>
                    {option.name?.length > MAX_LEN_PARENT_NAME
                      ? `${option.name?.substring(0, MAX_LEN_PARENT_NAME)}...`
                      : option.name}
                  </span>
                </ContextualMenuLink>
              ))}
          </React.Fragment>
        ))}
    </>
  );
}

export function ContextualMenuTaxonomy({
  onItemClick,
  onCreateClick,
  parentType,
  enableHeading,
  activeId,
}) {
  const { hiddenCategories } = useCategories();
  const { activeProjects, hiddenProjects } = useProjects();

  const { optionsGroups } = useCategoryOptions();

  let activeItems, hiddenItems;
  if (parentType === PROJECT) {
    activeItems = activeProjects;
    hiddenItems = hiddenProjects;
  } else if ([CATEGORY, BLOCK].includes(parentType)) {
    // Hide Uncategorized option
    activeItems = optionsGroups.filter(
      (option) => option.id !== UNCATEGORIZED_ID
    );
    hiddenItems = hiddenCategories;
  }

  const hiddenItemsMenuButtonRef = useRef();
  const hiddenItemsMenuButtonId = useUniqueId();
  const [hiddenItemsMenuVisible, setHiddenItemsMenuVisible] = useState(false);

  const heading = [CATEGORY, BLOCK].includes(parentType)
    ? TYPE_LABELS[CATEGORY]?.titlePlural
    : TYPE_LABELS[parentType]?.titlePlural;

  if (!onItemClick || !activeItems || !hiddenItems) return null;

  const itemListSharedProps = {
    activeId,
    onItemClick,
    parentType,
  };

  return (
    <>
      {enableHeading && (
        <ContextualMenuHeading hasButton={!!onCreateClick}>
          {heading}
          {onCreateClick && (
            <CreateButton
              onClick={onCreateClick}
              size={BUTTON_SIZE_XSMALL}
              aria-label={`New ${TYPE_LABELS[parentType]?.title}`}
            />
          )}
        </ContextualMenuHeading>
      )}

      <ItemList {...itemListSharedProps} items={activeItems} />

      {hiddenItems !== undefined && (
        <>
          <ContextualMenuTrigger
            visible={hiddenItemsMenuVisible}
            setVisible={setHiddenItemsMenuVisible}
            menuId={hiddenItemsMenuButtonId}
            submenu
          >
            <ContextualMenuLink
              ref={hiddenItemsMenuButtonRef}
              submenu
              disabled={!hiddenItems?.length}
            >
              Hidden {heading}
            </ContextualMenuLink>
          </ContextualMenuTrigger>

          {hiddenItemsMenuVisible && (
            <ContextualMenu
              buttonRef={hiddenItemsMenuButtonRef}
              onClose={() => setHiddenItemsMenuVisible(false)}
              submenu
              aria-labelledby={hiddenItemsMenuButtonId}
            >
              <ItemList {...itemListSharedProps} items={hiddenItems} />
            </ContextualMenu>
          )}
        </>
      )}
    </>
  );
}

export default ContextualMenuTaxonomy;
