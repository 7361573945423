import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import LoadingSpinner from "../LoadingSpinner";
import Tooltip from "../Tooltip";

import styles from "./Button.module.scss";

export const BUTTON_COLOR_OUTLINE = "outline";
export const BUTTON_COLOR_FILL = "fill";
export const BUTTON_COLOR_CATEGORY = "category";

export const BUTTON_BORDER_STYLE_ROUND = "round";
export const BUTTON_BORDER_STYLE_SQUARE = "square";

export const BUTTON_SIZE_XSMALL = "xsmall";
export const BUTTON_SIZE_SMALL = "small";
export const BUTTON_SIZE_REGULAR = "regular";
export const BUTTON_SIZE_LARGE = "large";

export const BUTTON_ICON_ALIGN_LEFT = "iconLeft";
export const BUTTON_ICON_ALIGN_RIGHT = "iconRight";

const Button = forwardRef(
  (
    {
      className,
      children,
      color = BUTTON_COLOR_OUTLINE,
      borderStyle = BUTTON_BORDER_STYLE_ROUND,
      iconOnly,
      iconAlign = BUTTON_ICON_ALIGN_LEFT,
      block,
      linkTo,
      href,
      loading,
      negative,
      disabled,
      tag,
      size = BUTTON_SIZE_REGULAR,
      tooltip,
      ...props
    },
    ref
  ) => {
    let ButtonTag = "button";
    if (tag) ButtonTag = tag;
    else if (linkTo) ButtonTag = Link;
    else if (href) ButtonTag = "a";

    const buttonProps = {
      ...props,
      to: linkTo,
      href,
      disabled,
      className: clsx(
        className,
        styles.button,
        color && styles[color],
        iconOnly && styles.iconOnly,
        iconAlign && styles[iconAlign],
        loading && styles.loading,
        negative && styles.negative,
        size && styles[size],
        borderStyle && styles[borderStyle],
        block && styles.block
      ),
      ref,
    };

    buttonProps.children = (
      <>
        <span className={styles.children}>{children}</span>
        {loading && (
          <LoadingSpinner className={styles.spinner} absolute opaque />
        )}
      </>
    );
    props.disabled = disabled;

    const tooltipTitle = tooltip && props["aria-label"];

    if (tooltipTitle) {
      return (
        <Tooltip title={tooltipTitle}>
          <ButtonTag {...buttonProps} />
        </Tooltip>
      );
    }

    return <ButtonTag {...buttonProps} />;
  }
);

Button.displayName = "Button";

export default Button;
