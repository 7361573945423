import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import clsx from "clsx";

import useReplaceMentions from "../../../hooks/useReplaceMentions";
import useUniqueId from "../../../hooks/useUniqueId";

import { scrollIntoViewIfNeeded } from "src/utils/scrollIntoViewIfNeeded";
import { timeToText } from "src/utils/timeToText";

import EmptyState from "../../EmptyState";
import Divider from "../../Divider";
import Heading, { HEADING_LEVEL_5 } from "../../Heading";

import { ReactComponent as IconCheck } from "../../../assets/icons/16-check.svg";
import { ReactComponent as IconStar } from "../../../assets/icons/16-star.svg";
import { ReactComponent as IconNext } from "../../../assets/icons/16-next.svg";

import styles from "./NewEventDialogList.module.scss";

function NewEventDialogList({
  // Items must have shape {category: {..}} or {block: {..}} or {action: {..}}
  // this to make it easier to recognise the type of each item
  items,
  emptyMessage,
  selectedActions,
  // Use useCallback for these two handlers, because every times they change
  // they run the effect managing the keys listeners
  onBlockSelected,
  onChange,
  parentHidden,
  dialogHidden,
}) {
  const ref = useRef();
  const idPrefix = useUniqueId();
  // Current is the index of the element highlighted, with mouse or keyboard
  const [current, setCurrent] = useState(null);
  const replaceMentions = useReplaceMentions();

  // Enrich items them with additional data, and extract the "options" meaning
  // the items that can be highlighted and navigated with the keyboard.
  // Currently they are only block and actions, not categories.
  const [itemsAndData, options] = useMemo(() => {
    if (items === null) {
      return [null, null];
    }

    const itemsWithIndexes = [];
    const options = [];

    let lastCategoryColor = null;
    items.forEach((item) => {
      const itemAndData = { ...item };
      if (item.category) {
        lastCategoryColor = item.category?.color;
        itemAndData.optionIndex = null;
      } else {
        itemAndData.optionIndex = options.length;
        options.push(item);
      }
      itemAndData.accentColor = lastCategoryColor;
      itemsWithIndexes.push(itemAndData);
    });
    return [itemsWithIndexes, options];
  }, [items]);

  const selectAction = useCallback(
    (action) => {
      if (selectedActions.includes(action)) {
        // The action is already selected: toggle
        onChange(selectedActions.filter((a) => a !== action));
      } else {
        // The action is not previously selected and it's in the same category of
        // uncheck the previous selection and select the current action only
        onChange([action]);
      }
    },
    [onChange, selectedActions]
  );

  const handleKeyDown = useCallback(
    (e) => {
      // With an empty options list there can be only bugs here
      // The events should only be handled if the currently focused element is the list
      if (options.length === 0 || document.activeElement !== ref?.current)
        return;

      const { key, altKey, metaKey } = e;

      if (
        key === "Home" ||
        key === "PageUp" ||
        ((altKey || metaKey) && key === "ArrowUp")
      ) {
        // Alt/Cmd + Up = PageUp
        e.preventDefault();
        setCurrent(0);
      } else if (
        key === "End" ||
        key === "PageDown" ||
        ((altKey || metaKey) && key === "ArrowDown")
      ) {
        // Alt/Cmd + Down = PageDown
        e.preventDefault();
        setCurrent(options.length - 1);
      } else if (key === "ArrowUp") {
        e.preventDefault();
        // Circular list: when you reach the top continue from the bottom
        if (current === null || current <= 0) setCurrent(options.length - 1);
        else setCurrent(current - 1);
      } else if (key === "ArrowDown") {
        e.preventDefault();
        // Circular list: when you reach the bottom continue from the top
        if (current === null || current >= options.length - 1) setCurrent(0);
        else setCurrent(current + 1);
      } else if (key === " ") {
        // Space bar
        e.preventDefault();
        if (current !== null) {
          const { action, block, accentColor } = options[current];
          if (action) {
            selectAction(action);
          } else {
            onBlockSelected(block, accentColor);
          }
        }
      }
    },
    [current, options, selectAction, onBlockSelected]
  );

  useEffect(() => {
    // Don't attach event listeners when dialog or parent are not visible
    if (parentHidden || dialogHidden) return;
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [parentHidden, dialogHidden, handleKeyDown]);

  // Reset scroll and current element when the dialog is hidden, but not when
  // the parent is (you want to maintain states)
  useEffect(() => {
    if (dialogHidden) {
      setCurrent(null); // Remove current option
    }
    if (!dialogHidden) {
      // Chrome bug: setting scrollTop on a hidden component doesn't work,
      // so I have to set it right when the component becomes visible
      if (ref.current) ref.current.scrollTop = 0;
    }
  }, [dialogHidden]);

  // When the current option changes, scroll the options list if
  // the option is not completely visible
  useEffect(() => {
    if (!ref.current || current === null) return;
    const currentElem = ref.current.querySelector(`[data-index="${current}"]`);
    scrollIntoViewIfNeeded(currentElem, ref.current);
  }, [current]);

  const getOptionId = useCallback(
    (index) => (index === null ? null : `${idPrefix}-${index}`),
    [idPrefix]
  );

  const handleItemClick = useCallback(
    (block, action) => {
      // Only one of the 2 parameters has a value when this handler is called
      if (action) selectAction(action);
      if (block) onBlockSelected(block);
    },
    [selectAction, onBlockSelected]
  );

  // Show nothing until the list has been calculated to either be empty or have items
  if (options === null) return null;

  if (options.length === 0) {
    return (
      <div className={clsx(styles.list, styles.empty)}>
        <EmptyState>{emptyMessage}</EmptyState>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div
        ref={ref}
        className={styles.list}
        role="listbox"
        aria-multiselectable={true}
        aria-activedescendant={getOptionId(current)}
        tabIndex={0}
      >
        {itemsAndData.map(
          ({ category, block, action, accentColor, optionIndex }) => {
            if (category) {
              return (
                <Heading
                  key={category.id}
                  className={styles.category}
                  level={HEADING_LEVEL_5}
                  data-category-color={accentColor}
                  tag="div"
                >
                  {category.name}
                </Heading>
              );
            }

            return (
              <React.Fragment key={block?.id || action?.id}>
                <div
                  className={clsx(
                    styles.item,
                    block && styles.block,
                    action && styles.action,
                    optionIndex !== null &&
                      optionIndex === current &&
                      styles.current
                  )}
                  data-category-color={accentColor}
                  data-index={optionIndex}
                  onMouseMove={() => setCurrent(optionIndex)}
                  onClick={() => handleItemClick(block, action)}
                  role="option"
                  aria-selected={optionIndex === current}
                  // Id needed for the aria-activedescendant attribute
                  id={getOptionId(optionIndex)}
                >
                  <IconStar
                    className={clsx(
                      styles.star,
                      (action || block)?.starred && styles.starActive
                    )}
                    role="presentation"
                  />
                  <span className={styles.label}>
                    {block ? block.result : replaceMentions(action.description)}
                  </span>

                  {block && (
                    <IconNext className={styles.next} role="presentation" />
                  )}

                  {action?.duration && (
                    <span
                      className={styles.time}
                      aria-label={timeToText(action.duration).long}
                    >
                      {timeToText(action.duration).dropdown}
                    </span>
                  )}

                  {action && (
                    <IconCheck
                      className={clsx(
                        styles.tick,
                        selectedActions.includes(action) && styles.selected
                      )}
                      role="presentation"
                    />
                  )}
                </div>
                <Divider className={styles.divider} />
              </React.Fragment>
            );
          }
        )}
      </div>
      <span className={styles.focusRing} aria-hidden="true" />
    </div>
  );
}

export default NewEventDialogList;
