import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { addDays, addHours, addWeeks, isBefore, startOfWeek } from "date-fns";
import semverCoerce from "semver/functions/coerce";
import semverSort from "semver/functions/sort";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "debounce";
import { mapById } from "src/utils/mapById";
import {
  ACTIVE,
  SNOOZED,
  COMPLETED,
  CAPTURE_LIST_LABEL,
  UNCATEGORIZED_ID,
  ENABLE_FIRESTORE_PERSISTENCE,
  BLOCK,
  CATEGORY,
  CAPTURE_LIST,
  PROJECT,
  SNOOZED_TO_SOMETIME,
  SNOOZED_TO_WEEK,
  SNOOZED_TO_MONTH,
  SNOOZED_TO_WEEKS_MAP,
  THIS_WEEK,
  ACTION,
} from "./constants";
import { Category } from "./categories";
import { Block } from "./blocks";
import { Action, updateAction, actionHasEvents } from "./actions";
import { updateEventScheduleFromActionId } from "./events";

export class ParameterError extends Error {
  constructor(obj, message) {
    // obj is meant to be a quick way to pass both param name and value, if
    // you use it this way: new ParameterError({param}, 'error!');
    const [paramName, paramValue] = Object.entries(obj)[0];
    super(
      `Error on parameter \`${paramName}\` with value \`${paramValue}\` (${typeof paramValue}): ${message}`
    );
  }
}

export const firestore = firebase.firestore;

export const getUserUid = () => firebase.auth().currentUser.uid;

// Gets a document or collection providing the relative path from the user
export const document = (path) =>
  firestore().doc(`/users/${getUserUid()}/${path || ""}`);
export const collection = (path) =>
  firestore().collection(`/users/${getUserUid()}/${path || ""}`);

// iOS uses uppercase whereas this generator uses lowercase
export const generateId = () => uuidv4().toUpperCase();

/**
 * Returns a function that calls all the unsubscribers passed as parameters.
 * Arrays of unsubscribers are supported too.
 */
export function composeUnsubscribers(...unsubscribers) {
  unsubscribers = unsubscribers.flat();
  return () => {
    for (const unsubscribe of unsubscribers) unsubscribe();
  };
}

export function getSortedDocuments(documentsById, order) {
  const sortedDocuments = [];
  for (const id of order) {
    const document = documentsById[id];
    // Sometimes order contains an id that is not present in documentsById.
    // This happens for example when a new document is added and the new
    // order with the new id arrives before the actual new document.
    // Since the documents and the order are collected using two separate
    // observables these race conditions can happen.
    // I simply skip these instances, they will be automatically fixed
    // when all the data is collected from all the observable.
    if (document !== undefined) sortedDocuments.push(document);
  }
  return sortedDocuments;
}

export class CacheDelayer {
  delayIfCached(fn, snapshot) {
    // When you call `onSnapshot()`, Firestore immediately sends to the callback
    // the data it has in cache, so you have already some data while you wait the
    // full updated payload from the server. But when persistence is disabled,
    // the data in the cache is very little. This was creating a bug on paginated
    // lists: there's a list with 25 elements, the user clicks "Load More", the
    // list goes to something like 3 docs (the cached ones), and then eventually
    // goes to 50 when the server replies. This flickers the view and messes up
    // the page scroll. To fix this, the cached docs are delayed on paginated
    // lists when persistence is disabled. We tried to discard the cache data
    // entirely in those instances, but when lists have just a few docs it happens
    // that Firestore uses only the cache without never calling the server.
    clearTimeout(this.timeout);
    if (!ENABLE_FIRESTORE_PERSISTENCE && snapshot.metadata.fromCache) {
      this.timeout = setTimeout(fn, 500);
    } else {
      fn();
    }
  }

  dispose() {
    // It's important to cancel pending calls if the parent observer is released
    clearTimeout(this.timeout);
  }
}

export function getParentInfo(parentId, parentType) {
  let parentDocument,
    parentIdField,
    orderField = "active_children_ordered_ids";
  switch (parentType) {
    case THIS_WEEK:
      parentDocument = document();
      parentIdField = undefined;
      orderField = "this_week_ordered_ids";
      break;
    case CAPTURE_LIST:
      parentDocument = document(`categories/${UNCATEGORIZED_ID}`);
      parentIdField = "parent_category_id";
      orderField = "capture_list_ordered_ids";
      break;
    case CATEGORY:
      parentDocument = document(`categories/${parentId}`);
      parentIdField = "parent_category_id";
      break;
    case PROJECT:
      parentDocument = document(`projects/${parentId}`);
      parentIdField = "parent_project_id";
      break;
    case BLOCK:
      parentDocument = document(`blocks/${parentId}`);
      parentIdField = "parent_block_id";
      break;
    default:
      throw new ParameterError(
        { parentType },
        `must be one of "${CAPTURE_LIST}", ` +
          `"${CATEGORY}", "${PROJECT}" or ` +
          `"${BLOCK}"`
      );
  }
  return { parentDocument, parentIdField, orderField };
}

export function getActionParentInfo(parentCategoryId, parentBlockId) {
  let parentType;
  if (parentCategoryId === UNCATEGORIZED_ID && parentBlockId === null) {
    parentType = CAPTURE_LIST;
  } else {
    parentType = parentBlockId ? BLOCK : CATEGORY;
  }
  const parentId = parentType === BLOCK ? parentBlockId : parentCategoryId;

  return {
    parentId,
    parentType,
    ...getParentInfo(parentId, parentType),
  };
}

export const calculateSnoozedToDate = (snoozedToString, formatToISO) => {
  const snoozedToWeeks = SNOOZED_TO_WEEKS_MAP[snoozedToString];

  if (snoozedToString === SNOOZED_TO_SOMETIME) return snoozedToWeeks;

  const thisSunday = startOfWeek(new Date(), {
    // startOfWeek is localised by default, this sets the first day to Sunday
    // for everyone as per the requirements of the platform
    weekStartsOn: 0,
  });

  // startOfWeek returns the start of this week, so 1 is added to the number
  // of weeks to add to get offset from the next Sunday.
  let snoozedToDate = addWeeks(thisSunday, snoozedToWeeks + 1);
  // Sets the time to be 7AM on that Sunday
  snoozedToDate = addHours(snoozedToDate, 7);

  if (formatToISO) {
    snoozedToDate = snoozedToDate.toISOString();
  }

  return snoozedToDate;
};

function isBeforeSnoozed(date, snoozedToString) {
  return isBefore(date, addDays(calculateSnoozedToDate(snoozedToString), 1));
}

export function calculateSnoozedToString(date) {
  let string = SNOOZED_TO_SOMETIME;

  if (date === null) {
    // To ensure that null isn't parsed as a date, this condition
    // is required. null is actually equal to SNOOZED_TO_SOMETIME
    // here, but that is already set as a catch all so nothing is
    // needed to be set for date === null.
  } else if (isBeforeSnoozed(date, SNOOZED_TO_WEEK)) {
    string = SNOOZED_TO_WEEK;
  } else if (isBeforeSnoozed(date, SNOOZED_TO_MONTH)) {
    string = SNOOZED_TO_MONTH;
  }

  return string;
}

export function watchQuickCaptureOptions(callback) {
  let categories, activeBlocksById, snoozedBlocksById;

  function handleChange() {
    if (
      categories === undefined ||
      activeBlocksById === undefined ||
      snoozedBlocksById === undefined
    )
      return;

    const optionsGroupsUnordered = [];

    const blocksById = {
      ...activeBlocksById,
      ...snoozedBlocksById,
    };

    // Historically each category had its ordered list stored for snoozed blocks.
    // This creates a map of unordered snoozed blocks to be consumed in the same way.
    const snoozedBlocksIdsByCategory = {};

    Object.entries(snoozedBlocksById).forEach(([testId, snoozedBlock]) => {
      if (!snoozedBlocksIdsByCategory[snoozedBlock.categoryId]) {
        snoozedBlocksIdsByCategory[snoozedBlock.categoryId] = [];
      }
      snoozedBlocksIdsByCategory[snoozedBlock.categoryId].push(testId);
    });

    const optionsMap = {
      [UNCATEGORIZED_ID]: {
        name: CAPTURE_LIST_LABEL,
        type: "capture",
      },
    };

    categories.forEach((category) => {
      const options = [];

      const { id: categoryId, name, orderedBlocksIds, color, state } = category;

      // Remove hidden categories from Capture Options
      if (state === "hidden") return;

      // Set removes duplicates that are found for some legacy users
      const blocksList = new Set([
        ...orderedBlocksIds[ACTIVE],
        ...(snoozedBlocksIdsByCategory[categoryId] || []),
      ]);

      [...blocksList].forEach((blockId) => {
        const block = blocksById[blockId];

        if (!block) return;

        options.push({
          name: block.result,
          id: blockId,
          state: block.state,
        });

        optionsMap[blockId] = {
          name: block.result,
          type: "block",
          parentId: categoryId,
          color,
          state: block.state,
        };
      });

      if (!options.length && categoryId === UNCATEGORIZED_ID) return;

      if (categoryId !== UNCATEGORIZED_ID) {
        optionsMap[categoryId] = {
          name,
          type: "category",
          color,
        };
      }

      optionsGroupsUnordered.push({
        id: categoryId,
        name,
        color,
        options,
      });
    });

    // Ensures that UNCATEGORIZED_ID is always the last item, with the rest alphabetised, but keeps
    // the ordered_ids (order) for future implementations
    const optionsGroups = optionsGroupsUnordered.sort((a, b) => {
      if (a.id === b.id) return 0;
      if (a.id === UNCATEGORIZED_ID) return 1;
      if (b.id === UNCATEGORIZED_ID) return -1;
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });

    callback({
      optionsGroups,
      optionsMap,
    });
  }

  return composeUnsubscribers(
    collection("categories").onSnapshot((snapshot) => {
      categories = snapshot.docs.map(Category.fromFirestore);
      handleChange();
    }),

    collection("blocks")
      .where("state", "==", ACTIVE)
      .onSnapshot((snapshot) => {
        const blocks = snapshot.docs.map(Block.fromFirestore);
        activeBlocksById = mapById(blocks);
        handleChange();
      }),

    collection("blocks")
      .where("state", "==", SNOOZED)
      .onSnapshot((snapshot) => {
        const blocks = snapshot.docs.map(Block.fromFirestore);
        snoozedBlocksById = mapById(blocks);
        handleChange();
      })
  );
}

export function watchUserCounters(startDate, callback) {
  // This watcher is very expensive and should be used only for short periods
  // of time. Remember that Firestore downloads all the documents in the
  // resultset of a query, and you are billed for a read operation for each one
  // of them. This is why this function can't be used to compute the all-time
  // counters.

  const startDateISOString = startDate.toISOString();
  let blocksStats, actionsStats;

  function handleChange() {
    // Wait all the counters before calling callback
    if (blocksStats === undefined || actionsStats === undefined) return;
    // Ensure to return a new object every time so React knows that the state
    // has changed
    callback({
      ...blocksStats,
      ...actionsStats,
    });
  }

  return composeUnsubscribers(
    // For these 2 watchers we could add the condition "state == COMPLETED"
    // to add some robustness, but adding it requires creating a custom index on
    // Firestore, and since we are just computing analytics we decided to avoid
    // adding this overhead

    collection("blocks")
      .where("date_of_completion", ">=", startDateISOString)
      .onSnapshot((snapshot) => {
        blocksStats = { blocksCompleted: snapshot.size };
        handleChange();
      }),

    collection("actions")
      .where("date_of_completion", ">=", startDateISOString)
      .onSnapshot((snapshot) => {
        actionsStats = {
          actionsCompleted: snapshot.size,
          actionsCompletedStarred: 0,
          actionsCompletedLeveraged: 0,
        };
        snapshot.docs.forEach((docSnapshot) => {
          const { starred, leveragedPersonId } =
            Action.fromFirestore(docSnapshot);
          if (starred) actionsStats.actionsCompletedStarred++;
          if (leveragedPersonId) actionsStats.actionsCompletedLeveraged++;
        });
        handleChange();
      })
  );
}

export function watchUserAllTimeCounters(callback) {
  // TODO: create a cloud function that regularly checks these counters and
  // rebuilds them if necessary. We could check for example if these all-time
  // counters are always >= of the corresponding last-month counters. If not
  // there is a clear consistency error, and the counters should be rebuilt.
  // Running this function on the client is dangerous because it would download
  // all the completed actions, that can be many thousands.
  return document().onSnapshot((snapshot) => {
    const counters = snapshot.get("counters");
    callback({
      blocksCompleted: counters?.blocks_completed || 0,
      actionsCompleted: counters?.actions_completed || 0,
      actionsCompletedStarred: counters?.actions_completed_starred || 0,
      actionsCompletedLeveraged: counters?.actions_completed_leveraged || 0,
    });
  });
}

export function watchCaptureListCount(callback) {
  return (
    collection("actions")
      .where("state", "==", ACTIVE)
      .where("parent_category_id", "==", UNCATEGORIZED_ID)
      // Uncategorized blocks should be ignored when finding Capture List actions
      .where("parent_block_id", "==", null)
      .onSnapshot((snapshot) => {
        callback(snapshot.size);
      })
  );
}

export function watchStoreVersions(onVersionsListChange) {
  return firestore()
    .collection("versions")
    .onSnapshot((snapshot) => {
      const versions = snapshot.docs.map((snapshotInstance) =>
        semverCoerce(snapshotInstance.id)
      );
      const [latestVersion] = semverSort(versions).reverse();
      onVersionsListChange(latestVersion);
    });
}

/**
 * Sorts the items using the order list, being reistant to missing or extra ids.
 * When an id is missing in the order list, the item is appended to the bottom
 * of the list. Extra ids are simply ignored.
 * @param {Array} items Array of the items to be sorted
 * @param {Array} order Array of the ids of the items in the correct order
 * @returns {Array} Array of the times in the correct order
 */
export function robustSort(items, order) {
  const itemsById = mapById(items);
  const list = [];
  for (const id of order) {
    const item = itemsById[id];
    if (!item) continue; // Ignore ids preseent in order but not in items
    list.push(item);
    delete itemsById[id];
  }
  // The items left in the map are the ones not present in the order list, let's
  // append them to the bottom of the list
  list.push(...Object.values(itemsById));
  return list;
}

export function watchActiveChildren(parentId, parentType, callback) {
  const { parentDocument, parentIdField } = getParentInfo(parentId, parentType);

  let order, blocks, actions;

  // The function is debounced to avoid flickerings in the interface when
  // handleChange is called several times because actions, blocks and order
  // have all changed (for example after a drag and drop)
  const handleChange = debounce(() => {
    if (!order || !blocks || !actions) return;
    const list = robustSort([...blocks, ...actions], order);
    callback(list);
  }, 100);

  const unsubscribers = [
    parentDocument.onSnapshot((docSnapshot) => {
      if (docSnapshot.data()) {
        order = getActiveChildrenOrder(docSnapshot.data(), parentType);
        handleChange();
      }
    }),
  ];

  if (parentId === UNCATEGORIZED_ID && parentType === CATEGORY) {
    // When watching the Uncategorized category the actions must be excluded,
    // because they are the capture list, so here just prentend the category has
    // no actions setting actionsById to an empty map without making any query.
    actions = [];
  } else {
    let actionsQuery = collection(`actions`).where("state", "==", ACTIVE);

    // In the case of parentType = CAPTURE_LIST, we will not filter by the parentId,
    // since we need to fetch data from all categories and uncategorized
    if (![THIS_WEEK, CAPTURE_LIST].includes(parentType)) {
      actionsQuery = actionsQuery.where(parentIdField, "==", parentId);
    }

    unsubscribers.push(
      actionsQuery.onSnapshot((snap) => {
        actions = snap.docs.map(Action.fromFirestore);
        if (parentType === THIS_WEEK) {
          // Filter out actions in the capture list, that can't be excluded
          // from the query
          actions = actions.filter(
            (action) =>
              action.categoryId !== UNCATEGORIZED_ID && !!action.blockId
          );
        }
        handleChange();
      })
    );
  }

  if ([BLOCK, THIS_WEEK, CAPTURE_LIST].includes(parentType)) {
    // Blocks can't be children so they'll not be fetched, so set blocksById to
    // an empty map and let handleChange() work as the blocks have been fetched
    // but there are none of them.

    // In the case of the THIS_WEEK parent, only valid actions are filtered and
    // then mapped back to blocks. Thus, we don't need to fetch the blocks.
    blocks = [];
  } else {
    let blocksQuery = collection(`blocks`)
      .where("state", "==", ACTIVE)
      .where(parentIdField, "==", parentId);
    unsubscribers.push(
      blocksQuery.onSnapshot((snap) => {
        blocks = snap.docs.map(Block.fromFirestore);
        handleChange();
      })
    );
  }

  return composeUnsubscribers(unsubscribers);
}

export function watchActiveBlocksMap(callback) {
  let blocks;

  // The function is debounced to avoid flickerings in the interface when
  // handleChange is called several times because actions, blocks and order
  // have all changed (for example after a drag and drop)
  const handleChange = () => {
    if (!blocks) {
      // since the rendering of the ActionItem in the ChildrenList depends on
      // a non null blocksMap, we must return an empty map when there are no blocks
      callback({});
      return;
    }

    callback(mapById(blocks));
  };

  let blocksQuery = collection(`blocks`).where("state", "==", ACTIVE);

  const unsubscriber = blocksQuery.onSnapshot((snap) => {
    blocks = snap.docs.map(Block.fromFirestore);
    handleChange();
  });

  return unsubscriber;
}

export function watchSnoozedChildren(
  parentId,
  parentType,
  snoozeSection,
  callback
) {
  const { parentIdField } = getParentInfo(parentId, parentType);

  let blocks = null;
  let actions = null;

  function handleChange() {
    if (!blocks || !actions) return;
    const children = [...blocks, ...actions];
    const filteredChildren = [];
    for (const child of children) {
      // In case we have an action with a past date snoozedTo, it means that it
      // has been expired and the state must be be updated to ACTIVE again, also
      // cleaning snoozedTo and any events created
      if (
        child.snoozedTo &&
        child.snoozedTo < new Date() &&
        child.__type === ACTION
      ) {
        actionHasEvents(child.id).then(async (hasEvents) => {
          if (hasEvents) {
            updateEventScheduleFromActionId(child.id);
          }
          updateAction(child, {
            state: ACTIVE,
            snoozedTo: null,
          });
        });
      }

      // Returns all children when snoozeSection is undefined
      const childSnoozeSection =
        snoozeSection && calculateSnoozedToString(child.snoozedTo);
      if (childSnoozeSection !== snoozeSection) continue;
      filteredChildren.push(child);
    }

    filteredChildren.sort((a, b) => {
      // Actions with a "null" snoozedTo are meant to be Snoozed indefinitely,
      // we set a very future date as defined in ECMA-262 to sort them to the end:
      // https://262.ecma-international.org/5.1/#sec-15.9.1.1
      const snoozedToCompare =
        (a.snoozedTo || new Date(864e13)) - (b.snoozedTo || new Date(864e13));
      const labelCompare = (a.result || a.description).localeCompare(
        b.result || b.description
      );
      return snoozedToCompare || labelCompare;
    });

    callback(filteredChildren);
  }

  const unsubscribers = [];

  let actionsQuery = collection(`actions`).where("state", "==", SNOOZED);
  let blocksQuery = collection(`blocks`).where("state", "==", SNOOZED);

  // In the case of parentType = CAPTURE_LIST, we will not filter by the parentId,
  // since we need to fetch data from all categories and uncategorized
  if (![CAPTURE_LIST, THIS_WEEK].includes(parentType)) {
    actionsQuery = actionsQuery.where(parentIdField, "==", parentId);
    blocksQuery = blocksQuery.where(parentIdField, "==", parentId);
  }

  unsubscribers.push(
    actionsQuery.onSnapshot((snap) => {
      actions = snap.docs.map(Action.fromFirestore);
      handleChange();
    })
  );

  unsubscribers.push(
    blocksQuery.onSnapshot((snap) => {
      blocks = snap.docs.map(Block.fromFirestore);
      handleChange();
    })
  );

  return composeUnsubscribers(unsubscribers);
}

export function watchHasCompletedChildren(parentId, parentType, callback) {
  const { parentIdField } = getParentInfo(parentId, parentType);

  let hasCompletedBlocks = null;
  let hasCompletedActions = null;

  function handleChange() {
    if (hasCompletedBlocks === null || hasCompletedActions === null) return;
    callback(hasCompletedBlocks || hasCompletedActions);
  }

  return composeUnsubscribers(
    collection(`blocks`)
      .where("state", "==", COMPLETED)
      .where(parentIdField, "==", parentId)
      .limit(1)
      .onSnapshot((snap) => {
        hasCompletedBlocks = snap.size > 0;
        handleChange();
      }),
    collection(`actions`)
      .where("state", "==", COMPLETED)
      .where(parentIdField, "==", parentId)
      .where("parent_block_id", "==", null) // Exclude actions nested in blocks
      .onSnapshot((snap) => {
        hasCompletedActions = snap.size > 0;
        handleChange();
      })
  );
}

export function setActiveChildrenOrder(parentId, parentType, newOrderedIds) {
  if (!Array.isArray(newOrderedIds))
    throw new ParameterError({ newOrderedIds }, "not an array");

  const { parentDocument, orderField } = getParentInfo(parentId, parentType);

  parentDocument.update({ [orderField]: newOrderedIds });

  updateLegacyOrder(parentId, parentType);
}

function getActiveChildrenOrder(parent, parentType) {
  let order;
  const {
    this_week_ordered_ids,
    capture_list_ordered_ids,
    active_children_ordered_ids,
    blocks_lists,
    actions_lists,
  } = parent;
  if (parentType === THIS_WEEK) {
    order = this_week_ordered_ids || [];
  } else if (parentType === CAPTURE_LIST) {
    if (capture_list_ordered_ids) {
      order = capture_list_ordered_ids;
    } else {
      order = [
        ...(actions_lists?.active?.starred?.ordered_ids || []),
        ...(actions_lists?.active?.unstarred?.ordered_ids || []),
      ];
    }
  } else {
    if (active_children_ordered_ids) {
      order = active_children_ordered_ids;
    } else {
      order = [
        ...(blocks_lists?.active?.ordered_ids || []),
        ...(blocks_lists?.active?.ordered_ids || []),
        ...(actions_lists?.active?.starred?.ordered_ids || []),
        ...(actions_lists?.active?.unstarred?.ordered_ids || []),
      ];
    }
  }
  return order;
}

export async function updateLegacyOrder(parentId, parentType) {
  const { parentDocument, parentIdField } = getParentInfo(parentId, parentType);

  if (
    parentType === THIS_WEEK ||
    parentType === PROJECT ||
    parentType === CAPTURE_LIST
  )
    return;

  const parent = (await parentDocument.get()).data();
  const orderedChildrenIds = getActiveChildrenOrder(parent, parentType);

  if (parentType === CATEGORY) {
    const blocksSnap = await collection("blocks")
      .where("state", "==", ACTIVE)
      .where(parentIdField, "==", parentId)
      .get();
    const childrenBlockIds = blocksSnap.docs.map((doc) => doc.id);
    const childrenBlocksById = mapById(blocksSnap.docs);
    const orderedBlocksIds = orderedChildrenIds.filter((id) => {
      if (childrenBlockIds[id]) {
        delete childrenBlocksById[id];
        return true;
      }
      return false;
    });
    // Make sure to add any block that was not present in the ordering list
    orderedBlocksIds.push(...Object.keys(childrenBlocksById));

    parentDocument.update({
      "blocks_lists.active.ordered_ids": orderedBlocksIds,
    });
  }

  let actionsQuery = collection("actions")
    .where("state", "==", ACTIVE)
    .where(parentIdField, "==", parentId);
  if (parentType !== BLOCK) {
    actionsQuery = actionsQuery.where("parent_block_id", "==", null);
  }
  const actionsSnap = await actionsQuery.get();
  const childrenActions = actionsSnap.docs.map(Action.fromFirestore);
  const childrenActionsById = mapById(childrenActions);
  const orderedStarredActionsIds = orderedChildrenIds.filter((id) => {
    if (childrenActionsById[id]?.starred) {
      delete childrenActionsById[id];
      return true;
    }
    return false;
  });
  const orderedUnstarredActionsIds = orderedChildrenIds.filter((id) => {
    if (childrenActionsById[id] && !childrenActionsById[id].starred) {
      delete childrenActionsById[id];
      return true;
    }
    return false;
  });
  // Make sure to add any action that was not present in the ordering list
  for (const action of Object.values(childrenActionsById)) {
    const destinationList = action.starred
      ? orderedStarredActionsIds
      : orderedUnstarredActionsIds;
    destinationList.push(action.id);
  }
  parentDocument.update({
    "actions_lists.active.starred.ordered_ids": orderedStarredActionsIds,
    "actions_lists.active.unstarred.ordered_ids": orderedUnstarredActionsIds,
  });
}
