import React, { useRef, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { PRODUCT_NAME } from "src/constants";

import { checkAccess, InvalidEmailError } from "../../services/AuthService";
import { NetworkError } from "../../services/ApiService";

import { SIGN_UP_URL, SIGN_IN_URL, SUBSCRIBE_URL } from "../App";

import Button, { BUTTON_COLOR_FILL } from "../Button";
import Head from "../Head";
import Input from "../Input";
import { YouTubeVideoEmbed } from "../VideoEmbed/VideoEmbed";

import { ReactComponent as Logo } from "../../assets/logo.svg";

import AuthForm from "./AuthForm";

import styles from "./Access.module.scss";

function Access() {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const emailRef = useRef();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!email) {
        setError("Email is required");
        emailRef.current.focus();
        return;
      }

      setLoading(true);

      let accessResponse;

      try {
        accessResponse = await checkAccess(email);
      } catch (e) {
        setLoading(false);
        if (e instanceof NetworkError) {
          setError(
            "Network error, please check your internet connection and try\xa0again"
          );
        } else if (e instanceof InvalidEmailError) {
          setError("Invalid email");
        } else {
          setError("Unexpected error, please try again");
          console.error(e);
        }
        // Move the focus on email so the user can make corrections or just
        // press Enter to try again
        emailRef.current.focus();
        return;
      }

      setLoading(false);

      if (!accessResponse.allowed) {
        history.push(SUBSCRIBE_URL, { email });
        return;
      }

      if (!accessResponse.isRegistered) {
        history.push(SIGN_UP_URL, { email });
        return;
      }

      history.push(SIGN_IN_URL, { prev: "access", email });
    },
    [history, email]
  );

  return (
    <main className={styles.accessPage}>
      <Head title="Access" />

      <div className={styles.wrapper}>
        <div className={styles.columns}>
          <div className={styles.authFormWrapper}>
            <h1>
              <span className="sr-only">{PRODUCT_NAME}</span>
              <Logo role="presentation" className={styles.logo} />
            </h1>

            <p className={styles.text}>
              We’re currently invite only, please enter your email to check if
              you have early&nbsp;access.
            </p>
            <AuthForm
              error={error}
              loading={loading}
              buttons={
                <Button
                  type="submit"
                  loading={loading}
                  onClick={onSubmit}
                  color={BUTTON_COLOR_FILL}
                  block
                >
                  Next
                </Button>
              }
            >
              <Input
                label="Your email"
                type="email"
                autoFocus
                autoComplete="email"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </AuthForm>
          </div>
          <div className={styles.videoWrapper}>
            <YouTubeVideoEmbed
              videoId="EHSzsBhnXH0"
              title="Welcome to RPM"
              width="600"
              height="400"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Access;
