import React, { useState, useCallback } from "react";
import clsx from "clsx";

import { NBSP } from "src/constants";

import Heading, { HEADING_LEVEL_5 } from "../Heading";
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from "../SmartLink";

import styles from "./DetailPanel.module.scss";

const CONCAT_DESCRIPTION_LENGTH = 320;

export function DetailPanelFields({ fields, setEditing, values }) {
  const [expandedFields, setExpandedFields] = useState({});

  const handleFieldExpandToggle = useCallback((slug) => {
    setExpandedFields((currentExpandedFields) => ({
      ...currentExpandedFields,
      [slug]: !currentExpandedFields[slug],
    }));
  }, []);

  return (
    <div className={clsx(styles.group, styles.fields)}>
      {fields &&
        fields.map(({ slug, label }) => {
          const fieldText = values[slug] || "";

          return (
            <React.Fragment key={slug}>
              <Heading level={HEADING_LEVEL_5} tag="h2">
                {label}
              </Heading>

              {!fieldText && (
                <SmartLink
                  className={styles.description}
                  onClick={() => setEditing(slug)}
                  linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                >
                  Add value…
                </SmartLink>
              )}

              {fieldText && (
                <p className={styles.description}>
                  {fieldText.length <= CONCAT_DESCRIPTION_LENGTH ||
                  expandedFields[slug]
                    ? `${fieldText}${NBSP}`
                    : `${fieldText.substr(
                        0,
                        CONCAT_DESCRIPTION_LENGTH
                      )}…${NBSP}`}
                  {fieldText.length > CONCAT_DESCRIPTION_LENGTH && (
                    <SmartLink onClick={() => handleFieldExpandToggle(slug)}>
                      Show&nbsp;{expandedFields[slug] ? "Less" : "More"}
                    </SmartLink>
                  )}
                </p>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default DetailPanelFields;
