import React, { useMemo } from "react";
import sanitizeHtml from "sanitize-html";

import EventDialog from "../EventDialog";
import EmptyState from "../../EmptyState";

import styles from "./ExternalEventDialog.module.scss";

const EXTERNAL_EVENT_DIALOG_HEIGHT = 250;

function ExternalEventDialog({ event, boxRef, onClose, ...props }) {
  const detailsHtml = useMemo(() => {
    if (!event.details) return;

    // event.details is already sanitized and tweaked by the backend, but it's
    // security best practice to not trust any external data, so we run a basic
    // sanitization here too
    return sanitizeHtml(event.details, {
      allowedAttributes: {
        // The default list of allowed attributes doesn't include `rel`, that we
        // need for better security. The backend will set it to "noopener
        // noreferrer", so we allow these values only. "target" will be set
        // always to "_blank", no other values will be allowed.
        a: [
          "href",
          {
            name: "target",
            values: ["_blank"],
          },
          {
            name: "rel",
            multiple: true,
            values: ["noopener", "noreferrer"],
          },
        ],
      },
    });
  }, [event.details]);

  return (
    <EventDialog
      boxRef={boxRef}
      onClose={onClose}
      startDate={event.startDate}
      eventName={event.description}
      dialogHeight={EXTERNAL_EVENT_DIALOG_HEIGHT}
      {...props}
    >
      {event.details && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: detailsHtml }}
        />
      )}

      {!event.details && (
        <EmptyState className={styles.empty}>
          No details for this event.
        </EmptyState>
      )}
    </EventDialog>
  );
}

export default ExternalEventDialog;
