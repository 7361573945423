import React, { useState } from "react";
import { useParams, Link, generatePath } from "react-router-dom";
import clsx from "clsx";

import usePeople from "../../hooks/usePeople";

import { PEOPLE_URL } from "../App";

import Sidebar from "../Sidebar";
import Heading, { HEADING_LEVEL_4 } from "../Heading";
import Avatar from "../Avatar";
import CreateButton from "../CreateButton";
import EmptyState from "../EmptyState";
import CreatePersonDialog from "../CreateDialog/CreatePersonDialog";
import LoadingSpinner from "../LoadingSpinner";
import Tooltip from "../Tooltip";

import { TYPE_LABELS } from "src/services/DbService/constants";

import styles from "./PeopleSidebar.module.scss";

function PeopleSidebar({ className, ...props }) {
  const { personId } = useParams();

  const [dialogVisible, setDialogVisible] = useState(false);
  const { people } = usePeople();

  return (
    <Sidebar className={clsx(className, styles.main)} {...props}>
      {people === null && <LoadingSpinner absolute />}
      {people && (
        <ul className={styles.list}>
          {people.map((person) => (
            <li
              {...props}
              key={person.id}
              className={clsx(
                styles.item,
                personId === person.id && styles.active
              )}
            >
              <Tooltip title={`@${person.nickname}`}>
                <Link
                  to={generatePath(PEOPLE_URL, { personId: person.id })}
                  className={styles.summary}
                >
                  <Avatar
                    className={styles.avatar}
                    name={person.nickname}
                    src={person.image}
                  />
                  <div className={styles.info}>
                    <Heading
                      className={styles.name}
                      tag="span"
                      level={HEADING_LEVEL_4}
                    >
                      @{person.nickname}
                    </Heading>
                  </div>
                </Link>
              </Tooltip>
            </li>
          ))}
        </ul>
      )}

      {people?.length === 0 && (
        <EmptyState>
          Create your first {TYPE_LABELS.person.text}&nbsp;below
        </EmptyState>
      )}

      <CreateButton
        className={styles.createButton}
        onClick={() => setDialogVisible(true)}
        aria-label={`Create New ${TYPE_LABELS.person.title}`}
      />

      {dialogVisible && (
        <CreatePersonDialog onClose={() => setDialogVisible(false)} />
      )}
    </Sidebar>
  );
}

export default PeopleSidebar;
