import React from "react";
import clsx from "clsx";

import Tooltip from "../Tooltip";

import styles from "./CounterBadge.module.scss";

function CounterBadge({ counter = 0 }) {
  if (counter === 0) return null;

  return (
    <Tooltip title={`${counter} pending actions`}>
      <div className={clsx(styles.counterBadge)}>
        <span
          className={styles.counter}
          role="status"
          aria-live="polite"
          aria-atomic={true}
          aria-label={`${counter} pending actions`}
        >
          {counter}
        </span>
      </div>
    </Tooltip>
  );
}

export default CounterBadge;
