/**
 * Create time increments for select options. Provide a value of 1-60 for minute
 * steps.
 *
 * @export
 * @param {number} [minutesStep=60]
 * @return {*}
 */
export function timeIncrements(minutesStep = 60) {
  const HOURS = 24;
  const MINUTES = 60;
  minutesStep = Math.max(1, Math.min(minutesStep, 60)); // clamp 1-60

  const increments = [];

  for (let h = 0; h < HOURS; h++) {
    for (let m = 0; m < MINUTES; m = m + minutesStep) {
      const min = m < 10 ? `0${m}` : m;
      const hour = h < 10 ? `0${h}` : h;
      let hourStr = h;
      const period = h < 12 ? "AM" : "PM";

      if (h === 0) {
        hourStr = 12;
      } else if (h > 12) {
        hourStr = h - 12;
      }

      const timeValue = `${hour}:${min}`;
      const timeLabel = `${hourStr}:${min} ${period}`;

      increments.push({ value: timeValue, label: timeLabel });
    }
  }

  return increments;
}
