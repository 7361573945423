/**
 * A clamp function that mimics the min, number, max syntax of CSS clamp
 * @param  {Number} min
 * @param  {Number} number
 * @param  {Number} max
 * @returns {Number} number clamped between min and max
 */
export function clamp(min, number, max) {
  if (
    typeof min !== "number" ||
    typeof number !== "number" ||
    typeof max !== "number"
  )
    throw new Error(
      "Invalid parmeter, min, number and max must all be numbers"
    );
  if (min > max)
    throw new Error("Invalid parameter, min must be less than or equal to max");
  return Math.min(Math.max(number, min), max);
}
