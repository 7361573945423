import React from "react";
import { useLocation } from "react-router";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import styles from "./PageTransition.module.scss";

const PageTransition = ({ children }) => {
  const location = useLocation();

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        classNames="slide"
        timeout={+styles.stateTransitionDuration}
        appear
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default PageTransition;
