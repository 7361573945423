import React from "react";
import clsx from "clsx";

import Tooltip from "../Tooltip";

import styles from "./Counters.module.scss";

function Counter({ className, icon: Icon, smallIcon, short, long, ...props }) {
  return (
    <Tooltip title={long}>
      <div
        className={clsx(className, styles.item)}
        aria-label={long}
        {...props}
      >
        {Icon && (
          <Icon
            className={clsx(styles.icon, smallIcon && styles.iconSmall)}
            role="presentation"
          />
        )}
        <span className={styles.label}>{short}</span>
      </div>
    </Tooltip>
  );
}

export default Counter;
