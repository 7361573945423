import React, { useCallback } from "react";
import clsx from "clsx";

import Heading, { HEADING_LEVEL_5 } from "../Heading";

import { ReactComponent as AccordionTriangle } from "./accordion-triangle.svg";

import styles from "./Accordion.module.scss";

function Accordion({ className, children, onToggle, open, heading, ...props }) {
  const handleToggle = useCallback(
    (e) => {
      if (typeof onToggle !== "function") return;

      onToggle(e?.target?.open);
    },
    [onToggle]
  );

  return (
    <details
      className={clsx(styles.details, className)}
      open={open}
      onToggle={handleToggle}
      {...props}
    >
      <summary className={styles.summary}>
        <Heading
          className={styles.summaryHeading}
          tag="h2"
          level={HEADING_LEVEL_5}
        >
          {heading}
          <AccordionTriangle role="presentation" />
        </Heading>
      </summary>
      {children}
    </details>
  );
}

export default Accordion;
