import isElectron from "is-electron";

import { ReactComponent as IconCategories } from "../../assets/icons/16-categories.svg";

import { ReactComponent as IconCategoryApple } from "../../assets/icons/16-category-apple.svg";
import { ReactComponent as IconCategoryAward } from "../../assets/icons/16-category-award.svg";
import { ReactComponent as IconCategoryBaby } from "../../assets/icons/16-category-baby.svg";
import { ReactComponent as IconCategoryBag } from "../../assets/icons/16-category-bag.svg";
// import { ReactComponent as IconCategoryBolt } from '../../assets/icons/16-category-bolt.svg';
import { ReactComponent as IconCategoryBrain } from "../../assets/icons/16-category-brain.svg";
import { ReactComponent as IconCategoryBulb } from "../../assets/icons/16-category-bulb.svg";
import { ReactComponent as IconCategoryCup } from "../../assets/icons/16-category-cup.svg";
import { ReactComponent as IconCategoryDog } from "../../assets/icons/16-category-dog.svg";
import { ReactComponent as IconCategoryFamily } from "../../assets/icons/16-category-family.svg";
import { ReactComponent as IconCategoryFlag } from "../../assets/icons/16-category-flag.svg";
import { ReactComponent as IconCategoryFlame } from "../../assets/icons/16-category-flame.svg";
import { ReactComponent as IconCategoryFlower } from "../../assets/icons/16-category-flower.svg";
import { ReactComponent as IconCategoryFriends } from "../../assets/icons/16-category-friends.svg";
import { ReactComponent as IconCategoryGoal } from "../../assets/icons/16-category-goal.svg";
import { ReactComponent as IconCategoryGym } from "../../assets/icons/16-category-gym.svg";
import { ReactComponent as IconCategoryHeart } from "../../assets/icons/16-category-heart.svg";
import { ReactComponent as IconCategoryLotus } from "../../assets/icons/16-category-lotus.svg";
// import { ReactComponent as IconCategoryLove } from '../../assets/icons/16-category-love.svg';
import { ReactComponent as IconCategoryMasks } from "../../assets/icons/16-category-masks.svg";
import { ReactComponent as IconCategoryMountain } from "../../assets/icons/16-category-mountain.svg";
import { ReactComponent as IconCategoryPath } from "../../assets/icons/16-category-path.svg";
import { ReactComponent as IconCategoryRun } from "../../assets/icons/16-category-run.svg";
// import { ReactComponent as IconCategorySea } from '../../assets/icons/16-category-sea.svg';
import { ReactComponent as IconCategorySmile } from "../../assets/icons/16-category-smile.svg";
import { ReactComponent as IconCategorySpeed } from "../../assets/icons/16-category-speed.svg";

// Firestore persistance should be enabled only on trusted
// devices, because it stores sensible data lasting even
// after the usage session.
// Better to enable persistance only in Electron and not on
// the web, since the web version is not even PWA enabled.
export const ENABLE_FIRESTORE_PERSISTENCE = isElectron();
export const PAGE_SIZE = 25;

export const ACTION = "action";
export const BLOCK = "block";
export const RESULT = "result";
export const CATEGORY = "category";
export const PROJECT = "project";
export const PERSON = "person";
export const CAPTURE_LIST = "capture_list";
export const THIS_WEEK = "this_week";
export const MENTIONED = "mentioned";
export const LEVERAGED = "leveraged";

export const TYPE_LABELS = {
  [CATEGORY]: {
    title: "Category",
    titlePlural: "Categories",
    text: "category",
    textPlural: "categories",
  },
  [PROJECT]: {
    title: "Project",
    titlePlural: "Projects",
    text: "project",
    textPlural: "projects",
  },
  [ACTION]: {
    title: "Action",
    titlePlural: "Actions",
    text: "action",
    textPlural: "actions",
  },
  [BLOCK]: {
    title: "Block",
    titlePlural: "Blocks",
    text: "block",
    textPlural: "blocks",
  },
  [RESULT]: {
    title: "Result",
    titlePlural: "Results",
    text: "result",
    textPlural: "results",
  },
  [PERSON]: {
    title: "Contact",
    titlePlural: "Contacts",
    text: "contact",
    textPlural: "contacts",
  },
};

export const ITEM_LABELS = {
  text: "item",
  textPlural: "items",
};

export const ACTIVE = "active";
export const SNOOZED = "snoozed";
export const SCHEDULED = "scheduled";
export const COMPLETED = "completed";
export const UNNECESSARY = "unnecessary";
export const PAST_DUE = "past-due";
export const UNSCHEDULED = "unscheduled";
export const UNCATEGORIZED = "uncategorized";

export const STATE_LABELS = {
  [ACTIVE]: {
    subheading: "This Week",
    title: "This Week's Results",
    text: "active",
  },
  [SCHEDULED]: {
    subheading: "Scheduled",
    title: "Scheduled",
    text: "scheduled",
  },
  [SNOOZED]: {
    subheading: "Snoozed",
    title: "Snoozed",
    text: "snoozed",
  },
  [COMPLETED]: {
    subheading: "Done",
    title: "Completed",
    text: "completed",
  },
  [PAST_DUE]: {
    subheading: "Past Due",
    title: "Past Due",
    text: "past-due",
  },
  [UNSCHEDULED]: {
    subheading: "Unscheduled",
    title: "Unscheduled",
    text: "unscheduled",
  },
  [UNCATEGORIZED]: {
    subheading: "Uncategorized",
    title: "Uncategorized",
    text: "uncategorized",
  },
};

export const STARRED = "starred";
export const UNSTARRED = "unstarred";

export const THIS_WEEK_TITLE = STATE_LABELS[ACTIVE].title;
export const THIS_WEEK_LABEL = STATE_LABELS[ACTIVE].subheading;
export const CAPTURE_LIST_LABEL = "Action List";

export const ACTION_DESCRIPTION_MAX_LENGTH = 75; // (Value from HRZ-7)

export const BLOCK_RESULT_MAX_LENGTH = 240; // (Value from HRZ-671)
export const BLOCK_PURPOSE_MAX_LENGTH = 240; // (Value from HRZ-671)
export const BLOCK_ROLE_MAX_LENGTH = 30; // (Value from HRZ-19)
export const BLOCK_ACTIONS_COMPLETION_THRESHOLD = 0.7; // Value from (HRZ-625)

export const PERSON_NICKNAME_MAX_LENGTH = 25;
export const PERSON_DESCRIPTION_MAX_LENGTH = 100;

export const PEOPLE_GROUP_NAME_MAX_LENGTH = 25;

export const DETAIL_BACKGROUND_IMAGE_MAX_LENGTH = 1920; // (Value from HRZ-33)
export const DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB = 1; // (Value from HRZ-33)

export const CATEGORY_NAME_MAX_LENGTH = 30;
export const CATEGORY_FIELD_MAX_LENGTH = 2000;
export const CATEGORY_ACTIVE = "active";
export const CATEGORY_HIDDEN = "hidden";

export const ALL_CATEGORY_ID = "all";
export const UNCATEGORIZED_ID = "00000000-0000-0000-0000-000000000000";

export const SNOOZED_TO_SOMETIME = "indefinitely";
export const SNOOZED_TO_WEEK = "week";
export const SNOOZED_TO_MONTH = "month";
export const SNOOZED_UNTIL_DATE = "until_date";

export const SNOOZED_TO_WEEKS_MAP = {
  [SNOOZED_TO_WEEK]: 0,
  [SNOOZED_TO_MONTH]: 4,
  [SNOOZED_UNTIL_DATE]: null,
  [SNOOZED_TO_SOMETIME]: null,
};

export const SNOOZED_LABELS = {
  [SNOOZED_TO_WEEK]: {
    subheading: "Next Week",
    cta: "One Week",
  },
  [SNOOZED_TO_MONTH]: {
    subheading: "Within a\xa0Month",
    cta: "One Month",
  },
  [SNOOZED_UNTIL_DATE]: {
    subheading: "Within Date",
    cta: "Snoozed Until",
  },
  [SNOOZED_TO_SOMETIME]: {
    subheading: "Indefinitely",
    cta: "Indefinitely",
  },
};

export const SNOOZED_LIST_ORDER = [
  SNOOZED_TO_WEEK,
  SNOOZED_TO_MONTH,
  SNOOZED_UNTIL_DATE,
  SNOOZED_TO_SOMETIME,
];

// This comes from the category set by the backend when a user registers and is
// used to make a weak check for the first category created
export const DEFAULT_FIRST_CATEGORY_NAME = "My First Category";

export const CATEGORY_DEFAULT_COLOR = "0";

export const CATEGORY_COLOR_LABELS = {
  0: "Grey",
  1: "Red",
  2: "Orange",
  3: "Umber",
  4: "Green",
  5: "Blue",
  6: "Violet",
  7: "Purple",
  8: "Pink",
};

export const CATEGORY_DEFAULT_ICON = "heart";

export const UNCATEGORIZED_ICON = {
  key: "uncategorized",
  icon: IconCategories,
  label: "Uncategorized",
};

export const CATEGORY_ICONS = {
  apple: {
    icon: IconCategoryApple,
    label: "Apple",
  },
  award: {
    icon: IconCategoryAward,
    label: "Award",
  },
  baby: {
    icon: IconCategoryBaby,
    label: "Baby",
  },
  bag: {
    icon: IconCategoryBag,
    label: "Bag",
  },
  // bolt: {
  //   icon: IconCategoryBolt,
  //   label: 'Bolt',
  // },
  brain: {
    icon: IconCategoryBrain,
    label: "Brain",
  },
  bulb: {
    icon: IconCategoryBulb,
    label: "Bulb",
  },
  cup: {
    icon: IconCategoryCup,
    label: "Cup",
  },
  dog: {
    icon: IconCategoryDog,
    label: "Dog",
  },
  family: {
    icon: IconCategoryFamily,
    label: "Family",
  },
  flag: {
    icon: IconCategoryFlag,
    label: "Flag",
  },
  flame: {
    icon: IconCategoryFlame,
    label: "Flame",
  },
  flower: {
    icon: IconCategoryFlower,
    label: "Flower",
  },
  friends: {
    icon: IconCategoryFriends,
    label: "Friends",
  },
  goal: {
    icon: IconCategoryGoal,
    label: "Goal",
  },
  gym: {
    icon: IconCategoryGym,
    label: "Gym",
  },
  heart: {
    icon: IconCategoryHeart,
    label: "Heart",
  },
  lotus: {
    icon: IconCategoryLotus,
    label: "Lotus",
  },
  // love: {
  //   icon: IconCategoryLove,
  //   label: 'Love',
  // },
  masks: {
    icon: IconCategoryMasks,
    label: "Masks",
  },
  mountain: {
    icon: IconCategoryMountain,
    label: "Mountain",
  },
  path: {
    icon: IconCategoryPath,
    label: "Path",
  },
  run: {
    icon: IconCategoryRun,
    label: "Run",
  },
  // sea: {
  //   icon: IconCategorySea,
  //   label: 'Sea',
  // },
  smile: {
    icon: IconCategorySmile,
    label: "Smile",
  },
  speed: {
    icon: IconCategorySpeed,
    label: "Speed",
  },
};

export const CATEGORY_FIELD_VISION = "vision";
export const CATEGORY_FIELD_PURPOSE = "purpose";
export const CATEGORY_FIELD_ROLES = "roles";
export const CATEGORY_FIELD_THRIVE = "thrive";
export const CATEGORY_FIELD_RESOURCES = "resources";
export const CATEGORY_FIELD_YEAR_RESULTS = "yearResults";

export const CATEGORY_FIELDS = [
  {
    slug: CATEGORY_FIELD_VISION,
    label: "Vision",
    placeholder: "What you ultimately want for this area of your life?",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
  {
    slug: CATEGORY_FIELD_PURPOSE,
    label: "Purpose",
    placeholder: "Why it’s an absolute “must” for you?",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
  {
    slug: CATEGORY_FIELD_ROLES,
    label: "Roles",
    placeholder: "Who you are being in this area of your life?",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
  {
    slug: CATEGORY_FIELD_THRIVE,
    label: "3-to-Thrive",
    placeholder: "Top 3 areas to focus on to have the greatest impact",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
  {
    slug: CATEGORY_FIELD_RESOURCES,
    label: "Resources",
    placeholder:
      "Things that can help you: books, podcasts, people you know, personal resources, etc.",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
  {
    slug: CATEGORY_FIELD_YEAR_RESULTS,
    label: "1 Year/90 Day Results",
    placeholder: "Your top goals or outcomes in this area",
    maxLength: CATEGORY_FIELD_MAX_LENGTH,
  },
];

export const EMPTY_STARRED_ORDERED_IDS = {
  [STARRED]: {
    ordered_ids: [],
  },
  [UNSTARRED]: {
    ordered_ids: [],
  },
};

export const PROJECT_NAME_MAX_LENGTH = 30;
export const PROJECT_FIELD_MAX_LENGTH = 2000;
export const PROJECT_ACTIVE = "active";
export const PROJECT_HIDDEN = "hidden";

export const PROJECT_FIELD_VISION = "vision";
export const PROJECT_FIELD_PURPOSE = "purpose";

export const PROJECT_FIELDS = [
  {
    slug: PROJECT_FIELD_VISION,
    label: "Ultimate Vision",
    placeholder: "What you ultimately want for this area of your life?",
    maxLength: PROJECT_FIELD_MAX_LENGTH,
  },
  {
    slug: PROJECT_FIELD_PURPOSE,
    label: "Ultimate Purpose",
    placeholder: "Why it’s an absolute “must” for you?",
    maxLength: PROJECT_FIELD_MAX_LENGTH,
  },
];

export const PERSON_DELETED_NICKNAME = "a deleted person";
