import React, { useState, useRef, useMemo, useEffect } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import { modifierKeyString } from "src/utils/modifierKey";

import { useTooltipContext } from "./TooltipContext";

import styles from "./Tooltip.module.scss";

function Tooltip({
  children,
  className,
  disabled,
  shortcut,
  shortcutModifier,
  title,
  ...props
}) {
  const { tooltipDelay, onTooltipOpen, onTooltipClose } = useTooltipContext();

  const tooltipRef = useRef();
  const timeoutRef = useRef(null);

  const [rect, setRect] = useState(null);
  const [css, setCss] = useState(null);

  useEffect(() => {
    if (!rect) return;

    let top = rect.top + rect.height;
    let left = rect.left + rect.width / 2 - tooltipRef.current.clientWidth / 2;

    const { clientHeight: tooltipHeight, clientWidth: tooltipWidth } =
      tooltipRef.current;

    if (top + tooltipHeight > window.innerHeight) {
      top = rect.top - tooltipHeight;
    }

    if (left <= 0) {
      left = rect.left;
    } else if (left + tooltipWidth > window.innerWidth) {
      left = rect.right - tooltipWidth;
    }

    // These values are floored to remove any half pixels
    setCss({
      top: Math.floor(top),
      left: Math.floor(left),
    });

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [rect]);

  const childrenWithListeners = useMemo(
    () =>
      React.Children.map(children, (child) => {
        const { onClick, onContextMenu, onMouseEnter, onMouseLeave } =
          child?.props || {};

        return React.cloneElement(child, {
          // Closes the tooltip on click in case a modal opens over the top that prevents
          // the mouseleave from being caught
          onClick: (e) => {
            onClick && onClick(e);

            clearTimeout(timeoutRef.current);
            setCss(null);
            setRect(false);
          },
          onContextMenu: (e) => {
            onContextMenu && onContextMenu(e);

            clearTimeout(timeoutRef.current);
            setCss(null);
            setRect(false);
          },
          onMouseEnter: (e) => {
            onMouseEnter && onMouseEnter(e);

            clearTimeout(timeoutRef.current);

            if (
              !e?.currentTarget ||
              // Buttons that are disabled don't trigger onMouseEnter events, but any other tag
              // with `disabled` as an attribute will so this must be checked manually
              e.currentTarget.getAttribute("disabled") !== null
            ) {
              return;
            }

            const rect = e.currentTarget.getBoundingClientRect();

            timeoutRef.current = setTimeout(() => {
              setRect(rect);
              onTooltipOpen();
            }, tooltipDelay);
          },
          onMouseLeave: (e) => {
            onMouseLeave && onMouseLeave(e);

            clearTimeout(timeoutRef.current);
            onTooltipClose();
            setCss(null);
            setRect(null);
          },
        });
      }),
    [children, onTooltipOpen, onTooltipClose, tooltipDelay]
  );

  return (
    <>
      {rect &&
        createPortal(
          <div
            ref={tooltipRef}
            className={styles.wrapper}
            // It might be expected that this would have role="tooltip", but as
            // the children elements use aria-label and don't always have corresponding
            // id attributes, it's easier to see this as an enhancement for visual users
            // and to hide it from screenreaders instead
            aria-hidden="true"
            // The tooltip is hidden until css has a value to avoid the jump
            // when the component first renders, but the coordinates haven't
            // been calculated yet.
            hidden={!css || disabled}
            style={css}
          >
            <div className={clsx(className, styles.tooltip)} {...props}>
              {title}
              {shortcut && (
                <span className={styles.keys}>
                  {shortcutModifier && <kbd>{modifierKeyString}</kbd>}
                  <kbd>{shortcut}</kbd>
                </span>
              )}
            </div>
          </div>,
          document.body
        )}
      {childrenWithListeners}
    </>
  );
}

export default Tooltip;
