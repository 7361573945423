import React, { useState, useCallback, useEffect } from "react";
import { addMinutes, differenceInMilliseconds } from "date-fns";
import clsx from "clsx";

import { setExternalCalendarSync } from "src/services/ExternalEventsService";

import Dialog from "../Dialog";
import Button, { BUTTON_SIZE_XSMALL } from "../Button";
import { GlobalToast } from "../Toast";

import { ReactComponent as IconCheck } from "../../assets/icons/16-check.svg";

import styles from "./ProfileSidebar.module.scss";

const EXTERNAL_CALENDAR_SYNC_EXPIRE = "externalCalendarSyncExpires";
const EXPIRE_DURATION = 5; // minutes

function ExternalCalendarSync({
  externalProfiles,
  showCalendarSyncDialog,
  onClose,
  setCalendarSyncExpire,
}) {
  const [calendarEnabled, setCalendarEnabled] = useState();
  const [showCalendarSyncToast, setShowCalendarSyncToast] = useState(false);

  const clearCalendarSync = useCallback(() => {
    setCalendarSyncExpire(false);
    setShowCalendarSyncToast(false);
    localStorage.removeItem(EXTERNAL_CALENDAR_SYNC_EXPIRE);
  }, [setCalendarSyncExpire]);

  useEffect(() => {
    if (!externalProfiles) return;

    // Check for existing calendars enabled
    let hasCalendarEnabled;
    externalProfiles.forEach((profile) =>
      profile.calendars.forEach((calendar) => {
        if (calendar.bi_directional_sync_enabled) {
          hasCalendarEnabled = calendar;
        }
      })
    );

    if (!hasCalendarEnabled) {
      clearCalendarSync();
      return;
    }

    const { id, bi_directional_sync_enabled } = hasCalendarEnabled;
    setCalendarEnabled({
      calendarId: id,
      biDirectionalSyncEnabled: bi_directional_sync_enabled,
    });
  }, [externalProfiles, clearCalendarSync]);

  useEffect(() => {
    const calendarSyncExpire = localStorage.getItem(
      EXTERNAL_CALENDAR_SYNC_EXPIRE
    );
    if (!calendarSyncExpire) return;

    const differenceBetweenDates = differenceInMilliseconds(
      new Date(calendarSyncExpire),
      new Date()
    );

    // External sync still in progress
    if (differenceBetweenDates > 0) {
      setCalendarSyncExpire(true);
    }

    // We need to handle setTimeout every time the component mount and unmount
    // to cleanup the freezed state and force a rerender when the duration ends
    const timer = setTimeout(() => {
      clearCalendarSync();
    }, differenceBetweenDates);

    return () => clearTimeout(timer);
  }, [clearCalendarSync, setCalendarSyncExpire]);

  const handleCalendarSyncClick = useCallback(async () => {
    console.log(calendarEnabled);
    if (!calendarEnabled) return;

    const { profileId, calendarId, biDirectionalSyncEnabled } = calendarEnabled;

    onClose();

    // User attempted to sync a calendar already enabled
    if (biDirectionalSyncEnabled) return;

    setShowCalendarSyncToast(true);
    setTimeout(() => setShowCalendarSyncToast(false), 3000);

    await setExternalCalendarSync(profileId, calendarId);

    // After activating an external calendar syncronization, keep it freezed
    // for 5 minutes to guarantee that the sync is done for all the events and
    // also to avoid that the user toggles other calendars while still syncing
    localStorage.setItem(
      EXTERNAL_CALENDAR_SYNC_EXPIRE,
      addMinutes(new Date(), EXPIRE_DURATION)
    );
    setCalendarSyncExpire(true);
  }, [onClose, calendarEnabled, setCalendarSyncExpire]);

  return (
    <>
      {showCalendarSyncDialog && (
        <Dialog
          headerTitle="Sync to External Calendar"
          footer={
            <>
              <Button block onClick={onClose}>
                Cancel
              </Button>
              <Button block negative onClick={handleCalendarSyncClick}>
                Sync Calendar
              </Button>
            </>
          }
        >
          <p>
            Choose the Calendar where RPM will automatically create calendar
            events for all Actions and Blocks.
          </p>

          <ul>
            {externalProfiles.map((profile) => (
              <li key={profile.id}>
                <div className={styles.externalService}>
                  <span>{profile.name}</span>
                </div>
                <ul>
                  {profile.calendars.map((calendar) => (
                    <li key={calendar.id}>
                      <label
                        className={clsx(
                          styles.externalCalendar,
                          calendar.enabled && styles.enabled
                        )}
                      >
                        <span>{calendar.name}</span>
                        <div className={styles.externalCalendarInput}>
                          <Button
                            aria-label={`Select ${calendar.name}`}
                            size={BUTTON_SIZE_XSMALL}
                            onClick={() =>
                              setCalendarEnabled({
                                profileId: profile.id,
                                calendarId: calendar.id,
                                calendarName: calendar.name,
                                biDirectionalSyncEnabled:
                                  calendar.bi_directional_sync_enabled,
                              })
                            }
                            iconOnly
                            tooltip
                          >
                            {(calendarEnabled?.calendarId === calendar.id ||
                              (!calendarEnabled &&
                                calendar.bi_directional_sync_enabled)) && (
                              <IconCheck role="presentation" />
                            )}
                          </Button>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Dialog>
      )}

      {showCalendarSyncToast && (
        <GlobalToast>Syncing to "{calendarEnabled.calendarName}"</GlobalToast>
      )}
    </>
  );
}

export default ExternalCalendarSync;
