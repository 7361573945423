import { isValid } from "date-fns";
import isUUID from "validator/es/lib/isUUID";
import isURL from "validator/es/lib/isURL";

import FirestoreRollingBatch from "./FirestoreRollingBatch";
import {
  STARRED,
  UNSTARRED,
  ACTIVE,
  COMPLETED,
  SNOOZED,
  BLOCK_RESULT_MAX_LENGTH,
  BLOCK_ACTIONS_COMPLETION_THRESHOLD,
  EMPTY_STARRED_ORDERED_IDS,
  SNOOZED_TO_WEEKS_MAP,
  CAPTURE_LIST,
  CATEGORY,
  BLOCK,
  UNNECESSARY,
  PROJECT,
} from "./constants";
import {
  firestore,
  collection,
  document,
  ParameterError,
  generateId,
  composeUnsubscribers,
  CacheDelayer,
  getParentInfo,
  getActionParentInfo,
  updateLegacyOrder,
  calculateSnoozedToDate,
  calculateSnoozedToString,
} from "./general";
import { getCategory } from "./categories";
import { getProject } from "./projects";
import { tickBlocksCompleted } from "./user";
import { Action, deleteActionHelper, updateAction } from "./actions";
import { deleteEvent, Event } from "./events";
import { trackEvent } from "../AnalyticsService";

export class Block {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Block(), {
      id: snapshot.id,
      categoryId: data.parent_category_id,
      // Handles legacy blocks where parent_project_id was not used
      projectId: data.parent_project_id || null,
      backgroundImage: data.background_image,
      orderedActiveChildrenIds: data.active_children_ordered_ids || [],
      // TODO: remove all usages of orderedActionsIds and orderedBlocksIds when
      // the new code using orderedChildrenIds has been out for a while
      orderedActionsIds: {
        [ACTIVE]: {
          [STARRED]: data.actions_lists?.[ACTIVE]?.[STARRED]?.ordered_ids || [],
          [UNSTARRED]:
            data.actions_lists?.[ACTIVE]?.[UNSTARRED]?.ordered_ids || [],
        },
      },
      purpose: data.purpose,
      // Handles legacy blocks where name was required, but result was not
      result: data.result || data.name,
      role: data.role,
      state: data.state,
      // Ensures that it is always passed as a boolean
      starred: !!data.is_starred,
      // Handles legacy blocks where snoozed_to was undefined, but now expect null
      snoozedTo: data.snoozed_to ? new Date(data.snoozed_to) : null,
      completionDate:
        data.date_of_completion && new Date(data.date_of_completion),
      dueDate: data.date_due && new Date(data.date_due),
      // This field has been added as a workaround for a bug in SortableJs. See
      // ChildrenList and ActionsList for more details
      __type: BLOCK,
    });
  }
}

export async function getBlock(blockId) {
  const docSnapshot = await document(`blocks/${blockId}`).get();
  return Block.fromFirestore(docSnapshot);
}

export function watchBlock(blockId, callback) {
  const block = document(`blocks/${blockId}`);

  return block.onSnapshot((snapshot) => {
    if (!snapshot.exists) return;

    callback(Block.fromFirestore(snapshot));
  });
}

export async function createBlock(
  categoryId,
  projectId,
  state,
  result,
  starred,
  snoozedToString = null
) {
  if (state !== ACTIVE && state !== SNOOZED)
    throw new ParameterError({ state }, `must be "${ACTIVE}" or "${SNOOZED}"`);
  if (typeof result !== "string")
    throw new ParameterError({ result }, "not a string");
  const resultTrim = result.trim();
  if (resultTrim === "")
    throw new ParameterError({ result }, "empty after trim");
  if (resultTrim.length > BLOCK_RESULT_MAX_LENGTH)
    throw new ParameterError(
      { result },
      "length must be <= " + BLOCK_RESULT_MAX_LENGTH
    );

  if (typeof starred !== "boolean")
    throw new ParameterError({ starred }, "must be a boolean");

  const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
  if (!snoozedToOptions.includes(snoozedToString) && snoozedToString !== null)
    throw new ParameterError(
      { snoozedToString },
      `must be ${snoozedToOptions.join(", ")} or null`
    );
  if (snoozedToString && state !== SNOOZED)
    throw new ParameterError(
      { snoozedToString },
      `cannot be used with any state other than "${SNOOZED}".`
    );
  if (state === SNOOZED && snoozedToString === null)
    throw new ParameterError(
      { snoozedToString },
      `cannot be null if state is "${SNOOZED}"`
    );

  const categoryDoc = getCategory(categoryId);
  if (!categoryDoc)
    throw new ParameterError({ categoryId }, `category does not exist`);

  if (projectId !== null && !isUUID(projectId))
    throw new ParameterError({ projectId }, `must be valid UUID or null`);
  if (projectId) {
    const projectDoc = await getProject(projectId);
    if (!projectDoc)
      throw new ParameterError({ projectId }, `project does not exist`);
  }

  const id = generateId();
  const snoozedTo =
    state === SNOOZED ? calculateSnoozedToDate(snoozedToString, true) : null;

  const block = {
    id,
    background_image: null,
    result: resultTrim,
    parent_category_id: categoryId,
    parent_project_id: projectId,
    parent_event_ids: [],
    active_children_ordered_ids: [],
    actions_lists: {
      [ACTIVE]: EMPTY_STARRED_ORDERED_IDS,
    },
    purpose: "",
    role: "",
    state,
    snoozed_to: snoozedTo,
    is_starred: starred,
    due_date: null,
    date_of_completion: null,
  };

  const batch = firestore().batch();

  batch.set(document(`blocks/${id}`), block);
  if (state === ACTIVE) {
    batch.update(document(`categories/${categoryId}`), {
      active_children_ordered_ids: firestore.FieldValue.arrayUnion(id),
    });
    if (projectId) {
      batch.update(document(`projects/${projectId}`), {
        active_children_ordered_ids: firestore.FieldValue.arrayUnion(id),
      });
    }
    // Add block to This Week
    batch.update(document(), {
      this_week_ordered_ids: firestore.FieldValue.arrayUnion(id),
    });
  }

  batch.commit();

  updateLegacyOrder(categoryId, CATEGORY);

  trackEvent("User created a Block", {
    id,
    categoryId,
    projectId,
    state,
    starred,
    resultLength: resultTrim.length,
  });
}

function activeActionRemoveFromBlock(batch, action) {
  // TODO: refactor setActionList so we can reuse that instead of this function
  const { id: actionId, blockId, categoryId, state } = action;

  // For completed actions there is more complexity to manage
  if (state !== ACTIVE && state !== SNOOZED)
    throw new ParameterError({ action }, `action state must be "${ACTIVE}"`);

  const { parentType } = getActionParentInfo(categoryId, blockId);

  if (parentType === CAPTURE_LIST) {
    // The action is in the capture list, nothing to do
    return;
  }

  // Remove action from the block/project (keep the category)
  batch.update(document(`actions/${actionId}`), {
    parent_block_id: null,
    parent_project_id: null,
  });
}

export async function deleteBlock(blockId, saveActiveActions) {
  const batch = new FirestoreRollingBatch();

  // Better to process the actions and the events first, and delete the block as
  // last thing, so if the block is very big (>400 actions) and the app crashes
  // after the first batch the data is still in a coherent state.

  const savedActions = new Set();

  const actionsSnap = await collection("actions")
    .where("parent_block_id", "==", blockId)
    .get();
  for (const actionSnap of actionsSnap.docs) {
    const action = Action.fromFirestore(actionSnap);
    if (saveActiveActions) {
      activeActionRemoveFromBlock(batch, action);
      savedActions.add(action.id);
    } else {
      // deleteActionHelper() is called with updateEvents=false because it has
      // no visibility on the other actions that are being deleted in this loop,
      // and so it cannot decide properly what to do with the linked events.
      // The next code block will take care of linked events.
      await deleteActionHelper(batch, action, false);
    }
  }

  const eventsSnap = await collection("events")
    .where("block_id", "==", blockId)
    .get();
  for (const eventSnap of eventsSnap.docs) {
    const { id: eventId, actionsIds } = Event.fromFirestore(eventSnap);
    const eventSavedActionsIds = actionsIds.filter((actionId) =>
      savedActions.has(actionId)
    );
    if (eventSavedActionsIds.length > 0) {
      batch.update(eventSnap.ref, {
        block_id: null,
        actions_ids: eventSavedActionsIds,
      });
    } else {
      deleteEvent(eventId, batch);
    }
  }

  const { categoryId } = await getBlock(blockId);
  batch.delete(document(`blocks/${blockId}`));
  // Delete from the ordered list in the parent...
  batch.update(document(`categories/${categoryId}`), {
    active_children_ordered_ids: firestore.FieldValue.arrayRemove(blockId),
  });
  // ...and from This Week (if it wasn't there this command has no effect)
  batch.update(document(), {
    this_week_ordered_ids: firestore.FieldValue.arrayRemove(blockId),
  });

  batch.commit();

  updateLegacyOrder(categoryId, CATEGORY);
}

export async function updateBlock(blockId, updates, externalBatch) {
  const {
    categoryId,
    projectId,
    result,
    purpose,
    role,
    backgroundImage,
    starred,
    dueDate,
    state,
    snoozedTo,
    snoozedToString,
    activeActionsTo,
  } = updates;

  const batch = externalBatch || new FirestoreRollingBatch();

  const blockDocument = document(`blocks/${blockId}`);
  const {
    categoryId: oldCategoryId,
    projectId: oldProjectId,
    state: oldState,
    snoozedTo: oldSnoozedTo,
  } = await getBlock(blockId);

  const finalCategoryId = categoryId || oldCategoryId;
  const finalProjectId = projectId !== undefined ? projectId : oldProjectId;
  const finalState = state || oldState;

  const categoryChanges =
    categoryId !== undefined && categoryId !== oldCategoryId;
  const projectChanges = projectId !== undefined && projectId !== oldProjectId;
  const stateChanges = state !== undefined && state !== oldState;

  if (result !== undefined) {
    if (typeof result !== "string")
      throw new ParameterError({ result }, "not a string");
    const resultTrim = result.trim();
    if (resultTrim === "")
      throw new ParameterError({ result }, "empty after trim");
    batch.update(blockDocument, { result: resultTrim });
  }

  if (purpose !== undefined) {
    if (typeof purpose !== "string")
      throw new ParameterError({ purpose }, "not a string");
    batch.update(blockDocument, { purpose });
  }

  if (role !== undefined) {
    if (typeof role !== "string")
      throw new ParameterError({ role }, "not a string");
    batch.update(blockDocument, { role });
  }

  if (backgroundImage !== undefined) {
    if (backgroundImage !== null && !isURL(backgroundImage))
      throw new ParameterError({ backgroundImage }, 'not a URL or "null"');
    batch.update(blockDocument, { background_image: backgroundImage });
  }

  if (starred !== undefined) {
    if (typeof starred !== "boolean")
      throw new ParameterError({ starred }, "must be a boolean");
    batch.update(blockDocument, { is_starred: starred });
  }

  if (dueDate !== undefined) {
    if (dueDate !== null && !isValid(dueDate))
      throw new ParameterError({ dueDate }, "can be only null or a valid date");
    batch.update(blockDocument, {
      date_due: dueDate ? dueDate.toISOString() : null,
    });
  }

  if (snoozedTo !== undefined) {
    if (finalState !== SNOOZED && snoozedTo !== null)
      throw new ParameterError(
        { snoozedTo },
        `cannot be used with any state other than "${SNOOZED}".`
      );

    batch.update(blockDocument, { snoozed_to: snoozedTo });
  }

  if (snoozedToString !== undefined) {
    if (snoozedTo !== undefined)
      throw new ParameterError(
        { snoozedToString },
        `can't use both snoozedToString and snoozedTo params at the same time.`
      );
    const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
    if (snoozedToString !== null && !snoozedToOptions.includes(snoozedToString))
      throw new ParameterError(
        { snoozedToString },
        `must be ${snoozedToOptions.join(", ")} or null`
      );
    if (snoozedToString && finalState !== SNOOZED)
      throw new ParameterError(
        { snoozedToString },
        `cannot be used with any state other than "${SNOOZED}".`
      );
    if (finalState === SNOOZED && snoozedToString === null)
      throw new ParameterError(
        { snoozedToString },
        `cannot be null if state is "${SNOOZED}"`
      );

    const oldSnoozedToString = calculateSnoozedToString(oldSnoozedTo);
    if (stateChanges || snoozedToString !== oldSnoozedToString) {
      batch.update(blockDocument, {
        snoozed_to: calculateSnoozedToDate(snoozedToString, true),
      });
    }
  }

  if (categoryChanges) {
    if (!isUUID(categoryId))
      throw new ParameterError({ categoryId }, "must be a valid UUID");
    batch.update(blockDocument, { parent_category_id: categoryId });
  }

  if (stateChanges) {
    if (![ACTIVE, SNOOZED, COMPLETED].includes(state))
      throw new ParameterError(
        { state },
        `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`
      );

    if (state === COMPLETED) {
      if (![CATEGORY, UNNECESSARY, COMPLETED, null].includes(activeActionsTo))
        throw new ParameterError(
          { activeActionsTo },
          `must be "${CATEGORY}", "${UNNECESSARY}", "${COMPLETED}" or null`
        );

      if (activeActionsTo) {
        const actionsSnap = await collection("actions")
          .where("parent_block_id", "==", blockId)
          .where("state", "==", ACTIVE)
          .get();
        actionsSnap.docs.forEach((actionSnap) => {
          const action = Action.fromFirestore(actionSnap);
          // on remove from block (block completion)
          if (activeActionsTo === CATEGORY) {
            activeActionRemoveFromBlock(batch, action);
            setTimeout(() => {
              // updateLegacyOrder must be called after the batch is committed,
              // so I use a timeout to not overcomplicate the code
              updateLegacyOrder(blockId, BLOCK);
            }, 1000);
          } else if (activeActionsTo === UNNECESSARY) {
            const updates = { state: COMPLETED, unnecessary: true };
            updateAction(action, updates);
          } else if (activeActionsTo === COMPLETED) {
            const updates = { state: COMPLETED, blockId: null };
            updateAction(action, updates);
          }
        });
      }
    }

    batch.update(blockDocument, {
      state,
      date_of_completion: state === COMPLETED ? new Date().toISOString() : null,
    });
    if (state !== SNOOZED) batch.update(blockDocument, { snoozed_to: null });

    if (oldState === COMPLETED || state === COMPLETED) {
      // The completion state changed, update the global user counter
      tickBlocksCompleted(batch, state === COMPLETED ? +1 : -1);
    }
  }

  if (projectChanges) {
    if (projectId !== null && !isUUID(projectId))
      throw new ParameterError({ projectId }, 'must be "null" or a valid UUID');

    batch.update(blockDocument, { parent_project_id: projectId });
  }

  // Update ordering lists
  if (categoryChanges || projectChanges || stateChanges) {
    if (categoryChanges) {
      batch.update(document(`categories/${oldCategoryId}`), {
        active_children_ordered_ids: firestore.FieldValue.arrayRemove(blockId),
      });
    }
    if (projectChanges && oldProjectId) {
      batch.update(document(`projects/${oldProjectId}`), {
        active_children_ordered_ids: firestore.FieldValue.arrayRemove(blockId),
      });
    }

    const operation =
      finalState === ACTIVE
        ? firestore.FieldValue.arrayUnion(blockId)
        : firestore.FieldValue.arrayRemove(blockId);

    batch.update(document(`categories/${finalCategoryId}`), {
      active_children_ordered_ids: operation,
    });
    if (finalProjectId) {
      batch.update(document(`projects/${finalProjectId}`), {
        active_children_ordered_ids: operation,
      });
    }
    // Add block to This Week ordering list (if already present this command
    // does nothing)
    batch.update(document(), {
      this_week_ordered_ids: operation,
    });

    setTimeout(() => {
      // The calls to updateLegacyOrder must happen after the batch.commit(),
      // but since this function does not end with a certain commit() call, they
      // are postponed to a later time. Since these functions are just aligning
      // data for old versions is ok to call them deferred
      updateLegacyOrder(finalCategoryId, CATEGORY);
      if (categoryChanges) updateLegacyOrder(oldCategoryId, CATEGORY);
    }, 1000);
  }

  // Update block's children
  if (categoryChanges || projectChanges) {
    const childrenActionsSnap = await collection("actions")
      .where("parent_block_id", "==", blockId)
      .get();
    childrenActionsSnap.forEach((actionSnap) => {
      batch.update(actionSnap.ref, {
        parent_category_id: finalCategoryId,
        parent_project_id: finalProjectId,
      });
    });
  }

  // Update events linked to this block
  if (categoryChanges) {
    const eventsSnap = await collection("events")
      .where("block_id", "==", blockId)
      .get();
    eventsSnap.docs.forEach((eventSnap) => {
      batch.update(eventSnap.ref, { category_id: finalCategoryId });
    });
  }

  // remove blockId from the events, in case it was removed
  // from a block (on block completion)
  if (activeActionsTo === CATEGORY) {
    const eventsSnap = await collection("events")
      .where("block_id", "==", blockId)
      .get();
    eventsSnap.docs.forEach((eventSnap) => {
      batch.update(eventSnap.ref, { block_id: null });
    });
  }

  if (!externalBatch) batch.commit();
}

export async function moveBlock(
  blockId,
  parentId,
  parentType,
  state,
  snoozedToString,
  orderedIds
) {
  const updates = {};
  switch (parentType) {
    case CATEGORY:
      updates.categoryId = parentId;
      break;
    case PROJECT:
      updates.projectId = parentId;
      break;
    default:
      throw new ParameterError(
        { parentType },
        `must be ${CATEGORY} or ${PROJECT}}`
      );
  }
  updates.state = state;
  if (state === SNOOZED) updates.snoozedToString = snoozedToString;

  const batch = new FirestoreRollingBatch();

  await updateBlock(blockId, updates, batch);

  if (state === ACTIVE && orderedIds) {
    const { parentDocument, orderField } = getParentInfo(parentId, parentType);
    batch.update(parentDocument, { [orderField]: orderedIds });
  }

  batch.commit();
}

export async function blockHasIncompleteActions(blockId) {
  const actionsSnap = await collection("actions")
    .where("parent_block_id", "==", blockId)
    .where("state", "==", ACTIVE)
    .limit(1)
    .get();
  return actionsSnap.size > 0;
}

export async function blockHasEvents(blockId) {
  const eventsSnapshot = await collection("events")
    .where("block_id", "==", blockId)
    .limit(1)
    .get();

  return eventsSnapshot.size > 0;
}

export function watchCompletedBlocks(parentId, parentType, limit, callback) {
  if (!limit) {
    // Completed blocks can be many thousands
    throw new ParameterError(
      { limit },
      "limit required when querying completed actions in categories"
    );
  }

  const { parentIdField } = getParentInfo(parentId, parentType);
  const cacheDelayer = new CacheDelayer();
  const unsubscribe = collection("blocks")
    .where(parentIdField, "==", parentId)
    .where("state", "==", COMPLETED)
    .orderBy("date_of_completion", "desc")
    // The extra action is used only to peek above the limit to see if there
    // are more actions
    .limit(limit + 1)
    .onSnapshot((snapshot) => {
      const blocks = snapshot.docs.map(Block.fromFirestore);
      let hasMore = false;
      if (blocks.length > limit) {
        hasMore = true;
        // Skip this extra action that we used only to peek above the limit
        blocks.pop();
      }
      cacheDelayer.delayIfCached(() => callback(blocks, hasMore), snapshot);
    });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  };
}

export function watchBlockCompletionProgress(blockId, blockState, callback) {
  if (blockState !== ACTIVE)
    throw new ParameterError(
      `watchBlockCompletionProgress is only supported for "${ACTIVE}" blocks.`
    );

  let completedActions, block;

  function handleChange() {
    if (completedActions === undefined || block === undefined) return;

    const activeActionsLists = block?.orderedActionsIds?.[ACTIVE];

    const starredActiveActions = activeActionsLists?.[STARRED] || [];

    // If any active actions are starred the block is not ready for completion
    if (starredActiveActions.length) {
      callback(null);
      return;
    }

    const starredCompletedActions = completedActions.filter(
      (action) => action.starred
    );

    if (starredCompletedActions.length) {
      // If there are any starred completions the block is ready for completion as
      // we ruled out the presence of any unfinished starred actions above already
      callback("starred");
      return;
    }

    const activeActions = [
      ...starredActiveActions,
      ...(activeActionsLists?.[UNSTARRED] || []),
    ];

    const completedActionsLen = completedActions.length;
    const totalActionsLen = completedActions.length + activeActions.length;

    // If there are no starred actions, completed or not then the user should be shown
    // the prompt if they have enough completed actions to meet the threshold for
    // assumed readiness
    const meetsThreshold =
      completedActionsLen / totalActionsLen >=
      BLOCK_ACTIONS_COMPLETION_THRESHOLD;

    callback(meetsThreshold ? "threshold" : null);
  }

  return composeUnsubscribers(
    collection("actions")
      .where("parent_block_id", "==", blockId)
      .where("state", "==", COMPLETED)
      // This limit is required because the Firestore cost of calculating this become
      // prohibitively high with unlimited completed actions. This limit is high enough
      // that it is treated as the absolute number (even if the actual number is higher)
      // against which the threshold can be measured. 100 is an arbitrary number that is
      // high enough
      .limit(100)
      .onSnapshot((snapshot) => {
        completedActions = snapshot.docs.map(Action.fromFirestore);
        handleChange();
      }),

    document(`blocks/${blockId}`).onSnapshot((docSnapshot) => {
      block = Block.fromFirestore(docSnapshot);
      // Fail-safe to ensure that this is only processed if the block is active which mimics
      // the old ParameterError that was thrown under these conditions, however
      // the addition of the blockState param means that this shouldn't be called anyway (and
      // has been tested under this hypothesis).
      if (block && block.state === ACTIVE) {
        handleChange();
      }
    })
  );
}
