import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

import {
  ALL_CATEGORY_ID,
  UNCATEGORIZED_ID,
  PROJECT,
  CATEGORY,
  ACTIVE,
  ACTION,
  BLOCK,
  RESULT,
  STATE_LABELS,
  PAST_DUE,
  UNSCHEDULED,
  SNOOZED,
  SCHEDULED,
} from "src/services/DbService/constants";
import useUniqueId from "src/hooks/useUniqueId";
import { watchCategory } from "src/services/DbService/categories";
import { watchProject } from "src/services/DbService/projects";

import { DashboardContext } from "../../Dashboard";

import TabPanel from "../../TabPanel";
import LoadingSpinner from "../../LoadingSpinner";
import CreateBlockDialog from "../../CreateDialog/CreateBlockDialog";
import CreateButton from "src/components/CreateButton";
import ChildrenList from "src/components/ChildrenList/ChildrenList";
import ActionList from "src/components/ActionList";
import { useShowCreateActionDialog } from "src/components/ActionDialog";

function TabResult({ parentId, parentType, detailUrl, onCreateClick }) {
  const { starredFilter } = useContext(DashboardContext);

  return (
    <>
      <ChildrenList
        parentId={parentId}
        parentType={parentType}
        state={ACTIVE}
        filter={(block) => starredFilter(block) && block.__type === BLOCK}
        enableProjectBanner={parentType !== PROJECT}
        detailUrl={detailUrl}
      />

      {/* User is able to create a block only in Category */}
      {parentType === CATEGORY && (
        <CreateButton onClick={onCreateClick} aria-label="Create New Result" />
      )}
    </>
  );
}

function TabAction({ parentId, parentType }) {
  const showCreateActionDialog = useShowCreateActionDialog();

  const pastDueId = useUniqueId();
  const unscheduledId = useUniqueId();
  const scheduledId = useUniqueId();
  const snoozedId = useUniqueId();

  const sectionFilters = useMemo(() => {
    return [
      {
        id: pastDueId,
        label: STATE_LABELS[PAST_DUE].subheading,
        state: ACTIVE,
        filter: (action) =>
          action.event?.startDate < new Date() && action.__type === ACTION,
      },
      {
        id: unscheduledId,
        label: STATE_LABELS[UNSCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) =>
          !action.event?.startDate && action.__type === ACTION,
      },
      {
        id: scheduledId,
        label: STATE_LABELS[SCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) =>
          action.event?.startDate >= new Date() && action.__type === ACTION,
      },
      {
        id: snoozedId,
        label: STATE_LABELS[SNOOZED].subheading,
        state: SNOOZED,
        filter: (action) => action.__type === ACTION,
      },
    ];
  }, [pastDueId, unscheduledId, snoozedId, scheduledId]);

  return (
    <>
      {
        <ActionList
          parentId={parentId}
          parentType={parentType}
          sectionFilters={sectionFilters}
          enableProjectBanner={parentType !== PROJECT}
        />
      }

      {/* User is able to create an action only in Category */}
      {parentType === CATEGORY && (
        <CreateButton
          onClick={() => showCreateActionDialog(parentId)}
          aria-label="Create New Action"
        />
      )}
    </>
  );
}

function DashboardSidebarTaxonomy({
  activeTab,
  parentId,
  parentType,
  tabs,
  detailUrl,
}) {
  const { setActiveCategoryId } = useContext(DashboardContext);

  const [parent, setParent] = useState(null);
  const [blockDialogVisible, setBlockDialogVisible] = useState(false);

  useEffect(() => {
    if (!parentId) return;

    const watchParent = parentType === PROJECT ? watchProject : watchCategory;

    return watchParent(parentId, (watchedParent) => {
      // Active category checking is handled in DashboardContext for users signing in and registering,
      // but this is needed in the rare event that a user deletes a category on iOS and that category
      // then no longer exists when they reload the page on web. It's a rare edge-case, but this
      // simple condition ensures that the app doesn't crash, and instead shows them the
      // "all categories" view.
      if (watchedParent) {
        setParent(watchedParent);
      } else {
        setActiveCategoryId(ALL_CATEGORY_ID);
      }
    });
  }, [parentId, parentType, setActiveCategoryId]);

  const handleCreateActiveBlock = useCallback(() => {
    setBlockDialogVisible(true);
  }, []);

  if (parent === null) {
    return <LoadingSpinner absolute />;
  }

  let dialogProps;

  if (parentType === PROJECT) {
    dialogProps = {
      categoryId: UNCATEGORIZED_ID,
      projectId: parentId,
    };
  } else if (parentType === CATEGORY) {
    dialogProps = {
      categoryId: parentId,
      projectId: null,
    };
  }

  return (
    <>
      {tabs &&
        tabs.map((tab) => (
          <TabPanel key={tab.id} id={tab.id} active={tab.type === activeTab}>
            {tab.type === RESULT && (
              <TabResult
                parentId={parentId}
                parentType={parentType}
                onCreateClick={handleCreateActiveBlock}
                detailUrl={detailUrl}
              />
            )}
            {tab.type === ACTION && (
              <TabAction parentId={parentId} parentType={parentType} />
            )}
          </TabPanel>
        ))}

      {blockDialogVisible && (
        <CreateBlockDialog
          {...dialogProps}
          state={ACTIVE}
          starred={false}
          onClose={() => setBlockDialogVisible(false)}
        />
      )}
    </>
  );
}

export default DashboardSidebarTaxonomy;
