import React from "react";
import clsx from "clsx";

import Button, { BUTTON_SIZE_SMALL } from "../Button";
import Heading, { HEADING_LEVEL_5 } from "../Heading";

import { ReactComponent as IconClose } from "../../assets/icons/16-close.svg";

import styles from "./Dialog.module.scss";

function DialogHeader({
  title,
  buttons = null,
  className,
  reversed,
  onClose,
  collapsed,
  subtitle,
  ...props
}) {
  const hasButtons = onClose || buttons;

  return (
    <header
      {...props}
      className={clsx(
        className,
        styles.header,
        reversed && styles.headerReversed,
        subtitle && styles.headerCollapsable,
        collapsed === true && styles.headerCollapsed,
        hasButtons && styles.headerHasButtons
      )}
    >
      {/* The title is duplicated in the collapsable section with more content so aria-hidden is
          set when that exists to avoid duplicate screenreader info */}
      <Heading
        className={styles.headerTitle}
        title={title}
        level={HEADING_LEVEL_5}
      >
        {title}
      </Heading>
      {/*collapsableTitle &&
        <h2 className={clsx(styles.headerTitle, styles.headerTitleCollapsable)} aria-hidden={!collapsableTitle}>{collapsableTitle}</h2>*/}

      {hasButtons && (
        <div className={styles.headerButtons}>
          {buttons}
          {/* The reversed prop here is a dark background with white text, so the overlay button should be used
              rather than the colour preference reversed button. It is not an error that it is this way around! */}
          {onClose && (
            <Button
              iconOnly
              aria-label="Close modal"
              onClick={onClose}
              size={BUTTON_SIZE_SMALL}
            >
              <IconClose role="presentation" />
            </Button>
          )}
        </div>
      )}
      {subtitle && (
        <div className={styles.headerCollapsableOuter}>
          <div className={styles.headerCollapsableInner}>
            <div className={styles.headerCollapsableContent}>{subtitle}</div>
          </div>
        </div>
      )}
    </header>
  );
}

export default DialogHeader;
