import React from "react";
import clsx from "clsx";

import { useGetCategoryColor } from "../CategoriesContext";

import styles from "./DetailPanel.module.scss";
import Heading, { HEADING_LEVEL_0 } from "../Heading";

export function DetailPanelHero({
  className,
  children,
  categoryId,
  backgroundImage,
  buttons,
  heading,
  reversed,
  headingIcon: HeadingIcon,
}) {
  const getCategoryColor = useGetCategoryColor();

  const isReversed = reversed || categoryId || backgroundImage;

  return (
    <header
      className={clsx(
        className,
        styles.hero,
        categoryId && styles.hasCategory,
        isReversed && styles.isReversed,
        backgroundImage && styles.hasBackground
      )}
      data-category-color={getCategoryColor(categoryId)}
    >
      {backgroundImage && (
        <div
          className={styles.heroBackgroundImage}
          aria-hidden="true"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
      )}

      {buttons && <div className={styles.buttons}>{buttons}</div>}

      {heading && (
        <div className={styles.group}>
          <Heading className={styles.heroHeading} level={HEADING_LEVEL_0}>
            {HeadingIcon && <HeadingIcon role="presentation" />}
            {heading}
          </Heading>
        </div>
      )}

      {children}
    </header>
  );
}

export default DetailPanelHero;
