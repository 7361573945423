import React from "react";
import clsx from "clsx";

import styles from "./VideoEmbed.module.scss";

export function YouTubeVideoEmbed({ className, videoId, title, ...props }) {
  return (
    <iframe
      title={title}
      src={`https://youtube.com/embed/${videoId}?showinfo=0&rel=0&modestbranding=1`}
      className={clsx(styles.Iframe, className)}
      {...props}
    />
  );
}
