import React, { useEffect, useState, useMemo, useCallback } from "react";

import { NBSP } from "src/constants";

import {
  composeUnsubscribers,
  watchActiveChildren,
} from "../../../services/DbService/general";
import { ACTIVE, CATEGORY } from "../../../services/DbService/constants";
import { Block } from "../../../services/DbService/blocks";
import {
  createEvent,
  updateEventScheduleFromActionId,
} from "../../../services/DbService/events";

import { useCategories, useGetCategoryColor } from "../../CategoriesContext";
import Button, { BUTTON_COLOR_CATEGORY } from "../../Button";
import DialogFooter from "../../Dialog/DialogFooter";
import NewEventDialogList from "./NewEventDialogList";
import { actionHasEvents, updateAction } from "src/services/DbService/actions";

function NewEventDialogRoot({
  hidden,
  dialogHidden,
  startDate,
  duration,
  onBlockSelected,
  onClose,
  ...props
}) {
  const [selected, setSelected] = useState([]);

  const { activeCategories } = useCategories();
  const [categoriesChildren, setCategoriesChildren] = useState({});

  const getCategoryColor = useGetCategoryColor();

  // Reset selection when the dialog gets closed
  useEffect(() => {
    if (dialogHidden) setSelected([]);
  }, [dialogHidden]);

  // Watch categories with their active blocks and actions
  // Note: it's required that all the lists maintain the user defined order
  useEffect(() => {
    if (activeCategories === null) return;

    const childrenUnsubscribers = [];
    // For each category install a watcher for the children
    activeCategories.forEach((category) => {
      const categoryId = category.id;
      childrenUnsubscribers.push(
        watchActiveChildren(categoryId, CATEGORY, (children) => {
          setCategoriesChildren((categoriesChildren) => ({
            ...categoriesChildren,
            [categoryId]: children,
          }));
        })
      );
    });

    return composeUnsubscribers(childrenUnsubscribers);
  }, [activeCategories]);

  // Prepare the data structure consumed by NewEventDialogList
  // see the component implementation for more info
  const items = useMemo(() => {
    if (activeCategories === null) return [];

    const items = [];
    activeCategories.forEach((category) => {
      items.push({ category });
      categoriesChildren[category.id]?.forEach((child) => {
        if (child instanceof Block) items.push({ block: child });
        else items.push({ action: child });
      });
    });
    return items;
  }, [activeCategories, categoriesChildren]);

  const handleChange = useCallback((selected) => setSelected(selected), []);

  const handleScheduleClick = useCallback(() => {
    // If one action is being scheduled, set the duration to that action's time.
    let newDuration = duration;
    if (selected.length === 1) {
      let parsedTime = parseInt(selected[0].duration);

      if (!isNaN(parsedTime)) {
        newDuration = parsedTime;
      }
    }

    // now, only one action can be selected in the new event dialog
    const selectedAction = selected[0];

    actionHasEvents(selectedAction.id).then(async (hasEvents) => {
      if (hasEvents) {
        await updateEventScheduleFromActionId(
          selectedAction.id,
          startDate,
          newDuration
        );
      } else {
        createEvent(startDate, newDuration, selected[0].categoryId, null, [
          selectedAction.id,
        ]);
      }

      // snoozed actions should be changed to active state
      // when scheduled, as well as the duration is updated
      await updateAction(selectedAction.id, {
        state: ACTIVE,
        duration: newDuration,
      });
    });

    onClose();
  }, [startDate, duration, selected, onClose]);

  return (
    <div hidden={hidden} {...props}>
      <NewEventDialogList
        items={items}
        emptyMessage={`No active${NBSP}items\nCreate one${NBSP}above`}
        selectedActions={selected}
        onChange={handleChange}
        onBlockSelected={onBlockSelected}
        parentHidden={hidden}
        dialogHidden={dialogHidden}
      />

      {selected.length > 0 && (
        <DialogFooter
          data-category-color={getCategoryColor(selected[0]?.categoryId)}
        >
          <Button
            color={BUTTON_COLOR_CATEGORY}
            onClick={handleScheduleClick}
            disabled={selected.length > 10}
            block
          >
            {selected.length > 10 ? "Max 10 actions" : `Schedule action`}
          </Button>
        </DialogFooter>
      )}
    </div>
  );
}

export default NewEventDialogRoot;
