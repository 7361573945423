import React from "react";
import clsx from "clsx";

import Tooltip from "../Tooltip";

import { ReactComponent as IconCheck } from "../../assets/icons/16-check.svg";

import styles from "./CompletionCheckbox.module.scss";

function CompletionCheckbox({
  className,
  ringClassName,
  completed,
  icon: Icon = IconCheck,
  categoryColor,
  tag: WrapperTag = "button",
  label,
  ...props
}) {
  const labelComputed =
    label || (completed ? "Mark as active" : "Mark as completed");

  return (
    <Tooltip title={labelComputed}>
      <WrapperTag
        className={clsx(className, styles.wrapper)}
        aria-label={labelComputed}
        {...props}
      >
        <span
          className={clsx(
            ringClassName,
            styles.ring,
            completed && styles.completed,
            categoryColor && styles.categoryColor
          )}
        >
          <Icon role="presentation" />
        </span>
      </WrapperTag>
    </Tooltip>
  );
}

export default CompletionCheckbox;
