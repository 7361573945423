import { useEffect, useState } from "react";

export const LOCALSTORAGE_COLOR_SCHEME = "RPM_COLOR_SCHEME";
export const COLOR_SCHEME_AUTO = "auto";
export const COLOR_SCHEME_LIGHT = "light";
export const COLOR_SCHEME_DARK = "dark";

export default function useColorScheme() {
  const [colorScheme, setColorScheme] = useState(
    localStorage.getItem(LOCALSTORAGE_COLOR_SCHEME) || COLOR_SCHEME_AUTO
  );

  useEffect(() => {
    document.documentElement.dataset.colorPreference = colorScheme;
    localStorage.setItem(LOCALSTORAGE_COLOR_SCHEME, colorScheme);
  }, [colorScheme]);

  return [colorScheme, setColorScheme];
}
