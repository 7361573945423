import React, { useEffect, useState, useCallback } from "react";

import { NBSP } from "src/constants";
import { actionHasEvents, updateAction } from "src/services/DbService/actions";

import { ACTIVE, BLOCK } from "src/services/DbService/constants";
import { watchActiveChildren } from "src/services/DbService/general";
import {
  createEvent,
  updateEventScheduleFromActionId,
} from "../../../services/DbService/events";

import Button, { BUTTON_COLOR_CATEGORY } from "../../Button";
import DialogFooter from "../../Dialog/DialogFooter";
import NewEventDialogList from "./NewEventDialogList";

function NewEventDialogBlock({
  hidden,
  dialogHidden,
  block,
  startDate,
  duration,
  onBack,
  onClose,
  ...props
}) {
  const [items, setItems] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!block) {
      // If block changes to null clean the items list and the selected actions
      setItems(null);
      setSelected([]);
      return;
    }
    return watchActiveChildren(block.id, BLOCK, (actions) =>
      setItems(actions.map((action) => ({ action })))
    );
  }, [block]);

  const handleChange = useCallback((selected) => setSelected(selected), []);

  const handleScheduleClick = useCallback(() => {
    // If one action is being scheduled, set the duration to that action's time.
    let newDuration = duration;
    if (selected.length === 1) {
      let parsedTime = parseInt(selected[0].duration);

      if (!isNaN(parsedTime)) {
        newDuration = parsedTime;
      }
    }

    // now, only one action can be selected in the new event dialog
    const selectedAction = selected[0];

    actionHasEvents(selectedAction.id).then(async (hasEvents) => {
      if (hasEvents) {
        await updateEventScheduleFromActionId(
          selectedAction.id,
          startDate,
          newDuration
        );
      } else {
        createEvent(startDate, newDuration, block.categoryId, block.id, [
          selectedAction.id,
        ]);
      }

      // snoozed actions should be changed to active state
      // when scheduled, as well as the duration is updated
      await updateAction(selectedAction.id, {
        state: ACTIVE,
        duration: newDuration,
      });
    });

    onClose();
  }, [startDate, duration, block, selected, onClose]);

  let buttonLabel = "";
  if (selected.length > 10) {
    buttonLabel = "Max 10 actions";
  } else if (selected.length) {
    buttonLabel = `Schedule action`;
  }

  return (
    <div hidden={hidden} {...props}>
      <NewEventDialogList
        items={items}
        emptyMessage={`No active${NBSP}actions\nAdd one to schedule${NBSP}it`}
        selectedActions={selected}
        onChange={handleChange}
        parentHidden={hidden}
        dialogHidden={dialogHidden}
      />

      {/* the footer should be removed from the DOM when this section is hidden to avoid
          any focusable items being wrongly identified as such. The benefit of continually
          holding this dialog in the DOM is related to the list above, and isn't affected
          by this being removed/present. */}
      {/* it is not possible to select a block for scheduling anymore */}
      {!hidden && selected.length > 0 && (
        <DialogFooter>
          <Button
            color={BUTTON_COLOR_CATEGORY}
            onClick={handleScheduleClick}
            disabled={selected.length && selected.length > 10}
            block
          >
            {buttonLabel}
          </Button>
        </DialogFooter>
      )}
    </div>
  );
}

export default NewEventDialogBlock;
