/**
 * @param {Array} items An array of objects (with an id string)
 * @returns {Object} map A map where the keys are the id from each object of the array
 */
export function mapById(objs) {
  if (!Array.isArray(objs)) return {};
  const mapById = {};
  for (const obj of objs) {
    if (!obj.id) continue;
    mapById[obj.id] = obj;
  }
  return mapById;
}
