import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

import styles from "./SmartLink.module.scss";

export const SMART_LINK_STYLE_LINK = "link";
export const SMART_LINK_STYLE_PLACEHOLDER = "placeholder";

export function getSmartLinkTag(to, href) {
  if (to) return Link;
  if (href) return "a";
  return "button";
}

const SmartLink = forwardRef(
  (
    {
      className,
      children,
      to,
      href,
      linkStyle = SMART_LINK_STYLE_LINK,
      replace,
      negative,
      ...props
    },
    ref
  ) => {
    const LinkTag = getSmartLinkTag(to, href);

    const linkProps = {
      ...props,
    };

    switch (LinkTag) {
      case Link:
        linkProps.to = to;
        linkProps.replace = replace;
        break;

      case "a":
        linkProps.href = href;
        if (!href.startsWith("#")) {
          linkProps.rel = "noopener";
        }
        break;

      default:
        break;
    }

    return (
      <LinkTag
        ref={ref}
        className={clsx(
          className,
          styles.base,
          styles[linkStyle],
          negative && styles.negative
        )}
        {...linkProps}
      >
        {children}
      </LinkTag>
    );
  }
);

SmartLink.displayName = "SmartLink";

SmartLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  href: PropTypes.string,
  replace: PropTypes.bool,
  linkStyle: PropTypes.oneOf([
    SMART_LINK_STYLE_LINK,
    SMART_LINK_STYLE_PLACEHOLDER,
  ]),
  negative: PropTypes.bool,
};

export default SmartLink;
