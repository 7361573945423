import React from "react";
import clsx from "clsx";

import { PRODUCT_NAME } from "src/constants";

import Head from "../Head";
import Heading, { HEADING_LEVEL_1 } from "../Heading";

import { ReactComponent as Logo } from "../../assets/logo.svg";

import styles from "./PageNoUi.module.scss";

function PageNoUi({ className, children, pageTitle, heading, text, ...props }) {
  // Ensures there is always an h1 on the page
  const HeaderTag = heading ? "header" : "h1";

  return (
    <main className={clsx(className, styles.page)}>
      <Head title={pageTitle} />

      <div className={styles.wrapper}>
        <HeaderTag>
          <span className="sr-only">{PRODUCT_NAME}</span>
          <Logo role="presentation" className={styles.logo} />
        </HeaderTag>
        {heading && (
          <Heading tag="h1" level={HEADING_LEVEL_1} className={styles.heading}>
            {heading}
          </Heading>
        )}
        {text && <p className={styles.text}>{text}</p>}
        {children}
      </div>
    </main>
  );
}

export default PageNoUi;
