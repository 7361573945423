import React, { useMemo, useContext } from "react";
import { endOfWeek, startOfWeek } from "date-fns";
import clsx from "clsx";

import { NBSP } from "src/constants";

import {
  ACTIVE,
  THIS_WEEK,
  THIS_WEEK_TITLE,
} from "src/services/DbService/constants";

import useAction from "src/hooks/useAction";

import { DashboardContext } from "../DashboardContext";
import ChildrenList from "src/components/ChildrenList/ChildrenList";
import Sidebar, { SidebarHeader } from "src/components/Sidebar";
import Heading, { HEADING_LEVEL_2 } from "src/components/Heading";
import StarredFilterButton from "src/components/StarredFilterButton";

import styles from "./DashboardSidebarThisWeek.module.scss";

function DashboardSidebarThisWeek({ className, detailUrl }) {
  const { starredFilter } = useContext(DashboardContext);
  const { actions, blocksById } = useAction(null, THIS_WEEK);

  const thisWeekList = useMemo(() => {
    if (!actions || !blocksById) return [];

    const now = new Date();

    // filter out actions that are not in the current week and
    // retrieve their curring parent block ids
    const thisWeekBlocksIds = actions.reduce((result, action) => {
      if (
        action?.event?.startDate >= startOfWeek(now) &&
        action?.event?.startDate <= endOfWeek(now) &&
        action?.blockId &&
        blocksById[action?.blockId]
      ) {
        result.add(action.blockId);
      }
      return result;
    }, new Set());

    // blocks that have categorized or parented Actions
    return Array.from(thisWeekBlocksIds).map((blockId) => blocksById[blockId]);
  }, [actions, blocksById]);

  return (
    <Sidebar
      className={clsx(className)}
      header={
        <SidebarHeader plain>
          <Heading
            className={styles.captureHeading}
            tag="h2"
            level={HEADING_LEVEL_2}
          >
            {THIS_WEEK_TITLE}
          </Heading>
        </SidebarHeader>
      }
    >
      <StarredFilterButton />

      <div>
        <ChildrenList
          state={ACTIVE}
          parentId={null}
          parentType={THIS_WEEK}
          list={thisWeekList}
          filter={(action) => starredFilter(action)}
          emptyState={`No results${NBSP}This Week`}
          detailUrl={detailUrl}
        />
      </div>
    </Sidebar>
  );
}

export default DashboardSidebarThisWeek;
