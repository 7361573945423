import React from "react";
import clsx from "clsx";

import styles from "./Input.module.scss";

function InputField({ className, children, tag, ...props }) {
  const InputFieldTag = tag || "label";

  return (
    <InputFieldTag className={clsx(className, styles.field)} {...props}>
      {children}
    </InputFieldTag>
  );
}

export default InputField;
