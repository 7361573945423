import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";

const LOCALSTORAGE_ACTION_LIST_EXPANDED = "actionListExpanded";
const LOCALSTORAGE_THIS_WEEK_LIST_EXPANDED = "thisWeekSidebarExpanded";
const LOCALSTORAGE_CATEGORY_LIST_EXPANDED = "categorySidebarExpanded";

export const SidebarContext = createContext();

export function SidebarContextProvider({ children }) {
  const [actionListExpanded, setActionListExpanded] = useState(() => {
    const localValue = localStorage.getItem(LOCALSTORAGE_ACTION_LIST_EXPANDED);
    return localValue ? JSON.parse(localValue) : false;
  });

  const [thisWeekSidebarExpanded, setThisWeekSidebarExpanded] = useState(() => {
    const localValue = localStorage.getItem(
      LOCALSTORAGE_THIS_WEEK_LIST_EXPANDED
    );
    return localValue ? JSON.parse(localValue) : false;
  });

  const [categorySidebarExpanded, setCategorySidebarExpanded] = useState(() => {
    const localValue = localStorage.getItem(
      LOCALSTORAGE_CATEGORY_LIST_EXPANDED
    );
    return localValue ? JSON.parse(localValue) : false;
  });

  const toggleActionListExpanded = useCallback(() => {
    setActionListExpanded((expanded) => !expanded);
  }, []);

  const toggleThisWeekSidebarExpanded = useCallback(() => {
    setThisWeekSidebarExpanded((expanded) => !expanded);
  }, []);

  const toggleCategorySidebarExpanded = useCallback(() => {
    setCategorySidebarExpanded((expanded) => !expanded);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_ACTION_LIST_EXPANDED,
      JSON.stringify(actionListExpanded)
    );
  }, [actionListExpanded]);

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_THIS_WEEK_LIST_EXPANDED,
      JSON.stringify(thisWeekSidebarExpanded)
    );
  }, [thisWeekSidebarExpanded]);

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_CATEGORY_LIST_EXPANDED,
      JSON.stringify(categorySidebarExpanded)
    );
  }, [categorySidebarExpanded]);

  const value = useMemo(
    () => ({
      actionListExpanded,
      toggleActionListExpanded,
      thisWeekSidebarExpanded,
      toggleThisWeekSidebarExpanded,
      categorySidebarExpanded,
      toggleCategorySidebarExpanded,
    }),
    [
      actionListExpanded,
      toggleActionListExpanded,
      thisWeekSidebarExpanded,
      toggleThisWeekSidebarExpanded,
      categorySidebarExpanded,
      toggleCategorySidebarExpanded,
    ]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}
