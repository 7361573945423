const MESSAGE_COLOR_MAP = {
  error: "#ff173c",
  warn: "orange",
  log: "white",
};

// This is enabled automatically for all environments except production and alpha,
// but can be manually enabled by setting `app-debug` in localStorage
export const appDebug = (messageType, title, taskName, task, args) => {
  if (
    process.env.REACT_APP_DEBUG === "false" &&
    !localStorage.getItem("app-debug")
  )
    return;

  const messages = [
    `%c ${title} %c${taskName ? `${taskName} ` : ""}%c${
      task ? ` ${task}` : ""
    }`,
    "background: #141925; color: #6AC6DA; font-weight: bold;",
    `background: #141925; color: ${MESSAGE_COLOR_MAP[messageType]};`,
    "color: unset; font-weight: bold;",
  ];

  if (args) messages.push(args);

  console.log(...messages);
};

export const appDebugLog = (...args) => appDebug("log", ...args);
export const appDebugWarn = (...args) => appDebug("warn", ...args);
export const appDebugError = (...args) => appDebug("error", ...args);
