import { useEffect } from "react";

// Fires handler every time the Esc button is pressed.
// The key capture is achieved with a mousedown event listener on the window
// object in the bubbling phase, so every other element in the DOM has the
// chance to capture the key first and stop the propagation.
export default function useEscKey(handler) {
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key !== "Escape") return;
      e.preventDefault();
      handler();
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handler]);
}
