import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useEffect,
} from "react";

export const ActionDialogContext = createContext();

export function ActionDialogContextProvider({ children }) {
  const [quickCaptureParent, setQuickCaptureParent] = useState(null);
  const [actionToEdit, setActionToEdit] = useState(null);
  const [newActionParent, setNewActionParent] = useState(null);
  const [newActionEvent, setNewActionEvent] = useState(null);

  useEffect(() => {
    if (newActionParent === null) {
      setNewActionEvent(null);
    }
  }, [newActionParent]);

  const value = useMemo(
    () => ({
      quickCaptureParent,
      setQuickCaptureParent,
      newActionEvent,
      setNewActionEvent,
      actionToEdit,
      setActionToEdit,
      newActionParent,
      setNewActionParent,
    }),
    [quickCaptureParent, newActionEvent, actionToEdit, newActionParent]
  );

  return (
    <ActionDialogContext.Provider value={value}>
      {children}
    </ActionDialogContext.Provider>
  );
}

/**
 * Returns a function to set the default selected parent when the
 * ActionDialog is opened in quick-capture mode
 */
export function useSetQuickCaptureParent() {
  const { setQuickCaptureParent } = useContext(ActionDialogContext);
  return setQuickCaptureParent;
}

/**
 * Returns a function that can be called to show the ActionDialog
 * in edit mode, like showEditActionDialog(action)
 */
export function useShowEditActionDialog() {
  const { setActionToEdit } = useContext(ActionDialogContext);
  // ActionDialog detects changes to this state and automatically
  // becomes visible when a new value is set
  return setActionToEdit;
}

/**
 * Returns a function that can be called to show the ActionDialog
 * in create mode, like showCreateActionDialog(parentId)
 */
export function useShowCreateActionDialog() {
  const { setNewActionParent } = useContext(ActionDialogContext);
  // ActionDialog detects changes to this state and automatically
  // becomes visible when a new value is set
  return setNewActionParent;
}
