import React, { useCallback, useRef, useState } from "react";
import clsx from "clsx";

import {
  deleteExternalProfile,
  setExternalCalendarEnabled,
} from "src/services/ExternalEventsService";
import { getRpmCalendarUrl } from "src/services/AuthService";

import useUniqueId from "src/hooks/useUniqueId";

import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
} from "../ContextualMenu";
import Button, { BUTTON_SIZE_XSMALL } from "../Button";
import { GlobalToast } from "../Toast";
import LoadingSpinner from "../LoadingSpinner";

import { ReactComponent as IconClose } from "../../assets/icons/16-close.svg";
import { ReactComponent as IconMore } from "../../assets/icons/16-more.svg";
import { ReactComponent as IconVisibilityOn } from "../../assets/icons/16-visibility-on.svg";
import { ReactComponent as IconVisibilityOff } from "../../assets/icons/16-visibility-off.svg";
import { ReactComponent as IconCompleted } from "../../assets/icons/16-completed.svg";

import styles from "./ProfileSidebar.module.scss";

function ExternalProfilesCalendars({
  externalProfiles,
  hasCalendarSyncExpire,
}) {
  const calendarOptionsMenuButtonRef = useRef();
  const calendarOptionsMenuButtonId = useUniqueId();
  const [calendarOptionsMenuVisible, setCalendarOptionsMenuVisible] =
    useState(false);
  const [showLinkCopiedToast, setShowLinkCopiedToast] = useState(false);

  const handleCopyLinkClick = useCallback(async () => {
    let url = null;
    try {
      url = await getRpmCalendarUrl();
    } catch (e) {
      console.error(e);
      // There can be exceptions thrown only if the user has logged in with an
      // old version of the application and now has problem reaching the
      // backend servers (see getRpmCalendarUrl() for more info). Since it's a
      // very rare error destined to completely disappear in future I opted for
      // a very lightweight error reporting, without adding extra complexity to
      // this component.
      alert(
        "Error retrieving the URL of the RPM calendar.\nPlease ensure to be connected to the internet and try again later."
      );
      return;
    }
    navigator.clipboard.writeText(url);
    setShowLinkCopiedToast(true);
    setCalendarOptionsMenuVisible(false);
    setTimeout(() => setShowLinkCopiedToast(false), 3000);
  }, []);

  return (
    <ul>
      <li>
        <div className={styles.externalService}>
          <span>My RPM Calendar</span>
          <ContextualMenuTrigger
            visible={calendarOptionsMenuVisible}
            setVisible={setCalendarOptionsMenuVisible}
            menuId={calendarOptionsMenuButtonId}
          >
            <Button
              ref={calendarOptionsMenuButtonRef}
              aria-label="Options"
              size={BUTTON_SIZE_XSMALL}
              tooltip
              iconOnly
            >
              <IconMore role="presentation" />
            </Button>
          </ContextualMenuTrigger>

          {calendarOptionsMenuVisible && (
            <ContextualMenu
              onClose={() => setCalendarOptionsMenuVisible(false)}
              buttonRef={calendarOptionsMenuButtonRef}
              aria-labelledby={calendarOptionsMenuButtonId}
            >
              <ContextualMenuLink onClick={handleCopyLinkClick}>
                Copy link
              </ContextualMenuLink>
            </ContextualMenu>
          )}

          {showLinkCopiedToast && (
            <GlobalToast>Link copied into clipboard</GlobalToast>
          )}
        </div>
      </li>

      {externalProfiles.map((profile) => (
        <li key={profile.id}>
          <div className={styles.externalService}>
            <span>{profile.name}</span>
            <Button
              aria-label={`Disconnect ${profile.name}`}
              onClick={() => deleteExternalProfile(profile.id)}
              iconOnly
              tooltip
              size={BUTTON_SIZE_XSMALL}
            >
              <IconClose role="presentation" />
            </Button>
          </div>
          <ul>
            {profile.calendars.map((calendar) => (
              <li key={calendar.id}>
                <label
                  className={clsx(
                    styles.externalCalendar,
                    calendar.enabled && styles.enabled
                  )}
                >
                  <span>{calendar.name}</span>
                  <div className={styles.externalCalendarInput}>
                    {/* Syncing in progress */}
                    {hasCalendarSyncExpire &&
                      calendar.bi_directional_sync_enabled && (
                        <span title="Syncing in progress">
                          <LoadingSpinner
                            className={styles.spinner}
                            opaque
                            role="presentation"
                          />
                        </span>
                      )}
                    {/* Syncing has been complete */}
                    {!hasCalendarSyncExpire &&
                      calendar.bi_directional_sync_enabled && (
                        <span title="External sync complete">
                          <IconCompleted role="presentation" />
                        </span>
                      )}
                    <input
                      type="checkbox"
                      aria-label={`Enable ${calendar.name}`}
                      title={`${
                        !calendar.enabled ? "Enable" : "Disable"
                      } Calendar`}
                      onChange={() =>
                        setExternalCalendarEnabled(
                          profile.id,
                          calendar.id,
                          !calendar.enabled
                        )
                      }
                      checked={calendar.enabled}
                    />
                    <span className={styles.focusRing} aria-hidden="true" />
                    {calendar.enabled ? (
                      <IconVisibilityOn
                        className={styles.visibilityIcon}
                        role="presentation"
                      />
                    ) : (
                      <IconVisibilityOff
                        className={styles.visibilityIcon}
                        role="presentation"
                      />
                    )}
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export default ExternalProfilesCalendars;
