import React from "react";
import clsx from "clsx";

import Dialog from "../Dialog";

import styles from "./CreateDialog.module.scss";

function CreateDialog({ wide, className, bottomRight, ...props }) {
  return (
    <Dialog
      className={clsx(className, styles.dialog, wide && styles.wide)}
      bottomRight={bottomRight}
      customBody
      {...props}
    />
  );
}

export default CreateDialog;
