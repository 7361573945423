/**
 * @returns {Array} keySet
 * @returns {string} keySet[0] Corresponding KeyboardEvent modifier key
 * @returns {string} keySet[1] Human-readable key
 */
export const getModifierKey = () => {
  const isMac = navigator?.platform?.indexOf("Mac") > -1;

  const key = isMac ? "metaKey" : "ctrlKey";
  const string = isMac ? "⌘" : "Ctrl";

  return [key, string];
};

const [modifierKey, modifierKeyString] = getModifierKey();

export { modifierKey, modifierKeyString };
