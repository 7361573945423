import React from "react";

import { DASHBOARD_URL } from "./App";
import Button, { BUTTON_COLOR_FILL } from "./Button";
import PageNoUi from "./PageNoUi";

function NotFound({ ...props }) {
  return (
    <PageNoUi
      role="alert"
      pageTitle="Not Found"
      heading={"Page Not Found"}
      text={"This page does not\xa0exist."}
      {...props}
    >
      <Button color={BUTTON_COLOR_FILL} linkTo={DASHBOARD_URL} block>
        Go back
      </Button>
    </PageNoUi>
  );
}

export default NotFound;
