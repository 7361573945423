import React from "react";
import clsx from "clsx";

import styles from "./Avatar.module.scss";

import { ReactComponent as IconProfile } from "./../../assets/icons/24-profile.svg";

function Avatar({ className, src, name, ...props }) {
  return (
    <div
      className={clsx(className, styles.avatar)}
      aria-label={`${name || "User"} Avatar`}
      {...props}
    >
      {src && (
        <img className={styles.image} src={src} alt="" width="40" height="40" />
      )}
      {!src && name && <span>{name[0]}</span>}
      {!src && !name && (
        <IconProfile className={styles.icon} role="presentation" />
      )}
    </div>
  );
}

export default Avatar;
