import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { generatePath, useRouteMatch, useHistory } from "react-router-dom";
import clsx from "clsx";

import { BLOCK_DETAIL_URL } from "../App";

import {
  BLOCK,
  ACTION,
  COMPLETED,
  ACTIVE,
  SNOOZED,
  STATE_LABELS,
  PAST_DUE,
  UNSCHEDULED,
  SCHEDULED,
} from "../../services/DbService/constants";
import { composeUnsubscribers } from "src/services/DbService/general";
import { updateBlock } from "src/services/DbService/blocks";
import {
  moveAction,
  watchActiveActionsCounters,
  watchSnoozedActionsCounters,
} from "../../services/DbService/actions";
import { trackEvent } from "../../services/AnalyticsService";

import useUniqueId from "src/hooks/useUniqueId";
import { timeToText } from "src/utils/timeToText";

import { useGetCategoryColor } from "../CategoriesContext";
import { useProjectName } from "../ProjectsContext";
import { CardButton } from "../Card";
import Counter from "../Counters/Counter";
import Dropzone from "../DragAndDrop/Dropzone";
import Tooltip from "../Tooltip";

import CardFavoriteButton from "../Card/CardFavoriteButton";
import CompletionCheckbox from "../CompletionCheckbox/CompletionCheckbox";
import LoadingSpinner from "../LoadingSpinner";
import ActionList from "../ActionList";

import { ReactComponent as IconCollapse } from "../../assets/icons/32-collapse.svg";
import { ReactComponent as IconExpand } from "../../assets/icons/32-expand.svg";
import { ReactComponent as IconActions } from "../../assets/icons/16-actions.svg";
import { ReactComponent as IconExclamation } from "../../assets/icons/16-exclamation.svg";
import { ReactComponent as IconStarOutline } from "../../assets/icons/16-star-outline.svg";

import { BlockItemCompleteDialog, tryCompleteBlock } from "./BlockItemMenu";

import styles from "./BlockItem.module.scss";

function BlockItem({
  className,
  block,
  detailUrl = BLOCK_DETAIL_URL,
  enableDetailLink = true,
  enableDropzone = true,
  snoozedToString,
  enableProjectBanner = true,
  cardWithColor,
  ...props
}) {
  const blockRef = useRef();

  const { id: blockId, result, categoryId, projectId, state, starred } = block;

  const history = useHistory();

  const match = useRouteMatch(detailUrl);
  const active = match?.params.blockId === blockId;

  const projectName = useProjectName(projectId, true);

  const getCategoryColor = useGetCategoryColor();

  const [activeCounters, setActiveCounters] = useState(null);
  const [snoozedCounters, setSnoozedCounters] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const [completionDialogVisible, setCompleteDialogVisible] = useState(false);

  const newState = state === SNOOZED || state === ACTIVE ? COMPLETED : ACTIVE;
  const completed = state === COMPLETED;

  const updateSnoozedCounters = useCallback((counters) => {
    const result = {
      total: 0,
      starred: 0,
      numberOfActions: 0,
      numberOfStars: 0,
    };

    for (const key in counters) {
      result.total = (result.total || 0) + counters[key].total;
      result.starred = (result.starred || 0) + counters[key].starred;
      result.numberOfActions =
        (result.numberOfActions || 0) + counters[key].numberOfActions;
      result.numberOfStars =
        (result.numberOfStars || 0) + counters[key].numberOfStars;
    }

    setSnoozedCounters(result);
  }, []);

  useEffect(() => {
    if (!blockId) return;
    return composeUnsubscribers(
      watchActiveActionsCounters(blockId, BLOCK, setActiveCounters),
      watchSnoozedActionsCounters(blockId, BLOCK, updateSnoozedCounters)
    );
  }, [blockId, updateSnoozedCounters]);

  const totalCounters = useMemo(() => {
    if (activeCounters && snoozedCounters) {
      return {
        total: activeCounters.total + snoozedCounters.total,
        starred: activeCounters.starred + snoozedCounters.starred,
        numberOfActions:
          activeCounters.numberOfActions + snoozedCounters.numberOfActions,
        numberOfStars:
          activeCounters.numberOfStars + snoozedCounters.numberOfStars,
      };
    }

    return null;
  }, [activeCounters, snoozedCounters]);

  const totalTimeText = useMemo(
    () => timeToText(totalCounters?.total || 0),
    [totalCounters]
  );

  const starredText = useMemo(
    () => timeToText(totalCounters?.starred),
    [totalCounters]
  );

  const handleActionDropped = useCallback(
    async (e) => {
      const { id: actionId, state } = e.item.dataset;

      let newState = state;
      // Check if block is active, set incoming action state to active.
      if (block.state === ACTIVE) {
        newState = ACTIVE;
      }

      await moveAction(actionId, blockId, BLOCK, newState);

      // after dropping an action in the block, open the block display
      setExpanded(true);
    },
    [blockId, block]
  );

  const handleStarClick = useCallback(
    (e) => {
      e.stopPropagation();
      updateBlock(blockId, { starred: !starred });
    },
    [blockId, starred]
  );

  const handleMakeBlockActive = useCallback(() => {
    updateBlock(blockId, { state: ACTIVE });
  }, [blockId]);

  const onToggleCompletionButton = useCallback(
    async (e, key) => {
      e.stopPropagation();

      if (state === COMPLETED) {
        handleMakeBlockActive();
      } else {
        e.currentTarget.blur();

        await tryCompleteBlock(blockId, () => setCompleteDialogVisible(true));
      }
    },
    [blockId, state, handleMakeBlockActive]
  );

  const handleEditBlock = useCallback(() => {
    const blockUrl = generatePath(detailUrl, { blockId });

    trackEvent("User clicked a Block Item", {
      id: blockId,
      state,
      starred,
    });

    history.push(blockUrl);
  }, [history, detailUrl, blockId, starred, state]);

  const pastDueId = useUniqueId();
  const unscheduledId = useUniqueId();
  const snoozedId = useUniqueId();
  const scheduledId = useUniqueId();

  const sectionFilters = useMemo(() => {
    return [
      {
        id: pastDueId,
        label: STATE_LABELS[PAST_DUE].subheading,
        state: ACTIVE,
        filter: (action) => action.event?.startDate < new Date(),
      },
      {
        id: unscheduledId,
        label: STATE_LABELS[UNSCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) => !action.event?.startDate,
      },
      {
        id: scheduledId,
        label: STATE_LABELS[SCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) => action.event?.startDate >= new Date(),
      },
      {
        id: snoozedId,
        label: STATE_LABELS[SNOOZED].subheading,
        state: SNOOZED,
        filter: () => Boolean,
      },
    ];
  }, [pastDueId, unscheduledId, snoozedId, scheduledId]);

  // The active state is the reverse of the default, so when the cardWithColor
  // prop is true, the active state is a card without colour and vice versa
  const isCardWithColor =
    (!active && cardWithColor) || (active && !cardWithColor);

  const tooltip = `Edit Block: ${result}`;

  const numberOfPastDueEvents = activeCounters?.numberOfPastDueEvents || 0;
  const numberOfActions = totalCounters?.numberOfActions || 0;
  const numberOfStars = totalCounters?.numberOfStars || 0;

  return (
    <li
      ref={blockRef}
      className={clsx(className, styles.cardContainer)}
      {...props}
    >
      {enableDropzone && (
        <Dropzone
          className={styles.dropzone}
          allowDrop={(to, from, item) =>
            // You can drop here only actions and only if they are not already
            // children of this block, otherwise a strange UI bug happens:
            // you drag an action from a block to the block itself using this
            // dropzone, and the action disappear from the block, becase
            // SortableJS removes it from the DOM, even if in the data model
            // it is still there (since the drag operation did not change the
            // parent), so reloading the block's children the action returns.
            item.dataset.type === ACTION && item.dataset.blockId !== blockId
          }
          onAdd={handleActionDropped}
        />
      )}
      <div
        className={clsx(styles.card)}
        role="button"
        aria-label={tooltip}
        onClick={handleEditBlock}
        tabIndex={0}
        data-category-color={getCategoryColor(categoryId)}
      >
        {enableProjectBanner && (
          <span aria-hidden={!projectId}>
            {projectId && (
              <Tooltip title={projectName}>
                <span>{projectName}</span>
              </Tooltip>
            )}
          </span>
        )}
        <div className={clsx(styles.content)}>
          <div className={styles.completionWrapper}>
            <CompletionCheckbox
              className={clsx(
                "sortable-ignore",
                styles.completionButton,
                categoryId !== 0 && cardWithColor && styles.cardWithColor
              )}
              onClick={(e) => onToggleCompletionButton(e)}
              label={
                newState === ACTIVE ? "Mark as active" : "Mark as completed"
              }
              completed={completed}
              categoryColor={cardWithColor}
            />
          </div>

          <div className={styles.info}>
            <Tooltip title={tooltip}>
              <span className={styles.label}>
                <span className={styles.result}>
                  <span>{result}</span>
                </span>
              </span>
            </Tooltip>
          </div>

          <div className={styles.blockButtons}>
            <CardFavoriteButton
              className={clsx(
                starred && styles.starredBlockFavoriteButton,
                "sortable-ignore"
              )}
              selected={starred}
              aria-label={starred ? "Unstar block" : "Star block"}
              onClick={handleStarClick}
              cardWithColor={isCardWithColor}
            />
          </div>
        </div>

        <div className={styles.optionsBar}>
          <div className={clsx(styles.options)}>
            {totalCounters ? (
              <>
                <span>
                  <span className={styles.counterGroup}>
                    <Counter
                      icon={
                        numberOfPastDueEvents > 0
                          ? IconExclamation
                          : IconActions
                      }
                      short={numberOfActions}
                      long={`${numberOfActions} action${
                        numberOfActions > 1 ? "s" : ""
                      } in the block`}
                      className={numberOfPastDueEvents > 0 && styles.negative}
                    />

                    {totalTimeText?.dropdown ? (
                      <Counter
                        className={styles.counterSpace}
                        short={totalTimeText.short}
                        long={`${totalTimeText.long} to be spent on this block`}
                      />
                    ) : (
                      <span className={styles.counterSpace} />
                    )}
                  </span>

                  <span className={styles.counterGroup}>
                    {numberOfStars > 0 && (
                      <Counter
                        icon={IconStarOutline}
                        short={numberOfStars}
                        long={`${numberOfStars} starred actions`}
                      />
                    )}

                    {starredText?.dropdown && (
                      <Counter
                        className={styles.counterSpace}
                        short={starredText.short}
                        long={`${starredText.long} starred actions`}
                      />
                    )}
                  </span>
                </span>

                {numberOfActions > 0 && (
                  <CardButton
                    aria-label={expanded ? "Collapse block" : "Expand block"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(!expanded);
                    }}
                    className={styles.expandButton}
                    cardWithColor={cardWithColor}
                    disabled={numberOfActions === 0}
                  >
                    {expanded ? (
                      <IconCollapse role="presentation" />
                    ) : (
                      <IconExpand role="presentation" />
                    )}
                  </CardButton>
                )}
              </>
            ) : (
              <span className={styles.loader}>
                <LoadingSpinner absolute />
              </span>
            )}
          </div>
        </div>
        <div
          className={clsx(styles.actionsList, expanded && styles.open)}
          onClick={(e) => e.stopPropagation()}
        >
          <ActionList
            parentId={blockId}
            parentType={BLOCK}
            sectionFilters={sectionFilters}
            enableActionNumbers
            enableProjectBanner={false}
          />
        </div>
      </div>
      {completionDialogVisible && (
        <BlockItemCompleteDialog
          blockId={blockId}
          onClose={() => setCompleteDialogVisible(false)}
        />
      )}
    </li>
  );
}

export default BlockItem;
