import { PROJECT_ACTIVE, PROJECT_NAME_MAX_LENGTH } from "./constants";
import {
  firestore,
  collection,
  document,
  ParameterError,
  generateId,
} from "./general";
import { trackEvent } from "../AnalyticsService";
import FirestoreRollingBatch from "./FirestoreRollingBatch";

export class Project {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    if (!data) return;

    return Object.assign(new Project(), {
      id: snapshot.id,
      name: data.name,
      state: data.state || PROJECT_ACTIVE,
      actionIds: [], // TODO: Add actions support
      blockIds: data.block_ids,
      vision: data.vision || "",
      purpose: data.purpose || "",
    });
  }
}

/**
 * Subscribes to a watcher for all projects of a state changing
 * TODO: Check requirements long term for these states, currently PROJECT_ACTIVE and
 * PROJECT_HIDDEN are currently supported, but there needs to be a completed state
 * @param  {String} state
 * @param  {Function} callback
 */
export function watchProjects(state, callback) {
  return collection("projects")
    .where("state", "==", state) // TODO: Check requirements around active/hidden
    .orderBy("name", "asc")
    .onSnapshot((snapshot) => {
      const projects = snapshot.docs.map(Project.fromFirestore);
      callback(projects);
    });
}

/**
 * Subscribes to a watcher for a specific project changing
 * @param  {String} projectId
 * @param  {Function} callback
 */
export function watchProject(projectId, callback) {
  return document(`projects/${projectId}`).onSnapshot((docSnapshot) => {
    callback(Project.fromFirestore(docSnapshot));
  });
}

/**
 * Create a new project with an optional callback
 * @param  {String} name the project name
 * @param  {Function} callback=null callback in lieu of async/await support
 */
export function createProject(name, callback = null) {
  if (typeof name !== "string")
    throw new ParameterError({ name }, "not a string");
  const nameTrim = name.trim();
  if (nameTrim === "") throw new ParameterError({ name }, "empty after trim");
  if (nameTrim.length > PROJECT_NAME_MAX_LENGTH)
    throw new ParameterError(
      { name },
      "length must be <= " + PROJECT_NAME_MAX_LENGTH
    );
  if (callback !== null && typeof callback !== "function")
    throw new ParameterError({ callback }, 'must be "null" or a function');

  const id = generateId();

  const newProject = {
    id,
    name: nameTrim,
    is_built_in: false,
    is_editable: true,
    state: PROJECT_ACTIVE,
  };

  const batch = firestore().batch();

  batch.set(document(`projects/${id}`), newProject);

  batch.commit();

  trackEvent("User created a Project", {
    id,
    nameLength: nameTrim.length,
  });

  callback && callback(id);
}

/**
 * Update the values of a project
 * @param  {String} projectId
 * @param  {String} name
 * @param  {String} vision
 * @param  {String} purpose
 */
export async function updateProject(projectId, name, vision, purpose) {
  if (typeof name !== "string")
    throw new ParameterError({ name }, "not a string");
  const nameTrim = name.trim();
  if (nameTrim === "") throw new ParameterError({ name }, "empty after trim");
  if (nameTrim.length > PROJECT_NAME_MAX_LENGTH)
    throw new ParameterError(
      { name },
      "length must be <= " + PROJECT_NAME_MAX_LENGTH
    );

  if (typeof vision !== "string")
    throw new ParameterError(
      { vision },
      "must be a string (empty strings are permitted)"
    );
  if (typeof purpose !== "string")
    throw new ParameterError(
      { purpose },
      "must be a string (empty strings are permitted)"
    );

  document(`projects/${projectId}`).update({
    name: nameTrim,
    vision: vision.trim(),
    purpose: purpose.trim(),
  });
}

/**
 * Retrieve a fromFirestore processed project by id
 * @param  {String} projectId
 */
export async function getProject(projectId) {
  const docSnapshot = await document(`projects/${projectId}`).get();
  return Project.fromFirestore(docSnapshot);
}

/**
 * Deletes a project from Firestore.
 * @param {String} projectId
 */
export async function deleteProject(projectId) {
  const projectRef = document(`projects/${projectId}`);
  const projectBlockRefs = collection(`blocks`).where(
    "parent_project_id",
    "==",
    projectId
  );
  const projectActionRefs = collection(`actions`).where(
    "parent_project_id",
    "==",
    projectId
  );

  const batch = new FirestoreRollingBatch();

  const [blockSnapshot, actionSnapshot] = await Promise.all([
    projectBlockRefs.get(),
    projectActionRefs.get(),
  ]);

  removeParentProjectId(blockSnapshot, batch);
  removeParentProjectId(actionSnapshot, batch);

  // Finally delete project.
  batch.delete(projectRef);

  // Batch commit.
  batch.commit();
}

function removeParentProjectId(snapshot, batch) {
  for (const doc of snapshot.docs) {
    batch.update(doc.ref, { parent_project_id: null });
  }
}
