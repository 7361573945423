import React, { useState, createContext, useEffect, useMemo } from "react";

import { getUser, watchUser } from "src/services/AuthService";
import { getDbUser } from "src/services/DbService/user";

const MILLISECONDS_IN_MINUTE = 1000 * 60;
const AUTH_POLL_INTERVAL = 5 * MILLISECONDS_IN_MINUTE;

export const AUTH_STATE_LOADING = "loading";
export const AUTH_STATE_READY = "ready";

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  // Deliberately empty as a valid user is null
  const [user, setUser] = useState();
  const [authState, setAuthState] = useState(AUTH_STATE_LOADING);

  useEffect(() => {
    return watchUser((newUser) => {
      if (newUser instanceof Error) {
        setAuthState(newUser);
        return;
      }

      if (!newUser) {
        setUser(null);
        return;
      }

      const { firstName, lastName, username } = newUser;
      const fullName = `${firstName || ""} ${lastName || ""}`.trim();

      setUser({
        ...newUser,
        fullName,
        displayName: fullName || username || "",
      });
    });
  }, []);

  useEffect(() => {
    if (user === undefined) return;

    // User is logged out, the app is ready
    if (user === null) {
      setAuthState(AUTH_STATE_READY);
      return;
    }

    // User is logged in, we need to check if their user exists in the DB, and
    // return the error state if it is not. It is written as a Promise as the
    // syntax is slightly easier than an async useEffect
    getDbUser()
      .then(() => {
        setAuthState(AUTH_STATE_READY);
      })
      .catch((err) => {
        setAuthState(err);
      });
  }, [user]);

  // Cognito has no onSnapshot equivalent to easily watch for changes, so a background
  // poll at 5 minutes is used to keep user data up to date. It is also refreshed in instances
  // where up to date info is needed – profile sidebar for example.
  useEffect(() => {
    if (!user) return;

    const pollUser = setInterval(getUser, AUTH_POLL_INTERVAL);

    return () => clearInterval(pollUser);
  }, [user]);

  const value = useMemo(() => {
    return {
      user,
      authState,
      setAuthState,
    };
  }, [user, authState]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
