import React from "react";
import clsx from "clsx";

import FormError from "../FormError";

import styles from "./AuthForm.module.scss";

function AuthForm({
  className,
  error,
  loading,
  buttons,
  bottomText,
  children,
  ...props
}) {
  return (
    <form className={clsx(styles.form, loading && styles.loading)} {...props}>
      <div className={styles.wrapper}>
        {children}

        <FormError className={styles.error} error={error} />

        {buttons && <div className={styles.buttons}>{buttons}</div>}

        {bottomText && <p className={styles.bottom}>{bottomText}</p>}
      </div>
    </form>
  );
}

export default AuthForm;
