import React, { useEffect, useState, useCallback, useMemo } from "react";
import { add, isWithinInterval } from "date-fns";

import {
  ACTIVE,
  SNOOZED,
  COMPLETED,
  STATE_LABELS,
  BLOCK,
} from "../../../services/DbService/constants";
import { watchActiveChildren } from "src/services/DbService/general";
import { watchCompletedActions } from "../../../services/DbService/actions";
import { deleteEvent } from "../../../services/DbService/events";

import useUniqueId from "../../../hooks/useUniqueId";
import usePreviousValue from "../../../hooks/usePreviousValue";
import useAction from "src/hooks/useAction";

import Button, { BUTTON_SIZE_SMALL } from "../../Button";
import TabBar from "../../TabBar";
import TabPanel from "../../TabPanel";
import ActionItem from "../../ActionItem";
import EventDialog from "./../EventDialog";
import EmptyState from "../../EmptyState";
import LoadingSpinner from "../../LoadingSpinner";

import { ReactComponent as IconTrash } from "../../../assets/icons/16-trash.svg";

import styles from "./ScheduledEventDialog.module.scss";

const SCHEDULED_EVENT_DIALOG_HEIGHT = 450;

const SCHEDULED_EVENT_PENDING_LABEL = "Pending";
const SCHEDULED_EVENT_CURRENT_LABEL = "Current";

function ScheduledEventDialog({
  eventId,
  category,
  block,
  actions,
  onClose,
  boxRef,
  startDate,
  duration,
  style = {},
  ...props
}) {
  const [actionsActive, setActionsActive] = useState(null);
  const [actionsCompleted, setActionsCompleted] = useState(null);
  const [activeTab, setActiveTab] = useState(false);
  const [now, setNow] = useState(new Date());

  const { mostRecentEventByActionId } = useAction(block.id, BLOCK);

  const endDate = useMemo(() => {
    return add(startDate, { seconds: duration });
  }, [startDate, duration]);

  const isEventHappeningNow = isWithinInterval(now, {
    start: startDate,
    end: endDate,
  });

  const activeTabLabel = isEventHappeningNow
    ? SCHEDULED_EVENT_CURRENT_LABEL
    : SCHEDULED_EVENT_PENDING_LABEL;

  // When there are actions in the `actions` prop, the full block actions aren't shown.
  useEffect(() => {
    if (!block || actions.length) return;

    return watchActiveChildren(block.id, BLOCK, setActionsActive);
  }, [block, actions]);

  useEffect(() => {
    if (!block || actions.length) return;
    const limit = null;
    return watchCompletedActions(block.id, BLOCK, limit, setActionsCompleted);
  }, [block, actions]);

  useEffect(() => {
    if (!actions.length) return;

    const orderedActions = {
      [ACTIVE]: [],
      [COMPLETED]: [],
    };

    actions.forEach((action) => {
      // Snoozed items are not shown in either list so are ignored from the filtering
      if (action.state === SNOOZED) return;
      orderedActions[action.state].push(action);
    });

    setActionsActive(orderedActions[ACTIVE]);
    setActionsCompleted(orderedActions[COMPLETED]);
  }, [actions]);

  // When both actionsActive and actionsCompleted have been set, the initial tab state
  // is determined. If neither have any actions it is left as null to display a singular
  // empty state with no tab bar.
  const prevActiveTab = usePreviousValue(activeTab);

  useEffect(() => {
    // This check should only be set the dialog is first opened
    if (prevActiveTab) return;

    if (actionsActive === null || actionsCompleted === null) return;

    if (actionsActive.length) {
      setActiveTab(ACTIVE);
    } else if (actionsCompleted.length) {
      setActiveTab(COMPLETED);
    } else {
      setActiveTab(null);
    }
  }, [prevActiveTab, actionsActive, actionsCompleted]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleDelete = useCallback(() => {
    deleteEvent(eventId);
    onClose();
  }, [eventId, onClose]);

  const HeaderDeleteButton = () => (
    <Button
      iconOnly
      aria-label="Delete event"
      onClick={handleDelete}
      size={BUTTON_SIZE_SMALL}
    >
      <IconTrash role="presentation" />
    </Button>
  );

  const TABS = [
    {
      state: ACTIVE,
      id: useUniqueId(),
      label: activeTabLabel,
    },
    {
      state: COMPLETED,
      id: useUniqueId(),
      label: STATE_LABELS[COMPLETED].subheading,
    },
  ];

  return (
    <EventDialog
      boxRef={boxRef}
      onClose={onClose}
      categoryId={category?.id}
      startDate={startDate}
      eventName={block?.result || category?.name || ""}
      dialogHeight={SCHEDULED_EVENT_DIALOG_HEIGHT}
      headerButtons={
        <>
          <HeaderDeleteButton />
        </>
      }
      preBodyChildren={
        activeTab && (
          <TabBar
            className={styles.dialogTabBar}
            active={activeTab && TABS.find((tab) => tab.state === activeTab).id}
            tabs={TABS}
            onTabClick={(tabId) =>
              setActiveTab(TABS.find((tab) => tab.id === tabId).state)
            }
            categoryId={category?.id}
          />
        )
      }
      style={{
        ...style,
        "--dialog-header-max-lines": 6,
      }}
      {...props}
    >
      {activeTab === false && <LoadingSpinner absolute />}

      {activeTab === null && <EmptyState actions>No&nbsp;actions</EmptyState>}

      {activeTab &&
        TABS.map((tab) => {
          const tabActions =
            tab.state === ACTIVE ? actionsActive : actionsCompleted;

          return (
            <TabPanel id="active" active={tab.state === activeTab} key={tab.id}>
              {!tabActions.length && (
                <EmptyState actions>No {tab.state}&nbsp;actions</EmptyState>
              )}

              <ul className={styles.actionsList}>
                {tabActions.map((action) => (
                  <ActionItem
                    key={action.id}
                    action={action}
                    event={mostRecentEventByActionId[action.id]}
                  />
                ))}
              </ul>
            </TabPanel>
          );
        })}
    </EventDialog>
  );
}

export default ScheduledEventDialog;
