import React, { useCallback, useRef, useState } from "react";

import useUniqueId from "../../hooks/useUniqueId";

import { PROJECT, TYPE_LABELS } from "src/services/DbService/constants";

import ContextualMenu, {
  ContextualMenuTrigger,
  ContextualMenuLink,
  ContextualMenuTaxonomy,
} from ".";

export function ContextualMenuProjects({
  activeProjectId,
  onProjectClick,
  onClose,
}) {
  const projectMenuButtonRef = useRef();
  const projectMenuButtonId = useUniqueId();
  const [projectMenuVisible, setProjectMenuVisible] = useState(null);

  const handleProjectClick = useCallback(
    (projectId) => {
      onProjectClick && onProjectClick(projectId);
      setProjectMenuVisible(false);
      onClose && onClose();
    },
    [onProjectClick, onClose]
  );

  return (
    <>
      <ContextualMenuTrigger
        visible={projectMenuVisible}
        setVisible={setProjectMenuVisible}
        menuId={projectMenuButtonId}
        submenu
      >
        <ContextualMenuLink ref={projectMenuButtonRef} submenu>
          {activeProjectId ? "Change" : "Assign to"} {TYPE_LABELS.project.title}
        </ContextualMenuLink>
      </ContextualMenuTrigger>

      {projectMenuVisible && (
        <ContextualMenu
          buttonRef={projectMenuButtonRef}
          onClose={() => setProjectMenuVisible(false)}
          submenu
          aria-labelledby={projectMenuButtonId}
        >
          {activeProjectId && (
            <ContextualMenuLink onClick={() => handleProjectClick(null)}>
              Remove from {TYPE_LABELS.project.text}
            </ContextualMenuLink>
          )}
          <ContextualMenuTaxonomy
            parentType={PROJECT}
            onItemClick={handleProjectClick}
            activeId={activeProjectId}
          />
        </ContextualMenu>
      )}
    </>
  );
}

export default ContextualMenuProjects;
