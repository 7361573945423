import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import usePreviousValue from "../../../hooks/usePreviousValue";
import useUniqueId from "../../../hooks/useUniqueId";

import {
  ACTIVE,
  COMPLETED,
  SNOOZED,
  BLOCK_ACTIONS_COMPLETION_THRESHOLD,
  BLOCK_RESULT_MAX_LENGTH,
  BLOCK_PURPOSE_MAX_LENGTH,
  BLOCK_ROLE_MAX_LENGTH,
  BLOCK,
  ACTION,
  TYPE_LABELS,
  STATE_LABELS,
  PAST_DUE,
  UNSCHEDULED,
  UNCATEGORIZED,
  SCHEDULED,
  UNCATEGORIZED_ID,
} from "src/services/DbService/constants";
import { composeUnsubscribers } from "src/services/DbService/general";
import {
  watchBlock,
  watchBlockCompletionProgress,
  updateBlock,
} from "src/services/DbService/blocks";
import {
  watchActiveActionsCounters,
  watchSnoozedActionsCounters,
} from "src/services/DbService/actions";
import fireConfetti from "src/services/ConfettiService";

import {
  useShowCreateActionDialog,
  useSetQuickCaptureParent,
} from "../../ActionDialog";
import { BLOCK_DETAIL_URL, DASHBOARD_URL } from "../../App";
import { useGetCategoryColor, useCategories } from "../../CategoriesContext";
import { useProjectName } from "../../ProjectsContext";
import Accordion from "../../Accordion";
import CreateButton from "../../CreateButton";
import DueDateButton from "../../DueDateButton";
import Dialog from "../../Dialog";
import Heading, { HEADING_LEVEL_3, HEADING_LEVEL_5 } from "../../Heading";
import Button from "../../Button";
import Toast, { Toaster } from "../../Toast";
import { BlockItemCompleteDialog, tryCompleteBlock } from "../../BlockItem";
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from "../../SmartLink";
import Tooltip from "../../Tooltip";
import { BlockItemMenu } from "../../BlockItem";
import { ContextualMenuTrigger } from "../../ContextualMenu";
import ChildrenList from "../../ChildrenList";
import { ActionsCounters } from "../../Counters";
import ActionList from "src/components/ActionList";

import DetailPanelWrapper from "../DetailPanelWrapper";
import DetailPanelHero from "../DetailPanelHero";
import DetailPanelForm from "../DetailPanelForm";

import { ReactComponent as IconSnoozed } from "../../../assets/icons/16-snoozed.svg";
import { ReactComponent as IconCompleted } from "../../../assets/icons/16-completed.svg";
import { ReactComponent as IconCompletedInverted } from "../../../assets/icons/16-completed-inverted.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/16-close.svg";
import { ReactComponent as IconMore } from "../../../assets/icons/16-more.svg";

import styles from "./BlockDetail.module.scss";

function BlockDetail({
  previousUrl = null,
  detailUrl = BLOCK_DETAIL_URL,
  ...props
}) {
  const { blockId: paramBlockId } = useParams();

  const [editing, setEditing] = useState(null);
  const [block, setBlock] = useState({});
  const [showCompleted, setShowCompleted] = useState(false);
  const [completedActionsTitle, setCompletedActionsTitle] = useState(null);
  const prevState = usePreviousValue(block.state);

  const {
    id: blockId,
    categoryId,
    projectId,
    dueDate,
    result = "",
    state,
    role,
    backgroundImage,
    purpose,
  } = block;

  const { hiddenCategories } = useCategories();
  const getCategoryColor = useGetCategoryColor();
  const projectName = useProjectName(projectId);

  const [pdfExporting, setPdfExporting] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const [completionProgress, setCompletionProgress] = useState(null);

  const setQuickCaptureParent = useSetQuickCaptureParent();
  const showCreateActionDialog = useShowCreateActionDialog();

  const completionProgressToastId = useUniqueId();
  const snoozedToastId = useUniqueId();
  const completedToastId = useUniqueId();

  const [completionDialogVisible, setCompleteDialogVisible] = useState(false);
  const [makeActiveDialogVisible, setMakeActiveDialogVisible] = useState(false);

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);

  useEffect(() => watchBlock(paramBlockId, setBlock), [paramBlockId]);

  const isCategoryHidden = useMemo(() => {
    if (!hiddenCategories || !categoryId) return;
    return !!hiddenCategories.find((category) => category.id === categoryId);
  }, [hiddenCategories, categoryId]);

  const [activeCounters, setActiveCounters] = useState(null);
  const [snoozedCounters, setSnoozedCounters] = useState(null);

  const updateSnoozedCounters = useCallback((counters) => {
    const result = {
      total: 0,
      starred: 0,
      numberOfActions: 0,
      numberOfStars: 0,
    };

    for (const key in counters) {
      result.total = (result.total || 0) + counters[key].total;
      result.starred = (result.starred || 0) + counters[key].starred;
      result.numberOfActions =
        (result.numberOfActions || 0) + counters[key].numberOfActions;
      result.numberOfStars =
        (result.numberOfStars || 0) + counters[key].numberOfStars;
    }

    setSnoozedCounters(result);
  }, []);

  const totalCounters = useMemo(() => {
    if (activeCounters && snoozedCounters) {
      return {
        total: activeCounters.total + snoozedCounters.total,
        starred: activeCounters.starred + snoozedCounters.starred,
        numberOfActions:
          activeCounters.numberOfActions + snoozedCounters.numberOfActions,
        numberOfStars:
          activeCounters.numberOfStars + snoozedCounters.numberOfStars,
      };
    }

    return null;
  }, [activeCounters, snoozedCounters]);

  useEffect(() => {
    if (!blockId) return;
    return composeUnsubscribers(
      watchActiveActionsCounters(blockId, BLOCK, setActiveCounters),
      watchSnoozedActionsCounters(blockId, BLOCK, updateSnoozedCounters)
    );
  }, [blockId, updateSnoozedCounters]);

  // Rather than throwing the celebration modal onComplete and limiting it to just the
  // BlockDetailHeader menu, this uses the previous block state and watches for changes.
  // When it detects a change to a completion, it throws the celebration dialog and
  // fires the confetti cannons!
  useEffect(() => {
    if (!prevState) return;

    if (state === COMPLETED && prevState !== COMPLETED) {
      fireConfetti();
    }
  }, [state, prevState]);

  useEffect(() => {
    // If the block is completed or category is hidden the
    // quick capture parent should be left as quick capture
    if (!blockId || state === COMPLETED || isCategoryHidden) return;
    setQuickCaptureParent(blockId);
    return () => setQuickCaptureParent(null);
  }, [blockId, state, setQuickCaptureParent, isCategoryHidden]);

  useEffect(() => {
    if (!blockId) return;
    if (state !== ACTIVE) {
      setCompletionProgress(null);
      return;
    }
    return watchBlockCompletionProgress(blockId, state, setCompletionProgress);
  }, [blockId, state]);

  useEffect(() => {
    if (state === COMPLETED) {
      // Expand completed actions when the block is/becomes completed
      setShowCompleted(true);
    }
  }, [state]);

  const handleCompleteClick = useCallback(async () => {
    await tryCompleteBlock(blockId, () => setCompleteDialogVisible(true));
  }, [blockId]);

  const handleMakeBlockActive = useCallback(() => {
    updateBlock(blockId, { state: ACTIVE });
    setMakeActiveDialogVisible(false);
  }, [blockId]);

  const handlePdfExport = useCallback(async () => {
    setPdfExporting(true);

    // This is downloaded asyncronously to avoid lumping all of the Pdf bundlers
    // in with the page load when most users won't use it
    const { generateBlockPdf } = await import("src/services/PdfService");

    await generateBlockPdf(blockId);

    setPdfExporting(false);
    setPdfVisible(false);
  }, [blockId]);

  const handleDueDateChange = useCallback(
    (newDate) => {
      updateBlock(blockId, { dueDate: newDate });
    },
    [blockId]
  );

  const handleSubmit = useCallback(
    (values) => {
      const { result, purpose, role, backgroundImage } = values;

      updateBlock(blockId, { result, purpose, role, backgroundImage });

      setEditing(null);
    },
    [blockId, setEditing]
  );

  const readonly = state === COMPLETED;

  const handleFetchCompleted = useCallback((actions = [], hasMore) => {
    let title = null;
    if (actions.length) {
      const count = `${actions.length}${hasMore ? "+" : ""}`;
      const actionLabel =
        count !== "1"
          ? TYPE_LABELS.action.titlePlural
          : TYPE_LABELS.action.title;
      title = `${count} ${STATE_LABELS.completed.title} ${actionLabel}`;
    }
    setCompletedActionsTitle(title);
  }, []);

  const pastDueId = useUniqueId();
  const unscheduledId = useUniqueId();
  const uncategorizedId = useUniqueId();
  const snoozedId = useUniqueId();
  const scheduledId = useUniqueId();

  const sectionFilters = useMemo(() => {
    return [
      {
        id: pastDueId,
        label: STATE_LABELS[PAST_DUE].subheading,
        state: ACTIVE,
        filter: (action) => action.event?.startDate < new Date(),
      },
      {
        id: unscheduledId,
        label: STATE_LABELS[UNSCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) => !action.event?.startDate,
      },
      {
        id: uncategorizedId,
        label: STATE_LABELS[UNCATEGORIZED].subheading,
        state: ACTIVE,
        filter: (action) =>
          action.categoryId === UNCATEGORIZED_ID &&
          action.event?.startDate >= new Date(),
      },
      {
        id: scheduledId,
        label: STATE_LABELS[SCHEDULED].subheading,
        state: ACTIVE,
        filter: (action) =>
          action.categoryId !== UNCATEGORIZED_ID &&
          action.event?.startDate >= new Date(),
      },
      {
        id: snoozedId,
        label: STATE_LABELS[SNOOZED].subheading,
        state: SNOOZED,
        filter: () => Boolean,
      },
    ];
  }, [pastDueId, unscheduledId, uncategorizedId, snoozedId, scheduledId]);

  // Calculates a number between 0 and 1 to represent the length of the title compared to the
  // maximum length which is used for dynamic sizing the long headers.
  const titleLengthLimit =
    1 - (BLOCK_RESULT_MAX_LENGTH - result.length) / BLOCK_RESULT_MAX_LENGTH;

  const toaster = (
    <Toaster>
      {!editing && completionProgress && (
        <Toast
          id={completionProgressToastId}
          aria-label={
            completionProgress === "threshold"
              ? `You have completed more than ${
                  BLOCK_ACTIONS_COMPLETION_THRESHOLD * 100
                }% of actions`
              : "You have completed all starred actions"
          }
          tooltip
          icon={IconCompleted}
          onClick={handleCompleteClick}
        >
          Complete Block
        </Toast>
      )}
      {!editing && block.state === COMPLETED && (
        <Toast
          id={completedToastId}
          onClick={() => setMakeActiveDialogVisible(true)}
          icon={IconCompletedInverted}
          categoryId={categoryId}
        >
          Block Completed
        </Toast>
      )}
      {!editing && block.state === SNOOZED && (
        <Toast
          id={snoozedToastId}
          icon={IconSnoozed}
          onClick={() => setMakeActiveDialogVisible(true)}
        >
          Block snoozed
        </Toast>
      )}
    </Toaster>
  );

  return (
    <DetailPanelWrapper
      name={result}
      editing={editing}
      loading={!blockId}
      outsideCard={toaster}
      data-category-color={getCategoryColor(categoryId)}
      {...props}
    >
      {!editing && (
        <>
          <DetailPanelHero
            className={styles.hero}
            backgroundImage={backgroundImage}
            categoryId={categoryId}
          >
            <div className={styles.buttons}>
              {(state !== COMPLETED || !!dueDate) && (
                <DueDateButton
                  date={dueDate}
                  onChange={handleDueDateChange}
                  categoryId={categoryId}
                  disabled={state === COMPLETED}
                />
              )}

              <Button onClick={() => setEditing("result")}>
                Edit
                <span className="sr-only">{TYPE_LABELS.block.title}</span>
              </Button>
              <ContextualMenuTrigger
                visible={optionsMenuVisible}
                setVisible={setOptionsMenuVisible}
                menuId={optionsMenuButtonId}
              >
                <Button
                  ref={optionsMenuButtonRef}
                  aria-label="Block tools"
                  tooltip
                  iconOnly
                >
                  <IconMore role="presentation" />
                </Button>
              </ContextualMenuTrigger>
              <BlockItemMenu
                block={block}
                previousUrl={previousUrl}
                detailUrl={detailUrl}
                onExportPdf={() => setPdfVisible(true)}
                menuVisible={optionsMenuVisible}
                buttonRef={optionsMenuButtonRef}
                onClose={() => setOptionsMenuVisible(false)}
                aria-labelledby={optionsMenuButtonId}
              />

              <Button
                linkTo={previousUrl ? previousUrl : DASHBOARD_URL}
                iconOnly
                aria-label="Back"
              >
                <IconClose role="presentation" />
              </Button>
            </div>

            <div className={clsx(styles.group, styles.heroGroup)}>
              <div
                className={clsx(
                  styles.result,
                  backgroundImage && styles.resultHasColor
                )}
              >
                <h1>
                  <Heading
                    level={HEADING_LEVEL_5}
                    tag="span"
                    className={styles.label}
                  >
                    Result
                  </Heading>
                  <Heading
                    level={HEADING_LEVEL_3}
                    tag="span"
                    className={styles.title}
                    style={{
                      "--title-length-limit": titleLengthLimit,
                    }}
                  >
                    {result}
                  </Heading>
                </h1>
              </div>

              <div className={styles.purpose}>
                <Heading
                  level={HEADING_LEVEL_5}
                  tag="h2"
                  className={styles.label}
                >
                  Purpose
                </Heading>
                {purpose || state === COMPLETED ? (
                  <p className={styles.description}>{purpose || "None set"}</p>
                ) : (
                  <Tooltip title="Add purpose">
                    <SmartLink
                      className={styles.description}
                      onClick={() => setEditing("purpose")}
                      aria-label="Add purpose"
                      linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                    >
                      Add the purpose for the achievement…
                    </SmartLink>
                  </Tooltip>
                )}
              </div>
            </div>
          </DetailPanelHero>

          <div className={styles.header}>
            <div className={styles.group}>
              <div>
                <Heading
                  level={HEADING_LEVEL_5}
                  tag="h2"
                  className={styles.label}
                >
                  Your Role
                </Heading>
                {role || state === COMPLETED ? (
                  <p className={styles.description}>{role || "None set"}</p>
                ) : (
                  <Tooltip title="Add role">
                    <SmartLink
                      className={styles.description}
                      onClick={() => setEditing("role")}
                      aria-label="Add role"
                      linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                    >
                      Add your role…
                    </SmartLink>
                  </Tooltip>
                )}
              </div>
              {projectId && (
                <div>
                  <Heading
                    level={HEADING_LEVEL_5}
                    tag="h2"
                    className={styles.label}
                  >
                    Project
                  </Heading>
                  <p className={styles.description}>{projectName}</p>
                </div>
              )}
            </div>
          </div>

          <div className={clsx(styles.group, styles.actions)}>
            {state !== COMPLETED && (
              <>
                <div className={styles.actionsHeader}>
                  <ActionsCounters
                    className={styles.actionsCounters}
                    total={totalCounters?.total}
                    starred={totalCounters?.starred}
                  />
                </div>

                <ActionList
                  parentId={blockId}
                  parentType={BLOCK}
                  sectionFilters={sectionFilters}
                  readonlyActions={readonly}
                  enableActionNumbers
                  enableProjectBanner={false}
                />

                {!readonly && (
                  <CreateButton
                    onClick={() => showCreateActionDialog(blockId)}
                    aria-label="Create New Action"
                  />
                )}
              </>
            )}

            <div
              className={clsx(!completedActionsTitle && styles.completedHidden)}
            >
              <Accordion
                open={showCompleted}
                onToggle={setShowCompleted}
                heading={completedActionsTitle}
              >
                <ChildrenList
                  state={COMPLETED}
                  parentId={blockId}
                  parentType={BLOCK}
                  completedChildType={ACTION}
                  onFetchCompleted={handleFetchCompleted}
                  emptyState={false}
                  enableProjectBanner={false}
                  readonlyActions={readonly}
                />
              </Accordion>
            </div>
          </div>
        </>
      )}

      <DetailPanelForm
        editing={editing}
        setEditing={setEditing}
        values={block}
        onSubmit={handleSubmit}
        fields={[
          {
            slug: "result",
            label: "Result",
            counter: true,
            maxLength: BLOCK_RESULT_MAX_LENGTH,
            placeholder: "Enter the result you want to achieve…",
          },
          {
            slug: "purpose",
            label: "Purpose",
            maxLength: BLOCK_PURPOSE_MAX_LENGTH,
            placeholder: "Enter the purpose…",
          },
          {
            slug: "role",
            label: "Role",
            maxLength: BLOCK_ROLE_MAX_LENGTH,
            placeholder: "Enter your role…",
          },
        ]}
      />

      {makeActiveDialogVisible && (
        <Dialog
          headerTitle={`Block ${
            block.state === SNOOZED ? "Snoozed" : "Completed"
          }`}
          onClose={() => setMakeActiveDialogVisible(false)}
          footer={
            <Button block onClick={handleMakeBlockActive}>
              Make Block Active
            </Button>
          }
        >
          {block.state === SNOOZED ? (
            <p>
              {
                "This block is currently snoozed. You can make this active, which will move it to the active\xa0tab."
              }
            </p>
          ) : (
            <p>
              {
                "This block is uneditable as it has been completed. To make changes, please make the block\xa0active."
              }
            </p>
          )}
        </Dialog>
      )}

      {completionDialogVisible && (
        <BlockItemCompleteDialog
          blockId={blockId}
          onClose={() => setCompleteDialogVisible(false)}
        />
      )}

      {pdfVisible && (
        <Dialog
          headerTitle={block?.result}
          onClose={() => setPdfVisible(false)}
          footer={
            <Button block onClick={handlePdfExport} loading={pdfExporting}>
              Export
            </Button>
          }
        >
          Generate a PDF from this&nbsp;block.
        </Dialog>
      )}
    </DetailPanelWrapper>
  );
}

export default BlockDetail;
