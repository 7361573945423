import React, { useCallback, useContext } from "react";

import CreateDialog from "../CreateDialog";
import Button, { BUTTON_COLOR_FILL, BUTTON_COLOR_OUTLINE } from "../Button";

import {
  PlannerSaveDialogContext,
  useShowPlannerSaveDialog,
} from "./PlannerSaveDialogContext";

import styles from "./PlannerSaveDialog.module.scss";

const PlannerSaveDialog = ({ onClose, onConfirm }) => {
  const showPlannerSaveDialog = useShowPlannerSaveDialog();

  const { visible } = useContext(PlannerSaveDialogContext);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      showPlannerSaveDialog(false);
      onConfirm && onConfirm();
    },
    [showPlannerSaveDialog, onConfirm]
  );

  if (!visible) return null;

  return (
    <CreateDialog
      //  onClose={onClose}
      className={styles.dialog}
      bottomRight
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.dialogTitle}>
          Would you like to save your Actions updates to your Calendar?
        </div>
        <div className={styles.actionsWrapper}>
          <Button
            className={styles.cancelButton}
            color={BUTTON_COLOR_OUTLINE}
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button color={BUTTON_COLOR_FILL}>
            SAVE
            <span className="sr-only">Close</span>
          </Button>
        </div>
      </form>
    </CreateDialog>
  );
};

PlannerSaveDialog.displayName = "PlannerSaveDialog";

export default PlannerSaveDialog;
