import React, { useEffect, forwardRef, useState, useRef } from "react";
import clsx from "clsx";

import InputLabel from "./InputLabel";
import InputField from "./InputField";
import CharacterCounter from "../CharacterCounter";

import { ReactComponent as SelectArrow } from "./select-arrow.svg";

import styles from "./Input.module.scss";

export const INPUT_SIZE_REGULAR = "regular";
export const INPUT_SIZE_SMALL = "small";

const Input = forwardRef(
  (
    {
      className,
      children,
      fieldTag,
      wrapperChildren,
      inlineLabel,
      label,
      value,
      onChange,
      tag,
      size = INPUT_SIZE_REGULAR,
      minRows = 2,
      maxRows = 3,
      style = {},
      visuallyHidden,
      counter,
      maxLength,
      readonly,
      ...props
    },
    ref
  ) => {
    // Input requires a ref for managing the height of textareas etc, so when it is
    // omitted a local ref is used instead which removes the need for a seemingly
    // unused useRef() at the parent level
    const localRef = useRef();
    ref = ref || localRef;

    const InputTag = tag || "input";

    const [filled, setFilled] = useState(false);
    const [mounted, setMounted] = useState(false);

    // Adding a class that stop transitions on .label while the component is mounting
    // and then removing it after one animation frame ensures that the input label doesn't
    // jump if there is a value that would mean .filled is applied.
    useEffect(() => {
      const handleMountAnimFrame = requestAnimationFrame(() => {
        setMounted(true);
      });
      return () => cancelAnimationFrame(handleMountAnimFrame);
    }, []);

    useEffect(() => {
      if (tag === "textarea" && ref?.current) {
        // Resizes the textarea to allow it to only take up the space it needs
        ref.current.style.height = "";
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
      setFilled(!!value);
    }, [ref, tag, value]);

    const hasCounter = counter && maxLength !== undefined;

    let fieldStyle = {};
    let inputProps = {
      ref,
      value,
      onChange,
      readOnly: readonly,
      maxLength,
      ...props,
    };

    if (hasCounter) {
      // Adds a small buffer to allow the character counter to tick into the negative numbers
      inputProps.maxLength += 5;
    }

    if (tag === "textarea") {
      fieldStyle = {
        "--textarea-min-rows": minRows,
        "--textarea-max-rows": maxRows,
        ...style,
      };
      inputProps = {
        autoComplete: "off",
        autoCorrect: "off",
        autoCapitalize: "off",
        spellCheck: "true",
        ...inputProps,
      };
    }

    const isFilled = filled || props.placeholder || props.type === "file";

    return (
      <InputField
        className={clsx(
          className,
          visuallyHidden && styles.fieldVisuallyHidden,
          inlineLabel && styles.inlineLabel,
          !inlineLabel && [
            styles.regularLabel,
            isFilled && styles.filled,
            !mounted && styles.mounting,
          ],
          size && styles[size],
          readonly && styles.fieldReadonly,
          counter && styles.fieldHasCounter
        )}
        tag={fieldTag}
        style={fieldStyle}
      >
        {label && (
          <InputLabel tag="span" size={size}>
            {label}
          </InputLabel>
        )}
        <div className={styles.inputWrapper}>
          <InputTag className={styles.inputInput} {...inputProps}>
            {children}
          </InputTag>
          <span className={styles.focusRing} aria-hidden="true" />

          {tag === "select" && (
            <SelectArrow aria-hidden="true" className={styles.arrow} />
          )}

          {hasCounter && (
            <CharacterCounter
              className={styles.counter}
              count={value?.length}
              max={maxLength}
            />
          )}

          {wrapperChildren}
        </div>
      </InputField>
    );
  }
);

Input.displayName = "Input";

export default Input;
