import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import clsx from "clsx";

import usePeople from "src/hooks/usePeople";
import useUniqueId from "src/hooks/useUniqueId";

import {
  PAGE_SIZE,
  PERSON_NICKNAME_MAX_LENGTH,
  PERSON_DESCRIPTION_MAX_LENGTH,
  TYPE_LABELS,
  MENTIONED,
  LEVERAGED,
  STATE_LABELS,
} from "src/services/DbService/constants";
import {
  watchPerson,
  watchPersonCompletedActions,
  watchPersonActiveActions,
  updatePerson,
  deletePerson,
} from "src/services/DbService/people";

import Accordion from "../../Accordion";
import Heading, { HEADING_LEVEL_3, HEADING_LEVEL_5 } from "../../Heading";
import ActionItem from "../../ActionItem";
import EmptyState from "../../EmptyState";
import LoadMoreButton from "../../LoadMoreButton";
import LoadingSpinner from "../../LoadingSpinner";
import Button from "../../Button";
import { PeopleCounters } from "../../Counters";
import Tooltip from "../../Tooltip";
import Avatar from "../../Avatar";
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from "../../SmartLink";
import ContextualMenu, {
  ContextualMenuLink,
  ContextualMenuTrigger,
} from "src/components/ContextualMenu";
import Dialog from "src/components/Dialog";

import { ReactComponent as IconMore } from "src/assets/icons/16-more.svg";

import DetailPanelWrapper from "../DetailPanelWrapper";
import DetailPanelHero from "../DetailPanelHero";
import DetailPanelForm from "../DetailPanelForm";

import styles from "./PersonDetail.module.scss";
import { PEOPLE_URL } from "src/components/App";

const ACTIONS_STATE_LOADING = "loading";
const ACTIONS_STATE_HAS_ACTIONS = "hasActions";
const ACTIONS_STATE_EMPTY = "empty";

function PersonDetail({ ...props }) {
  const { personId: paramPersonId } = useParams();
  const history = useHistory();

  const { people } = usePeople();

  const [person, setPerson] = useState({});
  const [editing, setEditing] = useState(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [newNickname, setNewNickname] = useState("");
  const [activeActions, setActiveActions] = useState(null);
  const [completedActionCounts, setCompletedActionCounts] = useState(null);

  useEffect(
    () =>
      watchPerson(paramPersonId, (contactPerson) => {
        if (contactPerson) {
          setPerson(contactPerson);
        } else {
          // Navigate away from person details.
          history.replace(generatePath(PEOPLE_URL));
        }
      }),
    [paramPersonId, history]
  );

  useEffect(() => {
    return watchPersonActiveActions(paramPersonId, setActiveActions);
  }, [paramPersonId]);

  const { id: personId, image, nickname, description } = person;

  const error = useMemo(() => {
    function compareNickname(a) {
      return a?.toLowerCase() === newNickname?.toLowerCase();
    }

    if (
      !nickname ||
      // Allow a user to edit it to be the same as what it currently is
      compareNickname(nickname) ||
      !people.find((person) => compareNickname(person?.nickname))
    ) {
      return;
    }

    return `${TYPE_LABELS.person.title} already exists with username @${newNickname}`;
  }, [people, nickname, newNickname]);

  const handleChange = useCallback((values) => {
    setNewNickname(values.nickname);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      const { nickname, description, image } = values;

      updatePerson(
        personId,
        nickname.toLowerCase(),
        description || "",
        image || null
      );
      setEditing(null);
    },
    [personId, setEditing]
  );

  const handlePersonDelete = useCallback(() => {
    deletePerson(personId);
    setEditing(null);
  }, [personId]);

  // These hooks are created to communicate with the PersonCompletedActionsLists
  // and figure out if none of them has found an action, so we can show
  // an empty state to the user. Unfortunately I have not found an
  // easier way to achieve this.
  const handleFetch = useCallback((type, newCount) => {
    // this handler receives a "type" identifing the list that called it, and
    // the number of actions that the list fetched
    setCompletedActionCounts((oldCounts) => ({
      ...oldCounts,
      [type]: newCount,
    }));
  }, []);

  const actionsState = useMemo(() => {
    // Until all actions have been fetched or returned from the watcher, it is
    // not possible to know whether a person has associated actions, so it is
    // deemed that it is still loading
    if (
      // Completed actions are fetched independently so both must be checked
      completedActionCounts?.[MENTIONED] === undefined ||
      completedActionCounts?.[LEVERAGED] === undefined ||
      activeActions === null
    ) {
      return ACTIONS_STATE_LOADING;
    }

    const counters = [
      activeActions[MENTIONED].length,
      activeActions[LEVERAGED].length,
      completedActionCounts[MENTIONED],
      completedActionCounts[LEVERAGED],
    ];

    // Return hasActions if at least one list has more than 0 elements
    return counters.some((count) => count > 0)
      ? ACTIONS_STATE_HAS_ACTIONS
      : ACTIONS_STATE_EMPTY;
  }, [activeActions, completedActionCounts]);

  const completedActionsTitle = useMemo(() => {
    const total =
      completedActionCounts?.[LEVERAGED] + completedActionCounts?.[MENTIONED];

    if (actionsState !== ACTIONS_STATE_HAS_ACTIONS || !total) return null;

    const actionLabel =
      total !== 1 ? TYPE_LABELS.action.titlePlural : TYPE_LABELS.action.title;
    return `${total} ${STATE_LABELS.completed.title} ${actionLabel}`;
  }, [completedActionCounts, actionsState]);

  return (
    <DetailPanelWrapper
      className={styles.main}
      name={nickname}
      editing={editing}
      tag="main"
      {...props}
    >
      {/* The loading prop for DetailPanelWrapper is unset and the loading is
          handled here directly to allow for a single spinner for both the
          person (with the header appearing when it is available) and then
          the actions which appear almost straight away after. Without this
          there is a noticable UI jump */}
      {(!personId || actionsState === ACTIONS_STATE_LOADING) && (
        <LoadingSpinner absolute />
      )}

      {!editing && (
        <>
          {/* Because the loading prop is not used (see above), these children
              have to be wrapped in a conditional Fragment that is dependant on
              the personId being present */}
          {personId && (
            <>
              <DetailPanelHero
                reversed
                buttons={<Button onClick={() => setEditing(true)}>Edit</Button>}
              />

              <div className={styles.header}>
                <Avatar className={styles.avatar} name={nickname} src={image} />
                <Heading
                  className={styles.title}
                  level={HEADING_LEVEL_3}
                  tag="h1"
                >
                  @{nickname}
                </Heading>
                <PeopleCounters
                  className={styles.counters}
                  leverages={activeActions?.[LEVERAGED]?.length}
                  mentions={activeActions?.[MENTIONED]?.length}
                />
                {description ? (
                  <p className={styles.description}>{description}</p>
                ) : (
                  <Tooltip title="Add description">
                    <SmartLink
                      className={styles.description}
                      onClick={() => setEditing("description")}
                      aria-label="Add description"
                      linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                    >
                      Add a description for @{nickname}…
                    </SmartLink>
                  </Tooltip>
                )}
              </div>
            </>
          )}

          <div className={styles.group}>
            {actionsState === ACTIONS_STATE_EMPTY && (
              <EmptyState actions className={styles.emptyState}>
                No {TYPE_LABELS.action.textPlural} leverage or mention&nbsp;
                {`@${person?.nickname}`}
              </EmptyState>
            )}

            {actionsState === ACTIONS_STATE_HAS_ACTIONS && (
              <>
                <PersonActiveActionsList
                  actions={activeActions[LEVERAGED]}
                  heading="Leveraged"
                />

                <PersonActiveActionsList
                  actions={activeActions[MENTIONED]}
                  heading="Mentioned"
                />
              </>
            )}

            {/* This must be in the DOM to allow handleFetch to be called
                  properly, but is hidden from the user with a 0px height, no
                  overflow div to get the same effect. */}
            <div
              className={clsx(!completedActionsTitle && styles.completedHidden)}
            >
              <Accordion
                open={showCompleted}
                onToggle={setShowCompleted}
                heading={completedActionsTitle}
              >
                <PersonCompletedActionsList
                  personId={personId}
                  type={LEVERAGED}
                  onFetch={handleFetch}
                />
                <PersonCompletedActionsList
                  personId={personId}
                  type={MENTIONED}
                  onFetch={handleFetch}
                />
              </Accordion>
            </div>
          </div>
        </>
      )}

      {/* See conditional Fragment reasoning above */}
      {personId && (
        <DetailPanelForm
          editing={editing}
          setEditing={setEditing}
          values={person}
          onSubmit={handleSubmit}
          onChange={handleChange}
          error={error}
          optionsMenu={<EditOptionsMenu onDelete={handlePersonDelete} />}
          fields={[
            {
              slug: "nickname",
              label: "Nickname",
              counter: true,
              maxLength: PERSON_NICKNAME_MAX_LENGTH,
              placeholder: "Enter nickname…",
              transformOnChange: (value) => value.replace(/[\n\s]/g, ""),
            },
            {
              slug: "description",
              label: "Description",
              placeholder: `Describe this ${TYPE_LABELS.person.text}…`,
              maxLength: PERSON_DESCRIPTION_MAX_LENGTH,
            },
          ]}
        />
      )}
    </DetailPanelWrapper>
  );
}

export default PersonDetail;

function PersonActiveActionsList({ actions = [], heading }) {
  if (actions.length === 0) return null;

  return (
    <>
      <Heading tag="h3" level={HEADING_LEVEL_5} className={styles.subheading}>
        {heading}
      </Heading>
      <ul>
        {actions.map((action) => (
          <ActionItem key={action.id} action={action} />
        ))}
      </ul>
    </>
  );
}

function PersonCompletedActionsList({ personId, type, onFetch }) {
  const [actions, setActions] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (!personId) return;

    const callback = (actions, hasMore) => {
      setLoadingMore(false);
      setActions(actions);
      setHasMore(hasMore);
      onFetch(type, actions.length);
    };
    return watchPersonCompletedActions(personId, type, limit, callback);
  }, [personId, type, limit, onFetch]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingMore(true);
    setLimit((limit) => limit + PAGE_SIZE);
  }, []);

  if (actions.length === 0) return null;

  return (
    <>
      <ul>
        {actions.map((action) => (
          <ActionItem key={action.id} action={action} />
        ))}
      </ul>

      {hasMore && (
        <LoadMoreButton loading={loadingMore} onClick={handleLoadMoreClick} />
      )}
    </>
  );
}

function EditOptionsMenu({ onDelete }) {
  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const handleDeleteConfirmation = useCallback(() => {
    setDeleteDialogVisible(true);
  }, []);

  const handlePerformDelete = () => {
    setDeleteDialogVisible(false);
    onDelete && onDelete();
  };

  return (
    <>
      <ContextualMenuTrigger
        menuId={optionsMenuButtonId}
        visible={optionsMenuVisible}
        setVisible={setOptionsMenuVisible}
      >
        <Button
          ref={optionsMenuButtonRef}
          iconOnly
          aria-label="Contact options"
          tooltip
          type="button"
        >
          <IconMore role="presentation" />
        </Button>
      </ContextualMenuTrigger>

      {optionsMenuVisible && (
        <ContextualMenu
          buttonRef={optionsMenuButtonRef}
          onClose={() => setOptionsMenuVisible(false)}
          aria-labelledby={optionsMenuButtonId}
        >
          <ContextualMenuLink onClick={handleDeleteConfirmation} negative>
            Delete Contact
          </ContextualMenuLink>
        </ContextualMenu>
      )}

      {deleteDialogVisible && (
        <Dialog
          headerTitle="Delete Contact"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>
                Cancel
              </Button>
              <Button block onClick={handlePerformDelete} negative>
                Delete contact
              </Button>
            </>
          }
        >
          <p>
            Deleting this contact will also update all blocks and actions. Do
            you wish to&nbsp;proceed?
          </p>
        </Dialog>
      )}
    </>
  );
}
