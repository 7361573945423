import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";

import useUniqueId from "../../hooks/useUniqueId";

import { SNOOZED, UNCATEGORIZED_ID } from "../../services/DbService/constants";

import { scrollIntoViewIfNeeded } from "src/utils/scrollIntoViewIfNeeded";
import { NBSP } from "src/constants";

import { InputLabel, INPUT_SIZE_SMALL } from "../Input";

import { ReactComponent as IconResult } from "../../assets/icons/32-target.svg";
import { ReactComponent as IconCheck } from "../../assets/icons/16-check.svg";
import { ReactComponent as IconSnoozed } from "../../assets/icons/16-snoozed.svg";
import { ReactComponent as SelectArrow } from "../Input/select-arrow.svg";

import styles from "./ActionParentSelect.module.scss";

const DIRECTION_KEYS = {
  ArrowUp: "up",
  ArrowDown: "down",
  PageUp: "up",
  PageDown: "down",
  Home: "up",
  End: "down",
};

const DIRECTION_JUMP_KEYS = ["PageUp", "PageDown", "Home", "End"];

function SelectListItem({
  tag: Tag = "div",
  value,
  id,
  optionId,
  selectedId,
  onHover,
  children,
  className,
  wrapperRef,
  ...props
}) {
  const ref = useRef();

  const handleMouseMove = useCallback(() => {
    if (selectedId === optionId || !onHover) return;

    onHover(optionId);
  }, [optionId, selectedId, onHover]);

  const hover = selectedId === optionId;

  useEffect(() => {
    if (!hover) return;

    scrollIntoViewIfNeeded(ref.current, wrapperRef.current);
  }, [hover, wrapperRef]);

  // id, aria-selected and the relevant aria-role and event handlers are omitted on the
  // Uncategorized category to make sure that it's not selectable in the dropdown
  // because Uncategorized (and not in a block) is the equivalent to Capture List
  if (!optionId) {
    return (
      <Tag {...props} className={clsx(className, styles.listItem)}>
        {children}
      </Tag>
    );
  }

  return (
    <Tag
      {...props}
      id={id}
      className={clsx(className, styles.listItem, hover && styles.hover)}
      ref={ref}
      onMouseMove={handleMouseMove}
      data-option-id={optionId}
      role="option"
      aria-selected={value === optionId}
    >
      {children}
      <IconCheck className={styles.listCheck} aria-hidden="true" />
    </Tag>
  );
}

function ActionParentSelect({
  className,
  children,
  optionsGroups,
  optionsMap,
  value,
  onOpen,
  onClose,
  onChange,
  onEnterKeyDown,
  ...props
}) {
  const wrapperRef = useRef();

  const selectIdPrefix = useUniqueId();

  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const currentOption = useMemo(
    () => optionsMap?.[value] || {},
    [optionsMap, value]
  );

  const optionsArray = useMemo(() => {
    return optionsGroups.reduce(
      (options, group) => {
        // Uncategorized is not selectable so shouldn't be included in the list of options
        if (group.id !== UNCATEGORIZED_ID) options.push(group.id);

        return options.concat(group.options.map((option) => option.id));
      },
      [
        // Uncategorized here is the same as Capture List so must be inserted at
        // the start of the options array
        UNCATEGORIZED_ID,
      ]
    );
  }, [optionsGroups]);

  const selectedId = optionsArray[selectedIndex];

  const handleOpen = useCallback(() => {
    if (open) return;

    setOpen(true);
    setSelectedIndex(optionsArray.indexOf(value));
    onOpen();

    // Ensures that the DOM element has been scrolled to before being made visible – avoids a noticable
    // jump in the menu
    setTimeout(() => {
      setVisible(true);
    });
  }, [open, value, optionsArray, onOpen]);

  const handleClose = useCallback(() => {
    onClose();
    setOpen(false);
    setVisible(false);
    setSelectedIndex(null);
  }, [onClose]);

  const handleChange = useCallback(
    (newValue) => {
      handleClose();
      onChange(newValue);
    },
    [handleClose, onChange]
  );

  const handleWrapperClick = useCallback(
    (e) => {
      if (!open) handleOpen();
    },
    [open, handleOpen]
  );

  const handleHover = useCallback(
    (id) => {
      setSelectedIndex(optionsArray.indexOf(id));
    },
    [optionsArray]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const { key } = e;

      if (key === "Escape") {
        e.preventDefault();
        handleClose();
        return;
      }

      // key === ' ' is not a typo, 'Space' is a printable character so is represented as a string of a space
      if (open && (key === "Enter" || key === " ")) {
        e.preventDefault();
        handleChange(selectedId);
        return;
      }

      if (!open && (key === " " || key === "ArrowUp" || key === "ArrowDown")) {
        e.preventDefault();
        handleOpen();
        return;
      }

      if (!open && key === "Enter") {
        e.preventDefault();
        onEnterKeyDown(e);
        return;
      }

      const direction = DIRECTION_KEYS[key];

      // Only directional keys are relevant now, ignore any other; and they're only relevant
      // if the menu is open, so if it's not, ignore them too.
      if (!direction || !open) return;

      const optionsArrayLength = optionsArray.length;

      let newIndex = selectedIndex;

      // Alt + ArrowUp/Down behaves like PageUp/Down when the menu is open which jump
      // to the first/last items respectively
      if (e.altKey || DIRECTION_JUMP_KEYS.includes(key)) {
        newIndex = direction === "up" ? 0 : optionsArrayLength - 1;
      } else if (key === "ArrowUp" && selectedIndex > 0) {
        newIndex--;
      } else if (
        key === "ArrowDown" &&
        selectedIndex < optionsArrayLength - 1
      ) {
        newIndex++;
      }

      setSelectedIndex(newIndex);
    },
    [
      selectedIndex,
      handleChange,
      open,
      handleOpen,
      handleClose,
      onEnterKeyDown,
      optionsArray,
      selectedId,
    ]
  );

  const getOptionId = useCallback(
    (id) => {
      return `${selectIdPrefix}-opt${optionsArray.indexOf(id)}`;
    },
    [optionsArray, selectIdPrefix]
  );

  if (!optionsArray.length) return;

  return (
    // [disabled] isn’t needed yet so tabIndex is statically set at 0, if it were needed this
    // would need to be handled here
    <div
      ref={wrapperRef}
      className={clsx(className, styles.wrapper)}
      tabIndex="0"
      onBlur={handleClose}
      onClick={handleWrapperClick}
      onKeyDown={handleKeyDown}
      role="listbox"
      aria-labelledby={`${selectIdPrefix}-label`}
      aria-activedescendant={value && getOptionId(value)}
    >
      <span
        className={styles.selected}
        data-category-color={currentOption.color}
      >
        <InputLabel
          tag="span"
          className={styles.label}
          id={`${selectIdPrefix}-label`}
          size={INPUT_SIZE_SMALL}
        >
          Save Action to
        </InputLabel>
        <span
          className={clsx(
            styles.selectedCurrent,
            value === UNCATEGORIZED_ID && styles.selectedCurrentCapture
          )}
        >
          <span>{currentOption.name || NBSP}</span>
          <SelectArrow role="presentation" />
        </span>
      </span>

      {open && (
        <div
          className={clsx(styles.list, !visible && styles.hidden)}
          role="list"
        >
          <div className={styles.listGroup}>
            <SelectListItem
              wrapperRef={wrapperRef}
              id={getOptionId(UNCATEGORIZED_ID)}
              optionId={UNCATEGORIZED_ID}
              value={value}
              selectedId={selectedId}
              onClick={() => handleChange(UNCATEGORIZED_ID)}
              onHover={() => handleHover(UNCATEGORIZED_ID)}
            >
              Action List
            </SelectListItem>
          </div>

          {optionsGroups &&
            optionsGroups
              .filter((group) => group.id !== UNCATEGORIZED_ID)
              .map((group) => (
                <div
                  className={styles.listGroup}
                  key={group.id}
                  data-category-color={group.color}
                >
                  <SelectListItem
                    wrapperRef={wrapperRef}
                    id={getOptionId(group.id)}
                    value={value}
                    optionId={group.id !== UNCATEGORIZED_ID && group.id}
                    selectedId={selectedId}
                    onClick={() => handleChange(group.id)}
                    onHover={() => handleHover(group.id)}
                  >
                    {group.name}
                  </SelectListItem>

                  <ul className={styles.blocks}>
                    {group.options.map((option) => (
                      <SelectListItem
                        wrapperRef={wrapperRef}
                        id={getOptionId(option.id)}
                        tag="li"
                        key={option.id}
                        optionId={option.id}
                        value={value}
                        selectedId={selectedId}
                        onClick={() => handleChange(option.id)}
                        onHover={() => handleHover(option.id)}
                      >
                        <IconResult
                          className={styles.blockIcon}
                          role="presentation"
                        />
                        <span>{option.name}</span>
                        {option.state === SNOOZED && (
                          <IconSnoozed
                            className={styles.blockSnoozed}
                            aria-label="Snoozed"
                          />
                        )}
                      </SelectListItem>
                    ))}
                  </ul>
                </div>
              ))}
        </div>
      )}
    </div>
  );
}

export default ActionParentSelect;
