import React, {
  useContext,
  useEffect,
  Suspense,
  useState,
  useCallback,
} from "react";
import {
  BrowserRouter,
  Switch,
  MemoryRouter,
  useLocation,
  Route,
} from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";
import isElectron from "is-electron";
import semverSatisfies from "semver/functions/satisfies";

import packageJson from "src/../package.json";
import { NBSP, PRODUCT_NAME } from "src/constants";

import { trackPage } from "src/services/AnalyticsService";
import { watchStoreVersions } from "src/services/DbService/general";
import {
  CorruptedUserError,
  InvalidCacheError,
  signOut,
} from "src/services/AuthService";

import { TRAPPABLE_KEYS } from "src/hooks/useFocusTrap";
import { LOCALSTORAGE_COLOR_SCHEME } from "src/hooks/useColorScheme";

import AuthedRoute from "src/components/AuthedRoute";
import UnauthedRoute from "src/components/UnauthedRoute";
import EventsNotifier from "src/components/EventsNotifier";
import Dashboard from "src/components/Dashboard";
import Planner from "src/components/Planner";
import SignIn from "src/components/Auth/SignIn";
import SignUp from "src/components/Auth/SignUp";
import EmailVerification from "src/components/Auth/EmailVerification";
import Access from "src/components/Auth/Access";
import Subscribe from "src/components/Auth/Subscribe";
import ForgotPassword from "src/components/Auth/ForgotPassword";
import OnboardingGoals from "src/components/Onboarding/Goals";
import OnboardingCreateCategory from "src/components/Onboarding/CreateCategory";
import OnboardingCategoryDetails from "src/components/Onboarding/CategoryDetails";
import Profile from "src/components/Profile";
import People from "src/components/People";
import HelpPage from "src/components/HelpPage";
import NotFound from "src/components/NotFound";

import NavContainer from "src/components/NavContainer";
import AppError from "src/components/AppError";
import LoadingSpinner from "src/components/LoadingSpinner";

import {
  NotificationsContext,
  NotificationsContextProvider,
} from "src/components/NotificationsContext";
import {
  AuthContext,
  AuthContextProvider,
  AUTH_STATE_LOADING,
} from "src/components/AuthContext";
import { CategoriesContextProvider } from "src/components/CategoriesContext";
import { ProjectsContextProvider } from "src/components/ProjectsContext";
import { SidebarContextProvider } from "src/components/SidebarContext";
import { ActionDialogContextProvider } from "src/components/ActionDialog";
import { PlannerSaveDialogContextProvider } from "../PlannerSaveDialog/PlannerSaveDialogContext";
import { DialogContextProvider } from "src/components/Dialog";
import { TooltipContextProvider } from "../Tooltip/TooltipContext";
import Toast, {
  Toaster,
  useGlobalToast,
  ToastContextProvider,
} from "src/components/Toast";

import MarqueSrc from "src/assets/marque.svg";

import styles from "./App.module.scss";

// The styleguide is shown conditionally so should be lazy loaded to avoid
// unnecessarily bundling it into the production build
const Styleguide =
  process.env.REACT_APP_STYLEGUIDE === "true"
    ? React.lazy(() => import("src/components/Styleguide"))
    : null;

const {
  rri_react_app: { data_schema_version: CODE_DATA_SCHEMA_VERSION },
} = packageJson;

export const DASHBOARD_URL = "/";
export const SIGN_IN_URL = "/sign-in";
export const SIGN_UP_URL = "/sign-up";
export const EMAIL_VERIFICATION_URL = "/verification";
export const ACCESS_URL = "/access";
export const SUBSCRIBE_URL = "/subscribe";
export const ONBOARDING_GOALS_URL = "/onboarding/goals";
export const ONBOARDING_CREATE_CATEGORY_URL = "/onboarding/create-category";
export const ONBOARDING_CATEGORY_DETAILS_URL = "/onboarding/category-details";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const BLOCK_DETAIL_URL = "/blocks/:blockId";
export const PEOPLE_URL = "/people/:personId?";
export const CATEGORY_DETAIL_URL = "/category/:categoryId";
export const PROJECT_DETAIL_URL = "/project/:projectId";
export const PROFILE_URL = "/profile";
export const PLANNER_URL = "/planner";
export const PLANNER_BLOCK_DETAIL_URL = `/planner/blocks/:blockId`;
export const PLANNER_CATEGORY_DETAIL_URL = `/planner/category/:categoryId`;
export const PLANNER_PROJECT_DETAIL_URL = `/planner/project/:projectId`;
export const HELP_URL = `/help`;
export const STYLEGUIDE_URL = "/_styleguide";

export const DASHBOARD_URLS = [
  DASHBOARD_URL,
  CATEGORY_DETAIL_URL,
  PROJECT_DETAIL_URL,
  BLOCK_DETAIL_URL,
  PEOPLE_URL,
  PLANNER_URL,
  PLANNER_BLOCK_DETAIL_URL,
  PROFILE_URL,
  HELP_URL,
];

export const DASHBOARD_DETAIL_URLS = [
  CATEGORY_DETAIL_URL,
  PROJECT_DETAIL_URL,
  BLOCK_DETAIL_URL,
];

// Electron loads the app with the file:// protocol, which in Chrome
// does not allow history.pushState(). Swtiching to HashRouter does
// the trick.
const Router = isElectron() ? MemoryRouter : BrowserRouter;

// useLocation can only be accessed by children of `<Router>` so a simple
// observer component is required to watch for route changes
function LocationObserver() {
  const { pathname } = useLocation();

  useEffect(() => trackPage(pathname), [pathname]);

  return null;
}

function App() {
  const { notifications } = useContext(NotificationsContext);
  const { user, authState } = useContext(AuthContext);
  const [isVersionMismatch, setIsVersionMismatch] = useState(false);

  useEffect(() => {
    return watchStoreVersions((dbDataSchemaVersion) => {
      if (semverSatisfies(dbDataSchemaVersion, CODE_DATA_SCHEMA_VERSION)) {
        return;
      }
      console.error(
        "Code and db data schema version mismatch! " +
          `Code: ${CODE_DATA_SCHEMA_VERSION}, database: ${dbDataSchemaVersion}`
      );
      setIsVersionMismatch(true);
    });
  }, []);

  const globalToast = useGlobalToast();

  useEffect(() => {
    const colorScheme = localStorage.getItem(LOCALSTORAGE_COLOR_SCHEME);
    if (colorScheme) {
      document.documentElement.dataset.colorPreference = colorScheme;
    }
  }, []);

  // Little function to detect when a user switches to tabbing to allow for
  // `:focus-visible` to only be shown for keyboard actions – inputs have this
  // regardless of how it was focused
  useEffect(() => {
    function detectMouse() {
      document.body.classList.remove("js--focus-visible");
    }

    function detectTab({ key }) {
      if (TRAPPABLE_KEYS.includes(key)) {
        document.body.classList.add("js--focus-visible");
      }
    }

    document.body.addEventListener("mousedown", detectMouse);
    document.body.addEventListener("keydown", detectTab);

    return () => {
      document.body.removeEventListener("mousedown", detectMouse);
      document.body.removeEventListener("keydown", detectTab);
    };
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
  }, []);

  const handleResetLocalStorage = useCallback(() => {
    // This adds an extra localStorage clear to be sure that nothing
    // is left over
    localStorage.clear();
    window.location.reload();
  }, []);

  if (isVersionMismatch) {
    return (
      <AppError
        pageText={`This version of the app is outdated. Please reload to${NBSP}update.`}
        pageTitle="Unsupported App Version"
      />
    );
  }

  if (authState === AUTH_STATE_LOADING) {
    return (
      <div className={styles.loading}>
        <span className="sr-only">{PRODUCT_NAME}</span>
        <img src={MarqueSrc} alt="" width="70" height="70" />
        <LoadingSpinner opaque />
      </div>
    );
  }

  if (authState instanceof Error) {
    // This error is deliberately vague, a user is unlikely to know what a
    // cache is, but logging a user out should reset any errors relating to
    // this as well as triggering the route guards that would reset the app.
    // If the error persisted, they'd be shown
    if (authState instanceof InvalidCacheError) {
      return (
        <AppError
          onReset={user ? handleSignOut : handleResetLocalStorage}
          error={authState}
        />
      );
    }

    if (authState instanceof CorruptedUserError) {
      // This error can be generated in both a logged in or logged out state,
      // and the reset functionality changes depending on which is used
      const pageText =
        "There is an issue with your user information. Please " +
        (user ? "sign out and " : "") +
        `try${NBSP}again`;

      return (
        <AppError
          pageText={pageText}
          onReset={user ? handleSignOut : undefined}
          resetButtonLabel={user ? "Sign Out" : "Try Again"}
          error={authState}
        />
      );
    }

    return <AppError error={authState} />;
  }

  const showNotifications = user && notifications === "granted";

  return (
    <ErrorBoundary fallback={AppError}>
      <Router>
        <NavContainer />
        <Switch>
          {/* Authentication flow routes */}
          <UnauthedRoute path={SIGN_IN_URL}>
            <SignIn />
          </UnauthedRoute>
          <UnauthedRoute path={SIGN_UP_URL}>
            <SignUp />
          </UnauthedRoute>
          <UnauthedRoute path={ACCESS_URL}>
            <Access />
          </UnauthedRoute>
          <UnauthedRoute path={SUBSCRIBE_URL}>
            <Subscribe />
          </UnauthedRoute>
          <UnauthedRoute path={EMAIL_VERIFICATION_URL}>
            <EmailVerification />
          </UnauthedRoute>
          <UnauthedRoute path={FORGOT_PASSWORD_URL}>
            <ForgotPassword />
          </UnauthedRoute>

          {/* Onboarding routes */}
          <AuthedRoute path={ONBOARDING_GOALS_URL}>
            <OnboardingGoals />
          </AuthedRoute>
          <AuthedRoute path={ONBOARDING_CREATE_CATEGORY_URL}>
            <OnboardingCreateCategory />
          </AuthedRoute>
          <AuthedRoute path={ONBOARDING_CATEGORY_DETAILS_URL}>
            <OnboardingCategoryDetails />
          </AuthedRoute>

          {/* Logged in user routes */}
          <AuthedRoute path={PROFILE_URL}>
            <Profile />
          </AuthedRoute>
          <AuthedRoute path={PLANNER_URL}>
            <Planner />
          </AuthedRoute>
          <AuthedRoute path={PEOPLE_URL}>
            <People />
          </AuthedRoute>
          <AuthedRoute path={DASHBOARD_DETAIL_URLS}>
            <Dashboard />
          </AuthedRoute>
          <AuthedRoute path={DASHBOARD_URL} exact>
            <Dashboard />
          </AuthedRoute>
          <AuthedRoute path={HELP_URL}>
            <HelpPage />
          </AuthedRoute>

          {/* Styleguide */}
          <Route path={STYLEGUIDE_URL}>
            {Styleguide ? (
              <Suspense fallback={null}>
                <Styleguide />
              </Suspense>
            ) : (
              <NotFound />
            )}
          </Route>

          {/* Unprotected routes */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <LocationObserver />
      </Router>
      {showNotifications && <EventsNotifier />}

      {/* Global toasts */}
      <Toaster>{globalToast && <Toast {...globalToast} global />}</Toaster>
    </ErrorBoundary>
  );
}

const AppWithContext = (props) => (
  <HelmetProvider>
    <AuthContextProvider>
      <CategoriesContextProvider>
        <ProjectsContextProvider>
          <SidebarContextProvider>
            <DialogContextProvider>
              <ActionDialogContextProvider>
                <PlannerSaveDialogContextProvider>
                  <NotificationsContextProvider>
                    <ToastContextProvider>
                      <TooltipContextProvider>
                        <App {...props} />
                      </TooltipContextProvider>
                    </ToastContextProvider>
                  </NotificationsContextProvider>
                </PlannerSaveDialogContextProvider>
              </ActionDialogContextProvider>
            </DialogContextProvider>
          </SidebarContextProvider>
        </ProjectsContextProvider>
      </CategoriesContextProvider>
    </AuthContextProvider>
  </HelmetProvider>
);

export default AppWithContext;
