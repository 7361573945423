import React, { useState, createContext, useEffect, useMemo } from "react";

export const NotificationsContext = createContext();

// Notification preferences are stored locally so that if a user allows, but then sets the boolean
// to false we still respect their preference (without localstorage checking the Notification.permission
// would still show as 'granted'). It is important to validate the browser's preference too though
// in case the browser thinks that it should be denied (i.e. the user changed it from their
// settings and not from the page) – if that is the case then the local preference is ignored.
export const getNotificationPreferences = () => {
  if (!("Notification" in window)) {
    return "unsupported";
  }

  const localPermission = localStorage.getItem("notificationState");
  const browserPermission = Notification.permission;

  if (localPermission === browserPermission) return localPermission;

  return browserPermission === "denied" ? "denied" : "default";
};

export function NotificationsContextProvider({ children }) {
  const [notifications, setNotifications] = useState(
    getNotificationPreferences()
  );

  useEffect(() => {
    localStorage.setItem("notificationState", notifications);
  }, [notifications]);

  const value = useMemo(
    () => ({
      notifications,
      setNotifications,
    }),
    [notifications]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}
