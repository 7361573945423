import React, { forwardRef, useCallback, useMemo, createRef } from "react";
import { addSeconds, format, isPast } from "date-fns";
import clsx from "clsx";

import { CATEGORY_DEFAULT_COLOR } from "../../services/DbService/constants";
import { deleteEvent } from "src/services/DbService/events";

import CompletionCheckbox from "../CompletionCheckbox/CompletionCheckbox";
import Tooltip from "../Tooltip";

import { ReactComponent as IconClose } from "../../assets/icons/16-close.svg";

import styles from "./EventCard.module.scss";

function formatTime(date) {
  return (
    <>
      {format(date, "h:mm")}
      <span className={styles.timeAmPm}>{format(date, "a")}</span>
    </>
  );
}

export function useBoxesRefs(boxesLength) {
  return useMemo(() => {
    if (!boxesLength) return [];
    return Array(boxesLength)
      .fill()
      .map(() => createRef());
  }, [boxesLength]);
}

function RemoveFromCalendarButton({
  className,
  eventId,
  onRemoveFromCalendar,
}) {
  const label = "Remove from calendar";

  const handleRemoveFromCalendar = useCallback(
    (e) => {
      e.stopPropagation();

      // Updates draftEvents in the Planner View
      if (onRemoveFromCalendar) {
        onRemoveFromCalendar(eventId);
      } else {
        deleteEvent(eventId);
      }
    },
    [eventId, onRemoveFromCalendar]
  );

  return (
    <Tooltip title={label}>
      <div
        className={clsx(styles.removeFromCalendarButton, className)}
        aria-label={label}
        onClick={(e) => handleRemoveFromCalendar(e)}
      >
        <IconClose role="presentation" />
      </div>
    </Tooltip>
  );
}

const EventCard = forwardRef(
  (
    {
      eventId,
      description,
      startDate,
      duration,
      boxes,
      boxesRefs,
      color = CATEGORY_DEFAULT_COLOR,
      allDay = false,
      draggable = false,
      dragging = false,
      resizable = false,
      completed = false,
      onClick,
      onComplete,
      onRemoveFromCalendar,
      external,
      ...props
    },
    ref
  ) => {
    // Constants used for specific updates on the UI
    const CARD_SIZE_XMALL = duration === 900;
    const CARD_SIZE_SMALL = duration === 1800;

    // The click event is called on the parent (and must be kept so for accesibility – see below)
    // so this gets the nearest box, converts it into a ref and returns that as the click event
    // argument so that it can be used to calculate the position of any dialogs
    const handleClick = useCallback(
      (e) => {
        if (!onClick) return;

        // For keyboard events the target is the event card rather than a box, so the first box
        // is treated as the anchor for the dialog
        const boxIndex =
          e.target.closest("[data-box-index]")?.dataset?.boxIndex || 0;
        onClick(boxesRefs[boxIndex]);
      },
      [onClick, boxesRefs]
    );

    if (!startDate || !boxes) return null;

    const endDate = addSeconds(startDate, duration);

    const EventCardTag = onClick ? "button" : "div";

    return (
      <>
        <EventCardTag
          ref={ref}
          className={styles.event}
          // onClick handler has to stay on this wrapper for accessibility, so it
          // can be triggered by keyboard when the container is focused
          onClick={handleClick}
          // These data attrs are used by PeriodView
          data-draggable={draggable || undefined}
          data-event-id={eventId}
          data-category-color={color}
          {...props}
        >
          {/* A multi-day event is made of several boxes, one for each day. */}
          {boxes.map((box, i) => (
            <div
              key={i}
              ref={boxesRefs?.[i]}
              data-box-index={i}
              title={`${description}, ${format(
                startDate,
                allDay ? "PP" : "PPp"
              )}`}
              className={clsx(
                styles.box,
                styles[box.className],
                dragging && styles.dragging,
                isPast(endDate) && styles.past,
                !external && styles.internal
              )}
              style={box.css}
              // Screen readers need only the first box, hide the others to them
              role={i > 0 ? "presentation" : undefined}
            >
              <div
                className={clsx(
                  styles.container,
                  onComplete &&
                    box.className !== "stacked" &&
                    styles.containerWithComplete
                )}
              >
                <h3
                  className={clsx(
                    styles.description,
                    onComplete &&
                      box.className === "stacked" &&
                      box.durationIncrements < 6 &&
                      styles.descriptionWithComplete
                  )}
                  style={{ "--duration-increments": box.durationIncrements }}
                >
                  <span
                    className={clsx(
                      styles.descriptionInner,
                      CARD_SIZE_SMALL && styles.small
                    )}
                  >
                    {description}
                  </span>
                </h3>
                <div
                  className={clsx(
                    styles.time,
                    onComplete &&
                      box.className === "stacked" &&
                      styles.timeWithComplete
                  )}
                  hidden={allDay}
                >
                  <time
                    className={styles.timeStart}
                    dateTime={startDate.toISOString()}
                  >
                    {formatTime(startDate)}
                  </time>
                  <time
                    className={styles.timeEnd}
                    dateTime={endDate.toISOString()}
                  >
                    {/* Hairspaces add a smaller space around the en-dash. It should be noted that
                  the dash is included within the second <time> to enable it to be kept as
                  a single line in the CSS, but the dateTime attribute means that semantically
                  it is still just the end date. */}
                    &#8202;&ndash;&#8202;
                    {formatTime(endDate)}
                  </time>
                </div>
              </div>

              {/* Place the resize handle on the last box only */}
              {resizable && i === boxes.length - 1 && (
                <div
                  className={styles.resizeHandle}
                  data-resize-handle={true}
                />
              )}

              {/* User should be able to remove scheduled Actions from the calendar */}
              {resizable && (
                <RemoveFromCalendarButton
                  className={clsx(
                    CARD_SIZE_XMALL && styles.xsmall,
                    CARD_SIZE_SMALL && styles.small
                  )}
                  eventId={eventId}
                  onRemoveFromCalendar={onRemoveFromCalendar}
                />
              )}

              {/*
              Place the completion checkbox at the bottom of boxes.
              Cannot nest buttons within each other, so the aria role
              is set to a button with Click and KeyDown events to mimic
              native keyboard:
              https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
              */}
              {onComplete && (
                <CompletionCheckbox
                  className={clsx(
                    styles.completionButton,
                    CARD_SIZE_XMALL && styles.xsmall
                  )}
                  ringClassName={clsx(styles.completionRing)}
                  tag="div"
                  tabIndex="0"
                  onClick={(e) => {
                    e.stopPropagation();
                    onComplete(!completed);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      onComplete(!completed);
                    }
                  }}
                  role="button"
                  completed={completed}
                  categoryColor
                />
              )}
            </div>
          ))}
        </EventCardTag>
      </>
    );
  }
);

EventCard.displayName = "EventCard";

export default EventCard;
