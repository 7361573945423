/**
 * Round up to the nearest interval, defaults to one hour interval
 *
 * Next 5 minutes:
 * timeRoundUp(5 * 60 * 1000)
 *
 * Next 30 minutes:
 * timeRoundUp(30 * 60 * 1000)
 *
 * @export
 * @param {number} intervalMilliseconds
 * @param {Date} [datetime=new Date()]
 * @return {Date}
 */
export function timeRoundUp(
  intervalMilliseconds = 60 * 60 * 1000,
  datetime = new Date()
) {
  if (!intervalMilliseconds || isNaN(intervalMilliseconds))
    throw new Error("'intervalMilliseconds' must be a number.");

  const modInterval = datetime.getTime() % intervalMilliseconds;

  if (modInterval === 0) return datetime;
  let delta = datetime.getTime() - modInterval;
  delta += intervalMilliseconds;
  return new Date(delta);
}
