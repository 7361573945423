// TODO: Add docs
export function replaceMentions(actionDescription, mentionReplaceCallback) {
  // The following sanitation ensures that no complete HTML tags can be
  // maliciously injected in actionDescription. It works by replacing, all
  // tag openings with entities, except for '<@' that is the opening of
  // the mention.
  const sanitized = actionDescription.replace(/<(?!@)/g, "&lt;");
  return sanitized.replace(/<@(.+?)>/g, (match, personId) =>
    mentionReplaceCallback(personId)
  );
}
