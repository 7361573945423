import React from "react";
import clsx from "clsx";

import Head from "../Head";

import styles from "./Page.module.scss";

export function PageMain({
  className,
  children,
  smoothScroll = true,
  ...props
}) {
  return (
    <main
      className={clsx(
        className,
        styles.main,
        smoothScroll && styles.mainSmoothScroll
      )}
      {...props}
    >
      {children}
    </main>
  );
}

function Page({ className, children, pageTitle, noNav, sidebarNav, ...props }) {
  return (
    <div
      className={clsx(
        className,
        styles.page,
        noNav && styles.pageNoNav,
        sidebarNav && styles.sidebarNav
      )}
      {...props}
    >
      <Head title={pageTitle} />

      {children}
    </div>
  );
}

export default Page;
