import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { mapById } from "src/utils/mapById.js";

import {
  PROJECT_ACTIVE,
  PROJECT_HIDDEN,
  TYPE_LABELS,
  PROJECT,
} from "../services/DbService/constants.js";

import { watchProjects } from "../services/DbService/projects.js";

import { AuthContext } from "./AuthContext";

const ProjectsContext = createContext();

export function ProjectsContextProvider({ children }) {
  const { user } = useContext(AuthContext);

  const [activeProjects, setActiveProjects] = useState(null);
  const [hiddenProjects, setHiddenProjects] = useState(null);

  useEffect(() => {
    if (!user) return;
    return watchProjects(PROJECT_ACTIVE, setActiveProjects);
  }, [user]);

  useEffect(() => {
    if (!user) return;
    return watchProjects(PROJECT_HIDDEN, setHiddenProjects);
  }, [user]);

  const value = useMemo(() => {
    const projectsById = mapById([
      ...(activeProjects || []),
      ...(hiddenProjects || []),
    ]);

    return {
      activeProjects,
      hiddenProjects,
      projectsById,
    };
  }, [activeProjects, hiddenProjects]);

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
}

export function useProjects() {
  const { activeProjects, hiddenProjects } = useContext(ProjectsContext);
  return { activeProjects, hiddenProjects };
}

export function useProjectName(projectId, labelPrefix) {
  const { projectsById } = useContext(ProjectsContext);

  const name = projectsById?.[projectId]?.name;

  if (!labelPrefix) return name;

  return `${TYPE_LABELS[PROJECT].title}: ${name || ""}`;
}
