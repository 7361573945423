import isUUID from "validator/es/lib/isUUID";

import { replaceMentions } from "src/utils/replaceMentions";
import { mapById } from "src/utils/mapById";

import FirestoreRollingBatch from "./FirestoreRollingBatch";

import {
  ACTIVE,
  COMPLETED,
  SNOOZED,
  ACTION_DESCRIPTION_MAX_LENGTH,
  PROJECT,
  CATEGORY,
  CAPTURE_LIST,
  BLOCK,
  UNCATEGORIZED_ID,
  ALL_CATEGORY_ID,
  SNOOZED_TO_WEEKS_MAP,
  SNOOZED_TO_WEEK,
  SNOOZED_TO_MONTH,
  SNOOZED_UNTIL_DATE,
  SNOOZED_TO_SOMETIME,
  THIS_WEEK,
  ACTION,
} from "./constants";
import {
  firestore,
  collection,
  document,
  ParameterError,
  generateId,
  CacheDelayer,
  getParentInfo,
  getActionParentInfo,
  updateLegacyOrder,
  calculateSnoozedToString,
  calculateSnoozedToDate,
  composeUnsubscribers,
} from "./general";
import {
  tickActionsCompleted,
  tickActionsCompletedStarred,
  tickActionsCompletedLeveraged,
} from "./user";
import { deleteEvent, createEventHelper, Event } from "./events";
import { Block } from "./blocks";
import { getProject } from "./projects";
import { trackEvent } from "../AnalyticsService";

export class Action {
  static fromFirestore(snapshot = {}, options) {
    const data = typeof snapshot?.data === "function" && snapshot.data(options);

    if (!data) return;

    return Object.assign(new Action(), {
      id: snapshot.id,
      categoryId: data.parent_category_id,
      blockId: data.parent_block_id || null,
      projectId: data.parent_project_id || null,
      description: data.description,
      // Normalises the time to either a number (in seconds) or null
      duration: parseInt(data.duration, 10) || null,
      starred: data.is_starred,
      unnecessary: data.is_unnecessary,
      state: data.state,
      mentionsPeopleIds: data.mentions,
      leveragedPersonId: data.leveraged_to,
      fromEmail: data.is_email_captured,
      emailBody: data.email_body,
      snoozedTo: data.snoozed_to ? new Date(data.snoozed_to) : null,
      completionDate:
        data.date_of_completion && new Date(data.date_of_completion),
      starringDate: data.date_of_starring && new Date(data.date_of_starring),
      // This field has been added as a workaround for a bug in SortableJs. See
      // ChildrenList and ActionsList for more details
      __type: ACTION,
    });
  }
}

export function fakeActionSnapshot(snapshotData) {
  return {
    id: generateId(),
    data: () => ({
      parent_category_id: "00000000-0000-0000-0000-000000000000",
      description: "Fake action",
      mentions: [],
      state: "active",
      ...(snapshotData || {}),
    }),
  };
}

export function fakeAction(snapshotData) {
  const snapshot = fakeActionSnapshot(snapshotData);
  return Action.fromFirestore(snapshot);
}

export async function getAction(actionId) {
  const docSnapshot = await document(`actions/${actionId}`).get();
  return Action.fromFirestore(docSnapshot);
}

export async function getActions(parentType, parentId) {
  if (parentType !== BLOCK)
    throw new ParameterError(
      { parentType },
      `Only "${BLOCK}" is current supported.`
    );

  const snapshot = await collection("actions")
    .where("parent_block_id", "==", parentId)
    .get();

  return snapshot.docs.map(Action.fromFirestore);
}

export function watchAction(actionId, callback) {
  return document(`actions/${actionId}`).onSnapshot((snapshot) => {
    callback(Action.fromFirestore(snapshot));
  });
}

export function watchCompletedActions(parentId, parentType, limit, callback) {
  if ((parentType === CATEGORY || parentType === PROJECT) && !limit) {
    // Completed actions in categories can be many thousands
    throw new ParameterError(
      { limit },
      "limit required when querying completed actions in categories"
    );
  }

  const { parentIdField } = getParentInfo(parentId, parentType);
  const cacheDelayer = new CacheDelayer();

  let query = collection("actions")
    .where("state", "==", COMPLETED)
    .where(parentIdField, "==", parentId);
  if (parentType === CATEGORY || parentType === PROJECT) {
    query = query.where("parent_block_id", "==", null);
  }
  query = query.orderBy("date_of_completion", "desc");
  if (limit) {
    // The extra action is used only to peek above the limit to see if there
    // are more actions
    query = query.limit(limit + 1);
  }

  const unsubscribe = query.onSnapshot((snapshot) => {
    const actions = snapshot.docs.map(Action.fromFirestore);

    let hasMore = false;
    if (limit) {
      if (actions.length > limit) {
        hasMore = true;
        // Skip this extra action that we used only to peek above the limit
        actions.pop();
      }
    }
    cacheDelayer.delayIfCached(() => callback(actions, hasMore), snapshot);
  });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  };
}

export async function createAction(
  description,
  categoryId,
  blockId,
  projectId,
  eventStartDate,
  eventDuration,
  starred = false,
  leveragedPersonId = null,
  state = ACTIVE,
  snoozeOptions = {}
) {
  if (!isUUID(categoryId))
    throw new ParameterError({ categoryId }, "not a valid UUID");
  if (blockId !== null && !isUUID(blockId))
    throw new ParameterError({ blockId }, 'must be "null" or a valid UUID');

  if (typeof description !== "string")
    throw new ParameterError({ description }, "not a string");
  const descriptionTrim = description.trim();
  if (descriptionTrim === "")
    throw new ParameterError({ description }, "empty after trim");

  const mentions = new Set(); // Set gets rid of duplicates automatically
  const descriptionWithoutMentions = replaceMentions(
    descriptionTrim,
    (personId) => {
      mentions.add(personId);
      return "";
    }
  );
  if (descriptionWithoutMentions.length > ACTION_DESCRIPTION_MAX_LENGTH)
    throw new ParameterError(
      { description },
      `length without mentions must be <= ${ACTION_DESCRIPTION_MAX_LENGTH}`
    );

  if (eventStartDate && eventDuration === null)
    throw new ParameterError(
      { eventDuration },
      'must not be "null" when there is an eventStartDate'
    );
  if (eventDuration && eventStartDate === null)
    throw new ParameterError(
      { eventStartDate },
      'must not be "null" when there is an eventDuration'
    );

  if (projectId !== null && !isUUID(projectId))
    throw new ParameterError({ projectId }, 'must be valid UUID or "null"');

  if (projectId) {
    const project = await getProject(projectId);
    if (!project)
      throw new ParameterError({ projectId }, "project does not exist");
  }

  if (starred !== undefined && typeof starred !== "boolean") {
    throw new ParameterError({ starred }, "must be a boolean");
  }

  if (leveragedPersonId !== null && !isUUID(leveragedPersonId))
    throw new ParameterError(
      { leveragedPersonId },
      "must be null or a valid UUID"
    );

  if (state && ![ACTIVE, SNOOZED, COMPLETED].includes(state))
    throw new ParameterError(
      { state },
      `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`
    );

  const { snoozedTo, snoozedToString } = snoozeOptions;

  if (snoozedTo && state !== SNOOZED)
    throw new ParameterError(
      { snoozedTo },
      `cannot be used with any state other than "${SNOOZED}".`
    );

  if (snoozedToString !== undefined) {
    if (snoozedTo !== undefined)
      throw new ParameterError(
        { snoozedToString },
        `can't use both snoozedToString and snoozedTo params at the same time.`
      );

    const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
    if (snoozedToString !== null && !snoozedToOptions.includes(snoozedToString))
      throw new ParameterError(
        { snoozedToString },
        `must be ${snoozedToOptions.join(", ")} or null`
      );
    if (snoozedToString && state !== SNOOZED)
      throw new ParameterError(
        { snoozedToString },
        `cannot be used with any state other than "${SNOOZED}".`
      );
    if (state === SNOOZED && snoozedToString === null)
      throw new ParameterError(
        { snoozedToString },
        `cannot be null if state is "${SNOOZED}"`
      );
  }

  const batch = firestore().batch();

  const actionId = generateId();

  const snoozedValue =
    state === SNOOZED
      ? snoozedTo || calculateSnoozedToDate(snoozedToString, true)
      : null;

  const newAction = {
    id: actionId,
    description: descriptionTrim,
    duration: eventDuration,
    is_starred: starred,
    is_unnecessary: false,
    parent_category_id: categoryId,
    parent_block_id: blockId,
    parent_project_id: projectId,
    date_of_completion: null,
    date_of_starring: starred ? new Date().toISOString() : null,
    state,
    snoozed_to: snoozedValue,
    mentions: [...mentions],
    leveraged_to: leveragedPersonId,
  };

  batch.set(document(`actions/${actionId}`), newAction);

  const { parentId, parentType, parentDocument, orderField } =
    getActionParentInfo(categoryId, blockId);
  batch.update(parentDocument, {
    [orderField]: firestore.FieldValue.arrayUnion(actionId),
  });

  let newEvent;
  if (eventStartDate) {
    newEvent = createEventHelper(
      batch,
      eventStartDate,
      eventDuration,
      categoryId,
      blockId,
      [actionId]
    );
  }

  batch.commit();

  updateLegacyOrder(parentId, parentType);

  let trackingEventName = "User created an Action";
  const trackingProperties = {
    id: actionId,
    categoryId,
    blockId,
    descriptionLength: descriptionWithoutMentions.length,
    mentionsCount: mentions.length,
  };

  if (newEvent) {
    trackingEventName += " with an Event";
    trackingProperties.eventId = newEvent.id;
  }

  trackEvent(trackingEventName, trackingProperties);
}

/**
 * @param {(Action|string)} actionOrId The Action object to be used for the
 *                                     the dirty checks, or alternatively just
 *                                     its id (in this case a database read will
 *                                     be issued to get the full object)
 * @param {Object} updates Map of the changes to make
 * @param {Object=} externalBatch Optional batch where to add all the updates
 */
export async function updateAction(actionOrId, updates, externalBatch) {
  const {
    categoryId,
    blockId,
    projectId,
    description,
    duration,
    starred,
    state,
    unnecessary,
    snoozedTo,
    snoozedToString,
    leveragedPersonId,
  } = updates;

  const batch = externalBatch || new FirestoreRollingBatch();

  let actionId, action;
  // it would be better to check for "instanceof Action", but since SortableJs
  // sometimes strips out the prototype better to not rely on that
  if (typeof actionOrId === "object") {
    action = actionOrId;
    actionId = action.id;
  } else {
    actionId = actionOrId;
    action = await getAction(actionId);
  }

  const actionDocument = document(`actions/${actionId}`);
  const {
    categoryId: oldCategoryId,
    blockId: oldBlockId,
    projectId: oldProjectId,
    state: oldState,
    snoozedTo: oldSnoozedTo,
    starred: oldStarred,
    leveragedPersonId: oldLeveragedPersonId,
  } = action;

  const finalCategoryId = categoryId || oldCategoryId;
  const finalBlockId = blockId !== undefined ? blockId : oldBlockId;
  const finalProjectId = projectId !== undefined ? projectId : oldProjectId;
  const finalState = state || oldState;
  const finalStarred = starred !== undefined ? starred : oldStarred;
  const finalLeveragedPersonId =
    leveragedPersonId !== undefined ? leveragedPersonId : oldLeveragedPersonId;

  const categoryChanges =
    categoryId !== undefined && categoryId !== oldCategoryId;
  const blockChanges = blockId !== undefined && blockId !== oldBlockId;
  const projectChanges = projectId !== undefined && projectId !== oldProjectId;
  const stateChanges = state !== undefined && state !== oldState;

  if (description !== undefined) {
    if (typeof description !== "string")
      throw new ParameterError({ description }, "not a string");
    const descriptionTrim = description.trim();
    if (descriptionTrim === "")
      throw new ParameterError({ description }, "empty after trim");

    const mentions = new Set(); // Set gets rid of duplicates automatically
    const descriptionWithoutMentions = replaceMentions(
      descriptionTrim,
      (personId) => {
        mentions.add(personId);
        return "";
      }
    );
    if (descriptionWithoutMentions.length > ACTION_DESCRIPTION_MAX_LENGTH)
      throw new ParameterError(
        { description },
        `length without mentions must be <= ${ACTION_DESCRIPTION_MAX_LENGTH}`
      );

    batch.update(actionDocument, {
      description: descriptionTrim,
      mentions: [...mentions],
    });
  }

  if (leveragedPersonId !== undefined) {
    if (leveragedPersonId !== null && !isUUID(leveragedPersonId))
      throw new ParameterError(
        { leveragedPersonId },
        "must be null or a valid UUID"
      );
    batch.update(actionDocument, { leveraged_to: leveragedPersonId });
  }

  if (duration !== undefined) {
    if (duration !== null && typeof duration !== "number")
      throw new ParameterError({ duration }, "can be only null or a number");

    batch.update(actionDocument, { duration });
  }

  if (starred !== undefined) {
    if (typeof starred !== "boolean")
      throw new ParameterError({ starred }, "must be a boolean");

    batch.update(actionDocument, {
      is_starred: starred,
      date_of_starring: starred ? new Date().toISOString() : null,
    });
  }

  if (snoozedTo !== undefined) {
    if (finalState !== SNOOZED && snoozedTo !== null)
      throw new ParameterError(
        { snoozedTo },
        `cannot be used with any state other than "${SNOOZED}".`
      );

    batch.update(actionDocument, { snoozed_to: snoozedTo });
  }

  if (snoozedToString !== undefined) {
    if (snoozedTo !== undefined)
      throw new ParameterError(
        { snoozedToString },
        `can't use both snoozedToString and snoozedTo params at the same time.`
      );
    const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
    if (snoozedToString !== null && !snoozedToOptions.includes(snoozedToString))
      throw new ParameterError(
        { snoozedToString },
        `must be ${snoozedToOptions.join(", ")} or null`
      );
    if (snoozedToString && finalState !== SNOOZED)
      throw new ParameterError(
        { snoozedToString },
        `cannot be used with any state other than "${SNOOZED}".`
      );
    if (finalState === SNOOZED && snoozedToString === null)
      throw new ParameterError(
        { snoozedToString },
        `cannot be null if state is "${SNOOZED}"`
      );

    const oldSnoozedToString = calculateSnoozedToString(oldSnoozedTo);
    const snoozedToDate = calculateSnoozedToDate(snoozedToString, true);
    if (
      stateChanges ||
      snoozedToString !== oldSnoozedToString ||
      snoozedToDate !== oldSnoozedTo
    ) {
      batch.update(actionDocument, {
        snoozed_to: snoozedToDate,
      });
    }
  }

  if (stateChanges) {
    if (![ACTIVE, SNOOZED, COMPLETED].includes(state))
      throw new ParameterError(
        { state },
        `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`
      );

    batch.update(actionDocument, {
      state,
      date_of_completion: state === COMPLETED ? new Date().toISOString() : null,
      // is_unnecessary is set to a default false value for all states, but
      // later in this function there is a block that overrides it if the
      // *unecessary* parameter is specified
      is_unnecessary: false,
    });
    if (state !== SNOOZED) {
      batch.update(actionDocument, { snoozed_to: null });
    }
    if (state !== COMPLETED) {
      batch.update(actionDocument, { is_unnecessary: false });
    }

    if (oldState === COMPLETED || state === COMPLETED) {
      tickActionsCompleted(batch, state === COMPLETED ? +1 : -1);
    }
  }

  // Note: the block managing the *unecessary* param must appear AFTER the
  // block managing the *state*, because when setting the state is_unnecessary
  // is set to a default value, that then gets overriden here if needed
  if (unnecessary !== undefined) {
    if (typeof unnecessary !== "boolean")
      throw new ParameterError({ unnecessary }, "must be a boolean");
    if (finalState !== COMPLETED)
      throw new ParameterError(
        { unnecessary },
        `cannot set unnecessary when state is not COMPLETED`
      );
    batch.update(actionDocument, { is_unnecessary: unnecessary });
  }

  const oldCompletedStarred = oldStarred && oldState === COMPLETED;
  const finalCompletedStarred = finalStarred && finalState === COMPLETED;
  if (finalCompletedStarred !== oldCompletedStarred) {
    tickActionsCompletedStarred(batch, finalCompletedStarred ? +1 : -1);
  }

  const oldCompletedLeveraged = oldLeveragedPersonId && oldState === COMPLETED;
  const finalCompletedLeveraged =
    finalLeveragedPersonId && finalState === COMPLETED;
  if (finalCompletedLeveraged !== oldCompletedLeveraged) {
    tickActionsCompletedLeveraged(batch, finalCompletedLeveraged ? +1 : -1);
  }

  if (categoryChanges) {
    if (!isUUID(categoryId))
      throw new ParameterError({ categoryId }, "must be a valid UUID");
    batch.update(actionDocument, { parent_category_id: categoryId });
  }

  if (blockChanges) {
    if (blockId !== null && !isUUID(blockId))
      throw new ParameterError({ blockId }, "must be null or a valid UUID");
    batch.update(actionDocument, { parent_block_id: blockId });
  }

  if (projectChanges) {
    if (projectId !== null && !isUUID(projectId))
      throw new ParameterError({ projectId }, "must be null or a valid UUID");
    batch.update(actionDocument, { parent_project_id: projectId });
  }

  const { parentDocument: oldParentDocument, orderField: oldOrderField } =
    getActionParentInfo(oldCategoryId, oldBlockId);
  const { parentType: oldParentType } = getActionParentInfo(
    oldCategoryId,
    oldBlockId
  );
  const {
    parentDocument: finalParentDocument,
    parentType: finalParentType,
    orderField: finalOrderField,
  } = getActionParentInfo(finalCategoryId, finalBlockId);
  const parentChanges = !oldParentDocument.isEqual(finalParentDocument);
  const oldInThisWeek =
    oldState === ACTIVE &&
    oldParentType === CATEGORY &&
    oldCategoryId !== UNCATEGORIZED_ID;
  const finalInThisWeek =
    finalState === ACTIVE &&
    finalParentType === CATEGORY &&
    finalCategoryId !== UNCATEGORIZED_ID;

  // Update ordering lists
  if (parentChanges || projectChanges || stateChanges) {
    if (parentChanges) {
      batch.update(oldParentDocument, {
        [oldOrderField]: firestore.FieldValue.arrayRemove(actionId),
      });
    }
    if (projectChanges && oldProjectId) {
      batch.update(document(`projects/${oldProjectId}`), {
        active_children_ordered_ids: firestore.FieldValue.arrayRemove(actionId),
      });
    }

    if (parentChanges || stateChanges) {
      const parentOrderListUpdate =
        finalState === ACTIVE
          ? firestore.FieldValue.arrayUnion(actionId)
          : firestore.FieldValue.arrayRemove(actionId);
      batch.update(finalParentDocument, {
        [finalOrderField]: parentOrderListUpdate,
      });
    }

    if (finalInThisWeek !== oldInThisWeek) {
      batch.update(document(), {
        this_week_ordered_ids: finalInThisWeek
          ? firestore.FieldValue.arrayUnion(actionId)
          : firestore.FieldValue.arrayRemove(actionId),
      });
    }

    if (
      finalProjectId &&
      (projectChanges || finalInThisWeek !== oldInThisWeek)
    ) {
      batch.update(document(`projects/${finalProjectId}`), {
        // If it's visilble in This Week (it's active, parent is a category and
        // it's not in the capture list) then it's visible in the project too
        active_children_ordered_ids: finalInThisWeek
          ? firestore.FieldValue.arrayUnion(actionId)
          : firestore.FieldValue.arrayRemove(actionId),
      });
    }

    setTimeout(() => {
      // The calls to updateLegacyOrder must happen after the batch.commit(),
      // but since this function does not end with a certain commit() call, they
      // are postponed to a later time. Since these functions are just aligning
      // data for old versions is ok to call them deferred
      updateLegacyOrder(finalCategoryId, CATEGORY);
      if (finalBlockId) updateLegacyOrder(finalBlockId, BLOCK);

      if (categoryChanges) updateLegacyOrder(oldCategoryId, CATEGORY);
      if (blockChanges && oldBlockId) updateLegacyOrder(oldBlockId, BLOCK);
    }, 1000);
  }

  if (parentChanges) {
    const eventsSnap = await collection("events")
      .where("actions_ids", "array-contains", actionId)
      .get();
    eventsSnap.docs.forEach((eventSnap) => {
      const { actionsIds } = Event.fromFirestore(eventSnap);
      if (actionsIds.length > 1) {
        // The event contained more than one action, and they had all the same
        // parent (it's mandatory), but now this action has not the same
        // parent of its peers, so it cannot be part of the group anymore
        batch.update(eventSnap.ref, {
          actions_ids: actionsIds.filter((id) => id !== actionId),
        });
      } else {
        // Since the event is made of this action alone, let's change its
        // parent too
        batch.update(eventSnap.ref, {
          category_id: finalCategoryId,
          block_id: finalBlockId,
        });
      }
    });
  }

  if (!externalBatch) batch.commit();
}

export async function actionHasEvents(actionId) {
  const eventsSnapshot = await collection("events")
    .where("actions_ids", "array-contains", actionId)
    .limit(1)
    .get();

  return eventsSnapshot.size > 0;
}

export async function deleteAction(actionId) {
  const batch = firestore().batch();
  const action = await getAction(actionId);
  await deleteActionHelper(batch, action);
  batch.commit();
  // updateLegacyOrder has to be called AFTER the changes have been committed
  const { parentId, parentType } = getActionParentInfo(
    action.categoryId,
    action.blockId
  );
  updateLegacyOrder(parentId, parentType);
}

export async function deleteActionHelper(batch, action, updateEvents = true) {
  const {
    id: actionId,
    categoryId,
    blockId,
    leveragedPersonId,
    starred,
    state,
  } = action;

  batch.delete(document(`actions/${actionId}`));
  const { parentDocument, orderField } = getActionParentInfo(
    categoryId,
    blockId
  );
  batch.update(parentDocument, {
    [orderField]: firestore.FieldValue.arrayRemove(actionId),
  });

  if (state === COMPLETED) {
    tickActionsCompleted(batch, -1);
    if (starred) tickActionsCompletedStarred(batch, -1);
    if (leveragedPersonId) tickActionsCompletedLeveraged(batch, -1);
  }

  if (updateEvents) {
    const eventsSnap = await collection("events")
      .where("actions_ids", "array-contains", actionId)
      .get();
    eventsSnap.docs.forEach((eventSnap) => {
      const { id: eventId, actionsIds } = Event.fromFirestore(eventSnap);
      if (actionsIds.length > 1) {
        // Remove this action from the event
        batch.update(eventSnap.ref, {
          actions_ids: actionsIds.filter((id) => id !== actionId),
        });
      } else {
        // There is only one action in this event, and it's this one we are
        // deleting: delete the event too.
        deleteEvent(eventId, batch);
      }
    });
  }
}

export async function moveAction(
  actionId,
  newParentId,
  newParentType,
  newState,
  newOrderedIds = null,
  newSnoozedToString = null
) {
  if (newParentType === THIS_WEEK && newState !== ACTIVE)
    throw new ParameterError(
      { newState },
      `must be "${ACTIVE}" when newParentType === THIS_WEEK`
    );

  if (newOrderedIds !== null && !Array.isArray(newOrderedIds))
    throw new ParameterError({ newOrderedIds }, "not an array");

  const updates = { state: newState };
  if (newState === SNOOZED && newSnoozedToString)
    updates.snoozedToString = newSnoozedToString;

  switch (newParentType) {
    case THIS_WEEK:
      // For an action to appear in the "This Week" list it must be removed from
      // its parent block, if it has one
      updates.blockId = null;
      break;
    case CATEGORY:
    case CAPTURE_LIST:
      updates.categoryId = newParentId;
      updates.blockId = null;
      if (newParentId === UNCATEGORIZED_ID) {
        // No projects for actions in the capture list
        updates.projectId = null;
      }
      break;
    case BLOCK:
      const blockSnapshot = await document(`blocks/${newParentId}`).get();
      const block = Block.fromFirestore(blockSnapshot);
      updates.blockId = newParentId;
      updates.categoryId = block.categoryId;
      updates.projectId = block.projectId;
      break;
    case PROJECT:
      updates.projectId = newParentId;
      // When the user moves an action to a project, if the action was inside
      // a block it gets exctracted from it and becomes a loose action in the
      // parent category. This to comply with the rule that actions in blocks
      // can't directly belong to a project, and to make the drag and drop feel
      // more natural (you can drag an action from a block to a project, and
      // you see it getting removed from the block and added to the project)
      updates.blockId = null;
      break;
    default:
      throw new Error(`Unexpected newParentType value: ${newParentType}`);
  }

  const batch = new FirestoreRollingBatch();

  await updateAction(actionId, updates, batch);

  if (newState === ACTIVE && newOrderedIds) {
    const { parentDocument, orderField } = getParentInfo(
      newParentId,
      newParentType
    );
    batch.update(parentDocument, { [orderField]: newOrderedIds });
  }

  batch.commit();
}

/**
 * @param  {Array} actions an array of Action instances or action snapshots
 * @param  {Object} eventsMapByActionId an objec of Event instances which
 *                  can be optionally used to compute the number of past
 *                  due events. The eventsMapByActionId object is indexed by
 *                  by action ids.
 * @returns {Object} the sum of the actions’ total and starred times in seconds,
 *                   and the number of past due events
 */
export function computeActionsCounters(actions, eventsMapByActionId = null) {
  let total = 0;
  let starred = 0;
  let numberOfActions = 0;
  let numberOfStars = 0;
  let numberOfPastDueEvents = 0;

  for (const action of actions) {
    const actionClassInstance =
      action instanceof Action ? action : Action.fromFirestore(action);

    const {
      duration,
      starred: isStarred,
      state,
      snoozedTo,
    } = actionClassInstance;

    // do not count indefinitely snoozed actions
    if (state === SNOOZED && !snoozedTo) continue;

    // verify if it is a past due start date event
    const startDate = eventsMapByActionId?.[action.id]?.startDate;

    if (startDate && startDate < new Date()) {
      numberOfPastDueEvents++;
    }

    total += duration || 0;
    if (isStarred) {
      starred += duration;
      numberOfStars++;
    }

    numberOfActions++;
  }

  return {
    total,
    starred,
    numberOfActions,
    numberOfStars,
    numberOfPastDueEvents,
  };
}

export function watchActiveActionsCounters(parentId, parentType, callback) {
  if (parentType === CATEGORY && parentId === ALL_CATEGORY_ID)
    throw new ParameterError(
      { parentId },
      "ALL_CATEGORY_ID is not supported for active actions"
    );

  // Active actions within a block don't need the block collection to be
  // queried so a direct look up to the actions collection is made instead
  if (parentType === BLOCK) {
    let blockActions, eventsMapByActionId;

    const blockActionsQuery = collection("actions")
      .where("state", "==", ACTIVE)
      .where("parent_block_id", "==", parentId);

    const blockEventsQuery = collection("events").where(
      "block_id",
      "==",
      parentId
    );

    function handleBlockActionsCounters() {
      callback(computeActionsCounters(blockActions || [], eventsMapByActionId));
    }

    return composeUnsubscribers(
      blockActionsQuery.onSnapshot((snapshot) => {
        blockActions = snapshot.docs.map(Action.fromFirestore);
        handleBlockActionsCounters();
      }),
      blockEventsQuery.onSnapshot((snapshot) => {
        const blockEvents = snapshot.docs.map(Event.fromFirestore);

        eventsMapByActionId = blockEvents.reduce((result, event) => {
          event.actionsIds.forEach((actionId) => {
            result[actionId] = event;
          });

          return result;
        }, {});

        handleBlockActionsCounters();
      })
    );
  }

  let actions, blocksById;

  const actionsQuery = collection("actions")
    .where("state", "==", ACTIVE)
    .where(`parent_${parentType}_id`, "==", parentId);

  // A snoozed block can have active actions within it so we need to get the
  // active blocks and filter out any actions not present within those blocks
  const blocksQuery = collection("blocks")
    .where("state", "==", ACTIVE)
    .where(`parent_${parentType}_id`, "==", parentId);

  function handleChange() {
    if (actions === undefined || blocksById === undefined) return;

    const filteredActions = actions.filter(({ blockId }) => {
      if (blockId) return !!blocksById[blockId];

      const isCaptureList =
        parentType === CATEGORY && parentId === UNCATEGORIZED_ID;
      return !isCaptureList;
    });

    callback(computeActionsCounters(filteredActions));
  }

  return composeUnsubscribers(
    blocksQuery.onSnapshot((snapshot) => {
      const blocks = snapshot.docs.map(Block.fromFirestore);
      blocksById = mapById(blocks);
      handleChange();
    }),
    actionsQuery.onSnapshot((snapshot) => {
      actions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    })
  );
}

export function watchSnoozedActionsCounters(parentId, parentType, callback) {
  if (![CATEGORY, BLOCK].includes(parentType))
    throw new ParameterError(
      { parentType },
      '"category" is the only parentType supported currently'
    );
  if (parentType === CATEGORY && parentId === ALL_CATEGORY_ID)
    throw new ParameterError(
      { parentId },
      "ALL_CATEGORY_ID is not supported for snoozed actions"
    );

  let activeActions, snoozedActions, blocksById;

  const categoryId = parentId;
  // Snoozed actions associated with a category qualify with one of two criteria:
  // 1. The action is ACTIVE and is a child of a block that is SNOOZED
  //    N.B. an action cannot be SNOOZED in a block
  // 2. The action is SNOOZED and is not a child of a block
  // To correctly ascertain the "snoozed" actions of a category we must watch
  // all ACTIVE and SNOOZED actions and then filter them based on the above.

  const activeActionsQuery = collection("actions")
    .where("state", "==", ACTIVE)
    .where("parent_category_id", "==", categoryId)
    .where("parent_block_id", "!=", null);

  let snoozedActionsQuery = collection("actions").where("state", "==", SNOOZED);

  if (parentType === BLOCK) {
    snoozedActionsQuery = snoozedActionsQuery.where(
      "parent_block_id",
      "==",
      parentId
    );
  } else {
    snoozedActionsQuery = snoozedActionsQuery.where(
      "parent_category_id",
      "==",
      categoryId
    );
  }

  const blocksQuery = collection("blocks")
    .where("state", "==", SNOOZED)
    .where("parent_category_id", "==", categoryId);

  function handleChange() {
    if (
      activeActions === undefined ||
      snoozedActions === undefined ||
      blocksById === undefined
    )
      return;

    const actionsMap = {
      [SNOOZED_TO_WEEK]: [],
      [SNOOZED_TO_MONTH]: [],
      [SNOOZED_UNTIL_DATE]: [],
      [SNOOZED_TO_SOMETIME]: [],
    };

    for (const action of snoozedActions) {
      const snoozedToString = calculateSnoozedToString(action.snoozedTo);
      actionsMap[snoozedToString].push(action);
    }

    for (const action of activeActions) {
      const parentBlock = blocksById[action.blockId];
      if (!parentBlock) continue; // Filter out any blocks that aren't snoozed

      const snoozedToString = calculateSnoozedToString(parentBlock.snoozedTo);
      actionsMap[snoozedToString].push(action);
    }

    const counters = {};
    for (const [snoozedToString, actions] of Object.entries(actionsMap)) {
      counters[snoozedToString] = computeActionsCounters(actions);
    }

    callback(counters);
  }

  const unsubscribers = [
    blocksQuery.onSnapshot((snapshot) => {
      blocksById = mapById(snapshot.docs.map(Block.fromFirestore));
      handleChange();
    }),
    activeActionsQuery.onSnapshot((snapshot) => {
      activeActions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    }),
    snoozedActionsQuery.onSnapshot((snapshot) => {
      snoozedActions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    }),
  ];

  return composeUnsubscribers(unsubscribers);
}
